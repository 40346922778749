import React from "react";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import { toDate } from "./../../../helpers/Util";
import {
  searchGeneral,
  searchUserProfile,
  resetAction,
  crudReset
} from "../../../actions/actions";
import ExportButton from "../../../components/TableExportButton";
import ConfirmAlert from '../../../components/ConfirmAlert';
import SimpleSelect from '../../../components/SimpleSelect';
import SimpleTable from '../../../components/SimpleTable';
import Modal from "react-awesome-modal";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import ReportTable from "../../../components/ReportTable";
import StoreSearchSingle from "../../system/users/StoreSearchSingle";

class MemberReport extends React.PureComponent {
  timings = {
    "t1":"00:00-05:59",
    "t2":"06:00-10:29",
    "t3":"10:30-16:00",
    "t4":"16:01-23:59"
  }
  constructor(props) {
    super(props);
    this.state={
        showSearchDiv: false,
        storeid: "",
        storelabel: "",
        currency: "",
        status: "close",
        overalldate: [new Date("June 1, 2020 0:00:00"), new Date()],
        date_from: new Date("June 1, 2020 0:00:00"),
        date_till: new Date(),
        memberdatalist: [],
        memberlist: [],
        tablerowlist:[],
        time:["00:00-05:59","06:00-10:29","10:30-16:00","16:01-23:59"],
        timefrom:"",
        timeto:"",
        customoptions: "",
        tableCols: [
          {
            dataField: "memberid",
            text: "Member Id",
            sort: true
          },
          {
            dataField: "userid",
            text: "User Id",
            sort: true
          },
          {
            dataField: "firstname",
            text: "First Name",
            sort: true
          },
          {
            dataField: "lastname",
            text: "Last Name",
            sort: true
          },
          {
            dataField: "emailid",
            text: "Email",
            sort: true
          },
          {
            dataField: "mobileno",
            text: "Mobile",
            sort: true
          },
          {
            dataField: "signupby",
            text: "Sign Up By",
            sort: true
          },
          {
            dataField: "status",
            text: "Status",
            sort: true
          }],
        //errors
        storeiderror: "",
        overalldateerror: ""
    }
  }

 
  componentDidMount = () => {
    // this.props.dispatch(
    //   searchGeneral(
    //     "/sales/v1/store/search/fields",
    //     {
    //       body: {
    //         groupid: localStorage.getItem("storegroup_access")
    //       }
    //     },
    //     "SEARCH_CITY_BY_COUNTRY_SUCCESS",
    //     "SEARCH_CITY_BY_COUNTRY_FAILURE"
    //   )
    // );
  }

  componentDidUpdate = (prevProps) => {
    const { dataByCountry, memberReportData, memberByGroup } = this.props.queryData;
    const { memberReportData: prevmemberReportData, memberByGroup: prevmemberByGroup } = prevProps.queryData;
    const { storeid, storelabel } = this.state;
    if(dataByCountry.length > 0){
      this.setCurrency();
    }
    if(storeid === ""){
      if(dataByCountry.length > 0){
        this.setState({
          storeid: dataByCountry[0].storeid,
          storelabel: dataByCountry[0].title
        },()=>{
          this._validateAndSearch("ONLOAD");
        })
      }
    }
    if (prevmemberReportData !== memberReportData) {
        if(JSON.stringify(memberReportData)!=="{}" && memberReportData.sales !== null){
          this.updateSalesData();          
        }
        else{
          this.setState({
            memberdatalist: []
          })
        }
    }
    if(memberByGroup !== prevmemberByGroup){
      // if(memberByGroup.length > 0){
        this.setState({
          memberlist: memberByGroup
        })
      //}
    }
  }

  handlefromdateChange = value => {
    this.setState({
      date_from: value
    });
  };
  handletodateChange = value => {
    this.setState({
      date_till: value
    });
  };

  handleChange(time, name) {
    let timeformat = moment(time).format("HH:mm");
    if(timeformat !== "Invalid date"){
        this.setState({
            [name]: timeformat
        })
    }
    else {
        this.setState({
            [name]: ""
        })
    }   
  }

  textareaHandler = (val) => {
    const { time } = this.state;
    let filterdata = time.filter(item=>
        item !== val
    );
    this.setState({
        time: filterdata
    })
  }

  buttonHanlder = () => {
    const { timefrom, timeto, time } = this.state;
    if(timefrom < timeto && timefrom !== "" && timeto !==""){
      let timeset = [timefrom+"-"+timeto];
      this.setState(prevState=>({
        timefrom: "",
        timeto: "",
        time: [...prevState.time,...timeset]
      }))
    }
  }

  _renderSearchForm= () => {
    var contentareastyle = {
      display: 'inline-flex',
      flexFlow: 'wrap',
      border: '1px solid #dcdcdc',
      padding: '10px',
      overflowY: 'scroll',
      maxHeight: '100px',
      maxWidth: '450px'
    }
    const { time } = this.state;
    let intervals = [...new Set(time)];
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="fromDate" className="floatLeft">
              Date From
            </label>
            <DatePicker
              onChange={this.handlefromdateChange}
              value={this.state.date_from}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="Transaction Date From" className="floatLeft">
              Date To
            </label>
            <DatePicker
              onChange={this.handletodateChange}
              value={this.state.date_till}
            />
          </div>
        </div>
        <div className="row">       
         <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="search_value" className="floatLeft">
              Choose Store
            </label>
               {/* <TenantCode
                  value={this.state.storelabel}
                  setTenantCode={this._setTenant}
                /> */}
                <StoreSearchSingle value={this.state.storelabel}
                onSelectStore={this._onSelectStore}
                />
          </div>       
        </div>
        <div className="row">
          <div className="d-flex ml-3">
            <div>
              <label htmlFor="timefrom" className="floatLeft">
                Time From
              </label>
              <div>
                <TimePicker
                onClose={this.buttonHanlder}
                value={moment(this.state.timefrom, "HH:mm").isValid() ? moment(this.state.timefrom, "HH:mm") : ''} 
                onChange={(time) => this.handleChange(time, "timefrom")} 
                showSecond={false} />
              </div>
            </div>
            <div style={{margin:"20px 10px"}}>-</div>
            <div>
              <label htmlFor="timeto" className="floatLeft">
                Time Till
              </label>
              <div>
                <TimePicker 
                onClose={this.buttonHanlder}
                value={moment(this.state.timeto, "HH:mm").isValid() ? moment(this.state.timeto, "HH:mm") : ''} 
                onChange={(time) => this.handleChange(time, "timeto")} 
                showSecond={false} />
              </div>
            </div>
          </div>
        </div>      
        <div className="row">
            <div classNmae="col-lg-6 col-sm-12" style={{margin:"10px"}}>
              <div style={contentareastyle}>
                {intervals.map(datevalue => {
                  return (
                    <div className="text-content" key={datevalue.toString()}>
                      <input type="text" value={datevalue} className="inputfield" readOnly />
                      <button className="btn-primary btntext" type="button" onClick={() => this.textareaHandler(datevalue)}>x</button>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        <div className="row btn-container1 form-button">
          <div className="col-sm-12 col-lg-8">
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                this._validateAndSearch("SEARCH");
                this._closeSearchDiv();
              }}
            >
              Search
            </button>
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                this._closeSearchDiv();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  setCurrency = () => {
    const { dataByCountry } = this.props.queryData;
    const { storeid } = this.state
    if(dataByCountry.length > 0){
      let getcnobj = dataByCountry.filter(e=>e.storeid === storeid);
      if(getcnobj.length > 0) {
        this.setState({
          currency: getcnobj[0].basecur
        })
      }
    }
  }

  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2 });
  };

  _onSelectStore = (selectedStores) => {
    this._setTenant(selectedStores.value, selectedStores.label)
  }

 
  callFields = () => {
    const { status, storeid, overalldate, time, date_from, date_till} = this.state;
    //const daterange = dateRange(overalldate);
    const requestBody = {
      body: {
        storeid,
        date_from: toDate(date_from),
        date_till: toDate(date_till),
        time: time.toString(),
        status
      }
    }
    return requestBody;
  }

  callFetch = (fetchType) => {
    if(fetchType === "ONLOAD"){
      this.props.dispatch(
        searchGeneral(
          "pos/v1/report/member",
          this.callFields(),
          "SEARCH_MEMBERREPORTTIME_SUCCESS",
          "SEARCH_MEMBERREPORTTIME_FAILURE"
        )
      );
    }
    else{
      this.props.dispatch(
        searchGeneral(
          "pos/v1/report/member",
          this.callFields(),
          "SEARCH_MEMBERREPORTTIME_SUCCESS",
          "SEARCH_MEMBERREPORTTIME_FAILURE"
        )
      );
    }
  }

  _validateAndSearch = (type) => {
    if (this.validateForm() === true) {
       this.callFetch(type);
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };

  validateForm = () => {
    this._clearErrorMessages();
    const { storeid, overalldate } = this.state;
    let valid = true;
    if (storeid.trim() === "") {
        this.setState({ storeiderror: "Please select store id" });
        valid = false;
    }
    if (overalldate == null) {
        this.setState({ overalldateerror: "Please select date" });
        valid = false;
    }
    return valid;
  }

  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      storeiderror: "",
      overalldateerror: ""
    });
  };


  updateSalesData = () => {
    const { memberReportData } = this.props.queryData;
      if (memberReportData && memberReportData.sales && memberReportData.sales.length > 0) {
        let modifiedlist = this.createObject(memberReportData);
        if (modifiedlist.length > 0) {
          this.setState({
            memberdatalist: memberReportData.sales,
            tablerowlist: modifiedlist
          },()=>this.basedonCondition(this.state.customoptions))
        }
      }
  }


  basedonCondition = (value) => {
    const { memberReportData } = this.props.queryData;
    if (memberReportData.length > 0 || memberReportData.sales !== null) {
      if (value == 0) {
        this.setState({
          memberdatalist: memberReportData.sales
        })
      }
      if (value > 0) {
          const result = memberReportData.sales !== undefined ? memberReportData.sales.filter(o => o.totalcount > value) : []; 
          this.setState({
            memberdatalist: result
          })
      }
    } 
    if (value === "activeday") {
      this.props.dispatch(searchUserProfile({ body: { activeday: 30 } }))
    }
    if (value === "createdday") {
      this.props.dispatch(searchUserProfile({ body: { createdday: 30 } }))
    }
    if (value === "cartopenday") {
      this.props.dispatch(searchUserProfile({ body: { cartopenday: 30 } }))
    }
  }


  createObject = (obj) => {
    if(JSON.stringify(obj)!== "{}"){
      let results = [
        { id: "memberid", Header: 'Member Id', accessor: 'memberid' },
        { id: "firstname", Header: 'FirstName', accessor: 'firstname' },
        { id: "lastname", Header: 'LastName', accessor: 'lastname' },
        { id: "totalcount", Header: 'Total Count', accessor: 'totalcount' },
        { id: "totalqty", Header: 'Total Qty', accessor: 'totalqty' },
        { id: "totalamt", Header: 'Total Amt', accessor: 'totalamount' }
      ];
      for (let item of obj.sales) {
        for (let opt in obj.weeks) {
         const columns = [];
          for (let key in obj.times) {
            columns.push({
                id: key+"_"+`${obj.weeks[opt].startdate} ${obj.weeks[opt].enddate}`+"_"+item.memberid,
                Header:  `${obj.times[key].starttime}-${obj.times[key].endtime}`,
                accessor: opt+"."+key+".qty"
              });
          }
          results.push({
            id: opt+"_"+item.memberid,
            Header: `${obj.weeks[opt].startdate+" to "+obj.weeks[opt].enddate}`,
            columns: columns.concat([
              {
                id: "count_" + `${obj.weeks[opt].startdate} ${obj.weeks[opt].enddate}` + "_" + item.memberid,
                Header: "Count",
                accessor: opt + ".count"
              },
              {
                id: "qty_"+`${obj.weeks[opt].startdate} ${obj.weeks[opt].enddate}`+"_"+item.memberid,
                Header: "Quantity",
                accessor: opt+".qty"
              },
              {
                id: "totalamount_"+`${obj.weeks[opt].startdate} ${obj.weeks[opt].enddate}`+"_"+item.memberid, 
                Header: "Amount",
                accessor: opt+".amount" 
              }])
          });
        }
      }
    let uniquelist = [...new Map(results.map(item => [item["Header"], item])).values()];
    return uniquelist;
  }
}

   _renderTable = () => {
    const { memberdatalist, tablerowlist,customoptions, memberlist } = this.state;
    return(
      <React.Fragment>
        <div className="row">
          <div className="form-group col-sm-12 col-lg-4">
            <label htmlFor="customoptions" className="floatLeft">
              Filter By
            </label>
            <SimpleSelect
              objArray={{ 
                0: "Member List based on current month",
                4: "Users bought 4x within last month", 
                8: "Users bought 8x within last month",
                12: "Users bought 12x within last month",
                activeday : "Active User in last 30 days",
                cartopenday : "Users who have cart open last 30 days",
                createdday : "New Customer created within last 30 days"
              }}
              callbackChangeFilter={value =>
                this.setState({ customoptions: value },()=>{this.basedonCondition(value)})}
              selectedOption={this.state.customoptions}
              id="customoptions"
            />
          </div>  
        </div>
        {customoptions >= 0 || customoptions == "" ?
        <div className="tab-content" id="myTabContent">
               <ReportTable rowdata={memberdatalist} columndata={tablerowlist}></ReportTable> 
        </div> :
           <SimpleTable
           columns={this.state.tableCols}
           rows={this.state.memberlist}
           callback={() => {
             this.basedonCondition(customoptions);
           }}
         />
        }
      </React.Fragment>
    )
  }
  _openSearchDiv = () => {
    this.setState({ showSearchDiv: true });
  };
  _closeSearchDiv = () => {
    this.setState({ showSearchDiv: false });
  };

  _renderSearchModal = () => {
    return (
      <Modal
        visible={this.state.showSearchDiv}
        width="70%"
        height="70%"
        effect="fadeInRight"
        onClickAway={() => this._closeSearchDiv()}
      >
        <div className="modalForm queryFormModal">
          <div className="row">
            <div className="col-xs-12 col-md-6 alignLeft">Report Search Fields</div>
            <div className="col-xs-12 col-md-6 alignRight">
              <a onClick={this._closeSearchDiv} className="closeIcon">
                X
              </a>
            </div>
          </div>
          <div className="title_devide" />
          {this._renderSearchForm()}
        </div>
      </Modal>
    );
  };

  getTableDataProd = (records, headers) => {
    if(typeof records !== "undefined" && records.length > 0){
    const tablerow = records.map(e => [e.memberid, e.userid, e.firstname, e.lastname, e.emailid, e.mobileno, e.signupby,e.status]);
    const colheader = headers.map(e => ({ label: e.text, key: e.dataField }))
    tablerow.unshift(colheader.map(e=>e.label));
    return tablerow;
    }
  }

  _renderSearchBtn = () => {
    return (
      <button
        type="submit"
        className="page-header-btn icon_btn display-inline"
        onClick={event => {
          if (event) event.preventDefault();
          this._openSearchDiv();
        }}
      >
        Advanced Filter
      </button>
    );
  };

  hideErrorAlertModal = (e, refresh) => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.dispatch(resetAction());
    // if (refresh === true) {
    //   this.callQueryApiForFetch("ONLOAD");
    // }
  };

  render() {
    const {
      servererror: serverError,
      errormsg: queryError
    } = this.props.queryData;
    let errormsg = "";
    const { customoptions } = this.state;
    let refresh = false;
    if (serverError) {
      errormsg = serverError;
      refresh = false;
    }
    if (queryError) {
      errormsg = queryError;
      refresh = true;
    }
    return (
   <React.Fragment>
    {this.state.showSearchDiv && this._renderSearchModal()}
      <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">Member Report</h2>
            </div>
            <div className="col-md-3 text-right noPadding noMargin">
              <ExportButton        
                title="Sales Member Report"
                disabled={customoptions >= 0 || customoptions =="" ? true : false}
                tabledata ={this.getTableDataProd(this.state.memberlist, this.state.tableCols)}
              />
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
                <div className="row">
                  <div className="col-lg-3 col-sm-12 noPadding noMargin">
                    <h3 className="page-title">Reports</h3>
                  </div>
                  <div className="col-lg-9 col-sm-12 noPadding noMargin alignRight">
                    {this._renderSearchBtn()}
                  </div>
                </div>      
                <div className="title_devide" />
                <div className="invalid-feedback">{errormsg}</div>
                 {this._renderTable()}
              </form>
            </div>
          </div>
          <ConfirmAlert
            show={errormsg}
            handleClose={event => this.hideErrorAlertModal(event, refresh)}
            children={
              <div
                style={
                  errormsg
                    ? { padding: "2em", color: "red", fontWeight: "500" }
                    : { padding: "2em", color: "red" }
                }
              >
                {errormsg ? `${errormsg}.` : ""}
              </div>
            }
          />
      </div>
    </React.Fragment>

    );
  }


}
MemberReport.propTypes = {};

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    responses: state.crudReducer,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(MemberReport);
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { produce } from 'immer';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import StatusOptionComp from '../../../components/StatusOptionComp';
import DynamicTable from '../../../components/DynamicTable';
import {
    renderCouponOptionsFilter,
    objtoarr,
} from '../../../helpers/Util';
import {
    getCouponCodeList,
    crudReset,
    updateCouponTrx,
    saveCouponTrx,
    saveMultipleCouponTrx,
    searchByCouponRule,
    searchGeneral,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import Filter from './../../../components/Filter';
import SimpleDropdown from '../../../components/SimpleDropdown';


const generateOptionList = (list, key, label) => {
    if (list.length > 0) {
        return list.map((item) => ({
            name: `${item[key]} (${item.title})`,
            code: item[label],
        }));
    } else {
        return [];
    }
};

class IssueCoupon extends React.PureComponent {
    categoryoption = [
        { id: '', name: 'Select' },
        { id: 'Member', name: 'Member' },
        { id: 'Tag', name: 'Tag' },
        { id: 'Discount', name: 'Discount' },
    ];
    statusCoupon = [
        { name: 'Select', id: '' },
        { name: 'New', id: 'New' },
        { name: 'Active', id: 'Active' },
        { name: 'Save', id: 'Save' },
        { name: 'Update', id: 'Update' },
        { name: 'Expired', id: 'Expired' },
    ];
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            formError: false,
            formErrorMsg: '',
            isEdit: false,
            title: '',
            couponcode: '',
            issuemode: 'Manual',
            rulecode: '',
            category: '',
            memberid: '',
            issuedate: new Date(),
            status: 'Active',
            couponvalue: '',
            couponvalueby: '',
            couponqty: 1,
            couponusedqty: '',
            additionalFields: [{ field: '', value: '', id: 0 }],
            storegrouptitle: {},
            // Error
            couponnoError: '',
            titleerror: '',
            couponcodeError: '',
            issuemodeError: '',
            rulecodeError: '',
            memberidError: '',
            issuedateError: '',
            filteredCouponCodes: null,
            couponcodeObj: '',
        };
    }

    toDate = (dateStr) => {
        const [day, month, year] = dateStr.split('-');
        return new Date(year, month - 1, day);
    };

    createFetchUrl = () => {
        const requestBody = {
            body: {
                search_field: 'status',
                search_condi: 'like',
                search_value: '',
            },
        };
        return requestBody;
    };

    componentWillMount = () => {
        const { dispatch, location } = this.props;
        // Dispatch actions
        dispatch(getCouponCodeList());
        dispatch(
            searchByCouponRule({
                body: {
                    search_field: 'status',
                    search_condi: 'like',
                    search_value: '',
                },
            }),
        );
        dispatch(
            searchGeneral(
                'campaign/v1/coupon/rule/search',
                this.createFetchUrl(),
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE',
            ),
        );

        // Handle location state
        if (location?.state) {
            const { param, item: rec, isEdit } = location.state;
            const members = param?.toString();

            if (rec) {
                const {
                    id = '',
                    title = '',
                    couponcode = '',
                    issuemode = '',
                    rulecode = '',
                    category = '',
                    couponvalue = 0,
                    couponvalueby = '',
                    couponqty = '',
                    couponusedqty = 0,
                    additionalfields = {},
                    status = 'Active',
                    memberid = '',
                } = rec;

                this.setState(
                    produce((draft) => {
                        draft.id = id;
                        draft.isEdit = isEdit;
                        draft.title = title;
                        draft.couponcode = couponcode;
                        draft.issuemode = issuemode;
                        draft.rulecode = rulecode;
                        draft.category = category;
                        draft.couponvalue = couponvalue;
                        draft.couponvalueby = couponvalueby;
                        draft.couponqty = couponqty;
                        draft.couponusedqty = couponusedqty;
                        draft.memberid = memberid;
                        draft.status = status;
                        draft.additionalFields = Object.keys(additionalfields)
                            .length
                            ? objtoarr(additionalfields)
                            : [{ field: '', value: '', id: 0 }];
                    }),
                );
            } else if (members) {
                this.setState(
                    produce((draft) => {
                        draft.memberid = members;
                    }),
                );
            }
        }
    };

    setStoreGroups = (value) => {
        this.setState({
            storegroup: value,
        });
    };
    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _renderAdditionalFields = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12 label2">
                        <label htmlFor="additionalfields" className="floatLeft">
                            Additional Fields
                        </label>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12">
                        <DynamicTable
                            columns={[
                                {
                                    dataFieldId: 'field',
                                    label: 'Key',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'value',
                                    label: 'Value',
                                    addRow: true,
                                },
                                { dataFieldId: 'AD', label: '' },
                            ]}
                            rows={this.state.additionalFields}
                            deleteRow={this.deleteRow}
                            addRow={this.handleAddRow}
                            handleInputChange={this.handleInputChange}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderStatusDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Actions</div>

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusOptionComp
                            statusoption={this.statusCoupon}
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    getCreatePayload = () => {
        const {
            title,
            couponcode,
            issuemode,
            rulecode,
            category,
            memberid,
            couponvalue,
            couponvalueby,
            couponqty,
            couponusedqty,
            status,
            additionalFields,
        } = this.state;
        let addfield = additionalFields.reduce(function (acc, item) {
            if (item.field.trim() !== '' && item.value.trim() !== '') {
                acc[item.field] = item.value;
                return acc;
            }
            return acc;
        }, {});
        let body = {
            title,
            couponcode,
            issuemode,
            category,
            rulecode: rulecode?.code || '',
            memberid,
            couponvalue: parseFloat(couponvalue),
            couponvalueby,
            couponqty: parseInt(couponqty),
            couponusedqty: parseInt(couponusedqty),
            status,
            additionalfields: addfield,
        };
        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }
        const payload = {
            body: body,
        };
        return payload;
    };

    _createCouponTrx = () => {
        const { memberid } = this.state;
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateCouponTrx(this.getCreatePayload(), this.state.id),
                );
            } else {
                if (memberid.includes(',') === true) {
                    this.props.dispatch(
                        saveMultipleCouponTrx(this.getCreatePayload()),
                    );
                } else {
                    this.props.dispatch(saveCouponTrx(this.getCreatePayload()));
                }
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };
    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            couponnoError: '',
            titleerror: '',
            couponcodeError: '',
            issuemodeError: '',
            rulecodeError: '',
            memberidError: '',
            couponcodeerror: '',
        });
    };

    setCouponTitle = (code) => {
        const { couponCodeList } = this.props.queryData;
        let getcode = couponCodeList.filter((e) => e.value == code);
        if (getcode.length > 0) {
            this.setState({
                title: getcode[0].title,
            });
        }
        if (this._isEditMode() == true) {
            this.props.dispatch(
                searchByCouponRule({ body: { couponcode: code } }),
            );
        }
        this.getRuleCode(code);
    };

    handleAddRow = (e) => {
        if (e) e.preventDefault();
        const rowLength = this.state.additionalFields.length;
        let lastele = this.state.additionalFields[rowLength - 1];
        const item = {
            field: '',
            value: '',
            id: lastele.id + 1,
        };
        this.setState(
            {
                additionalFields: [...this.state.additionalFields, item],
            },
            () => {
                console.log('Rows After Add', this.state.additionalFields);
            },
        );
    };

    handleInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]:
                dataField === 'validation'
                    ? [event.target.value]
                    : event.target.value,
        };
        this.setState(
            produce((draft) => {
                if (draft.additionalFields.length === 0) {
                    draft.additionalFields.push(obj);
                } else {
                    let found = false;
                    draft.additionalFields.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.additionalFields.push(obj);
                    }
                }
            }),
            () => {},
        );
    };
    deleteRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.additionalFields.length;

        if (rowLength > 1) {
            const updateValues = this.state.additionalFields.filter(
                (value) => rowId !== value.id,
            );

            this.setState({ additionalFields: updateValues });
        }
    };

    validateForm = () => {
        this._clearErrorMessages();
        const {
            couponcode,
            issuemode,
            rulecode,
            memberid,
            title,
            category,
        } = this.state;

        let valid = true;
        if (couponcode.trim() === '') {
            this.setState({ couponcodeerror: 'Please select coupon code' });
            valid = false;
        }
        if (title.trim() === '') {
            this.setState({
                titleerror: 'Title is required.',
            });
            valid = false;
        }
        if (couponcode.trim() === '') {
            this.setState({
                couponcodeError: 'Coupon Title is required.',
            });
            valid = false;
        }
        if (!rulecode.code) {
            this.setState({
                rulecodeError: 'Rule code is required.',
            });
            valid = false;
        }
        if (memberid.trim() === '') {
            this.setState({
                memberidError: 'Member id is required',
            });
            valid = false;
        }
        if (issuemode.trim() === '') {
            this.setState({
                issuemodeError: 'Issue Mode is required.',
            });
            valid = false;
        }
        if (category.trim() === '') {
            this.setState({
                categoryError: 'Rule type is required.',
            });
            valid = false;
        }

        return valid;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _renderMainButton = () => {
        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => this._createCouponTrx()}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => this._createCouponTrx()}
                    >
                        Update
                    </button>
                )}

                <Link
                    to={
                        this._isEditMode()
                            ? '/ui/issue-member-coupon'
                            : '/ui/issue-member-coupon'
                    }
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };
    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 });
    };

    componentWillReceiveProps = () => {
        if (this._isEditMode()) {
            const { storeArray } = this.props.queryData;
            if (storeArray) this.setState({ storelabel: storeArray.title });
        }
    };

    componentDidUpdate = (prevProps) => {
        const { couponRuleList } = this.props.queryData;
        const { couponRuleList: prevcouponRuleList } = prevProps.queryData;
        if (
            couponRuleList !== prevcouponRuleList &&
            couponRuleList.length > 0
        ) {
            const optionList = generateOptionList(
                couponRuleList,
                'rulecode',
                'rulecode',
            );
            this.setState({ ruleCodeArr: optionList });
        }
    };

    handleChangeCouponObj = (e) => {
        this.setState({
            couponcodeObj: e.target.value,
            couponcode: e.target.value ? e.target.value.id : '',
        });
        if (e.target.value && e.target.value !== '') {
            this.setCouponTitle(e.target.value.id);
        }
    };

    searchCouponCode = (event) => {
        const { couponCodeList } = this.props.queryData;
        let couponList = [];
        if (couponCodeList && couponCodeList.length > 0) {
            couponList = couponCodeList.map((e) => ({
                name: e.title + ' - ' + e.value,
                id: e.value,
            }));
        }
        setTimeout(() => {
            let _filteredCouponCodes;
            if (!event.query.trim().length) {
                _filteredCouponCodes = [...couponList];
            } else {
                _filteredCouponCodes = couponList.filter((country) => {
                    return country.name
                        .toLowerCase()
                        .startsWith(event.query.toLowerCase());
                });
            }
            this.setState({ filteredCouponCodes: _filteredCouponCodes });
        }, 250);
    };

    getRuleCode = (couponcode) => {
        this.props.dispatch(
            searchByCouponRule({
                body: { couponcode: couponcode, status: 'Active' },
            }),
        );
    };

    handleRuleCodeChange = (e) => {
        const { couponRuleList } = this.props.queryData;
        const ruletypeObj = couponRuleList.filter(
            (c) => c.rulecode == e.value.code,
        );
        if (ruletypeObj.length > 0) {
            const { couponvaluetype, couponvalue, ruletype } = ruletypeObj[0]
            this.setState({ category: ruletype, couponvalueby: couponvaluetype, couponvalue });
        }
        this.setState({ rulecode: e.value });
    };

    _renderFormArea = () => {
        const { couponRuleList } = this.props.queryData;
        const couponRuleCategory = couponRuleList.map((e) => ({
            title: e.ruletype,
            value: e.ruletype,
        }));

        const getManualCategory = couponRuleCategory.filter(
            (item, index, array) => {
                // Check if the title is not empty and it's the first occurrence in the array
                return (
                    item.title.trim() !== '' &&
                    array.findIndex((obj) => obj.title === item.title) === index
                );
            },
        );

        const {
            couponcodeerror,
            issuemodeError,
            categoryError,
            memberidError,
            couponqtyError,
            couponvalueError,
            couponvaluebyError,
            rulecodeError,
        } = this.state;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft required">
                            Coupon Code
                        </label>
                        <AutoComplete
                            className="w-100"
                            value={this.state.couponcodeObj}
                            suggestions={this.state.filteredCouponCodes}
                            completeMethod={this.searchCouponCode}
                            field="name"
                            dropdown
                            forceSelection
                            onChange={this.handleChangeCouponObj}
                            aria-label="Coupon Code"
                            dropdownAriaLabel="Select Coupon Code"
                        />
                        <div className="invalid-feedback">
                            {couponcodeerror}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            readOnly={true}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft required">
                            Rule Code
                        </label>
                        <Dropdown
                            className="w-100"
                            id="rulecode"
                            name="rulecode"
                            value={this.state.rulecode}
                            onChange={this.handleRuleCodeChange}
                            options={this.state.ruleCodeArr}
                            optionLabel="name"
                            placeholder="Select"
                        />
                        <div className="invalid-feedback">{rulecodeError}</div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="category"
                            className="floatLeft required"
                        >
                            Rule Type
                        </label>
                        {this._isEditMode() ? (
                            <SimpleDropdown
                                id="category"
                                listoptions={this.categoryoption}
                                selectedOption={this.state.category}
                                callbackChangeFilter={(value) => {
                                    this.setState({ category: value });
                                }}
                            />
                        ) : (
                            <Filter
                                cssClass={
                                    categoryError === ''
                                        ? 'form-control-prime'
                                        : 'form-control-prime error-control'
                                }
                                filterOptions={renderCouponOptionsFilter(
                                    getManualCategory,
                                )}
                                selectedOption={this.state.category}
                                callbackChangeFilter={(event) => {
                                    this.setState({
                                        category: event.target.value,
                                    });
                                }}
                                id="category"
                            />
                        )}
                        <div className="invalid-feedback">{categoryError}</div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        {this._isEditMode() ? (
                            <div>
                                <label
                                    htmlFor="issuemode"
                                    className="floatLeft"
                                >
                                    Issue Mode
                                </label>
                                <input
                                    type="text"
                                    className={
                                        issuemodeError === ''
                                            ? 'form-control'
                                            : 'error-control'
                                    }
                                    id="issuemode"
                                    value={this.state.issuemode}
                                    onChange={(event) => {
                                        this.setState({
                                            issuemode: event.target.value,
                                        });
                                    }}
                                />
                                <div className="invalid-feedback">
                                    {issuemodeError}
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label
                            htmlFor="memberid"
                            className="floatLeft required"
                        >
                            Member Id
                        </label>
                        <textarea
                            className={
                                memberidError === ''
                                    ? 'form-control'
                                    : 'error-control'
                            }
                            id="memberid"
                            value={this.state.memberid}
                            readOnly={true}
                        />
                        <div className="invalid-feedback">{memberidError}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="couponvalueby" className="floatLeft">
                            Coupon Value By
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="couponvalueby"
                            value={this.state.couponvalueby}
                            onChange={(event) => {
                                this.setState({
                                    couponvalueby: event.target.value,
                                });
                            }}
                        />
                        <div className="invalid-feedback">
                            {couponvaluebyError}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="couponvalue" className="floatLeft">
                            Coupon Value
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="couponvalue"
                            value={this.state.couponvalue}
                            onChange={(event) => {
                                this.setState({
                                    couponvalue: event.target.value,
                                });
                            }}
                        />
                        <div className="invalid-feedback">
                            {couponvalueError}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="couponqty" className="floatLeft">
                            Coupon Quantity
                        </label>
                        <input
                            type="number"
                            className={
                                couponqtyError === ''
                                    ? 'form-control'
                                    : 'error-control'
                            }
                            id="couponqty"
                            value={this.state.couponqty}
                            onChange={(event) => {
                                this.setState({
                                    couponqty: event.target.value,
                                });
                            }}
                        />
                    </div>
                    {this._isEditMode() ? (
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="couponqtyused"
                                className="floatLeft"
                            >
                                Coupon Used Quantity
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="couponusedqty"
                                value={this.state.couponusedqty}
                                readOnly={true}
                            />
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            </React.Fragment>
        );
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };
    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                Issue Coupon
                            </h2>
                        </div>
                    </div>
                    <div className="grid px-3">
                        <div className="col-9">
                            <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                                {this._renderErrorMessage()}
                                {this._renderFormArea()}
                                {this._renderAdditionalFields()}
                            </div>
                            <div className="row btn-container form-button">
                                <div className="col-12">
                                    {this._renderMainButton()}
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                                {this._renderStatusDetails()}
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to={'/ui/member-coupon/list'}
                        children={
                            <div style={{ color: 'green', padding: '2em' }}>
                                {this._isEditMode()
                                    ? 'Issued Coupon updated successfully'
                                    : 'Coupon issued successfully'}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(IssueCoupon);

import React from 'react';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import Modal from 'react-awesome-modal';
import { produce } from 'immer';
import {
    getProductMapInfoById,
    createAddon,
    updateAddons,
    searchByStoreProdId,
    crudReset,
    deleteAddonRow,
    searchGeneral,
} from '../../../actions/actions';
import DynamicGroupAddOn from './../../../components/DynamicGroupAddOn';
import Autocomplete from '../../../components/Autocomplete';
import { notify } from 'react-notify-toast';
import ConfirmAlert from './../../../components/ConfirmAlert';
import Loading from './../../../components/Loading';
import { renderTaxValues } from './../../../helpers/Util';

var databaseid = 0;

class StoreProductAddOn extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            selectedgroup: '',
            dataFieldId: 'productcode',
            showItemMappingModal: false,
            selectGroup: [],
            row: '',
            itemmaprow: [],
            addoncount: this.props.addonitems || 0,
            g1: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g1',
                    id: null,
                },
            ],
            g2: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: '',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    sortorder: 1,
                    groupid: 'g2',
                    id: null,
                },
            ],
            g3: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g3',
                    id: null,
                },
            ],
            g4: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g4',
                    id: null,
                },
            ],
            g5: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g5',
                    id: null,
                },
            ],
            g6: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g6',
                    id: null,
                },
            ],
            g7: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g7',
                    id: null,
                },
            ],
            g8: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g8',
                    id: null,
                },
            ],
            g9: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g9',
                    id: null,
                },
            ],
            g10: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g10',
                    id: null,
                },
            ],
            g11: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g11',
                    id: null,
                },
            ],
            g12: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g12',
                    id: null,
                },
            ],
            g13: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g13',
                    id: null,
                },
            ],
            g14: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g14',
                    id: null,
                },
            ],
            g15: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g15',
                    id: null,
                },
            ],
            g16: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g16',
                    id: null,
                },
            ],
            g17: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g17',
                    id: null,
                },
            ],
            g18: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g18',
                    id: null,
                },
            ],
            g19: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g19',
                    id: null,
                },
            ],
            g20: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g20',
                    id: null,
                },
            ],
            g21: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g21',
                    id: null,
                },
            ],
            g22: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g22',
                    id: null,
                },
            ],
            g23: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g23',
                    id: null,
                },
            ],
            g24: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g24',
                    id: null,
                },
            ],
            g25: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g25',
                    id: null,
                },
            ],
            g26: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g26',
                    id: null,
                },
            ],
            g27: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g27',
                    id: null,
                },
            ],
            g28: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g28',
                    id: null,
                },
            ],
            g29: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g29',
                    id: null,
                },
            ],
            g30: [
                {
                    productcode: '',
                    price: 0,
                    taxcategory: 'TX0',
                    selected: 'N',
                    mandatoryaddon: 'N',
                    itemidx: 1,
                    storeproductid: this.props.storeproductid,
                    unitqty: 1,
                    status: 'Active',
                    itemmap: {},
                    additionalfields: {},
                    groupid: 'g30',
                    id: null,
                },
            ],
            showProdModal: false,
        };
    }

    _renderToastMessages = () => {
        const { addondelesuccess, addondelefailure } = this.props.crudData;
        let myColorF = { background: 'red', text: '#FFFFFF' };
        let myColorS = { background: 'green', text: '#FFFFFF' };
        if (addondelesuccess) {
            // notify.show(
            //     'Addon deleted successfully !!',
            //     'custom',
            //     3000,
            //     myColorS
            // );
            this.props.dispatch(crudReset());
            this.props.dispatch(
                searchByStoreProdId({
                    body: {
                        storeproductid: this.props.storeproductid,
                        status: 'Active',
                    },
                })
            );
        } else if (!addondelesuccess && addondelefailure) {
            // notify.show(
            //     `Addon deletion unsuccessful `,
            //     'custom',
            //     3000,
            //     myColorF
            // );
            this.props.dispatch(crudReset());
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.queryData.storeproductdata !==
            this.props.queryData.storeproductdata
        ) {
            const { storeproductdata } = this.props.queryData;
            if (storeproductdata.length > 0) {
                const groups = this.groupBy(storeproductdata);

                for (var key in groups) {
                    if (groups.hasOwnProperty(key)) {
                        this.setState({ [key]: groups[key] }, () => {});
                    }
                }
            }
        }
    }

    groupBy = (data, key) =>
        data.reduce(
            (result, item) => ({
                ...result,
                [item['groupid']]: [...(result[item['groupid']] || []), item],
            }),
            {}
        );
    _incrId = () => {
        databaseid = databaseid + 1;
        return databaseid;
    };
    _updateGroup = () => {
        this._closeProductModal();

        const { selectedgroup, dataFieldId, row, selectedprodcode, title } =
            this.state;
        const { productSuggestions } = this.props.queryData;

        const newproductcode = selectedprodcode;
        const productcodesadded = this.state[selectedgroup].map((grp) => {
            return grp.productcode;
        });

        if (productcodesadded.indexOf(newproductcode) >= 0) {
            let myColor = { background: 'red', text: '#FFFFFF' };
            // notify.show(
            //     'Product code already added !',
            //     'custom',
            //     2000,
            //     myColor
            // );
            return;
        } else {
            this.handleAddGroupTitle(
                selectedprodcode || '',
                dataFieldId,
                row,
                selectedgroup,
                title
            );
        }
    };

    componentWillMount = () => {
        const { storeid, productcode, storeproductid } = this.props;
        this.props.dispatch(
            getProductMapInfoById({
                body: {
                    search_field: 'productcode',
                    search_condi: 'eq',
                    search_value: productcode,
                },
            })
        );

        this.props.dispatch(
            searchByStoreProdId({
                body: {
                    storeproductid: storeproductid,
                    status: 'Active',
                },
            })
        );

        this.props.dispatch(
            searchGeneral(
                '/sales/v1/category/search',
                {
                    body: {
                        search_field: 'catgroup',
                        search_condi: 'eq',
                        search_value: 'TAX',
                    },
                },
                'TAX_VALUE_SEARCH_SUCCESS',
                'TAX_VALUE_SEARCH_FAILURE'
            )
        );
    };

    _updateTitle = (productcode, title) => {
        this.setState({ selectedprodcode: productcode, title: title }, () => {
            this._updateGroup();
        });
    };
    _renderProductModal = () => {
        localStorage.setItem('keyword', this.state.title);
        return (
            <Modal
                visible={this.state.showProdModal}
                width="40%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeProductModal()}
            >
                <div className="suggestionForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-12 alignRight">
                            <a
                                onClick={this._closeProductModal}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row" style={{ height: '15em' }}>
                        <Autocomplete
                            input={this.state.title}
                            updateTitle={this._updateTitle}
                        ></Autocomplete>
                    </div>
                </div>
            </Modal>
        );
    };
    _showProductModal = (event, title, group, row) => {
        this.setState({
            showProdModal: true,
            title: title,
            selectedgroup: group,
            row,
        });
        if (event) event.preventDefault();
    };
    _closeProductModal = (event) => {
        this.setState({ showProdModal: false });
        if (event) event.preventDefault();
    };
    splitStr = (x) => {
        const y = x.split(':');
        return { [y[0].trim()]: y[1].trim() };
    };

    setMapping = (value, dataField, row, addongrp) => {
        const objects = value.map(this.splitStr);
        var element = {};
        objects.forEach(function (item) {
            for (var i in item) {
                if (element.hasOwnProperty(i)) {
                    element[i] = element[i] + ',' + item[i];
                } else {
                    element[i] = item[i];
                }
            }
        });
        this.handleAddonInputChange(element, dataField, row, addongrp, true);
    };

    _getGroupId = (addonsGroup, orderId) => {
        return addonsGroup.find((ag) => {
            return ag.sortorder === orderId;
        });
    };

    _createSelectGroup = (currentgroupid) => {
        const { productStoreUpdate } = this.props.queryData;
        const addonsGroup = productStoreUpdate[0].addongroups;

        let incrlimit = 30;

        if (currentgroupid && productStoreUpdate[0]) {
            incrlimit = addonsGroup.find(
                (itm) => itm.groupid === currentgroupid
            ).sortorder;
        }

        let selectGroup = [];

        for (var i = 1; i < incrlimit; i++) {
            const _groupId = this._getGroupId(addonsGroup, i);
            //it is blank used only for display
            const groupId =
                typeof _groupId !== 'undefined' ? _groupId.groupid : `g${i}`;
            if (this.state[groupId][0].productcode !== '') {
                for (var j = 0; j < this.state[groupId].length; j++) {
                    if (this.state[groupId][`${j}`].productcode !== '') {
                        let id = this.state[groupId][`${j}`].itemidx;
                        //let group = groupId;
                        let title = '';
                        if (this.state[groupId][`${j}`].additionalfields) {
                            title =
                                this.state[groupId][`${j}`].additionalfields
                                    .title;
                        }

                        let titles =
                            productStoreUpdate &&
                            addonsGroup.filter((value) => {
                                if (value.groupid === groupId) {
                                    return value.title;
                                }
                            });
                        let item = {
                            group: titles.length > 0 && titles[0].title,
                            value: `${groupId}:${id}`,
                            label: title,
                        };
                        selectGroup.push(item);
                    }
                }
            }
        }
        return selectGroup;
    };
    _getItemsMap = (itemmap) => {
        let itemmapping = itemmap;
        let retArray = [];
        if (itemmap) {
            let keys = Object.keys(itemmap);
            let values = Object.values(itemmap);
            let valuestring = '';

            for (var i = 0; i < keys.length; i++) {
                if (values[i].indexOf(',') > 0) {
                    valuestring = values[i].split(',');
                } else {
                    valuestring = values[i];
                }
                for (var j = 0; j < valuestring.length; j++) {
                    retArray.push(`${keys[i]}:${valuestring[j]}`);
                }
            }
        }
        return retArray;
    };
    _createMappedValues = () => {
        let mappedgroup = [];
        let incrlimit = 30;
        const { storeproductdata } = this.props.queryData;

        if (storeproductdata && storeproductdata.length > 0) {
            for (var i = 1; i < storeproductdata.length; i++) {
                if (storeproductdata[i].itemmap) {
                    let itemmap = storeproductdata[i].itemmap;
                    const values = this._getItemsMap(itemmap);
                    mappedgroup.push(...values);
                }
            }
        }
        return mappedgroup;
    };
    _showItemMapModal = (event, itemmap) => {
        this.setState({ itemmaprow: this._getItemsMap(itemmap) });

        this.setState({
            showItemMappingModal: true,
        });
        if (event) event.preventDefault();
    };
    _closeItemMapModal = (event) => {
        this.setState({ showItemMappingModal: false });
        if (event) event.preventDefault();
    };
    handleAddonAddRow = (e, addongrp) => {
        if (e) e.preventDefault();
        const rowLength = this.state[addongrp].length;
        let lastele = this.state[addongrp][rowLength - 1];

        const item = {
            productcode: '',
            price: 0,
            taxcategory: 'TX0',
            selected: 'N',
            mandatoryaddon: 'N',
            // itemidx: this._incrId(),
            itemidx: lastele.itemidx + 1,
            id: null,
            unitqty: 1,
            storeproductid: this.props.storeproductid,
            status: 'Active',
            itemmap: {},
            additionalfields: {},
            groupid: addongrp,
        };
        this.setState(
            {
                [addongrp]: [...this.state[addongrp], item],
            },
            () => {
                console.log('Rows After Add', this.state[addongrp]);
            }
        );
    };
    handleAddonInputChange = (
        event,
        dataField,
        row,
        addongrp,
        isForMultselect,
        grpMultiSelectable
    ) => {
        let obj;
        if (grpMultiSelectable === 'N') {
            let selectablelist = this.state[addongrp].filter((row) => {
                return row.selected === 'Y';
            });
            if (selectablelist.length !== 0) {
                let myColor = { background: 'red', text: '#FFFFFF' };
                notify.show(
                    `Only one item can be selected.Group is not multi-selectable!`,
                    'custom',
                    4000,
                    myColor
                );
                return;
            }
        }
        if (isForMultselect) {
            obj = {
                itemidx: row.itemidx,
                [dataField]: event,
            };
        } else {
            obj = {
                itemidx: row.itemidx,
                [dataField]: event.target.value,
            };
        }
        this.setState(
            produce((draft) => {
                if (draft[addongrp].length === 0) {
                    draft[addongrp].push(obj);
                } else {
                    let found = false;
                    draft[addongrp].forEach((command) => {
                        if (command.itemidx === obj.itemidx) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft[addongrp].push(obj);
                    }
                }
            })
        );
    };
    _getAllItemsMap = () => {
        let mappedgroup = [];
        let incrlimit = 30;

        for (var i = 1; i < incrlimit; i++) {
            if (this.state[`g${i}`][0].productcode !== '') {
                for (var j = 0; j < this.state[`g${i}`].length; j++) {
                    if (this.state[`g${i}`][`${j}`].productcode !== '') {
                        let rows = this.state[`g${i}`][`${j}`];
                        mappedgroup.push(rows);
                    }
                }
            }
        }
        let uniquearray = [...new Set(mappedgroup)];
        return uniquearray;
    };

    _createAddONForProduct = () => {
        // create rows of all mapping
        const values = this._getAllItemsMap();
        this.setState({ addoncount: values.length });
        this._createOrUpdate(values, true);
    };

    _createOrUpdate = (values, forAddon) => {
        const saveaddon = values.filter((e) => e.id == null);
        const updateaddon = values.filter((e) => e.id !== null);
        saveaddon.forEach((e) => {
            this.props.dispatch(createAddon({ body: e }));
        });
        updateaddon.forEach((e) => {
            this.props.dispatch(updateAddons({ body: e }));
        });
    };
    handleAddGroupTitle = (value, dataField, row, addongrp, title) => {
        let obj;
        obj = {
            itemidx: row.itemidx,
            [dataField]: value,
            additionalfields: { title: title },
        };

        this.setState(
            produce((draft) => {
                if (draft[addongrp].length === 0) {
                    draft[addongrp].push(obj);
                } else {
                    let found = false;
                    draft[addongrp].forEach((command) => {
                        if (command.itemidx === obj.itemidx) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft[addongrp].push(obj);
                    }
                }
            }),
            () => {
                console.log(this.state.addongrp);
            }
        );
    };
    _checkIfMapped = (row) => {
        let idtobedeleted = `${row.groupid}:${row.itemidx}`;
        const mappedgroups = this._createMappedValues();

        if (mappedgroups.indexOf(idtobedeleted) >= 0) {
            return true;
        } else {
            return false;
        }
    };
    deleteAddonRow = (row, addongrp) => {
        let rowId = row.itemidx;
        const rowLength = this.state[addongrp].length;
        let check = this._checkIfMapped(row);

        if (check !== true) {
            if (rowLength > 1) {
                const updateValues = this.state[addongrp].filter(
                    (value) => rowId !== value.itemidx
                );
                //console.log(this.state);
                this.setState({ [addongrp]: updateValues }, () => {});

                if (row.id) {
                    this.props.dispatch(
                        deleteAddonRow(
                            'sales/v1/store/product/addon/delete/',
                            row.id
                        )
                    );
                }
            }
            if (rowLength === 1) {
                const updateValues = this.state[addongrp].filter(
                    (value) => rowId !== value.itemidx
                );

                if (updateValues.length === 0) {
                    this.setState(
                        {
                            [addongrp]: [
                                {
                                    productcode: '',
                                    price: 0,
                                    taxcategory: 'TX0',
                                    selected: 'N',
                                    mandatoryaddon: 'N',
                                    itemidx: 1,
                                    storeproductid: this.props.storeproductid,
                                    unitqty: 1,
                                    status: 'Active',
                                    itemmap: {},
                                    additionalfields: {},
                                    groupid: addongrp,
                                    id: null,
                                },
                            ],
                        },
                        () => {}
                    );
                    if (row.id) {
                        this.props.dispatch(
                            deleteAddonRow(
                                'sales/v1/store/product/addon/delete/',
                                row.id
                            )
                        );
                    }
                }
            }
        } else {
            let myColor = { background: 'red', text: '#FFFFFF' };
            notify.show(
                `${
                    row.additionalfields && row.additionalfields.title
                } is already mapped.To delete the item,please remove from mapping first and save the record!`,
                'custom',
                4000,
                myColor
            );
        }
    };
    _createGrouping = (group, index) => {
        var columns = [
            {
                dataFieldId: 'additionalfields.title',
                label: 'Title',
                modal: true,
            },
            {
                dataFieldId: 'price',
                label: 'Price',
                addRow: true,
            },
            {
                dataFieldId: 'taxcategory',
                label: 'Tax Value (%)',
                forTax: true,
            },
            {
                dataFieldId: 'selected',
                label: 'Selected',
                check: true,
            },
            {
                dataFieldId: 'mandatoryaddon',
                label: 'Mandatory Addon',
                checkaddon: true,
            },
            // {
            //   dataFieldId: "sortorder",
            //   label: "Sort Order",
            //   sortorder: true
            // },
            {
                dataFieldId: 'AD',
                label: '',
            },
        ];
        //if (group.groupid !== "g1") {
        columns.splice(1, 0, {
            dataFieldId: 'itemmap',
            label: 'Groups',
            itemmap: true,
        });
        //}
        const { taxValue } = this.props.queryData;
        let taxvalues = [];
        taxvalues = renderTaxValues(taxValue);
        return (
            <div className="groupdiv splitFrmDiv" key={index}>
                <span style={{ margin: '5px 0' }}>{group.title}</span>
                <div className="title_devide"></div>
                <DynamicGroupAddOn
                    columns={columns}
                    rows={this.state[group.groupid]}
                    deleteRow={this.deleteAddonRow}
                    addRow={this.handleAddonAddRow}
                    handleInputChange={this.handleAddonInputChange}
                    addongrpid={group.groupid}
                    title={group.title}
                    showProductModal={this._showProductModal}
                    selectGroup={this.state.selectGroup}
                    setMapping={this.setMapping}
                    // createOrUpdate={this.createOrUpdate}
                    beforemulselectopen={this._createSelectGroup}
                    height="6em"
                    taxfields={taxvalues}
                    multiSelect={group.multiselect}
                />
            </div>
        );
    };

    _renderGroup = (addongroups) => {
        return (
            <React.Fragment>
                {addongroups.map((group, key) => {
                    return this._createGrouping(group, key);
                })}
            </React.Fragment>
        );
    };

    _renderFormArea = () => {
        const { productStoreUpdate, apiPending } = this.props.queryData;
        let addongroups = [];
        if (productStoreUpdate.length > 0) {
            addongroups = productStoreUpdate[0].addongroups || [];
            addongroups = addongroups.sort((a, b) => a.sortorder - b.sortorder);
        }
        if (addongroups.length === 0 && apiPending === false) {
            return (
                <div className="row frmDiv" style={{ textAlign: 'center' }}>
                    <div className="col-6 groupdivnone ">
                        <img
                            className="noStoreImage"
                            onClick={this.props.addRow}
                            src={`${process.env.PUBLIC_URL}/assets/icons/img_vector_product.svg`}
                        />
                    </div>
                    <div className="col-6 groupdivnone centerdiv ">
                        <p className="noStoreHeader">No grouping found!!!</p>
                        <p className="noStoreBody">
                            Please add grouping data in product table to start
                            with grouping.
                        </p>
                    </div>
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-md-12 col-sm-12">
                        <form className=" text-center ">
                            {this._renderGroup(addongroups)}
                        </form>
                    </div>
                </div>
                {this.state.showProdModal && this._renderProductModal()}
                {this.state.showItemMappingModal && this._renderItemMapModal()}
            </React.Fragment>
        );
    };
    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.updateaddoncount(this.state.addoncount);
        this.props.updateAddon();
    };

    _renderMainButton = () => {
        if (this.props.isView || false) {
            return (
                <React.Fragment>
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                        onClick={() => {
                            this.props.updateAddon();
                        }}
                    >
                        Close
                    </button>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={() => this._createAddONForProduct()}
                >
                    Save
                </button>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    onClick={() => {
                        this.props.updateAddon();
                    }}
                >
                    Cancel
                </button>
            </React.Fragment>
        );
    };
    render() {
        const { apiPending, productStoreUpdate } = this.props.queryData;
        const { isSuccess } = this.props.crudData;
        if (apiPending || !productStoreUpdate) {
            return <Loading></Loading>;
        }
        return (
            <React.Fragment>
                <Toast ref={(el) => this.toast = el} />
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">Add On</h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderFormArea()}
                            {this._renderErrorMessage()}
                            <div className="row btn-container form-button">
                                <div className="col-12">
                                    {this._renderMainButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this._renderToastMessages()}
                <ConfirmAlert
                    show={isSuccess}
                    handleClose={this.hideModal}
                    children={
                        <div className="alert alert-success">
                            Addons updated successfully
                        </div>
                    }
                />
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(StoreProductAddOn);

import React from 'react';
import { connect } from 'react-redux';
import { produce } from 'immer';
import { Link, withRouter } from 'react-router-dom';
import {
    canDelete,
    canView,
    canWrite,
    permissions,
} from '../../../../helpers/Util';
import {
    searchGeneral,
    crudReset,
    deleteStoreTag,
    storeSearchByFields,
    resetAction,
    storeTagsSearchByFields,
} from '../../../../actions/actions';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import * as CONSTANTS from '../../../../constants/constants';
import StatusOptionComp from '../../../../components/StatusOptionComp';
import PrimeTable from '../../../../components/PrimeTable';
import BreadCrumbWrapper from '../../../../components/BreadCrumbWrapper';
import PrimeStoreSelector from '../../../../components/PrimeStoreSelector';
import ConfirmAlert from '../../../../components/ConfirmAlert';

const items = [
    { label: 'Stores', path: '/ui/stores' },
    { label: 'Store Tenders', path: '/ui/storetenders' },
];

const statusOptions = [
    { name: 'Select', id: '' },
    { name: 'New', id: 'New' },
    { name: 'Active', id: 'Active' },
    { name: 'Deleted', id: 'Deleted' },
    { name: 'Inactive', id: 'Inactive' },
];

class DisplayStoreTenders extends React.PureComponent {
    
    taggroup = 'storeprops';
    tagtype = 'tender';

    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.group = 'DE_STOREMGMT_STORE_TENDERS';
        this.module = 'STOREMGMT';
        this.allPermissions = [];

        this.requestBody = {}; //current one
        this.currPage = 1;
        this.pageSize = 10;

        this.state = {
            currPage: this.currPage,
            pageSize: this.pageSize,
            showSearchModal: false,
            query: {},
            openDeleteprompt: false,
            delete_rec_id: '',
            message: '',
            action: '',
            search_field: 'status',
            search_condi: 'like',
            search_value: '',

            storeid: '',
            storelabel: '',
            status: '',
            selectedStore: {},
            storesList: [],

            //errors
            storeiderror: '',
        };
    }

    actionTemplate = (row) => {
        if (row.status === 'Deleted') {
            return;
        }
        return (
            <span>
                {canView(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/storetenders/addNew',
                            state: {
                                item: row,
                                isEdit: false,
                                isView: true,
                            },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                        />
                    </Link>
                )}

                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/storetenders/addNew',
                            state: {
                                item: row,
                                isEdit: true,
                            },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                        />
                    </Link>
                )}

                {canDelete(this.allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this._deleteTerminal(row)}
                    />
                )}
            </span>
        );
    };

    // API Calls
    createFetchUrl = (fetchType) => {
        const {
            pageSize,
            currPage,
            storeid,
            // taggroup,
            status,
        } = this.state;

        if (fetchType === 'ONLOAD') {
            const requestBody = {
                body: {
                    taggroup: this.taggroup,
                    storeid,
                    pagesize: pageSize,
                    pageno: currPage,
                    tagtype: this.tagtype,
                    status,
                },
            };
            this.requestBody['storeid'] = storeid;
            return requestBody;
        }

        const requestBody = {
            body: {
                ...this.requestBody,
                pagesize: pageSize,
                pageno: currPage,
                taggroup: this.taggroup,
                tagtype: this.tagtype,
            },
        };
        console.log('...requestBoyd::', requestBody);
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                'system/v1/store/tag/search/fields',
                this.createFetchUrl(fetchType),
                CONSTANTS.SEARCH_STORE_TAGS_SUCCESS,
                CONSTANTS.SEARCH_STORE_TAGS_FAILURE
            )
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
        }
    };

    checkStoreIdSelected = () => {
        const storeid = localStorage.getItem('storeid');
        const storelabel = localStorage.getItem('storelabel');
        if (storeid && storeid !== '' && storelabel && storelabel !== '') {
            this.callQueryApiForFetch('ONLOAD');
        } else {
            this.setState({ storeiderror: 'Select store' });
        }
    };

    componentDidUpdate = (prevProps) => {
        const { stores } = this.props.queryData;
        const { stores: prevStores } = prevProps.queryData;

        let filteredStores = [];

        const store_access = localStorage.getItem('store_access');
        if (store_access) {
            const accessStores = store_access.split(',');
            filteredStores = stores.filter((e) =>
                accessStores.includes(e.storeid)
            );
        }

        if (stores !== prevStores) {
            if (stores && stores.length > 0) {
                const options = filteredStores.map((e) => ({
                    label: '(' + e.storeid + ') ' + e.title,
                    value: e.storeid,
                }));
                this.setState({ storesList: options });
                if (this.state.storeid != '') {
                    const _selectedStores = filteredStores.filter(
                        (e) => e.storeid == this.state.storeid
                    );
                    if (_selectedStores && _selectedStores.length > 0) {
                        const selectedStore = _selectedStores.map((e) => ({
                            label: e.storeid + '-' + e.title,
                            value: e.storeid,
                        }));
                        this.setState({ selectedStore: selectedStore });
                    }
                }
            } else {
                this.setState({ storesList: [], selectedStore: {} });
            }
        }
    };

    componentWillMount() {
        this.props.queryData.storeTags = [];
        this._searchStores();
        const storeid = localStorage.getItem('storeid');
        const storelabel = localStorage.getItem('storelabel');
        if (storeid && storeid !== '' && storelabel && storelabel !== '') {
            this.setState({ storeid: storeid, storelabel: storelabel }, () =>
                this.callQueryApiForFetch('ONLOAD')
            );
        } else {
            this.state.storeiderror = 'Select store';
        }
        this.allPermissions = permissions(this.module, this.group);
    }

    _searchStores = () => {
        this.props.dispatch(storeSearchByFields(`companyid=${this.companyId}`));
    };

    _renderAddNewBtn = () => {
        const { history } = this.props
        if (!canWrite(this.allPermissions)) {
            return;
        }

        return (
            <Button
                label="Add Store Tender"
                icon="pi pi-plus"
                className="p-button-outlined"
                disabled={!this.state.storeid}
                severity="info"
                onClick={() => history.push('/ui/storetenders/addNew')}
            />
        );
    };

    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(deleteStoreTag(this.state.delete_rec_id));
        }
    };

    hideAlertModal = (e) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };

    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
        this._searchStores();
    };

    _deleteTerminal = (rowData) => {
        this.setState({ message: 'Config deleted', action: 'Delete' });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };

    _handleStoreSelection = (selected) => {
        this.setState({ selectedStore: selected });
        if (selected.label.indexOf('(') !== -1) {
            const storeid = selected.value; // selected.substring(0, selected.indexOf('-'))
            const storeLabel = selected.label.substring(
                selected.label.indexOf('-1') + selected.value.length + 4,
                selected.label.length
            );
            this._setStore(storeid, storeLabel);
        }
    };

    handleStatusChange = e => {
        this.setState({ status: e.value })
    }

    render() {
        const {
            errormsg: queryError,
            servererror: serverError,
            storeTags,
            // stores
        } = this.props.queryData;
        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        const columns = [
            { field: 'storeid', header: 'Store Id', sortable: true },
            { field: 'title', header: 'Title', sortable: true },
            { field: 'taggroup', header: 'Tag Group', sortable: true },
            { field: 'tagtype', header: 'Tag Type', sortable: true },
            { field: 'tagtypevalue', header: 'Tag Type Value', sortable: true },
            { field: 'status', header: 'Status' },
        ];

        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-5 col-sm-12">
                            <h2 className="page-header">
                                Store Tenders
                                <img
                                    title="Refresh"
                                    onClick={() => {
                                        this.checkStoreIdSelected();
                                    }}
                                    className="refreshtree"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                    alt="RefreshButton"
                                />
                            </h2>
                        </div>
                        <div className="form-group col-lg-3 col-sm-12 alignWithPageHeader">
                            <PrimeStoreSelector
                                storeList={this.state.storesList}
                                handleStoreSelection={
                                    this._handleStoreSelection
                                }
                                selectedStore={this.state.selectedStore}
                                required
                            />
                        </div>
                        <div className="col-lg-4 col-sm-12 alignRight alignWithPageHeader">
                            {this._renderAddNewBtn()}
                        </div>
                    </div>

                    <div className="row form-container">
                        <div className="col">
                            <form
                                className="frmDiv"
                                style={{ paddingTop: '10px' }}
                            >
                                <BreadCrumbWrapper items={items} />
                                <div className="p-fluid formgrid grid w-100 mb-4">
                                    <div className="field col-3 md:col-3">
                                    <label htmlFor="status">
                                            Status
                                        </label>
                                        <Dropdown
                                            id="status"
                                            name="status"
                                            value={this.state.status}
                                            onChange={this.handleStatusChange}
                                            options={statusOptions}
                                            optionLabel="name"
                                            placeholder="Select"
                                        />
                                    </div>

                                    <div
                                        className="field col-3 md:col-3"
                                        style={{ display: 'flex', alignItems: 'flex-end' }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <Button
                                                type="button"
                                                label="Search"
                                                className="mr-4"
                                                onClick={(e) =>
                                                    this._searchStoreTenders(e)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="title_devide" />
                                <PrimeTable
                                    list={storeTags}
                                    columns={columns}
                                    actionColumn={this.actionTemplate}
                                    linkColumn={this.linkTemplate}
                                    pageSizeChangecallback={(pageSize) => {
                                        this.setState(
                                            produce(this.state, (draft) => {
                                                if (draft.pageSize < pageSize) {
                                                    draft.currPage = 1;
                                                }
                                                draft.pageSize = pageSize;
                                            }),
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                    pageChangeCallback={(currPage) => {
                                        this.setState(
                                            {
                                                currPage: currPage,
                                            },
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                />
                            </form>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {isSuccess ? this.state.message : ''}
                            </div>
                        }
                    />

                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div className="deleteAlertDiv">
                                Are you sure you want to delete this config?
                                <div style={{ margin: '2em' }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec deletBtn"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded deletBtn"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />

                    <ConfirmAlert
                        show={errormsg}
                        handleClose={(event) =>
                            this.hideErrorAlertModal(event, refresh)
                        }
                        children={
                            <div
                                style={
                                    errormsg
                                        ? {
                                              padding: '2em',
                                              color: 'red',
                                              fontWeight: '500',
                                          }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {errormsg ? `${errormsg}.` : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }

    formatDate = (inpDate) => {
        let convertedDate = new Date(inpDate);
        let month = convertedDate.getMonth() + 1;
        let day = convertedDate.getDate();
        let year = convertedDate.getFullYear();
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        let shortDate = `${day}-${month}-${year}`;
        return shortDate;
    };

    _searchStoreTenders = (e) => {
        if (e) {
            e.preventDefault();
        }
        const { storeid, status } = this.state;

        this.requestBody = {};
        const queryParams = [];

        if (storeid !== '') {
            queryParams.push(`storeid=${storeid}`);
            this.setState({ storeiderror: '' });
            this.requestBody['storeid'] = storeid;
        } else {
            this.setState({ storeiderror: 'Select store' });
            return;
        }

        if (status && status.id) {
            queryParams.push(`status=${status.id}`);
            this.requestBody['status'] = status.id;
        }

        //validate the filters
        if (queryParams.length <= 0) {
            console.log('select atlease one filter');
            return;
        }

        queryParams.push(`tagtype=${this.tagtype}`);
        queryParams.push(`taggroup=${this.taggroup}`);
        queryParams.push(`pagesize=${this.pageSize}`);
        queryParams.push(`pageno=${this.currPage}`);
        //query
        this.props.dispatch(storeTagsSearchByFields(queryParams.join('&')));
    };

    _clearFiltersAndResults = (e) => {
        if (e) {
            e.preventDefault();
        }
        //clear all the filters
        this.setState({
            status: '',
            storeiderror: '',
        });
        //clear the search results
    };

    _setStore = (p1, p2) => {
        this.setState(
            { storeid: p1, storelabel: p2, search_value: p1, storeiderror: '' },
            () => {
                localStorage.setItem('storeid', p1);
                localStorage.setItem('storelabel', p2);
            }
        );
    };
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

//export default connect(mapStateToProps)(DisplayStoreTenders);
export default withRouter(connect(mapStateToProps)(DisplayStoreTenders));


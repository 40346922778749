import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import * as CONSTANTS from '../../../../constants/constants';
import {
    createStoreTerminal,
    storeTerminalsSearchByFields,
    searchGeneral,
    createStoreTagBatch
} from '../../../../actions/actions';

const StoreTagsObj = {
    terminalOptions:
        'topup,receiptheader,receiptfooter,invoiceheader,taxreportheader,topupreportheader,topupreceiptheader',
    terminalTenders: 'tender',
    birinfo: 'birinfo',
    truckinfo: 'truckinfo',
    creditinfo: 'creditinfo'
};

const DuplicateTerminalForm = (props) => {
    const {
        selectedTerminal,
        storeList,
        onSuccessTerminalCreate,
        crudData,
        queryData,
    } = props;
    const { title, terminalid, storeid } = selectedTerminal;
    const { storeTags, storeTerminals } = queryData
    const [terminalIdError, setTerminalIdError] = useState('')

    useEffect(() => {
        getTerminalTagValues()
        getAllTerminal(storeid)
    }, []); //get all terminal tags, get all terminal

    useEffect(() => {
        const { createUpdateStoreTerminalSuccess } = props.crudData;
        if (createUpdateStoreTerminalSuccess) {
            props.dispatch(createStoreTagBatch({ body: getTerminalTagsPayload() }));
            onSuccessTerminalCreate();
        }
    }, [crudData]);

    const formik = useFormik({
        initialValues: {
            storeid: '',
            title: title + '-copy',
            terminalid: '',
            terminalOptions: true,
            terminalTenders: true,
            birinfo: true,
            truckinfo: true,
            creditinfo: true
        },
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};
            if (!data.storeid) {
                errors.storeid = 'Store id is required.';
            }
            if (!data.title) {
                errors.title = 'Title is required.';
            }
            if (!data.terminalid) {
                errors.terminalid = 'Terminal id is required.';
            }
            if (data.terminalid && validateTerminalId(data.terminalid)) {
                errors.terminalid = 'Terminal id is exist.';
            }
            return errors;
        },
        onSubmit: (values) => {
            applyDuplicateTerminal(values);
            getTerminalTagsPayload()
        },
    });

    const validateTerminalId = terminalid => {
        if (storeTerminals && storeTerminals.length > 0) {
            const terminal = storeTerminals.find(element => element.terminalid === terminalid)
            if (terminal && terminal.id) {
                return true
            }
        }
        return false
    }

    const getAllTerminal = storeid => {
        const queryParams = [];
        queryParams.push(`storeid=${storeid}`);
        queryParams.push(`devicegroup=terminal`);
        props.dispatch(
            storeTerminalsSearchByFields(
                queryParams.join('&'),
                CONSTANTS.SEARCH_STORE_TERMINALS_SUCCESS_SILENT,
                CONSTANTS.SEARCH_STORE_TERMINALS_FAILURE_SILENT
            )
        );
    }

    const handleTerminalIdChange = e => {
        setTerminalIdError('')
        const inputValue = e.target.value;
        const digitsOnly = inputValue.replace(/\D/g, ''); // Remove non-digit characters

        if (digitsOnly.length <= 3) {
            checkTerminalId(digitsOnly)
            formik.setFieldValue('terminalid', digitsOnly)
        }
    }

    const handleStoreChange = e => {
        setTerminalIdError('')
        getAllTerminal(e.target.value)
        formik.setFieldValue('storeid', e.target.value)
    }

    const checkTerminalId = (terminalid) => {
        if (storeTerminals && storeTerminals.length > 0) {
            const terminal = storeTerminals.find(element => element.terminalid === terminalid)
            if (terminal && terminal.id) {
                setTerminalIdError('Terminal already exist')
            }
        }
    };

    const getTerminalTagValues = () => {
        const {
            birinfo,
            truckinfo,
            creditinfo,
            terminalOptions,
            terminalTenders} = formik.values
        const { terminalid } = selectedTerminal
        const tags = [
            terminalOptions && StoreTagsObj.terminalOptions,
            terminalTenders && StoreTagsObj.terminalTenders,
            birinfo && StoreTagsObj.birinfo,
            truckinfo && StoreTagsObj.truckinfo,
            creditinfo&& StoreTagsObj.creditinfo
        ].filter(Boolean);
        let tagsType = tags.join(',');
        const requestBody = {
            body: {
                tagtype: tagsType,
                storeid: storeid,
                taggroup: 'tprops',
                terminalid
            },
        };
        props.dispatch(
            searchGeneral(
                'system/v1/store/tag/search/fields',
                requestBody,
                CONSTANTS.SEARCH_STORE_TAGS_SUCCESS,
                CONSTANTS.SEARCH_STORE_TAGS_FAILURE
            )
        );
    };

    const getTerminalTagsPayload = () => {
        const { terminalOptions, terminalTenders} = formik.values
        let terminalTags = storeTags
        let terminalTagsData = terminalTags.map((item) => {
            const {
                id,
                storeid,
                lastupdatedby,
                lastupdated,
                createdon,
                ...rest
            } = item;
            const restData = Object.fromEntries(Object.entries(rest));
            return {
                ...restData,
                storeid: formik.values.storeid,
                terminalid: formik.values.terminalid
            };
        });
        if (!terminalTenders) {
            terminalTagsData = terminalTagsData.filter(data => data.tagtype !== StoreTagsObj.terminalTenders)
        }
        if (!terminalOptions) {
            const storeOptionsArr = StoreTagsObj.terminalOptions.split(',')
            terminalTagsData = terminalTagsData.filter((item) => !storeOptionsArr.includes(item.tagtype));
        }
        return terminalTagsData
    }

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const applyDuplicateTerminal = (values) => {
        const { id, deviceid, storeid, terminalid, ...rest } = selectedTerminal;
        const terminalData = Object.fromEntries(Object.entries(rest));
        terminalData['storeid'] = values.storeid;
        terminalData['title'] = values.title;
        terminalData['terminalid'] = values.terminalid;
        terminalData['deviceid'] = getDeviceId();
        props.dispatch(createStoreTerminal({ body: terminalData }));
    };

    const getDeviceId = () => {
        return 'T' + Math.floor(Math.random() * 899999 + 100000);
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="row p-fluid formgrid grid w-100">
                    <div
                        className="field col-12 col-md-6"
                        style={{ paddingLeft: '0px' }}
                    >
                        <label
                            htmlFor="storeid"
                            className={classNames({
                                'p-error': isFormFieldValid('storeid'),
                            })}
                        >
                            Store Id*
                        </label>
                        <Dropdown
                            id="storeid"
                            name="storeid"
                            value={formik.values.storeid}
                            onChange={handleStoreChange}
                            options={storeList}
                            optionLabel="label"
                            placeholder="Select"
                        />
                        {getFormErrorMessage('storeid')}
                    </div>
                    <div
                        className="field col-12 col-md-6"
                        style={{ paddingLeft: '0px' }}
                    >
                        <label
                            htmlFor="title"
                            className={classNames({
                                'p-error': isFormFieldValid('title'),
                            })}
                        >
                            Title*
                        </label>
                        <InputText
                            id="title"
                            type="text"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                        />
                        {getFormErrorMessage('title')}
                    </div>
                    <div
                        className="field col-12 col-md-6"
                        style={{ paddingLeft: '0px' }}
                    >
                        <label
                            htmlFor="terminalid"
                            className={classNames({
                                'p-error': isFormFieldValid('terminalid'),
                            })}
                        >
                            Terminal Id*
                        </label>
                        <InputText
                            id="terminalid"
                            type="text"
                            name="terminalid"
                            value={formik.values.terminalid}
                            onChange={handleTerminalIdChange}
                            disabled={!formik.values.storeid}
                        />
                        {getFormErrorMessage('terminalid')}
                        <small className="p-error">{terminalIdError}</small>
                    </div>
                    <div className='field col-12 col-md-6' />
                    <div className="field-checkbox col-12 col-md-3 pl-0">
                        <Checkbox
                            inputId="terminalOptions"
                            name="terminalOptions"
                            value="formik.values.terminalOptions"
                            onChange={formik.handleChange}
                            checked={formik.values.terminalOptions}
                        />
                        <label htmlFor="terminalOptions">Terminal Options</label>
                    </div>
                    <div className="field-checkbox col-12 col-md-3 pl-0">
                        <Checkbox
                            inputId="terminalTenders"
                            name="terminalTenders"
                            value="formik.values.terminalTenders"
                            onChange={formik.handleChange}
                            checked={formik.values.terminalTenders}
                        />
                        <label htmlFor="terminalTenders">Terminal Tenders</label>
                    </div>
                </div>

                <div style={{ textAlign: 'right' }}>
                    <Button type="submit" label="Apply" icon="pi pi-check" />
                </div>
            </form>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};
export default connect(mapStateToProps)(DuplicateTerminalForm);

import React from "react";
import { connect } from "react-redux";
import { produce } from 'immer';
import CategoryTree from '../../components/CategoryTree'
import {
  queryStoreCategoryInfo,
  crudReset,
  searchGeneral,
  exportReport,
} from  '../../actions/actions';
import SimpleTable from '../../components/SimpleTable'
import "react-tabs/style/react-tabs.css";


import StatusComp from '../../components/StatusComp';
import ConfirmAlert from '../../components/ConfirmAlert'
import { filterFormatter, permissions } from '../../helpers/Util'
import { productPriceDetailsReport } from '../../actions/actions'
import StoreSearchSingle from "../system/users/StoreSearchSingle";
import { SEARCH_JOB_TASK_LOGS_FAILURE, SEARCH_JOB_TASK_LOGS_SUCCESS } from "../../constants/constants";


class ExportAndDownloadProductPriceDetailsReport extends React.PureComponent {

  constructor(props) {
    
    super(props);

    this.companyId = localStorage.getItem("company_id")
    this.group = "DE_REPORTS_PRODUCT_PRICE_REPORT"
    this.module = "REPORTS"
    this.allPermissions = []

    this.currPage = 1
    this.pageSize = 10

    this.state = {
      currPage: this.currPage,
      pageSize: this.pageSize,

      categorycodes: "",
      categories: "",
      productcode: '',
      storeid: "",
      status: "Active",
      mapProducts: false,
      formError: false,
      formErrorMsg: "",
      tempMappedProd: this.props.queryData.storeCategoryMapping || [],
      showSecForm: "false",
      isView: false,
      selectedProdId: "",
      prodData: {},
      storeproductdata:[],
      s_storelabel: "",
      storeiderror: "",
      categoryerror: "",
      showTable: false,

      params: {},
      showExportReport: false,

      tableCols: [
        {
          dataField: "title",
          text: "Path",
          sort: true
        },
        {
          dataField: "status",
          text: "Status",
          sort: true
        },
        {
          dataField: "additionalfields",
          text: "Filters",
          sort: false,
          formatter: filterFormatter
        },
        {
          dataField: "createdon",
          text: "Exported On",
          sort: true
        },
        {
          dataField: "lastupdatedby",
          text: "Generated by",
          sort: true
        },
        {
          dataField: "",
          text: "Actions",
          formatter: this.cellFormatter,
          formatExtraData: {
            action: "ED"
          },
          classes: () => {
            return "lastTd";
          }
        }
      ]
    };
  }
  
  componentDidMount = () => {
    //({ body: {} }, "PD01"));
  };

  componentWillMount = () => {

    if (this.props.params) {
      this.setState({
        storeid: this.props.params.storeid,
        s_storelabel: this.props.params.s_storelabel,
        // categories: this.props.params.categories,
        //categorycode: this.props.params.categorycode,
        status: this.props.params.status,
        //categorycodes: this.props.params.categorycode
        })
    }

    this.callQueryApiForFetch("ONLOAD")
    this.allPermissions = permissions(this.module, this.group)

    
  }

  getStoreData= (obj1) =>{
    if (typeof obj1 !== "undefined") {
      var result = obj1.map(e => {
        this.compareObject(e);
        if ("children" in e)
          e.children = e.children.map(child => {
            this.compareObject(child);
            if ("children" in child)
              child.children.map(c => {
                this.compareObject(c);

                if ("children" in c)
                  c.children.map(m => {
                    this.compareObject(m);
                  });
              });
            return child;
          });
        return e;
      });
      return result;
    }
  }

  compareObject = (col) => {
    const { storeCategoryMapping } = this.props.queryData;
    var result = storeCategoryMapping.map(e => {
      if (e.categorycodes == col.categorycode) {
        e["categorytitle"] = col.title;
      }
      return e;
    });
  }

  componentDidUpdate = (prevProps) => {
    const { categoryTree, storeCategoryMapping} = this.props.queryData;
    const { storeCategoryMapping: prevstoreCategoryMapping } = prevProps.queryData;
    if(storeCategoryMapping !== prevstoreCategoryMapping) {
      if(storeCategoryMapping.length > 0){
        this.getStoreData(categoryTree.children); 
        this.setState({
          storeproductdata: storeCategoryMapping
        })      
      }
      else{
        this.setState({
          storeproductdata: []
        }) 
      }
    }
  }

  hideModal = () => {
    this.setState({ mapProducts: false });
  };

  componentWillReceiveProps = prevProps => {
   // this.setState({ treeData: newProps.queryData.categoryTree });
  };


  _searchProductsMapped = event => {
    const { categorycodes, storeid, status, pageSize, currPage } = this.state;
    this.props.dispatch(
      queryStoreCategoryInfo(
        {
          body: {
            categorycode: categorycodes,
            storeid: storeid,
            status: status,
            pagesize: pageSize,
            pageno: currPage,
          }
        },
        categorycodes,
        storeid
      )
    );
  };
  
  rename = obj => {
    for (var prop in obj) {
      if (Array.isArray(obj[prop])) {
        obj[prop] = obj[prop].map(this.rename);
      }

      if (prop === "subcategories") {
        obj.children = obj[prop];
        delete obj[prop];
      }

      if (prop === "title") {
        obj['subtitle'] = obj['categorycode']
      }
    }

    return obj;
  };
  
  _mapProductsToStore = event => {
    if (event) event.preventDefault();
    this.setState({ mapProducts: true });
  };

  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      storeiderror: "",
      categoryerror: ""
    });
  };

  validateForm = () => {
    this._clearErrorMessages();
    const { storeid, categorycodes } = this.state;
    let valid = true;
    if (storeid === "" || storeid === null) {
      this.setState({ storeiderror: "Please select store id" });
      valid = false;
    }
    return valid;
  };


  viewExportReport = (row) => {
    this.setState({params: {
      storeid: this.state.storeid,
      s_storelabel: this.state.s_storelabel,
    }}, () => {
      this.setState({showExportReport: true})
    })
  }

  hideAlertModal = e => {
    this.setState({ message: "", action: '' });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.queryData.reportexport = {}
    this.callQueryApiForFetch("ONLOAD");
  };

  // _hideExport = () => {
  //   this.props.queryData.reportexport = {}
  //   this.setState({showExportReport: false})
  // }

  exportReport = (e) => {
    if (e) e.preventDefault();

    const {
      storeid,
      status,
      categorycodes: categorycode,
      productcode
    } = this.state

    this.requestBody = {}
    const queryParams = []

    console.log('storeid::', this.state.storeid)
    if (this.state.storeid === '' || this.state.storeid === null) {
      this.setState({storeiderror: 'Please select the store'})
      return
    } else {
      this.setState({storeiderror: ''})
      this.requestBody['storeid'] = storeid
      queryParams.push(`storeid=${storeid}`)
    }

    if (status !== '') {
      this.requestBody['status'] = status
      queryParams.push(`status=${status}`)
    }

    if (categorycode !== '') {
      this.requestBody['categorycode'] = categorycode
      queryParams.push(`categorycode=${categorycode}`)
    }
    
    if (productcode !== '') {
      this.requestBody['productcode'] = productcode
      queryParams.push(`productcode=${productcode}`)
    }


    // queryParams.push(`pagesize=${this.pageSize}`)
    // queryParams.push(`pageno=${this.currPage}`)
    console.log('requstBody::', this.requestBody)
    //query
    this.setState({message: 'Exported Sucessfully'})
    this.props.dispatch(exportReport('/sales/v1/product/report/price/details/export', queryParams.join('&')))
  }

  _validateAndSearch = (event) => {

    if (event) event.preventDefault();

    const {
      storeid,
      status,
      categorycodes: categorycode
    } = this.state

    this.requestBody = {}
    const queryParams = []

    console.log('storeid::', storeid)
    if (storeid === '' || storeid === null) {
      this.setState({storeiderror: 'Please select the store'})
      return
    } else {
      this.setState({storeiderror: ''})
      this.requestBody['storeid'] = storeid
      queryParams.push(`storeid=${storeid}`)
    }

    if (status !== '') {
      this.requestBody['status'] = status
      queryParams.push(`status=${status}`)
    }

    if (categorycode !== '') {
      this.requestBody['categorycode'] = categorycode
      queryParams.push(`categorycode=${categorycode}`)
    }

    queryParams.push(`pagesize=${this.pageSize}`)
    queryParams.push(`pageno=${this.currPage}`)
    console.log('requstBody::', this.requestBody)
    //query
    this.props.dispatch(productPriceDetailsReport(queryParams.join('&')))
  }


  /*
  _validateAndSearch = event => {
    if (event) event.preventDefault();
    if (this.validateForm()) {
      this.setState({ showTable: true });
      //reset the pagination props
      this.setState({ pageSize: this.pageSize, currPage: this.currPage }, () => {
        this._searchProductsMapped(event);
      })
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };
  */

  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };

  _clearCriteria = event => {
    if (event) event.preventDefault();
    this._clearErrorMessages();
    this.setState({ showTable: true, storeid: "", categorycodes: "" });
  };

  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, s_storelabel: p2 },()=>{
      localStorage.setItem("storeid", p1);
      localStorage.setItem("storelabel", p2);
    });
  };

  _renderSearchCiteria = () => {
    const { categoryTree } = this.props.queryData;
    const newArrayOfObj = [categoryTree].map(this.rename);
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-3 col-sm-12 ">
            <label htmlFor="storeid" className="floatLeft required">
              Select Store
            </label>
            <StoreSearchSingle value={this.state.s_storelabel} onSelectStore={this._onSelectStore} checkStoreAccess={true}/>
            <div className="invalid-feedback">{this.state.storeiderror}</div>
          </div>
          <div className="form-group col-lg-2 col-sm-12">
            <label htmlFor="status" className="floatLeft">
              Select Status
            </label>
            <StatusComp
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>
          <div className="form-group col-lg-2 col-sm-12">
            <label htmlFor="categorycodes" className="floatLeft">
              Select Product Category
            </label>
            <CategoryTree
              treeData={newArrayOfObj}
              categoryTree={categoryTree}
              setCategoryCode={(code, title) => {
                this.setState({ categorycodes: code, categories: title });
              }}
              value={this.state.categories}
            ></CategoryTree>
            {/* <div className="invalid-feedback">{this.state.categoryerror}</div> */}
          </div>
          <div className="form-group col-lg-2 col-sm-12">
            <label htmlFor="productcode" className="col-form-label ">
              Product Code
            </label>
            <input
                type="text"
                className="form-control"
                id="productcode"
                value={this.state.productcode}
                onChange={event =>
                    this.setState({ productcode: event.target.value })
                }
            />
          </div>
          <div className="col-lg-3 col-sm-12 btn-container1" style={{ marginTop: "1em" }}>
              <button
                type="submit"
                onClick={(e) => this.exportReport(e)}
                className="btn btn-themes btn-rounded"
              >
                Export
              </button>
            {/* 
              <button
                type="submit"
                onClick={this._clearFiltersAndResults}
                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
              >
                Clear
              </button> */}
            </div>
        </div>
      </React.Fragment>
    );
  };

  _onSelectStore = (selectedStores) => {
    this._setTenant(selectedStores.value, selectedStores.label)
  }

  cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.status !== 'exported') { return }
    return (
      <span>
          <img
            title="Download"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/list_download.svg`}
            onClick={() => this.download(row)}
          />
      </span>
    );
  };

  download = (row) => {
    var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          //var url =  localStorage.getItem('imagepath') + "/media" + row.title;
          var url =  localStorage.getItem('imagepath') + row.title;
          console.log('url::', url)
          link.setAttribute("href", url);

          var exportedFilenmae = row.tasktype + '.csv' || 'export.csv';
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
  }

  // API Calls
  createFetchUrl = fetchType => {

    const {
      pageSize,
      currPage
    } = this.state;

    if (fetchType === 'ONLOAD') {
      const requestBody = {
        body: {
          pagesize: pageSize,
          pageno: currPage,
          tasktype: 'sales_price_details'
        }
      };
      console.log("...requestBody for ONLOAD::", requestBody)
      return requestBody;
    }

    console.log('tasktype:::', this.requestBody['tasktype'])
    if (this.requestBody['tasktype'] === undefined) {
      this.requestBody['tasktype'] = 'sales_price_details'
    }

    const requestBody = {
      body: { ...this.requestBody, pagesize: pageSize, pageno: currPage }
    };
    
    console.log("...requestBody::", requestBody)
    return requestBody;
};

callFetch = fetchType => {
  this.props.dispatch(
    searchGeneral(
      "/system/v1/jobtask/search/fields",
      this.createFetchUrl(fetchType),
      SEARCH_JOB_TASK_LOGS_SUCCESS,
      SEARCH_JOB_TASK_LOGS_FAILURE,
    )
  );
};

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetch("ONLOAD");
        break;
      case "PAGECLICK":
        this.callFetch()
        break;
    }
  };

  styleDisplayRow = (row, rowIndex) => {
    return {};
  }
  
  render() {
    const { showSecForm, isView } = this.state;
    const { 
      errormsg: queryError,
      servererror: serverError,
      jobtasklogs,
      reportexport
    } = this.props.queryData;
   
    return (  
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h2 className="page-header">Product Price Report</h2>
            </div>
            {/* <div className="col-lg-6 col-sm-12 alignRight alignWithPageHeader">
              {this._renderExportReportBtn()}
            </div> */}
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv" onSubmit={this._validateAndSearch}>
                <input type="submit" className="d-none"></input>
                <div className="row">
                  {this._renderSearchCiteria()}
                </div>
                <div className="title_devide" />
                {/* <div className="invalid-feedback">{errormsg}</div> */}
                
                <SimpleTable
                  columns={this.state.tableCols}
                  rows={jobtasklogs}
                  pageChangeCallback={(currPage) => {
                    this.setState({
                      currPage: currPage
                    }, () => {
                      this.callQueryApiForFetch("PAGECLICK");
                    })
                  }}
                  callback={(pageSize) => {
                    this.setState(produce(this.state, draft => {
                      if (draft.pageSize < pageSize) {
                        draft.currPage = 1
                      }
                      draft.pageSize = pageSize
                    }), () => {
                      this.callQueryApiForFetch("PAGECLICK")
                    })
                  }}
                  // withoutSearchBar={true}
                  withPageSize={this.state.pageSize}
                  withCurrPage={this.state.currPage}
                  withPageFeedback={true}
                />
               
              </form>
            </div>
          </div>
          <ConfirmAlert
            show={(reportexport && reportexport.status === 'exporting') ? true : false}
            handleClose={this.hideAlertModal}
            children={
              <div
                style={
                  (reportexport && reportexport.status === 'exporting')
                    ? { padding: "2em", color: "green" }
                    : { padding: "2em", color: "red" }
                }
              >
                {(reportexport && reportexport.status === 'exporting') ? this.state.message : ""}
              </div>
            }
          />
        </div>
       
        <div className="row btn-container form-button">
          <div className="col-sm-12 col-lg-12">
              {this._renderMainButton()}
          </div>
        </div>
        
      </React.Fragment>
    );
  }
  

_renderMainButton = () => {

  return (
      <React.Fragment>
          <button
              type="submit"
              className="btn btn-themes btn-rounded btn-sec link-sec-btn"
              onClick={() => {
                this.props.queryData.errormsg = ''
                this.props.queryData.servererror = ''
                this.props.crudData.errormsg = ''
                  this.props._hideExport();
              }}
          >
              Back
          </button>
      </React.Fragment>
  );
};

_searchJobTaskLogs = (e) => {
  if (e) { e.preventDefault() }
  console.log('_search Logs')
  const {
    storedate,
  } = this.state
  
  console.log('state::', this.state)

  this.requestBody = {}
  const queryParams = []

  

  queryParams.push(`tasktype=sales_price_details`)
  this.requestBody["tasktype"] = 'sales_price_details'

  queryParams.push(`pagesize=${this.pageSize}`)
  queryParams.push(`pageno=${this.currPage}`)

  console.log('requstBody::', this.requestBody)
  //query
  // this.props.dispatch(exportedCouponTransactions(queryParams.join('&')))
}
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(ExportAndDownloadProductPriceDetailsReport);

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    getStoreById,
    createInvTransaction,
    updateInvTransaction,
    queryInvTrx,
    searchGeneral,
} from '../../../actions/actions';
// Local Imports
// Local Imports
import ConfirmAlert from '../../../components/ConfirmAlert';
import { crudReset } from '../../../actions/actions';
import TenantCode from '../../../components/TenantCode';
import Autocomplete from './../../../components/Autocomplete';
import StatusComp from './../../../components/StatusComp';
import Modal from 'react-awesome-modal';
import { produce } from 'immer';
import DatePicker from 'react-date-picker';
import DynamicGroupRadio from '../../../components/DynamicGroupRadio';
import SimpleDropdown from '../../../components/SimpleDropdown';
import StoreSearchSingle from '../../system/users/StoreSearchSingle';
import { formatDate } from '../../../helpers/Util';

class AddStock extends React.PureComponent {
    invtypeoptions = [
        { id: '', name: 'Select' },
        { id: 'Stock', name: 'Stock' },
        { id: 'Reversal', name: 'Reversal' },
    ];
    transtypeoptions = [
        { id: '', name: 'Select' },
        { id: 'po', name: 'Purchase Order' },
    ];

    transgrpoptions = [
        { id: '', name: 'Select' },
        { id: 'elevate', name: 'Elevate' },
    ];
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            showProdModal: false,
            showProdModel: false,
            isEdit: false,
            title: '',
            // Formrelated
            storeid: '',
            storelabel: '',
            productcode: '',
            qty: '',
            trxdate: new Date(),
            trxgroup: '',
            trxtype: '',
            trxtypevalue: '',
            trxno: '',
            status: 'Active',
            showStockPage: false,
            warehouseid: '',
            invtype: 'Stock',
            updateMode: false,
            productmodel: '',
            remark: '',
            invallocationid: 1,
            // Errors
            qtyerror: '',
            productmodelerror: '',
        };
    }

    getCreatePayload = () => {
        const {
            storeid,
            productcode,
            qty,
            trxdate,
            status,
            trxgroup,
            trxtypevalue,
            trxtype,
            invtype,
            id,
            warehouseid,
            updateMode,
            productmodel,
            trxno,
            remark,
            invallocationid
        } = this.state;
        let body = {
            storeid,
            productcode,
            productmodel,
            qty: parseInt(qty),
            trxdate: trxdate ? formatDate(trxdate) : '',
            trxgroup,
            trxtype,
            trxtypevalue,
            warehouseid,
            invtype,
            invallocationid,
            status,
            additionalfields: {
                remark,
            },
        };
        if (updateMode === true) {
            body['id'] = id;
        }
        if (trxno !== '') {
            body['trxno'] = trxno;
        }
        const payload = {
            body: body,
        };
        return payload;
    };

    componentWillMount = () => {
        if (this.props.propDetails && this.props.propDetails.fields) {
            const { propDetails } = this.props;
            if (typeof propDetails.productcode !== 'undefined') {
                this.setState(
                    {
                        storeid: propDetails.storeid,
                        storelabel: propDetails.storelabel,
                        productcode: propDetails.productcode,
                        status: propDetails.status,
                    },
                    () => {
                        this.props.dispatch(
                            searchGeneral(
                                'sales/v1/product/search/fields',
                                {
                                    body: {
                                        productcode: propDetails.productcode,
                                    },
                                },
                                'SEARCH_BY_PRODUCT_SUCCESS',
                                'SEARCH_BY_PRODUCT_FAILURE',
                            ),
                        );
                    },
                );
            }
        }
        this.props.dispatch(
            searchGeneral(
                'cms/v1/prop/config/search/fields',
                {
                    body: {
                        propgroup: 'size',
                        language: 'en',
                        status: 'Active',
                    },
                },
                'SEARCH_SIZECONFIG_SUCCESS',
                'SEARCH_SIZECONFIG_FAILURE',
            ),
        );

        this.props.dispatch(
            searchGeneral(
                'cms/v1/prop/config/search/fields',
                {
                    body: {
                        propgroup: 'color',
                        language: 'en',
                        status: 'Active',
                    },
                },
                'SEARCH_COLORCONFIG_SUCCESS',
                'SEARCH_COLORCONFIG_FAILURE',
            ),
        );

        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            if (rec) {
                const {
                    id,
                    storeid,
                    productcode,
                    qty,
                    trxdate,
                    trxgroup,
                    trxtype,
                    trxtypevalue,
                    warehouseid,
                    invtype,
                    status,
                    trxno,
                    productmodel,
                    additionalfields,
                } = rec;
                let tdate = this.toDate(trxdate);
                this.props.dispatch(
                    searchGeneral(
                        'sales/v1/product/search/fields',
                        {
                            body: { productcode },
                        },
                        'SEARCH_BY_PRODUCT_SUCCESS',
                        'SEARCH_BY_PRODUCT_FAILURE',
                    ),
                );
                this.props.dispatch(getStoreById(storeid));
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : '';
                        draft.storeid = storeid ? storeid : '';
                        draft.productcode = productcode ? productcode : '';
                        draft.productmodel = productmodel ? productmodel : '';
                        draft.qty = qty ? qty : '';
                        draft.trxno = trxno ? trxno : '';
                        draft.trxdate = trxdate ? tdate : '';
                        draft.trxgroup = trxgroup ? trxgroup : '';
                        draft.trxtype = trxtype ? trxtype : '';
                        draft.trxtypevalue = trxtypevalue ? trxtypevalue : '';
                        draft.invtype = invtype ? invtype : '';
                        draft.warehouseid = warehouseid ? warehouseid : '';
                        draft.status = status ? status : 'Active';
                        draft.remark = additionalfields.remark
                            ? additionalfields.remark
                            : '';
                    }),
                );
            }
        }
    };

    toDate = (dateStr) => {
        let totalStr = dateStr.split(' ');
        if (totalStr.length > 1) {
            var st = totalStr[0];
            var parts = st.split('-');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        } else {
            var parts = dateStr.split('-');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
    };

    componentDidUpdate = (prevProps) => {
        const { invTrxData } = this.props.queryData;
        const { invTrxData: previnvTrxData } = prevProps.queryData;
        if (invTrxData !== previnvTrxData) {
            if (invTrxData.length > 0) {
                this.setState({
                    updateMode: true,
                });
            }
        }
    };

    _showProdModel = (event) => {
        this.setState({
            showProdModel: true,
        });
        if (event) event.preventDefault();
    };

    _closeProdModel = (event) => {
        this.setState({ showProdModel: false });
        if (event) event.preventDefault();
    };

    getPropArr = (objlist) => {
        const proparr = Object.values(objlist);
        const newList = proparr.map((i) => ({
            group: i.propgroup,
            label: i.propvalue,
            value: i.propcode,
        }));
        return newList;
    };

    setProductModel = (values) => {
        this.setState({
            productmodel: values,
        });
    };

    _renderProductModel = () => {
        const { sizePropList, colorPropList, listByProduct } =
            this.props.queryData;
        const { productmodel } = this.state;
        console.log(listByProduct);
        const colorlist = listByProduct[0].productcolor.split(',').map((e) => ({
            propcode: e,
            propgroup: 'Color',
            propvalue: e,
        }));
        const sizelist = listByProduct[0].productsize.split(',').map((e) => ({
            propcode: e,
            propgroup: 'Size',
            propvalue:
                e == 'xs'
                    ? 'extra small'
                    : e == 's'
                      ? 'small'
                      : e == 'l'
                        ? 'large'
                        : e == 'm'
                          ? 'medium'
                          : e == 'xl'
                            ? 'extra large'
                            : e,
        }));
        const colorprop = this.getPropArr(colorlist);
        const sizeprop = this.getPropArr(sizelist);
        let splitcolor = '',
            splitsize = '',
            colorselected = [],
            sizeselected = [];
        if (productmodel !== '') {
            splitcolor = productmodel.split('-')[0];
            splitsize = productmodel.split('-')[1];
            colorselected = colorprop.filter((e) => e.value === splitcolor);
            sizeselected = sizeprop.filter((e) => e.value === splitsize);
        }
        return (
            <Modal
                visible={this.state.showProdModel}
                width="70%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeProdModel()}
            >
                <div className="comboform">
                    <div className="row">
                        <div className="col-lg-12 col-xs-12 col-md-12 alignRight">
                            <a
                                onClick={this._closeProdModel}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-12 groupdiv">
                            <DynamicGroupRadio
                                showProp={true}
                                colorprop={colorprop}
                                sizeprop={sizeprop}
                                colorselected={colorselected}
                                sizeselected={sizeselected}
                                setProductModel={this.setProductModel}
                                hidePropModal={this._closeProdModel}
                            ></DynamicGroupRadio>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    componentWillReceiveProps = (prevProps) => {
        //if (this._isEditMode()) {
        const { storeArray, prodKeySearch } = this.props.queryData;
        if (storeArray) {
            storeArray.storeid === this.state.storeid
                ? this.setState({ storelabel: storeArray.title })
                : this.setState({ storelabel: this.state.storelabel });
        }
        if (prodKeySearch.length > 0) {
            let getbycode = prodKeySearch.filter(
                (e) => e.productcode === this.state.productcode,
            );
            let gettitle = getbycode.length > 0 ? getbycode[0].title : '';
            this.setState({ title: gettitle });
        }
        //}
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            qtyerror: '',
            productmodelerror: '',
        });
    };
    validateForm = () => {
        this._clearErrorMessages();
        const { qty, productmodel } = this.state;
        let valid = true;
        let showFields = window && window.config.enableProductModel;
        if (showFields === true) {
            if (productmodel === '') {
                this.setState({
                    productmodelerror: 'Please enter product model',
                });
                valid = false;
            }
        }
        if (qty == 0 || qty == '') {
            this.setState({
                qtyerror: 'Please enter quantity',
            });
            valid = false;
        }
        return valid;
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.clearAllFields();
    };

    clearAllFields() {
        this.setState(
            {
                storeid: '',
                storelabel: '',
                productcode: '',
                qty: '',
                trxdate: '',
                trxgroup: '',
                trxtype: '',
                trxtypevalue: '',
                status: 'Active',
                showStockPage: false,
                warehouseid: '',
                invtype: '',
            },
            () => {
                this.props.updateStock();
            },
        );
    }

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg === 'Invalid Parameter') {
            return '';
        } else if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _createInvTransaction = () => {
        const { invTrxData } = this.props.queryData;
        if (this.validateForm() === true) {
            this.props.dispatch(createInvTransaction(this.getCreatePayload()));
            // if(invTrxData.length > 0){
            //   this.props.dispatch(updateInvTransaction(this.getCreatePayload()));
            // }
            // else {
            //   this.props.dispatch(createInvTransaction(this.getCreatePayload()));
            // }
        }
    };

    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 });
    };

    _onSelectStore = (selectedStore) => {
        this._setTenant(selectedStore.value, selectedStore.label);
    };

    _showProductModal = (event) => {
        this.setState({
            showProdModal: true,
        });
        if (event) event.preventDefault();
    };
    _closeProductModal = (event) => {
        this.setState({ showProdModal: false });
        if (event) event.preventDefault();
    };
    _updateProdCode = (param, param1, param2) => {
        this.setState(
            { productcode: param, title: param1, categorycodes: param2 },
            () => this._closeProductModal(),
        );
    };
    _renderProductModal = () => {
        return (
            <Modal
                visible={this.state.showProdModal}
                width="50%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeProductModal()}
            >
                <div className="suggestionForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-12 alignRight">
                            <a
                                onClick={this._closeProductModal}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row" style={{ height: '15em' }}>
                        <Autocomplete
                            updateTitle={this._updateProdCode}
                        ></Autocomplete>
                    </div>
                </div>
            </Modal>
        );
    };

    _showStock = () => {
        this.setState({ showStockPage: true });
    };
    _hideStock = () => {
        this.setState({ showStockPage: false });
    };

    handleTrxDate = (val) => {
        this.setState({
            trxdate: val,
        });
    };

    _renderStockInformation = () => {
        const { storeiderror, productcodeerror } = this.state;
        const { listByProduct } = this.props.queryData;
        let showFields = window && window.config.enableProductModel;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="storeid" className="floatLeft required">
                            Store
                        </label>
                        <StoreSearchSingle
                            value={this.state.storelabel}
                            onSelectStore={this._onSelectStore}
                        />
                        <div className="invalid-feedback">{storeiderror}</div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="productcode"
                            className="floatLeft required"
                        >
                            Product Code
                        </label>
                        <input
                            type="text"
                            value={this.state.productcode}
                            className="addoninput productcodemodal"
                            onClick={this._showProductModal}
                        />
                        <div className="invalid-feedback">
                            {productcodeerror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="quantity"
                            className="floatLeft required"
                        >
                            Quantity
                        </label>
                        <input
                            type="number"
                            id="quantity"
                            value={this.state.qty}
                            onChange={(event) =>
                                this.setState({
                                    qty: parseInt(event.target.value),
                                })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.qtyerror}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="invtype" className="floatLeft">
                            Inventory Type
                        </label>
                        <SimpleDropdown
                            id="invtype"
                            listoptions={this.invtypeoptions}
                            selectedOption={this.state.invtype}
                            callbackChangeFilter={(value) => {
                                this.setState({ invtype: value });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="warehouseid" className="floatLeft">
                            Warehouse Id
                        </label>
                        <input
                            type="text"
                            id="warehouseid"
                            value={this.state.warehouseid}
                            onChange={(event) =>
                                this.setState({
                                    warehouseid: event.target.value,
                                })
                            }
                        />
                    </div>
                    {showFields === true ? (
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="productmodel"
                                className="floatLeft required"
                            >
                                Product Model
                            </label>
                            <input
                                type="text"
                                id="productmodel"
                                value={this.state.productmodel}
                                onClick={this._showProdModel}
                            />
                            <div className="invalid-feedback">
                                {this.state.productmodelerror}
                            </div>
                        </div>
                    ) : (
                        <div className="form-group col-lg-6 col-sm-12">
                            <label htmlFor="productmodel" className="floatLeft">
                                Product Model
                            </label>
                            <input
                                type="text"
                                id="productmodel"
                                value={this.state.productmodel}
                                onChange={(event) =>
                                    this.setState({
                                        productmodel: event.target.value,
                                    })
                                }
                            />
                        </div>
                    )}
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="invallocationid" className="floatLeft">
                           Inventory Allocation Id
                        </label>
                        <input
                            type="text"
                            id="invallocationid"
                            value={this.state.invallocationid}
                            onChange={(event) =>
                                this.setState({
                                    invallocationid: event.target.value,
                                })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderTrxInformation = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="trxdate" className="floatLeft">
                            Transaction Date
                        </label>
                        <DatePicker
                            minDate={
                                this.state.updateMode === false
                                    ? new Date()
                                    : this.state.trxdate
                            }
                            onChange={this.handleTrxDate}
                            value={this.state.trxdate}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="trxgroup" className="floatLeft">
                            Transaction Group
                        </label>
                        <SimpleDropdown
                            id="trxgroup"
                            listoptions={this.transgrpoptions}
                            selectedOption={this.state.trxgroup}
                            callbackChangeFilter={(value) => {
                                this.setState({ trxgroup: value });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="trxtype" className="floatLeft">
                            Transaction Type
                        </label>
                        <SimpleDropdown
                            id="trxtype"
                            listoptions={this.transtypeoptions}
                            selectedOption={this.state.trxtype}
                            callbackChangeFilter={(value) => {
                                this.setState({ trxtype: value });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="trxtypevalue" className="floatLeft">
                            Transaction Type Value
                        </label>
                        <input
                            type="text"
                            id="trxtypevalue"
                            value={this.state.trxtypevalue}
                            onChange={(event) =>
                                this.setState({
                                    trxtypevalue: event.target.value,
                                })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="trxno" className="floatLeft">
                            Transaction Number
                        </label>
                        <input
                            type="text"
                            id="trxno"
                            value={this.state.trxno}
                            onChange={(event) =>
                                this.setState({ trxno: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="remark" className="floatLeft">
                            Remark
                        </label>
                        <input
                            type="text"
                            id="remark"
                            value={this.state.remark}
                            onChange={(event) =>
                                this.setState({ remark: event.target.value })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderStatusInformation = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Actions</div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <StatusComp
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderStockForm = () => {
        return (
          <div className="grid px-4">
          <div className="col-9">
              <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                  {this._renderStockInformation()}
              </div>
              <div
                  className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                  style={{ marginTop: '2em' }}
              >
                  {this._renderTrxInformation()}
              </div>
          </div>
          <div className="col-3">
              <div
                  className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
              >
                  {this._renderStatusInformation()}
              </div>
          </div>
      </div>
        );
    };

    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv text-center ">
                            {this._renderMainFormDetails()}
                        </form>
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderGeneralInformation()}
                        </form>
                    </div>
                    <div className="col-md-3 col-sm-12  doubleCol">
                        <form className="splitFrmDiv text-center">
                            {this._renderStock()}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderMainButton = () => {
        if (this.props.isView || false) {
            return (
                <React.Fragment>
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                        onClick={() => {
                            this.props.updateStock();
                        }}
                    >
                        Close
                    </button>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={() => {
                        this._createInvTransaction();
                        if (this.validateForm()) {
                            setTimeout(() => {
                                this.props.updateStock();
                            }, 2000);
                        }
                    }}
                >
                    Save
                </button>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    onClick={() => {
                        this.props.updateStock();
                    }}
                >
                    Cancel
                </button>
            </React.Fragment>
        );
    };

    render() {
        const { propDetails } = this.props;
        const isEmpty = Object.values(propDetails).every(
            (x) => x === null || x === '',
        );
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="w-full mb-3 px-4">
                        <h2 className="page-header">
                                Inventory Quantity Update
                            </h2>
                    </div>
                    {this._renderStockForm()}
                    <div className="row btn-container form-button">
                        <div className="col-sm-12 col-lg-12">
                            {this._renderMainButton()}
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to={
                            typeof propDetails !== 'undefined' ||
                            isEmpty == false
                                ? {
                                      pathname: '/ui/inventory',
                                      state: {
                                          storeid:
                                              this.props.propDetails.fields
                                                  .storeid,
                                          status: this.props.propDetails.fields
                                              .status,
                                          storelabel:
                                              this.props.propDetails.fields
                                                  .storelabel,
                                          categorycodes:
                                              this.props.propDetails.fields
                                                  .categorycodes,
                                          categorygrp:
                                              this.props.propDetails.fields
                                                  .categories,
                                          stopsell:
                                              this.props.propDetails.fields
                                                  .stopsell,
                                          stock: this.props.propDetails.fields
                                              .stock,
                                      },
                                  }
                                : {
                                      pathname: '/ui/inventory',
                                  }
                        }
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this.state.updateMode === true
                                    ? 'Inventory Quantity Updated successfully!'
                                    : 'Inventory Quantity Updated successfully!'}
                            </div>
                        }
                    />
                </div>
                {this.state.showProdModal && this._renderProductModal()}
                {this.state.showProdModel && this._renderProductModel()}
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddStock);

import React from "react";
import { connect } from "react-redux";
import { produce } from "immer";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import ConfirmAlert from "../../../../components/ConfirmAlert";
import {
    canDelete,
    canView,
    canWrite,
    permissions,
} from "../../../../helpers/Util";
import {
    searchGeneral,
    crudReset,
    deleteStoreGroup,
    resetAction,
} from "../../../../actions/actions";
import * as CONSTANTS from "../../../../constants/constants";
import PrimeTable from "../../../../components/PrimeTable";

class DisplayStoreGroups extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem("company_id");
        this.group = "DE_STOREMGMT_STOREGROUPS";
        this.module = "STOREMGMT";
        this.allPermissions = [];

        this.requestBody = {}; //current one
        this.currPage = 1;
        this.pageSize = 10;

        this.state = {
            currPage: this.currPage,
            pageSize: this.pageSize,
            openDeleteprompt: false,
            delete_rec_id: "",
            message: "",
            action: "",

            tableCols: [
                {
                    dataField: "companyid",
                    text: "Company Id",
                    sort: true,
                },
                {
                    dataField: "groupid",
                    text: "Store Group Id",
                    sort: true,
                },
                {
                    dataField: "title",
                    text: "Title",
                    sort: true,
                },
                {
                    dataField: "status",
                    text: "Status",
                    sort: true,
                },
                {
                    dataField: "",
                    text: "Actions",
                    formatter: this.cellFormatter,
                    formatExtraData: {
                        action: "ED",
                    },
                    classes: () => {
                        return "lastTd";
                    },
                },
                {
                    dataField: "stores",
                    text: "Stores",
                    formatter: this.linkStores,
                    classes: () => {
                        return "link";
                    },
                },
            ],
        };
    }

    linkStores = (cell, row) => {
        if (row.status === "Deleted") {
            return;
        }
        return (
            <Link
                to={{
                    pathname: "/ui/stores",
                }}
            >
                <Button
                    label="Stores"
                    icon="pi pi-arrow-right"
                    className="p-button-outlined"
                />
            </Link>
        );
    };

    cellFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (row.status === "Deleted") {
            return;
        }

        return (
            <span>
                {canView(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: "/ui/storegroups/addNew",
                            state: {
                                item: row,
                                isEdit: false,
                                isView: true,
                            },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                        />
                    </Link>
                )}

                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: "/ui/storegroups/addNew",
                            state: {
                                item: row,
                                isEdit: true,
                            },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                        />
                    </Link>
                )}

                {canDelete(this.allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this.deleteStoreGroup(row)}
                    />
                )}
            </span>
        );
    };

    actionTemplate = (row) => {
        if (row.status === "Deleted") {
            return;
        }
        return (
            <span>
                {canView(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: "/ui/storegroups/addNew",
                            state: {
                                item: row,
                                isEdit: false,
                                isView: true,
                            },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                        />
                    </Link>
                )}

                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: "/ui/storegroups/addNew",
                            state: {
                                item: row,
                                isEdit: true,
                            },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                        />
                    </Link>
                )}

                {canDelete(this.allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this.deleteStoreGroup(row)}
                    />
                )}
            </span>
        );
    };

    // API Calls
    createFetchUrl = (fetchType) => {
        const { pageSize, currPage } = this.state;

        if (fetchType === "ONLOAD") {
            const requestBody = {
                body: {
                    companyid: this.companyId,
                    pagesize: pageSize,
                    pageno: currPage,
                },
            };
            this.requestBody["companyid"] = this.companyId;
            return requestBody;
        }

        const requestBody = {
            body: { ...this.requestBody, pagesize: pageSize, pageno: currPage },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                "system/v1/store/group/search/fields",
                this.createFetchUrl(fetchType),
                CONSTANTS.SEARCH_STORE_GROUP_SUCCESS,
                CONSTANTS.SEARCH_STORE_GROUP_FAILURE,
            ),
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        // var newState;
        switch (triggerPoint) {
            case "ONLOAD":
                this.callFetch("ONLOAD");
                break;
            case "PAGECLICK":
                this.callFetch();
                break;
            default:
                break;
        }
    };

    componentWillMount() {
        this.props.queryData.storeGroups = [];
        this.callQueryApiForFetch("ONLOAD");
        this.allPermissions = permissions(this.module, this.group);
    }

    _renderAddNewBtn = () => {
        if (!canWrite(this.allPermissions)) {
            return;
        }

        return (
            <Link
                to={{
                    pathname: "/ui/storegroups/addNew",
                }}
            >
                <Button
                    label="Add Store Group"
                    icon="pi pi-plus"
                    className="p-button-outlined"
                    severity="info"
                />
            </Link>
        );
    };

    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(deleteStoreGroup(this.state.delete_rec_id));
        }
    };

    hideAlertModal = (e) => {
        this.setState({ message: "", action: "" });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch("ONLOAD");
    };

    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: "", action: "" });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
    };

    deleteStoreGroup = (rowData) => {
        this.setState({ message: "Store Group Deleted", action: "Delete" });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };

    linkTemplate = (row) => {
        if (row.status === "Deleted") {
            return;
        }
        return (
            <>
                <Button
                    label="Stores"
                    icon="pi pi-arrow-right"
                    className="p-button-sm p-button-outlined"
                    severity="info"
                    onClick={() => this.linkToRelated(row, "stores")}
                />
            </>
        );
    };

    linkToRelated = (row, urlName) => {
        this.props.history.push(`/ui/${urlName}`);
    };

    render() {
        const {
            errormsg: queryError,
            servererror: serverError,
            storeGroups,
        } = this.props.queryData;

        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = "";
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        const columns = [
            { field: "companyid", header: "Company Id", sortable: true },
            { field: "groupid", header: "Store Group Id", sortable: true },
            { field: "title", header: "Title", sortable: true },
            { field: "status", header: "Status" },
        ];

        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-9 col-sm-12">
                            <h2 className="page-header">
                                Store Groups
                                <img
                                    title="Refresh"
                                    onClick={() => {
                                        this.callQueryApiForFetch("ONLOAD");
                                    }}
                                    className="refreshtree"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                />
                            </h2>
                        </div>
                        <div className="col-lg-3 col-sm-12 alignRight alignWithPageHeader">
                            {this._renderAddNewBtn()}
                        </div>
                    </div>

                    <div className="row form-container">
                        <div className="col">
                            <form className="frmDiv">
                                <PrimeTable
                                    list={storeGroups}
                                    columns={columns}
                                    actionColumn={this.actionTemplate}
                                    linkColumn={this.linkTemplate}
                                    pageSizeChangecallback={(pageSize) => {
                                        this.setState(
                                            produce(this.state, (draft) => {
                                                if (draft.pageSize < pageSize) {
                                                    draft.currPage = 1;
                                                }
                                                draft.pageSize = pageSize;
                                            }),
                                            () => {
                                                this.callQueryApiForFetch(
                                                    "PAGECLICK",
                                                );
                                            },
                                        );
                                    }}
                                    pageChangeCallback={(currPage) => {
                                        this.setState(
                                            {
                                                currPage: currPage,
                                            },
                                            () => {
                                                this.callQueryApiForFetch(
                                                    "PAGECLICK",
                                                );
                                            },
                                        );
                                    }}
                                />
                            </form>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: "2em", color: "green" }
                                        : { padding: "2em", color: "red" }
                                }
                            >
                                {isSuccess ? this.state.message : ""}
                            </div>
                        }
                    />

                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div className="deleteAlertDiv">
                                Are you sure you want to delete this store
                                group?
                                <div style={{ margin: "2em" }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec deletBtn"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded deletBtn"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />

                    <ConfirmAlert
                        show={errormsg}
                        handleClose={(event) =>
                            this.hideErrorAlertModal(event, refresh)
                        }
                        children={
                            <div
                                style={
                                    errormsg
                                        ? {
                                              padding: "2em",
                                              color: "red",
                                              fontWeight: "500",
                                          }
                                        : { padding: "2em", color: "red" }
                                }
                            >
                                {errormsg ? `${errormsg}.` : ""}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DisplayStoreGroups);

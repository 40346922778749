import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import {
    searchGeneral,
    deleteUserProfile,
    crudReset,
    resetAction,
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import Loading from "../../../components/Loading";
import formik from "../../../hooks/useFormik";
import { CreateEditDeleteHook } from "../../../hooks/useTableEditor";
import PrimeTable from "../../../components/PrimeTable";
import {
    canDelete,
    canView,
    canWrite,
    permissions,
} from "../../../helpers/Util";
import RefreshIcon from "../../../components/ImageComponents/RefreshIcon";

const validationSchema = Yup.object().shape({});

const defaultSearchInfo = {
    search_field: "emailid",
    search_condi: "like",
    search_value: "",
    pageSize: 10,
    currPage: 1,
};

const MemberProfileList = ({
    location,
    dispatch,
    queryData,
    crudData,
    history,
}) => {
    const initSearchInfo = useMemo(
        () => location?.state?.searchInfo || defaultSearchInfo,
        [location],
    );
    const initSearchedInfo = useMemo(
        () => ({
            ...defaultSearchInfo,
            ...location?.state?.searchedInfo,
        }),
        [location],
    );

    const [message, setMessage] = useState("");
    const [searchedInfo, setSearchedInfo] = useState(initSearchedInfo);

    const clearSearch = () => {
        setSearchedInfo(defaultSearchInfo);
        fetchResults(defaultSearchInfo);
    };

    const submitForm = (values, { setSubmitting }) => {
        const updatedSearchInfo = {
            search_field: values.search_field?.id || "",
            search_condi: values.search_condi,
            search_value: values.search_value,
            currPage: 1,
        };
        setSearchedInfo((prev) => ({ ...prev, ...updatedSearchInfo }));
        setSubmitting(false);
    };

    const deleteUser = useCallback((rowData) => {
        setMessage("Customer profile deleted successfully");
        editDeleteTable.setDeleteRecId(rowData.id);
    }, []);

    const allPermissions = permissions("CRM", "DE_CRM_MEMBER_PROFILE");

    const { values, handleSubmit, handleChange, isSubmitting, setFieldValue } =
        formik(initSearchInfo, validationSchema, submitForm);

    const editDeleteTable = CreateEditDeleteHook(
        "/ui/member-profile/addNew",
        deleteUser,
        allPermissions,
    );

    const actionTemplate = (row) => {
        if (row.status === "Deleted") return null;

        return (
            <span>
                {canView(allPermissions) && (
                    <Link
                        to={{
                            pathname: "/ui/member-profile/addNew",
                            state: { item: row, isEdit: false, isView: true },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                        />
                    </Link>
                )}
                <Link
                    to={{
                        pathname: "/ui/member-profile/addNew",
                        state: { item: row, isEdit: true },
                    }}
                >
                    <img
                        title="Edit"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                    />
                </Link>
            </span>
        );
    };

    const createFetchUrl = () => ({
        body: {
            pagesize: searchedInfo.pageSize,
            pageno: searchedInfo.currPage,
            ...searchedInfo,
        },
    });

    const fetchResults = useCallback(() => {
        dispatch(
            searchGeneral(
                "crm/v1/member/search",
                createFetchUrl(),
                "SEARCH_MEMBER_PROFILE_SUCCESS",
                "SEARCH_MEMBER_PROFILE_FAILURE",
            ),
        );
    }, [searchedInfo]);

    useEffect(fetchResults, [searchedInfo]);

    const confirmDelete = useCallback(
        (confirm) => {
            editDeleteTable.setDeleteRecId(-1);
            if (confirm) {
                dispatch(deleteUserProfile(editDeleteTable.deleteRecId));
            }
        },
        [editDeleteTable.deleteRecId],
    );

    const columns = useMemo(
        () => [
            { field: "memberid", header: "Customer Code" },
            { field: "companyname", header: "Customer Name" },
            { field: "taxnumber", header: "Customer TIN" },
            { field: "address", header: "Address" },
        ],
        [],
    );

    const handleClear = () => {
        const resetValues = {
            search_field: "emailid",
            search_condi: "like",
            search_value: "",
        };

        // Clear the formik values
        Object.keys(resetValues).forEach((key) =>
            setFieldValue(key, resetValues[key]),
        );

        setSearchedInfo({
            ...resetValues,
            pageSize: 10,
            currPage: 1,
        });

        const requestBody = {
            body: resetValues,
        };
        dispatch(
            searchGeneral(
                `crm/v1/member/search`,
                requestBody,
                "SEARCH_MEMBER_PROFILE_SUCCESS",
                "SEARCH_MEMBER_PROFILE_FAILURE",
            ),
        );
    };

    const renderSearchCriteria = () => (
        <div className="p-fluid formgrid grid w-100">
            <div className="field col-3">
                <label htmlFor="search_field">Search By</label>
                <Dropdown
                    name="search_field"
                    value={values.search_field}
                    onChange={handleChange}
                    options={[
                        { name: "Select", id: "" },
                        { name: "Customer Name", id: "companyname" },
                        { name: "Customer Code", id: "memberid" },
                        { name: "Email", id: "emailid" },
                        { name: "Store Id", id: "storeid" },
                        { name: "Salesperson", id: "salespersonname" },
                        { name: "Collector", id: "collectorname" },
                        { name: "Status", id: "status" },
                    ]}
                    optionLabel="name"
                    className="w-full"
                />
            </div>
            <div className="field col-3">
                <label htmlFor="search_value">Search Value</label>
                <InputText
                    id="search_value"
                    name="search_value"
                    value={values.search_value}
                    onChange={handleChange}
                />
            </div>
            <div
                className="field"
                style={{ display: "flex", marginTop: "28px" }}
            >
                <Button
                    type="submit"
                    label="Search"
                    className="mr-4"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                />
                <Button
                    type="button"
                    label="Cancel"
                    className="p-button-secondary"
                    onClick={handleClear}
                />
            </div>
        </div>
    );

    const { userProfileList, errormsg, servererror } = queryData;
    const { isSuccess } = crudData;

    if (!userProfileList && !errormsg) return <Loading />;

    const errorMessage = errormsg || servererror;

    const nameTemplate = (rowData) => {
        const { firstname, lastname } = rowData;
        return firstname + ' ' + lastname;
    };

    return (
        <div className="form_height">
            <div className="flex px-4 mt-4 align-items-center">
                <h2
                    className="text-3xl font-normal"
                    style={{ color: "#212121" }}
                >
                    Customer List
                    <RefreshIcon handleClick={fetchResults} />
                </h2>
                <div className="ml-auto">
                <Button
                            icon="pi pi-plus"
                            label="Add New"
                            severity="info"
                            outlined
                            className="ml-2"
                            onClick={() =>
                                history.push("/ui/member-profile/addNew")
                            }
                        />
                </div>
            </div>
            <div className="p-fluid formgrid grid br-2 bg-white p-4 border-round-xl shadow-1 m-4">
                <form className="w-full" onSubmit={handleSubmit}>{renderSearchCriteria()}</form>
                <PrimeTable
                    responsive={true}
                    list={userProfileList}
                    columns={columns}
                    actionColumn={actionTemplate}
                    pageSizeChangecallback={(pageSize) =>
                        setSearchedInfo((prev) => ({
                            ...prev,
                            pageSize,
                            currPage: 1,
                        }))
                    }
                    pageChangeCallback={(currPage) =>
                        setSearchedInfo((prev) => ({ ...prev, currPage }))
                    }
                >
                    <Column
                                    field="contactname"
                                    header="Contact Name"
                                    body={nameTemplate}
                                />
                                <Column
                                    field="contactno"
                                    header="Contact Number"
                                />
                                <Column
                                    field="storeid"
                                    header="Store Id"
                                />
                                <Column
                                    field="salespersonname"
                                    header="Saleperson"
                                />
                                <Column
                                    field="collectorname"
                                    header="Collector"
                                />
                                <Column field="status" header="Status" />
                </PrimeTable>
            </div>

            <ConfirmAlert
                show={editDeleteTable.deleteRecId !== -1}
                isPrompt={true}
                children={
                    <div className="deleteAlertDiv">
                        Are you sure you want to delete this customer profile?
                        <div style={{ margin: "2em" }}>
                            <button
                                className="btn btn-themes btn-rounded btn-sec"
                                onClick={() => confirmDelete(false)}
                            >
                                No
                            </button>
                            <button
                                className="btn btn-themes btn-rounded btn-pri"
                                onClick={() => confirmDelete(true)}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    queryData: state.queryData,
    crudData: state.crudData,
});

export default connect(mapStateToProps)(MemberProfileList);

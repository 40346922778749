import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import {
    searchGeneral,
    crudReset,
    deleteStore,
} from '../../../../actions/actions';
import * as CONSTANTS from '../../../../constants/constants';
import {
    canDelete,
    canView,
    canWrite,
    permissions,
} from '../../../../helpers/Util';
import PrimeTable from '../../../../components/PrimeTable';
import DuplicateStoreForm from './DuplicateStoreForm';
import RefreshIcon from '../../../../components/ImageComponents/RefreshIcon';

const DisplayStores = (props) => {
    const { crudData } = props;
    const { stores } = props.queryData;
    const history = useHistory();
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteRecId, setDeleteRcId] = useState();

    const companyid = localStorage.getItem('company_id');
    const group = 'DE_STOREMGMT_STORES';
    const module = 'STOREMGMT';
    const duplicategroup = 'DE_STOREMGMT_STORE_DUPLICATE';
    const duplicatedmodule = 'STOREMGMT';
    const [allPermissions, setAllPermissions] = useState([]);
    const [openDeleteprompt, setOpenDeletePrompt] = useState(false);
    const [openDuplicatePrompt, setOpenDuplicatePrompt] = useState(false);
    const [selectedStore, setSelectedStore] = useState({});

    const [duplicatePermissions, setDuplicatePermissions] = useState([]);

    const [openSuccessPrompt, setOpenSuccessPrompt] = useState(false);
    const [openDeleteSuccessPrompt, setOpenDeleteSuccessPrompt] =
        useState(false);

    useEffect(() => {
        callFetch();
        setAllPermissions(permissions(module, group));
        setDuplicatePermissions(permissions(duplicatedmodule, duplicategroup));
    }, []);

    useEffect(() => {
        const { isSuccess } = crudData;
        if (isSuccess && deleteRecId) {
            setOpenDeleteSuccessPrompt(true);
        }
    }, [crudData]);

    const callFetch = () => {
        props.dispatch(
            searchGeneral(
                'system/v1/store/search/fields',
                createFetchUrl(pageSize, currentPage),
                CONSTANTS.SEARCH_STORE_SUCCESS,
                CONSTANTS.SEARCH_STORE_FAILURE,
            ),
        );
    };

    const createFetchUrl = (pageSize, currentPage) => {
        const requestBody = {
            body: {
                //companyid,
                pagesize: pageSize,
                pageno: currentPage,
            },
        };
        return requestBody;
    };

    const getParsedValue = (pageSize, pageNo) => {
        const requestBody = {
            body: {
                companyid,
                pagesize: pageSize,
                pageno: pageNo,
            },
        };
        return requestBody;
    };

    const getStoreAccess = (storeid) => {
        const store_access = localStorage.getItem('store_access');
        if (store_access && stores.length > 0) {
            const accessStores = store_access.split(',');
            if (accessStores.includes(storeid)) {
                return true;
            }
            return false;
        }
    };

    const actionTemplate = (row) => {
        if (row.status === 'Deleted' || !getStoreAccess(row.storeid)) {
            return;
        }
        return (
            <div className="flex">
                {canView(allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/stores/addNew',
                            state: {
                                item: row,
                                isEdit: false,
                                isView: true,
                            },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                            alt="ViewIcon"
                        />
                    </Link>
                )}

                {canWrite(allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/stores/addNew',
                            state: {
                                item: row,
                                isEdit: true,
                                isView: false,
                            },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                            alt="EditIcon"
                        />
                    </Link>
                )}

                {canWrite(duplicatePermissions) && (
                    <img
                        title="Duplicate"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_duplicate.svg`}
                        onClick={() => duplicateRecord(row)}
                    />
                )}

                {canDelete(allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => deleteRecord(row)}
                    />
                )}
            </div>
        );
    };

    const linkToRelated = (row, urlName) => {
        const { storeid, title } = row;
        localStorage.setItem('storeid', storeid);
        localStorage.setItem('storelabel', title);
        history.push(`/ui/${urlName}`);
    };

    const linkTemplate = (row) => {
        if (row.status === 'Deleted' || !getStoreAccess(row.storeid)) {
            return;
        }
        return (
            <>
                <Button
                    label="Terminals"
                    className="p-button-sm p-button-outlined mr-2 mb-2"
                    onClick={() => linkToRelated(row, 'terminals')}
                />
                <Button
                    label="Options"
                    className="p-button-sm p-button-outlined p-button-secondary mr-2 mb-2"
                    onClick={() => linkToRelated(row, 'storeoptions')}
                />
                <Button
                    label="Tenders"
                    className="p-button-sm p-button-outlined p-button-success mr-2 mb-2"
                    onClick={() => linkToRelated(row, 'storetenders')}
                />
            </>
        );
    };

    const duplicateRecord = (row) => {
        setSelectedStore(row);
        setOpenDuplicatePrompt(true);
        //get tag types of selected store
    };

    const refreshPage = () => {
        callFetch();
    };

    const renderAddNewBtn = () => {
        if (!canWrite(allPermissions)) {
            return;
        }

        return (
            <Link
                to={{
                    pathname: '/ui/stores/addNew',
                }}
            >
                <Button
                    label="Add Store"
                    icon="pi pi-plus"
                    className="p-button-outlined"
                    severity="info"
                />
            </Link>
        );
    };

    const deleteRecord = (row) => {
        setDeleteRcId(row.id);
        setOpenDeletePrompt(true);
    };

    const handleDeleteSuccess = () => {
        setDeleteRcId('');
        setOpenDeleteSuccessPrompt(false);
        props.dispatch(crudReset());
        callFetch();
    };

    const renderDeleteSuccessFooter = () => {
        return (
            <div>
                <Button
                    label="Ok"
                    icon="pi pi-check"
                    onClick={() => setOpenDeleteSuccessPrompt(false)}
                    autoFocus
                />
            </div>
        );
    };

    const renderFooter = () => {
        return (
            <div>
                <Button
                    label="Ok"
                    icon="pi pi-check"
                    onClick={() => setOpenSuccessPrompt(false)}
                    autoFocus
                />
            </div>
        );
    };

    const handleDuplicateSuccess = () => {
        setOpenDuplicatePrompt(false);
        setOpenSuccessPrompt(true);
        callFetch();
    };

    const handleDelete = () => {
        props.dispatch(deleteStore(deleteRecId));
    };

    const pageChangeCallback = (currPage) => {
        setCurrentPage(currPage);
        props.dispatch(
            searchGeneral(
                'system/v1/store/search/fields',
                getParsedValue(pageSize, currPage),
                CONSTANTS.SEARCH_STORE_SUCCESS,
                CONSTANTS.SEARCH_STORE_FAILURE,
            ),
        );
    };

    const pageSizeChangecallback = (pagesize) => {
        setPageSize(pagesize);
        props.dispatch(
            searchGeneral(
                'system/v1/store/search/fields',
                getParsedValue(pagesize, currentPage),
                CONSTANTS.SEARCH_STORE_SUCCESS,
                CONSTANTS.SEARCH_STORE_FAILURE,
            ),
        );
    };

    const columns = [
        { field: 'companyid', header: 'Company Id' },
        { field: 'groupid', header: 'Store Group Id' },
        { field: 'storeid', header: 'Store Id' },
        { field: 'title', header: 'Title' },
        { field: 'status', header: 'Status' },
    ];

    const filterStores = (list) => {
        if (!list) {
            return [];
        }

        //filter the stores only the user has access
        let storesToReturn = [];
        const store_access = localStorage.getItem('store_access');
        if (store_access) {
            const mystoreaccess = store_access.split(',');
            storesToReturn = list.filter((e) =>
                mystoreaccess.includes(e.storeid),
            );
        }
        return storesToReturn;
    };

    return (
        <>
            <div className="form_height">
                <div className="flex px-4 mt-4 align-items-center mb-4">
                        <h2 className="text-3xl font-normal mb-0">
                            Stores
                            <RefreshIcon handleClick={refreshPage} />
                        </h2>
                    <div className="ml-auto">{renderAddNewBtn()}</div>
                </div>
                <div className="bg-white mx-4 pv-20 ph-20 br-6 shadow-white">
                    <PrimeTable
                        list={filterStores(stores)}
                        columns={columns}
                        actionColumn={actionTemplate}
                        linkColumn={linkTemplate}
                        pageSizeChangecallback={pageSizeChangecallback}
                        pageChangeCallback={pageChangeCallback}
                        tablePageSize={50}
                    />
                </div>
            </div>
            <Dialog
                header={`Success`}
                visible={openDeleteSuccessPrompt}
                style={{ width: '300px' }}
                footer={renderDeleteSuccessFooter()}
                onHide={handleDeleteSuccess}
            >
                <p>Store deleted successfully.</p>
            </Dialog>
            <Dialog
                header={`Duplicate ${selectedStore.title}`}
                visible={openDuplicatePrompt}
                style={{ width: '50vw' }}
                onHide={() => setOpenDuplicatePrompt(false)}
            >
                <DuplicateStoreForm
                    selectedStore={selectedStore}
                    onSuccess={handleDuplicateSuccess}
                />
            </Dialog>
            <Dialog
                header={`Success`}
                visible={openSuccessPrompt}
                style={{ width: '300px' }}
                footer={renderFooter()}
                onHide={() => setOpenSuccessPrompt(false)}
            >
                <p>Store duplicated successfully.</p>
            </Dialog>
            <ConfirmDialog
                visible={openDeleteprompt}
                onHide={() => setOpenDeletePrompt(false)}
                message="Are you sure you want to delete this store?"
                header="Confirmation"
                icon="pi pi-exclamation-triangle"
                accept={handleDelete}
                reject={() => setOpenDeletePrompt(false)}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};
export default connect(mapStateToProps)(DisplayStores);

import React from "react";
import LinkButton from "../../../components/LinkButton";
import { connect } from "react-redux";
import { produce } from "immer";
import {
  deleteSalesPromoPurchaseGroup,
  searchGeneral,
  crudReset,
  resetAction,
  promotionPGroupSearchByFields
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import SimpleTable from "../../../components/SimpleTable";
import { Link } from "react-router-dom";
import StoreSearchSingle from "../../system/users/StoreSearchSingle";
import { canDelete, canView, canWrite, permissions } from '../../../helpers/Util'
import StatusOptionComp from "../../../components/StatusOptionComp";
import SimpleDropdown from "../../../components/SimpleDropdown";
import * as CONSTANTS from '../../../constants/constants'

class DisplayPromoPurchaseGroups extends React.PureComponent {

  statusOptions = [
    { name: "Select", id: "" },
    { name: "New", id: "New" },
    { name: "Active", id: "Active" },
    { name: "Inactive", id: "Inactive" },
    { name: "Deleted", id: "Deleted" }

  ]

  validateOnOptions = [
    { name: "Select", id: "" },
    { name: "Category", id: "Category" },
    { name: "Product", id: "Product" },
    { name: "Product Group", id: "ProductMix" }
  ]


  constructor(props) {
    super(props);

    this.companyId = localStorage.getItem("company_id")
    this.group = "DE_SAL_SALES_PROMO_PGROUP"
    this.module = "SALESPRM"
    this.allPermissions = []

    this.requestBody = {} //current one
    this.currPage = 1
    this.pageSize = 10

    this.state = {
      currPage: this.currPage,
      pageSize: this.pageSize,

      query: {},
      openDeleteprompt: false,
      delete_rec_id: "",
      message: "",
      search_field: "status",
      search_condi: "like",
      search_value: "",
      tableCols: [
        {
          dataField: "id",
          text: "Id",
          sort: true
        },
        {
          dataField: "promopurchasecode",
          text: "Purchase Group Code",
          sort: true
        },
        {
          dataField: "title",
          text: "Title",
          sort: true
        },
        {
          dataField: "validatelevel",
          text: "Validate On",
          sort: true
        },
        {
          dataField: "storeid",
          text: "Store Id",
          sort: true
        },
        {
          dataField: "status",
          text: "Status",
          sort: true
        },
        {
          dataField: "",
          text: "Actions",
          formatter: this.cellFormatter,
          formatExtraData: {
            action: "ED"
          },
          classes: () => {
            return "lastTd";
          }
        }
      ],

      validatelevel: '',
      storeid: "",
      storelabel: "",
      storeiderror: '',
      status: ""
    };
  }

  cellFormatter = (cell, row, rowIndex, formatExtraData) => {

    if (row.status === "Deleted") { return }
    return (
      <span>
        {canView(this.allPermissions) && (
          <Link
            to={{
              pathname: "/ui/sales-promotions-pgroups/addNew",
              state: {
                item: row,
                isEdit: false,
                isView: true
              }
            }}
          >
            <img
              title="View"
              className="tableImage"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
            />
          </Link>
        )}

        {canWrite(this.allPermissions) && (
          <Link
            to={{
              pathname: "/ui/sales-promotions-pgroups/addNew",
              state: {
                item: row,
                isEdit: true
              }
            }}
          >
            <img
              title="Edit"
              className="tableImage"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
            />
          </Link>

        )}

        {canDelete(this.allPermissions) && (
          <img
            title="Delete"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            onClick={() => this._deletePromoPGroup(row)}
          />
        )}
      </span>
    );

  };

  // API Calls
  createFetchUrl = fetchType => {
    const {
      pageSize,
      currPage,
      storeid
    } = this.state;

    if (fetchType === 'ONLOAD') {
      const requestBody = {
        body: {
          storeid: storeid,
          pagesize: pageSize,
          pageno: currPage
        }
      };
      this.requestBody["storeid"] = storeid
      return requestBody;  
    }

    console.log('pageclick requestBody::', this.requestBody)
    const requestBody = {
      // body: {
      //   storeid: storeid,
      //   pagesize: pageSize,
      //   pageno: currPage
      // }
      body: { ...this.requestBody, pagesize: pageSize, pageno: currPage }
    };
    console.log("...requestBoyd::", requestBody)
    return requestBody;
  };

  callFetch = fetchType => {
    this.props.dispatch(
      searchGeneral(
        "/pos/v1/promo/purchase/search/fields",
        this.createFetchUrl(fetchType),
        CONSTANTS.SEARCH_PROMOTIONS_PGROUP_SUCCESS,
        CONSTANTS.SEARCH_PROMOTIONS_PGROUP_FAILURE
      )
    );
  };

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    // var newState;
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetch("ONLOAD");
        break;
      case "SEARCH":
        this.callFetch("SEARCH");
        break;
      case "PAGECLICK":
        // const [pgNumber] = rest;

        // newState = produce(this.state, draft => {
        //   draft.currPage = pgNumber - 1;
        // });
        // this.setState({ ...newState }, () => {
        //   this.callFetch("PAGECLICK");
        // });
        this.callFetch()
        break;
    }
  };

  // searchQuery = (val1, val2, val3) => {
  //   if (val1 === "id") val3 = parseInt(val3);
  //   this.setState(
  //     {
  //       search_field: val1,
  //       search_condi: val2,
  //       search_value: val3
  //     },
  //     () => {
  //       this.callQueryApiForFetch("SEARCH");
  //     }
  //   );
  // };

  componentWillMount() {
    const storeid = localStorage.getItem('storeid')
    const storelabel = localStorage.getItem('storelabel')
    if (storeid && storeid !== '' && storelabel && storelabel !== '') {
      this.setState({ storeid: storeid, storelabel: storelabel }, () =>
        this.callQueryApiForFetch("ONLOAD")
      );
    }
    this.allPermissions = permissions(this.module, this.group)
  }

  // pageNumberOnClick = pgNumber => {
  //   this.callQueryApiForFetch("PAGECLICK", pgNumber);
  // };

  _renderAddNewBtn = () => {

    if (!canWrite(this.allPermissions)) { return }

    return (
      <LinkButton
        to="/ui/sales-promotions-pgroups/addNew"
        className="page-header-btn icon_btn display-inline "
        onClick={event => event.preventDefault()}
      >
        <img
          title="edit"
          className="tableImage"
          src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
        />
        {`Add Promotion Purchase Group`}
      </LinkButton>
    );
  };

  shdDeleteRec = arg => {
    this.setState({ openDeleteprompt: false });
    if (arg === true) {
      this.props.dispatch(deleteSalesPromoPurchaseGroup(this.state.delete_rec_id));
    }
  };

  hideAlertModal = e => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.callQueryApiForFetch("ONLOAD");
  };

  hideErrorAlertModal = (e, refresh) => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.dispatch(resetAction());
    // if (refresh === true) {
    //   this.callQueryApiForFetch("ONLOAD");
    // }
  };

  _deletePromoPGroup = rowData => {
    this.setState({ message: "Sales Promotion  deleted successfully" });
    this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
  };

  getTableData = (records, headers) => {
    const tablerow = records.map(e => [e.id, e.title, e.promocode, e.promogroup, e.storeid, e.promotypevalue, e.categorytypevalue, e.validfrom, e.validtill, e.status]);
    const colheader = headers.filter(e => e.text !== "Actions").map(e => ({ label: e.text, key: e.dataField }))
    tablerow.unshift(colheader.map(e => e.label));
    return tablerow;
  }

  render() {
    const {
      articleFlowData,
      languageConfig,
      errormsg: queryError,
      servererror: serverError,
      promotionspurchasegroups
    } = this.props.queryData;
    const { isSuccess, errormsg: crudError } = this.props.crudData;
    let errormsg = "";
    let refresh = false;
    if (serverError) {
      errormsg = serverError;
      refresh = false;
    }
    if (queryError) {
      errormsg = queryError;
      refresh = true;
    }
    if (crudError) {
      errormsg = crudError;
      refresh = true;
    }

    // if (!articleFlowData && !errormsg) {
    //   return <Loading />;
    // }
    // if (this.props.queryData.apiPending) {
    //   return <Loading />;
    // }

    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h2 className="page-header">Promotion Purchase Groups</h2>
            </div>
            <div className="col-lg-6 col-sm-12 alignRight alignWithPageHeader">
              {this._renderAddNewBtn()}
            </div>
          </div>

          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
                <div className="row">

                  <div className="form-group col-lg-3 col-sm-12">
                    <label className="col-form-label alignLeft required">
                      Store
                    </label>
                    <StoreSearchSingle value={this.state.storelabel} onSelectStore={this._onSelectStore} />
                    <div className="invalid-feedback">{this.state.storeiderror}</div>
                  </div>

                  <div className="form-group col-lg-3 col-sm-12">
                    <label className="col-form-label alignLeft">
                      Validate On
                    </label>
                    <SimpleDropdown
                      id="promogroup"
                      listoptions={this.validateOnOptions}
                      selectedOption={this.state.validatelevel}
                      callbackChangeFilter={value => {
                        this.setState({ validatelevel: value });
                      }}
                    />
                  </div>

                  <div className="form-group col-lg-3 col-sm-12">
                    <label className="col-form-label alignLeft">
                      Status
                    </label>
                    <StatusOptionComp
                      statusoption={this.statusOptions}
                      selectedOption={this.state.status}
                      callbackChangeFilter={value => {
                        this.setState({ status: value });
                      }}
                    />
                  </div>


                  <div className="col-lg-3 col-sm-12 btn-container1" style={{ marginTop: "1em" }}>
                    <button
                      type="submit"
                      onClick={(e) => this._searchPromotionPurchaseGroups(e)}
                      className="btn btn-themes btn-rounded"
                    >
                      Search
                    </button>

                    <button
                      type="submit"
                      onClick={this._clearFiltersAndResults}
                      className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                      Clear
                    </button>
                  </div>

                </div>


                <div className="title_devide" />
                <SimpleTable
                  columns={this.state.tableCols}
                  rows={promotionspurchasegroups}
                  pageChangeCallback={(currPage) => {
                    this.setState({
                      currPage: currPage
                    }, () => {
                      this.callQueryApiForFetch("PAGECLICK");
                    })
                  }}
                  callback={(pageSize) => {
                    this.setState(produce(this.state, draft => {
                      if (draft.pageSize < pageSize) {
                        draft.currPage = 1
                      }
                      draft.pageSize = pageSize
                    }), () => {
                      this.callQueryApiForFetch("PAGECLICK")
                    })
                  }}
                  // withoutSearchBar={true}
                  withPageSize={this.state.pageSize}
                  withCurrPage={this.state.currPage}
                  withPageFeedback={true}
                />
                {/* <TableFooter /> */}
              </form>
            </div>
          </div>


          <ConfirmAlert
            show={this.state.openDeleteprompt}
            isPrompt={true}
            children={
              <div className="deleteAlertDiv">
                Are you sure you want to delete this promotion purchase group?
                <div style={{ margin: "2em" }}>
                  <button
                    className="btn btn-themes btn-rounded btn-sec deletBtn"
                    onClick={() => this.shdDeleteRec(false)}
                  >
                    No
                  </button>
                  <button
                    className="btn btn-themes  btn-rounded deletBtn"
                    onClick={() => this.shdDeleteRec(true)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            }
          />
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideAlertModal}
            children={
              <div
                style={
                  isSuccess
                    ? { padding: "2em", color: "green" }
                    : { padding: "2em", color: "red" }
                }
              >
                {isSuccess ? this.state.message : ""}
              </div>
            }
          />

          <ConfirmAlert
            show={errormsg}
            handleClose={event => this.hideErrorAlertModal(event, refresh)}
            children={
              <div
                style={
                  errormsg
                    ? { padding: "2em", color: "red", fontWeight: "500" }
                    : { padding: "2em", color: "red" }
                }
              >
                {errormsg ? `${errormsg}.` : ""}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }

  _searchPromotionPurchaseGroups = (e) => {
    if (e) { e.preventDefault() }
    console.log('_search Promotion Purchase Groups')
    const {
      storeid,
      validatelevel,
      status
    } = this.state

    this.requestBody = {}
    const queryParams = []
    let atleastOneFilter = false
    if (storeid !== '') {
      queryParams.push(`storeid=${storeid}`)
      this.setState({storeiderror: ''})
      this.requestBody["storeid"] = storeid
    }else {
      this.setState({storeiderror: 'select store'})
      return
    }
    if (status !== '') {
      queryParams.push(`status=${status}`)
      this.requestBody["status"] = status
    }
    if (validatelevel !== '') {
      queryParams.push(`validatelevel=${validatelevel}`)
      this.requestBody["validatelevel"] = validatelevel
    }

    //validate the filters
    if (queryParams.length <= 0) {
      console.log('select atlease one filter')
      return
    }

    queryParams.push(`pagesize=${this.pageSize}`)
    queryParams.push(`pageno=${this.currPage}`)

    console.log('requstBody::', this.requestBody)
    //query
    this.props.dispatch(promotionPGroupSearchByFields(queryParams.join('&')))
    // if (e) { e.preventDefault() }
  }

  _clearFiltersAndResults = (e) => {
    if (e) { e.preventDefault() }
    //clear all the filters
    this.setState({
      storeid: "",
      validatelevel: "",
      status: "",
      storelabel: "",
      storeiderror: ''
    })
    //clear the search results
  }

  _onSelectStore = (selectedStores) => {
    console.log('selected stores from storesearch:;', selectedStores, this.state.storeid)
    this._setStore(selectedStores.value, selectedStores.label)
  }

  _setStore = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2, search_value: p1, storeiderror: '' },
      () => {
        localStorage.setItem("storeid", p1);
        localStorage.setItem("storelabel", p2);
      });
  };

}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(DisplayPromoPurchaseGroups);

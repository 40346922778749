import React from 'react';
import { connect } from 'react-redux';
import { produce } from 'immer';
import DatePicker from 'react-date-picker';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import {
    deleteSalesPromo,
    searchGeneral,
    crudReset,
    resetAction,
    promotionSearchByFields,
    updateSalesPromo,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import SimpleTable from '../../../components/SimpleTable';
import StoreSearchSingle from '../../system/users/StoreSearchSingle';
import {
    canApprove,
    canDelete,
    canPublish,
    canUnPublish,
    canView,
    canWrite,
    formatDate,
    permissions,
} from '../../../helpers/Util';
import StatusOptionComp from '../../../components/StatusOptionComp';
import SimpleDropdown from '../../../components/SimpleDropdown';
import * as CONSTANTS from '../../../constants/constants';
import PrimeTable from '../../../components/PrimeTable';
import LinkButton from "../../../components/LinkButton";

class DisplaySalesPromotions extends React.PureComponent {
    statusOptions = [
        { name: 'Select', id: '' },
        { name: 'New', id: 'New' },
        { name: 'Active', id: 'Active' },
        { name: 'Pending Approval', id: 'PendingApproval' },
        { name: 'Inactive', id: 'Inactive' },
        { name: 'Approved', id: 'Approved' },
        { name: 'Rejected', id: 'Rejected' },
        { name: 'Expired', id: 'Expired' },
    ];

    dateTypes = [
        { name: 'Select', id: '' },
        { name: 'Promo Start Date', id: 'validfrom' },
        { name: 'Promo End Date', id: 'validtill' },
    ];

    customerTypeOptions = [
        { name: 'Select', id: '' },
        { name: 'Guest', id: 'Guest' },
        { name: 'Member', id: 'Member' },
    ];

    promoTypeOptions = [
        { name: 'Select', id: '' },
        { name: 'Item', id: 'Item' },
        { name: 'Cart', id: 'Cart' },
        { name: 'RTC', id: 'Rtc' },
        { name: 'Coupon', id: 'Coupon' },
    ];

    promoGroupOptions = [
        { name: 'Select', id: '' },
        { name: 'By Percentage', id: 'Percent' },
        { name: 'By Amount', id: 'Value' },
        { name: 'Buy X Get Y', id: 'BuyGet' },
    ];

    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.module = 'SALESPRM';
        this.group = 'DE_SAL_SALES_PROMO';
        // this.module = "SALES"
        this.allPermissions = [];

        this.requestBody = {}; //current one
        this.currPage = 1;
        this.pageSize = 10;

        this.state = {
            // pages: 10,
            // currPage: 0,
            currPage: this.currPage,
            pageSize: this.pageSize,
            showSearchModal: false,
            query: {},
            openDeleteprompt: false,
            delete_rec_id: '',
            message: '',
            action: '',
            search_field: 'status',
            search_condi: 'like',
            search_value: '',
            date_type: '',
            date_from: '',
            date_till: '',
            tableCols: [
                {
                    dataField: 'promocode',
                    text: 'Promo Code',
                    sort: true,
                },
                {
                    dataField: 'title',
                    text: 'Promotion Name',
                    sort: true,
                },
                {
                    dataField: 'promogroup',
                    text: 'Promo Group',
                    sort: true,
                },
                {
                    dataField: 'promotype',
                    text: 'Promo Type',
                    sort: true,
                },

                {
                    dataField: 'storeid',
                    text: 'Store Id',
                    sort: true,
                },
                {
                    dataField: 'validfrom',
                    text: 'Valid From',
                    sort: true,
                },
                {
                    dataField: 'validtill',
                    text: 'Valid Till',
                    sort: true,
                },
                {
                    dataField: 'status',
                    text: 'Status',
                    sort: true,
                },

                {
                    dataField: '',
                    text: 'Actions',
                    formatter: this.cellFormatter,
                    formatExtraData: {
                        action: 'ED',
                    },
                    classes: () => {
                        return 'lastTd';
                    },
                },
            ],
            storeid: '',
            storelabel: '',
            customerType: '',
            promoType: '',
            promoGroup: '',
            status: '',

            //errors
            storeiderror: '',
        };
    }

    cellFormatter = (cell, row) => {
        if (row.status === 'Deleted') {
            return;
        }

        return (
            <span>
                {canView(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/sales-promotions/addNew',
                            state: {
                                item: row,
                                isEdit: true,
                                isView: false,
                                isViewInEdit: true,
                            },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                        />
                    </Link>
                )}

                {canWrite(this.allPermissions) && row.status !== 'Active' && (
                    <Link
                        to={{
                            pathname: '/ui/sales-promotions/addNew',
                            state: {
                                item: row,
                                isEdit: true,
                            },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                        />
                    </Link>
                )}

                {canApprove(this.allPermissions) &&
                    row.status === 'PendingApproval' && (
                        <Link
                            to={{
                                pathname: '/ui/sales-promotions/addNew',
                                state: {
                                    item: row,
                                    isEdit: true,
                                    isView: false,
                                    isViewInEdit: true,
                                    isForApproval: true,
                                },
                            }}
                        >
                            <img
                                title="Approve"
                                className="tableImage"
                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_approve.svg`}
                            />
                        </Link>
                    )}

                {canPublish(this.allPermissions) &&
                    row.status === 'Approved' && (
                        <img
                            title="Publish"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_published.svg`}
                            onClick={() => this._publishPromo(row)}
                        />
                    )}

                {canUnPublish(this.allPermissions) &&
                    row.status === 'Active' && (
                        <img
                            title="UnPublish"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_published.svg`}
                            onClick={() => this._unPublishPromo(row)}
                        />
                    )}

                {row.status === 'InActive' && (
                    <img
                        title="InActive"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_inactive.svg`}
                    />
                )}

                {canDelete(this.allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this._deletePromo(row)}
                    />
                )}
            </span>
        );
    };

    // API Calls
    createFetchUrl = (fetchType) => {
        const { pageSize, currPage, storeid } = this.state;

        if (fetchType === 'ONLOAD') {
            const requestBody = {
                body: {
                    storeid: storeid,
                    pagesize: pageSize,
                    pageno: currPage,
                },
            };
            this.requestBody['storeid'] = storeid;
            console.log('...requestBody for ONLOAD::', requestBody);
            return requestBody;
        }

        const requestBody = {
            body: { ...this.requestBody, pagesize: pageSize, pageno: currPage },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                '/pos/v1/promo/search/fields',
                this.createFetchUrl(fetchType),
                CONSTANTS.SEARCH_PROMOTIONS_SUCCESS,
                CONSTANTS.SEARCH_PROMOTIONS_FAILURE
            )
        );
    };

    callQueryApiForFetch = (triggerPoint) => {
        // var newState;
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
        }
    };

    componentWillMount() {
        const storeid = localStorage.getItem('storeid');
        const storelabel = localStorage.getItem('storelabel');
        if (storeid && storeid !== '' && storelabel && storelabel !== '') {
            this.setState({ storeid: storeid, storelabel: storelabel }, () =>
                this.callQueryApiForFetch('ONLOAD')
            );
        }
        this.allPermissions = permissions(this.module, this.group);
    }

    _renderAddNewBtn = () => {
        const { history } = this.props;
        if (!canWrite(this.allPermissions)) {
            return;
        }
        // return (
        //   <Link
        //     to="/ui/sales-promotions/addNew"
        //   >
        //     <Button
        //          label="Add Sales Promo"
        //        icon="pi pi-plus"
        //          className="p-button-outlined mr-2"
        //      />
        //   </Link>
        // );
        return (
          <LinkButton
            to="/ui/sales-promotions/addNew"
            className="page-header-btn icon_btn display-inline "
            onClick={event => event.preventDefault()}
          >
            <img
              title="edit"
              className="tableImage"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
            />
            {`Add Sales Promo`}
          </LinkButton>
        );
    
    
    };

    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(deleteSalesPromo(this.state.delete_rec_id));
        }
    };

    hideAlertModal = (e) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };

    hideErrorAlertModal = (e) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
    };

    _deletePromo = (rowData) => {
        this.setState({ message: 'Promotion  deleted', action: 'Delete' });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };

    getCreatePayload = (row, status) => {
        const {
            id,
            promocode,
            storeid,
            promogroup,
            title,
            promotype,
            promotypevalue,
            discounton,
            discountvalue,
            discountcapamt,
            discountbatch,
            discountbatchvalue,
            customertype,
            // status,
            validfrom,
            validtill,
            activedays,
            closedates,
            pricesegments,
            priority,
            sortorder,
        } = row;

        let body = {
            id,
            promocode,
            storeid,
            promogroup,
            title,
            promotype,
            promotypevalue,
            discounton,
            discountvalue,
            discountcapamt,
            discountbatch,
            discountbatchvalue,
            customertype,
            status: status,
            validfrom,
            validtill,
            activedays,
            closedates,
            pricesegments,
            priority,
            sortorder,
        };

        const payload = {
            body: body,
        };

        return payload;
    };

    _publishPromo = (rowData) => {
        this.setState({ message: 'Promotion Published ', action: 'Publish' });
        this.props.dispatch(
            updateSalesPromo(
                this.getCreatePayload(rowData, 'Active'),
                rowData.id
            )
        );
    };

    _unPublishPromo = (rowData) => {
        this.setState({ message: 'Promotion UnPublished ', action: 'Publish' });
        this.props.dispatch(
            updateSalesPromo(
                this.getCreatePayload(rowData, 'InActive'),
                rowData.id
            )
        );
    };

    getTableData = (records, headers) => {
        const tablerow = records.map((e) => [
            e.id,
            e.title,
            e.promocode,
            e.promogroup,
            e.storeid,
            e.promotypevalue,
            e.categorytypevalue,
            e.validfrom,
            e.validtill,
            e.status,
        ]);
        const colheader = headers
            .filter((e) => e.text !== 'Actions')
            .map((e) => ({ label: e.text, key: e.dataField }));
        tablerow.unshift(colheader.map((e) => e.label));
        return tablerow;
    };

    render() {
        const {
            errormsg: queryError,
            servererror: serverError,
            promotions,
        } = this.props.queryData;
        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <h2 className="page-header">Sales Promotion</h2>
                        </div>
                        <div className="col-lg-6 col-sm-12 alignRight alignWithPageHeader">
                            {this._renderAddNewBtn()}
                        </div>
                    </div>

                    <div className="row form-container">
                        <div className="col">
                            <form className="frmDiv">
                                <div className="formgrid grid">
                                    <div className="field col-lg-3 col-md-3">
                                        <label className="col-form-label alignLeft">
                                            Date Type
                                        </label>
                                        <StatusOptionComp
                                            statusoption={this.dateTypes}
                                            selectedOption={
                                                this.state.date_type
                                            }
                                            callbackChangeFilter={(value) => {
                                                this.setState({
                                                    date_type: value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="field col-lg-3 col-md-3">
                                        <label className="col-form-label alignLeft">
                                            Date From
                                        </label>
                                        <DatePicker
                                            onChange={(value) => {
                                                this.setState({
                                                    date_from: value,
                                                });
                                            }}
                                            value={this.state.date_from}
                                        />
                                    </div>
                                    <div className="field col-lg-3 col-md-3">
                                        <label className="col-form-label alignLeft">
                                            Date Till
                                        </label>
                                        <DatePicker
                                            onChange={(value) => {
                                                this.setState({
                                                    date_till: value,
                                                });
                                            }}
                                            value={this.state.date_till}
                                        />
                                    </div>
                                    <div className="field col-lg-3 col-md-3 form-group">
                                        <label className="col-form-label alignLeft required w-100">
                                            Store
                                        </label>
                                        <StoreSearchSingle
                                            className="w-100"
                                            value={this.state.storelabel}
                                            onSelectStore={this._onSelectStore}
                                        />
                                        <div className="invalid-feedback">
                                            {this.state.storeiderror}
                                        </div>
                                    </div>
                                    <div className="field col-lg-3 col-md-3">
                                        <label className="col-form-label alignLeft">
                                            Status
                                        </label>
                                        <StatusOptionComp
                                            statusoption={this.statusOptions}
                                            selectedOption={this.state.status}
                                            callbackChangeFilter={(value) => {
                                                this.setState({
                                                    status: value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="field col-lg-3 col-md-3">
                                        <label className="col-form-label alignLeft">
                                            Customer Type
                                        </label>
                                        <SimpleDropdown
                                            id="customertype"
                                            listoptions={
                                                this.customerTypeOptions
                                            }
                                            selectedOption={
                                                this.state.customerType
                                            }
                                            callbackChangeFilter={(value) => {
                                                this.setState({
                                                    customerType: value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="field col-lg-3 col-md-3">
                                        <label className="col-form-label alignLeft">
                                            Promo Type
                                        </label>
                                        <SimpleDropdown
                                            id="promotype"
                                            listoptions={this.promoTypeOptions}
                                            selectedOption={
                                                this.state.promoType
                                            }
                                            callbackChangeFilter={(value) => {
                                                this.setState({
                                                    promoType: value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="field col-lg-3 col-md-3">
                                        <label className="col-form-label alignLeft">
                                            Promo Group Type
                                        </label>
                                        <SimpleDropdown
                                            id="promogroup"
                                            listoptions={this.promoGroupOptions}
                                            selectedOption={
                                                this.state.promoGroup
                                            }
                                            callbackChangeFilter={(value) => {
                                                this.setState({
                                                    promoGroup: value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="col-lg-2 col-sm-12 btn-container1"
                                        style={{ marginTop: '1em' }}
                                    >
                                        <Button
                                            className='p-button-success'
                                            onClick={(e) => this._searchPromotions(e)}
                                            label="Search"
                                            style={{ marginRight: '10px'}}
                                        />

                                        <Button
                                            className='p-button-outlined p-button-secondary'
                                            onClick={this._clearFiltersAndResults}
                                            label="Clear"
                                            style={{ marginRight: '10px'}}
                                        />  
                                    </div>
                                </div>

                                <div className="title_devide" />
                                {/* <PrimeTable
                                    showFilterSearch={false}
                                    list={promotions}
                                    columns={columns}
                                    actionColumn={this.actionTemplate}
                                    pageSizeChangecallback={(pageSize) => {
                                        this.setState(
                                            produce(this.state, (draft) => {
                                                if (draft.pageSize < pageSize) {
                                                    draft.currPage = 1;
                                                }
                                                draft.pageSize = pageSize;
                                            }),
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                    pageChangeCallback={(currPage) => {
                                        this.setState(
                                            {
                                                currPage: currPage,
                                            },
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                ></PrimeTable> */}
                                <SimpleTable
                                    columns={this.state.tableCols}
                                    rows={promotions}
                                    pageChangeCallback={(currPage) => {
                                        this.setState(
                                            {
                                                currPage: currPage,
                                            },
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                    callback={(pageSize) => {
                                        this.setState(
                                            produce(this.state, (draft) => {
                                                if (draft.pageSize < pageSize) {
                                                    draft.currPage = 1;
                                                }
                                                draft.pageSize = pageSize;
                                            }),
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                    // withoutSearchBar={true}
                                    withPageSize={this.state.pageSize}
                                    withCurrPage={this.state.currPage}
                                    withPageFeedback={true}
                                />
                            </form>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {isSuccess ? this.state.message : ''}
                            </div>
                        }
                    />

                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div className="deleteAlertDiv">
                                Are you sure you want to delete this promotion?
                                <div style={{ margin: '2em' }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec deletBtn"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded deletBtn"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />

                    <ConfirmAlert
                        show={errormsg}
                        handleClose={(event) =>
                            this.hideErrorAlertModal(event, refresh)
                        }
                        children={
                            <div
                                style={
                                    errormsg
                                        ? {
                                              padding: '2em',
                                              color: 'red',
                                              fontWeight: '500',
                                          }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {errormsg ? `${errormsg}.` : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }

    _searchPromotions = (e) => {
        if (e) {
            e.preventDefault();
        }
        console.log('_search Promotions');
        const {
            storeid,
            date_type,
            date_from,
            date_till,
            customerType,
            promoType,
            promoGroup,
            status,
        } = this.state;

        this.requestBody = {};
        const queryParams = [];
        if (storeid !== '') {
            queryParams.push(`storeid=${storeid}`);
            this.setState({ storeiderror: '' });
            this.requestBody['storeid'] = storeid;
        } else {
            this.setState({ storeiderror: 'Select store' });
            return;
        }

        if (date_type !== '') {
            if (date_from === '' && date_till === '') {
                //do nothing
            } else {
                if (date_from !== '' && date_till !== '') {
                    queryParams.push(`date_type=${date_type}`);
                    queryParams.push(`date_from=${formatDate(date_from)}`);
                    queryParams.push(`date_till=${formatDate(date_till)}`);
                } else if (date_from !== '') {
                    queryParams.push(`date_type=${date_type}`);
                    queryParams.push(`date_from=${formatDate(date_from)}`);
                    queryParams.push(`date_till=${formatDate(date_from)}`);
                } else if (date_till !== '') {
                    queryParams.push(`date_type=${date_type}`);
                    queryParams.push(`date_from=${formatDate(date_till)}`);
                    queryParams.push(`date_till=${formatDate(date_till)}`);
                }

                this.requestBody['date_type'] = date_type;
                this.requestBody['date_from'] = formatDate(date_from);
                this.requestBody['date_till'] = formatDate(date_till);
            }
        }

        if (status !== '') {
            queryParams.push(`status=${status}`);
            this.requestBody['status'] = status;
        }
        if (customerType !== '') {
            queryParams.push(`customertype=${customerType}`);
            this.requestBody['customertype'] = customerType;
        }
        if (promoGroup !== '') {
            queryParams.push(`promogroup=${promoGroup}`);
            this.requestBody['promogroup'] = promoGroup;
        }
        if (promoType !== '') {
            queryParams.push(`promotype=${promoType}`);
            this.requestBody['promotype'] = promoType;
        }

        //validate the filters
        if (queryParams.length <= 0) {
            console.log('select atlease one filter');
            return;
        }

        queryParams.push(`pagesize=${this.pageSize}`);
        queryParams.push(`pageno=${this.currPage}`);

        console.log('requstBody::', this.requestBody);
        //query
        this.props.dispatch(promotionSearchByFields(queryParams.join('&')));
        // if (e) { e.preventDefault() }
    };

    _clearFiltersAndResults = (e) => {
        if (e) {
            e.preventDefault();
        }
        //clear all the filters
        this.setState({
            storeid: '',
            storelabel: '',
            date_type: '',
            date_from: '',
            date_till: '',
            customerType: '',
            promoType: '',
            promoGroup: '',
            status: '',
            storeiderror: '',
        });
        //clear the search results
    };

    _onSelectStore = (selectedStores) => {
        console.log(
            'selected stores from storesearch:;',
            selectedStores,
            this.state.storeid
        );
        this._setStore(selectedStores.value, selectedStores.label);
    };

    _setStore = (p1, p2) => {
        this.setState(
            { storeid: p1, storelabel: p2, search_value: p1, storeiderror: '' },
            () => {
                localStorage.setItem('storeid', p1);
                localStorage.setItem('storelabel', p2);
            }
        );
    };
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DisplaySalesPromotions);

import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";

// Validation Schema using Yup
const ProductSchema = Yup.object().shape({
    dx_product_code: Yup.string().required("Product Code is required"),
    dx_addons: Yup.string().required("Addons are required"),
    netsuite_product_code: Yup.string().required(
        "Netsuite Product Code is required",
    ),
    netsuite_product_desc: Yup.string().required(
        "Netsuite Product Description is required",
    ),
    salesUnitId: Yup.string().required("Sales Unit ID is required"),
    dx_product_name: Yup.string().required("Product Name is required"),
    dx_addons_name: Yup.string().required("Addons Name is required"),
});

const NetsuiteMappingConfigure = ({ initialValues, onSubmit }) => {
    const defaultValues = {
        dx_product_code: "",
        dx_addons: "",
        netsuite_product_code: "",
        netsuite_product_desc: "",
        salesUnitId: "",
        dx_product_name: "",
        dx_addons_name: "",
    };

    return (
        <div className="form_height">
            <div className="grid px-3 m-4">
                <h2 className="text-3xl font-normal">Save/Edit Product</h2>
            </div>
            <div className="grid px-3">
                <div className="col-9">
                    <div className="p-fluid formgrid br-2 bg-white p-4 border-round-xl shadow-1">
                        <Formik
                            initialValues={initialValues || defaultValues}
                            validationSchema={ProductSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                onSubmit(values);
                                setSubmitting(false);
                            }}
                        >
                            {({ errors, touched, isSubmitting }) => (
                                <Form className="p-fluid">
                                    <div className="grid">
                                        <div className="col-6 mb-3">
                                            <div className="p-field">
                                                <label htmlFor="dx_product_code">
                                                    DX Product Code
                                                </label>
                                                <Field
                                                    as={InputText}
                                                    id="dx_product_code"
                                                    name="dx_product_code"
                                                    className={
                                                        touched.dx_product_code &&
                                                        errors.dx_product_code
                                                            ? "p-invalid"
                                                            : ""
                                                    }
                                                />
                                                {touched.dx_product_code &&
                                                    errors.dx_product_code && (
                                                        <small className="p-error">
                                                            {
                                                                errors.dx_product_code
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="col-6 mb-3">
                                            <div className="p-field">
                                                <label htmlFor="netsuite_product_code">
                                                    Netsuite Product Code
                                                </label>
                                                <Field
                                                    as={InputText}
                                                    id="netsuite_product_code"
                                                    name="netsuite_product_code"
                                                    className={
                                                        touched.netsuite_product_code &&
                                                        errors.netsuite_product_code
                                                            ? "p-invalid"
                                                            : ""
                                                    }
                                                />
                                                {touched.netsuite_product_code &&
                                                    errors.netsuite_product_code && (
                                                        <small className="p-error">
                                                            {
                                                                errors.netsuite_product_code
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="col-6 mb-3">
                                        <div className="p-field">
                                            <label htmlFor="dx_addons">
                                                DX Addons
                                            </label>
                                            <Field
                                                as={InputTextarea}
                                                id="dx_addons"
                                                name="dx_addons"
                                                rows="3"
                                                className={
                                                    touched.dx_addons &&
                                                    errors.dx_addons
                                                        ? "p-invalid"
                                                        : ""
                                                }
                                            />
                                            {touched.dx_addons &&
                                                errors.dx_addons && (
                                                    <small className="p-error">
                                                        {errors.dx_addons}
                                                    </small>
                                                )}
                                        </div>
                                    </div>
                                    </div>
                                    

                                    <div className="p-field">
                                        <label htmlFor="netsuite_product_desc">
                                            Netsuite Product Description
                                        </label>
                                        <Field
                                            as={InputTextarea}
                                            id="netsuite_product_desc"
                                            name="netsuite_product_desc"
                                            rows="3"
                                            className={
                                                touched.netsuite_product_desc &&
                                                errors.netsuite_product_desc
                                                    ? "p-invalid"
                                                    : ""
                                            }
                                        />
                                        {touched.netsuite_product_desc &&
                                            errors.netsuite_product_desc && (
                                                <small className="p-error">
                                                    {
                                                        errors.netsuite_product_desc
                                                    }
                                                </small>
                                            )}
                                    </div>

                                    <div className="p-field">
                                        <label htmlFor="salesUnitId">
                                            Sales Unit ID
                                        </label>
                                        <Field
                                            as={InputText}
                                            id="salesUnitId"
                                            name="salesUnitId"
                                            className={
                                                touched.salesUnitId &&
                                                errors.salesUnitId
                                                    ? "p-invalid"
                                                    : ""
                                            }
                                        />
                                        {touched.salesUnitId &&
                                            errors.salesUnitId && (
                                                <small className="p-error">
                                                    {errors.salesUnitId}
                                                </small>
                                            )}
                                    </div>

                                    <div className="p-field">
                                        <label htmlFor="dx_product_name">
                                            DX Product Name
                                        </label>
                                        <Field
                                            as={InputText}
                                            id="dx_product_name"
                                            name="dx_product_name"
                                            className={
                                                touched.dx_product_name &&
                                                errors.dx_product_name
                                                    ? "p-invalid"
                                                    : ""
                                            }
                                        />
                                        {touched.dx_product_name &&
                                            errors.dx_product_name && (
                                                <small className="p-error">
                                                    {errors.dx_product_name}
                                                </small>
                                            )}
                                    </div>

                                    <div className="p-field">
                                        <label htmlFor="dx_addons_name">
                                            DX Addons Name
                                        </label>
                                        <Field
                                            as={InputTextarea}
                                            id="dx_addons_name"
                                            name="dx_addons_name"
                                            rows="3"
                                            className={
                                                touched.dx_addons_name &&
                                                errors.dx_addons_name
                                                    ? "p-invalid"
                                                    : ""
                                            }
                                        />
                                        {touched.dx_addons_name &&
                                            errors.dx_addons_name && (
                                                <small className="p-error">
                                                    {errors.dx_addons_name}
                                                </small>
                                            )}
                                    </div>

                                    <Button
                                        type="submit"
                                        label={
                                            isSubmitting ? "Saving..." : "Save"
                                        }
                                        className="p-button-success"
                                        disabled={isSubmitting}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NetsuiteMappingConfigure;

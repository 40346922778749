import React, { useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';

const StoreSelection = ({ 
    stores = [], 
    selectedStores = [], 
    updateSelectedStore, 
    isMulti = true 
}) => {
    const [optionSelected, setOptionSelected] = useState(selectedStores);

    const handleChange = (selected) => {
        setOptionSelected(selected);
        if (updateSelectedStore) {
            updateSelectedStore({
                "label": `(${selected.storeid}) ${selected.title}`,
                "value": selected.storeid
            });
        }
    };

    // Transform store data into the format required by PrimeReact components
    const storeSelectOptions = stores.map((store) => ({
        label: `(${store.storeid}) ${store.title}`,
        value: store, // Store the entire object here to retain both label and value
    }));

    return (
        <div>
            {isMulti ? (
                <MultiSelect
                    value={optionSelected}
                    options={storeSelectOptions}
                    onChange={(e) => handleChange(e.value)} // `e.value` contains the selected objects
                    placeholder="Select stores"
                    display="chip"
                    optionLabel="label"
                    className="w-full"
                />
            ) : (
                <Dropdown
                    value={optionSelected}
                    options={storeSelectOptions}
                    onChange={(e) => handleChange(e.value)} // `e.value` contains the selected object
                    placeholder="Select a store"
                    optionLabel="label"
                    className="w-full"
                />
            )}
        </div>
    );
};

export default StoreSelection;

import React from "react";
import SimpleTable from '../../components/SimpleTable'
import DatePicker from "react-date-picker";
import { produce } from "immer";
import {
  crudReset,
  resetAction,
  productSalesSummary,
} from '../../actions/actions';
import { formatDate, permissions } from '../../helpers/Util'


import "react-accessible-accordion/dist/fancy-example.css";
import { connect } from "react-redux";
import ConfirmAlert from '../../components/ConfirmAlert';
import StoreSearchSingle from '../system/users/StoreSearchSingle';
import ExportAndDownloadReport from "./ExportAndDownloadReport";
import { PRODUCT_SALES_SUMMARY_REPORT } from "../../constants/constants";
import ReportWarning from '../../components/ReportWarning';

class ProductSalesSummary extends React.PureComponent {

  constructor(props) {
    super(props);

    this.companyId = localStorage.getItem("company_id")
    this.group = "DE_REPORTS_PRODUCT_SALES_SUMMARY"
    this.module = "REPORTS"
    this.allPermissions = []

    this.requestBody = {}
    this.currPage = 1
    this.pageSize = 10

    this.state = {
      storeid: '',
      trxno: '',
      invoiceno: '',
      signonid: '',
      storesessionid: '',
      usercode: '',
      terminalid: '',
      orderid: '',
      trxdate: '',
      date_from: new Date(),
      date_till: new Date(),
      // storedate: new Date(),
      // storesessions: [{name: "Select", id: ''}],
      params: {},
      showExportReport: false,

      s_storelabel: "",
      pageSize: this.pageSize,
      currPage: this.currPage,
      fromDate: new Date(),
      toDate: new Date(),
      order_id: "",
      date_type: "trxdate",
      searchbtn: false,
      searchfieldbtn: false,
      storelabel: "",
      cartdata:[],
      storeiderror: '',
      tableCols: [
        // {
        //   dataField: "storeid",
        //   text: "Store Id",
        //   sort: true
        // },
        // {
        //   dataField: "storesessionid",
        //   text: "Store Session Id",
        //   sort: true
        // },
        {
          dataField: "productcode",
          text: "Product Code",
          sort: true
        },
        {
          dataField: "title",
          text: "Title",
          sort: true
        },
        {
          dataField: "categorycode",
          text: "Category Code",
          sort: true
        },
        {
          dataField: "totalquantity",
          text: "Total Quantity",
          sort: true
        },
        {
          dataField: "totalamount",
          text: "Total Amount",
          sort: true
        }
      ]
    };
  }

  styleDisplayRow = (row, rowIndex) => {
    return {}
  }


  toDate = dateStr => {
    const [day, month, year] = dateStr.split("-");
    return new Date(year, month - 1, day);
  };

  // API Calls
  createFetchUrl = fetchType => {
    const {
      pageSize,
      currPage,
      storeid,
      //storedate,
      date_from,
      date_till,
    } = this.state;

    const queryParams = []

    if (fetchType === "ONLOAD") {
      queryParams.push(`storeid=${storeid}`)
      //queryParams.push(`storedate=${formatDate(storedate)}`)
      queryParams.push(`date_from=${formatDate(date_from)}`)
      queryParams.push(`date_till=${formatDate(date_till)}`)
      queryParams.push(`pagesize=${this.pageSize}`)
      queryParams.push(`pageno=${this.currPage}`)

      return queryParams.join('&');
    }

    this.requestBody['pagesize'] = pageSize
    this.requestBody['pageno'] = currPage
    
    for (const [key, value] of Object.entries(this.requestBody)) {
      queryParams.push(`${key}=${value}`)
    }

    console.log('params::', queryParams)
    return queryParams.join('&');
  };

  callFetch = fetchType => {
      this.props.dispatch(productSalesSummary(this.createFetchUrl(fetchType)))
  };

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    switch (triggerPoint) {
      case "ONLOAD":
        // this.setState(produce(this.state, draft => {
        //   draft.currPage = 1;
        // }), () => {
          this.callFetch(triggerPoint);
        // });
        break;
      case "PAGECLICK":
        this.callFetch();
        break;
      default:
    }
  };

  componentWillMount() {
    this.setState({
      storeid: localStorage.getItem("storeid"),
      s_storelabel: localStorage.getItem("storelabel")
    });

    
    //this.fetchStoreSessions()
    
    this.allPermissions = permissions(this.module, this.group)
  }

  /*
  fetchStoreSessions = () => {
    const {
      storeid,
      storedate
    } = this.state

    if (storeid !== '' && storedate !== '') {
      const queryParams = []
      queryParams.push(`storeid=${storeid}`)
      queryParams.push(`storedate=${formatDate(storedate)}`)
      this.props.dispatch(storeOpenSearchByFields(
        queryParams.join('&'), 
        SEARCH_STORE_OPEN_SUCCESS_SILENT, 
        SEARCH_STORE_OPEN_FAILURE_SILENT)
      )
    }
  }
  */

  componentWillReceiveProps = () => {
    console.log(this.props.queryData)
    // const { storeSessions } = this.props.queryData;
    // console.log('returned storeSessions::', storeSessions)
    
    // if (storeSessions && storeSessions.length > 0) {
    //   var sessions = [
    //     {name: "Select", id: ''}
    //   ]
    //   var returnedSessions = storeSessions.map(e => 
    //     ({name : `${e.trxdate} (${e.status})`, id : e.trxval})
    //   )
    //   sessions.push(returnedSessions)
    //   console.log('sessions::', sessions)
    //   this.setState( { storesessions: sessions })
    // }
  }

  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, s_storelabel: p2, search_value: p1 },
      ()=>{
      localStorage.setItem("storeid", p1);
      localStorage.setItem("storelabel", p2);
    });
    this.setState({storeiderror: ''})
  };

  hideErrorAlertModal = (e, refresh) => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.dispatch(resetAction());
    // if (refresh === true) {
    //   this.callQueryApiForFetch("ONLOAD");
    // }
  };

  _renderExportReportBtn = () => {
    //if (!this.allPermissions.includes('Read')) { return }
    return (
      <button
          type="submit"
          className="btn btn-themes btn-rounded"
          onClick={(e) => {
              this.viewExportReport(e)
          }}
      >
        Export/Download Report
      </button>
    );
  };

  viewExportReport = (row) => {
    this.setState({params: {
      storeid: this.state.storeid,
      s_storelabel: this.state.s_storelabel,
      // storedate: this.state.storedate,
      // paytypes: this.state.paytypes,
      // paytype: this.state.paytype,
      // terminalid: this.state.terminalid,
      // usercode: this.state.usercode,
      // trxno: this.state.trxno,
    }}, () => {
      this.setState({showExportReport: true})
    })
  }


  _validateAndSearch = (event) => {

    if (event) event.preventDefault();

    const {
      storeid,
      date_from, 
      date_till
      // storedate,
      // storesessionid
    } = this.state

    this.requestBody = {}
    const queryParams = []

    console.log('storeid::', this.state.storeid)
    if (this.state.storeid === '' || this.state.storeid === null) {
      this.setState({storeiderror: 'Please select the store'})
      return
    } else {
      this.setState({storeiderror: ''})
      this.requestBody['storeid'] = storeid
      queryParams.push(`storeid=${storeid}`)
    }

    if (date_from !== '') {
      this.requestBody['date_from'] = formatDate(date_from)
      queryParams.push(`date_from=${formatDate(date_from)}`)
    }
    if (date_till !== '') {
      this.requestBody['date_till'] = formatDate(date_till)
      queryParams.push(`date_till=${formatDate(date_till)}`)
    }

    /*
    if (storedate !== '') {
      this.requestBody['storedate'] = formatDate(storedate)
      queryParams.push(`storedate=${formatDate(storedate)}`)
    }

    if (storesessionid !== '') {
      this.requestBody['storesessionid'] = storesessionid
      queryParams.push(`storesessionid=${storesessionid}`)
    }
    */

    //default
    this.requestBody['status'] = 'close'
    queryParams.push(`status=close`)

    queryParams.push(`pagesize=${this.pageSize}`)
    queryParams.push(`pageno=${this.currPage}`)
    console.log('requstBody::', this.requestBody)
    //query
    this.props.dispatch(productSalesSummary(queryParams.join('&')))
  }

  _hideExport = () => {
    this.props.queryData.reportexport = {}
    this.setState({showExportReport: false})
  }

  _renderSearchCiteria = () => {
    console.log('_renderSearchCriteria.....')

    const {
      propConfigs,
      // storeSessions
    } = this.props.queryData;

    //var paytypes = ''

    if (propConfigs && propConfigs.length > 0 && propConfigs[0].additionalfields ) {
      this.setState( {paytypes: propConfigs[0].additionalfields}) //propConfigs[0].additionalfields.values().join(',')
    }

    // if (storeSessions && storeSessions.length > 0) {
    //   var sessions = [
    //     {name: "Select", id: ''}
    //   ]
    //   var returnedSessions = storeSessions.map(e => 
    //     ({name : `${e.trxdate} (${e.status})`, id : e.trxval})
    //   )
    //   sessions.push(returnedSessions)
    //   this.setState( { storesessions: sessions })
    // }

    return (
      <React.Fragment>
        <div className="row" />
        <div className="row">
          <div className="form-group col-lg-3 col-sm-12 ">
            <label htmlFor="storeid" className="floatLeft required">
              Select Store
            </label>
            <StoreSearchSingle value={this.state.s_storelabel} onSelectStore={this._onSelectStore}/>
            <div className="invalid-feedback">{this.state.storeiderror}</div>
          </div>

          <div className="form-group col-lg-3 col-sm-12">
            <label htmlFor="fromDate" className="floatLeft">
              Date From
            </label>
            <DatePicker
              onChange={(value)=>this.setState({date_from: value}, () => {
                if (this.state.date_from > this.state.date_till) {
                  this.setState({date_till: this.state.date_from})
                }
              })
              }
              value={this.state.date_from}
              maxDate={new Date()}
              format="d/M/yy"
            />
          </div>
          <div className="form-group col-lg-3 col-sm-12">
            <label htmlFor="toDate" className="floatLeft">
              Date To
            </label>
            <DatePicker
              minDate={this.state.date_from}
              onChange={(value)=>this.setState({date_till: value})}
              value={this.state.date_till}
              maxDate={new Date()}
              format="d/M/yy"
            />
          </div>

          <div className="form-group col-1 noPadding">
              <img
                alt="search"
                src={`${process.env.PUBLIC_URL}/assets/icons/ic_search_blue.png`}
                onClick={event => this._validateAndSearch(event)}
                className="searchBtn"
              />
            </div>

          {/* <div className="form-group col-lg-3 col-sm-12">
            <label htmlFor="storeDate" className="floatLeft required">
              Store Date
            </label>
            <DatePicker
              onChange={(value)=>this.setState({storedate: value}, () => {
                this.fetchStoreSessions()
              })
              }
              value={this.state.storedate}
            />
          </div> */}

          {/* <div className="form-group col-lg-3 col-sm-12">
            <label htmlFor="terminalid" className="floatLeft">
              Terminal Id
            </label>
            <input
                type="text"
                className="form-control"
                id="terminalid"
                value={this.state.terminalid}
                onChange={event => {
                  this.setState({ terminalid: event.target.value });
                }}
              />
          </div> */}
          

          {/* <div className="form-group col-lg-3 col-sm-4">
            <label htmlFor="storesessionid" className="floatLeft">
              Store Session
            </label>
            <div className="row">
              <select
                name="storesession"
                className="simpleSearchSelect2"
                value={this.state.storesessionid}
                onChange={event =>
                  this.setState({ storesessionid: event.target.value })
                }
              >
                {this.state.storesessions.length !== 0 &&
                  this.state.storesessions.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div> */}
        </div>
         
          <div className="row">

            {/* <div className="form-group col-lg-3 col-sm-4">
                <label htmlFor="paytype" className="floatLeft">
                  Pay Type
                </label>
                <div className="row">
                  <MultiSelect
                      filterOptions={formatLanguageArray(
                        this.state.paytypes
                      )}
                      setSelectedFieldValues={value => {
                        this.setState({ paytype: value });
                      }}
                      id="paytype"
                      itemsSelected={this.state.paytype}
                      label="Select Pay Type"
                  />
                </div>
              </div>  */}

              {/* <div className="form-group col-lg-3 col-sm-12">
                <label htmlFor="usercode" className="floatLeft">
                  User Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="usercode"
                  value={this.state.usercode}
                  onChange={event => {
                    this.setState({ usercode: event.target.value });
                  }}
                />
              </div>
           
            <div className="form-group col-lg-3 col-sm-12">
              <label htmlFor="trxno" className="floatLeft">
                Transaction No
              </label>
              <input
                type="text"
                className="form-control"
                id="trxno"
                value={this.state.trxno}
                onChange={event => {
                  this.setState({ trxno: event.target.value });
                }}
              />
            </div> */}
          

            {/* <div className="form-group col-1 noPadding">
              <img
                alt="search"
                src={`${process.env.PUBLIC_URL}/assets/icons/ic_search_blue.png`}
                onClick={event => this._validateAndSearch(event)}
                className="searchBtn"
              />
            </div> */}
            </div>

            <div className="form-group col-lg-7 col-sm-3 noPadding" />
        
      </React.Fragment>
    );
  }

  _onSelectStore = (selectedStores) => {
    console.log('selected stores from storesearch:;', selectedStores, this.state.storeid)
    this._setTenant(selectedStores.value, selectedStores.label)
  }

  render() {
    const {
      productSalesSummaryReport,
      servererror: serverError,
      errormsg: queryError
    } = this.props.queryData;

    let errormsg = "";
    let refresh = false;
    if (serverError) {
      errormsg = serverError;
      refresh = false;
    }
    if (queryError) {
      errormsg = queryError;
      refresh = true;
    }

    if (this.state.showExportReport) {
      return (
        <React.Fragment>
          <ExportAndDownloadReport
          params={this.state.params}
          _hideExport={this._hideExport}
          reporttype={PRODUCT_SALES_SUMMARY_REPORT}
          />
        </React.Fragment>
      )
    }
    else {
    return (
    
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h2 className="page-header">Product Sales Report</h2>
            </div>
            {/* <div className="col-lg-6 col-sm-12 alignRight alignWithPageHeader">
              {this._renderExportReportBtn()}
            </div> */}
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv" onSubmit={this._validateAndSearch}>
                <input type="submit" className="d-none"></input>
                <div className="row">
                  {this._renderSearchCiteria()}
                </div>
                <div className="title_devide" />
                <div className="invalid-feedback">{errormsg}</div>
                <ReportWarning />
                <SimpleTable
                  columns={this.state.tableCols}
                  rows={productSalesSummaryReport}
                  rowStyle={ this.styleDisplayRow }
                  pageChangeCallback={(currPage) => {
                    this.setState({
                      currPage: currPage
                    },()=>{
                      this.callQueryApiForFetch("PAGECLICK");
                    })
                  }}
                  callback={(pageSize) => {
                    this.setState(produce(this.state, draft => {
                      if (draft.pageSize < pageSize) {
                        draft.currPage = 1
                      }
                      draft.pageSize = pageSize
                    }),()=>{
                      this.callQueryApiForFetch("PAGECLICK");
                    })
                  }}
                  withoutSearchBar={true}
                  withPageSize={this.state.pageSize}
                  withCurrPage={this.state.currPage}
                  withPageFeedback={true}
                />
                {/* <TableFooter /> */}
              </form>
            </div>
          </div>
          <ConfirmAlert
            show={errormsg}
            handleClose={event => this.hideErrorAlertModal(event, refresh)}
            children={
              <div
                style={
                  errormsg
                    ? { padding: "2em", color: "red", fontWeight: "500" }
                    : { padding: "2em", color: "red" }
                }
              >
                {errormsg ? `${errormsg}.` : ""}
              </div>
            }
          />
        </div>
      </React.Fragment>
          );}
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(ProductSalesSummary);

import React, {useState, useEffect, useMemo} from "react";
import { connect } from "react-redux";
import TenantCode from "../../../components/TenantCode";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DatePicker from "react-date-picker";
import { toDate, renderConfigInMultiSelect, dateRange } from "./../../../helpers/Util";
import {
  searchGeneral,
  resetAction,
  crudReset
} from "../../../actions/actions";
import ExportButton from "../../../components/TableExportButton";
import ConfirmAlert from '../../../components/ConfirmAlert';
import Modal from "react-awesome-modal";
import 'rc-time-picker/assets/index.css';
import ReportTable from "../../../components/ReportTable";
import StoreSearchSingle from "../../system/users/StoreSearchSingle";

const SalesTrackerReport = (props) => {
  const REPORT_TYPE = ["Cart Inventory Stock", "Product Search By Keys"]

  const tableColsReport1 = [
    {
      dataField: "productcode",
      text: "Product Code",
      sort: true
    },
    {
      dataField: "title",
      text: "Title",
      sort: true
    },
    {
      dataField: "stock",
      text: "Stock",
      sort: true
    },
    {
      dataField: "stopsell",
      text: "Stop Sell",
      sort: true
    }
  ]

  const tableColsReport2 = [
    {
      dataField: "storeid",
      text: "Store Id",
      sort: true
    },
    {
      dataField: "searchkey",
      text: "Search Key",
      sort: true
    },
    {
      dataField: "result",
      text: "Result",
      sort: true
    }
  ]

  const [showSearchDiv, setShowSearchDiv] = useState(false)
  const [storeInfo, setStoreInfo] = useState({
    storeid: "",
    storelabel: ""
  })
  const [memberid, setMemberId] = useState("")
  const [date_from, setDate_from] = useState(new Date("June 1, 2020 0:00:00"))
  const [date_till, setDate_till] = useState(new Date())
  const [showReport, setShowReport] = useState(REPORT_TYPE[0])
  const [storeiderror, setStoreiderror] = useState("")

  // useEffect(() => {
  //   props.dispatch(
  //     searchGeneral(
  //       "/sales/v1/store/search/fields",
  //       {
  //         body: {
  //           groupid: localStorage.getItem("storegroup_access")
  //         }
  //       },
  //       "SEARCH_CITY_BY_COUNTRY_SUCCESS",
  //       "SEARCH_CITY_BY_COUNTRY_FAILURE"
  //     )
  //   )
  // }, [])

  useEffect(() => {
    const { dataByCountry } = props.queryData;
    if(storeInfo.storeid === ""){
      if(dataByCountry.length > 0){
        setStoreInfo({
          storeid: dataByCountry[0].storeid,
          storelabel: dataByCountry[0].title
        })
      }
    }
  }, [props.queryData])

  useEffect(() => {
    if(storeInfo.storeid !== "")
      _validateAndSearch();
  },[storeInfo])

  const _createInventoryObject = useMemo(() => {
    let results = [
      { id: "productcode", Header: 'Product Code', accessor: 'productcode' },
      { id: "title", Header: 'Title', accessor: 'title' },
      { id: "stock", Header: 'Stock', accessor: 'stock' },
      { id: "stopsell", Header: 'Stop Sell', accessor: 'stopsell' }
    ];
    let uniquelist = [...new Map(results.map(item => [item["Header"], item])).values()];
    return uniquelist;
  }, [])

  const _createSearchKeyObject = useMemo(() => {
    let results = [
      { id: "storeid", Header: 'Store Id', accessor: 'storeid' },
      { id: "searchkey", Header: 'Search Key', accessor: 'searchkey' },
      { id: "result", Header: 'Result', accessor: 'result' }
    ];
    let uniquelist = [...new Map(results.map(item => [item["Header"], item])).values()];
    return uniquelist;
  }, [])

  const _drawNoData = useMemo(() => {
    return (
      <div className="sc-AxjAm cKckJm">
        <div className="tableNoData">Your search does not retrieve any data. Please search again using another criteria.</div>
      </div>
    )
  }, [])

  const _renderSearchForm= () => {
    var contentareastyle = {
      display: 'inline-flex',
      flexFlow: 'wrap',
      border: '1px solid #dcdcdc',
      padding: '10px',
      overflowY: 'scroll',
      maxHeight: '100px',
      maxWidth: '450px'
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className="row">
            <div className="form-group col-lg-6 col-sm-12">
              <label htmlFor="memberid" className="floatLeft">
                Member Id
              </label>
              <input
                type="text"
                className="form-control"
                id="memberid"
                value={memberid}
                onChange={event => {setMemberId(event.target.value)}}
              />
            </div>
          </div>
          <div className="row">
           <div className="form-group col-lg-6 col-sm-12">
              <label htmlFor="search_value" className="floatLeft">
                Choose Store
              </label>
                 {/* <TenantCode
                    value={storeInfo.storelabel}
                    setTenantCode={_setTenant}
                  /> */}
                  <StoreSearchSingle value={storeInfo.storelabel} onSelectStore={_onSelectStore}/>
            </div>
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="fromDate" className="floatLeft">
              Date From
            </label>
            <DatePicker
              onChange={setDate_from}
              value={date_from}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="Transaction Date From" className="floatLeft">
              Date To
            </label>
            <DatePicker
              onChange={setDate_till}
              value={date_till}
            />
          </div>
        </div>
        <div className="row btn-container1 form-button">
          <div className="col-sm-12 col-lg-8">
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                _validateAndSearch();
                _closeSearchDiv();
              }}
            >
              Search
            </button>
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                _closeSearchDiv();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const _setTenant = (p1, p2) => {
    setStoreInfo({
      storeid: p1, storelabel: p2
    })
  };

  const _onSelectStore = (selectedStores) => {
    _setTenant(selectedStores.value, selectedStores.label)
  }

  const _callFields = (reportType) => {
    //const daterange = dateRange(overalldate);
    const _body = {
      storeid: storeInfo.storeid,
      date_from: toDate(date_from),
      date_till: toDate(date_till)
    };

    if(reportType === REPORT_TYPE[0]) {
      _body.memberid = memberid
    }

    const requestBody = {
      body: _body
    }
    return requestBody;
  }

  const _callFetch = () => {
    props.dispatch(
      searchGeneral(
        "sales/v1/tracker/search/inventory/stock",
        _callFields(REPORT_TYPE[0]),
        "SEARCH_TRACKER_INVENTORY_SUCCESS",
        "SEARCH_TRACKER_INVENTORY_FAILURE"
      )
    );
    props.dispatch(
      searchGeneral(
        "sales/v1/tracker/search/product/searchbykeys",
        _callFields(REPORT_TYPE[1]),
        "SEARCH_TRACKER_SEARCHKEY_SUCCESS",
        "SEARCH_TRACKER_SEARCHKEY_FAILURE"
      )
    );
  }

  const _validateAndSearch = () => {
    if (_validateForm()) {
      _callFetch();
    } else {
      window.scrollTo(0, 0);
    }
  };

  const _validateForm = () => {
    _clearErrorMessages();
    let valid = true;
    if (storeInfo.storeid.trim() === "") {
      setStoreiderror("Please select store id");
      valid = false;
    }
    return valid;
  }

  const _clearErrorMessages = () => {
    setStoreiderror("")
  };

  const handleProductReport = () => {
    setShowReport(REPORT_TYPE[0])
  }

  const handleCategoryReport = () => {
    setShowReport(REPORT_TYPE[1])
  }

  const _renderTable = () => {
    return(
      <React.Fragment>
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <div className="btn-group" role="group">
              <button type="button"
                style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px", whiteSpace: "nowrap" }}
                className={showReport === REPORT_TYPE[0] ? 'active btn btn-outline-primary' : "icon_btn display-inline"}
                onClick={handleProductReport}>{REPORT_TYPE[0]}</button>
              <button type="button"
                style={{ borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", whiteSpace: "nowrap" }}
                className={showReport === REPORT_TYPE[1] ? 'active btn btn-outline-primary' : "icon_btn display-inline"}
                onClick={handleCategoryReport}>{REPORT_TYPE[1]}</button>
            </div>
          </div>
        </div>


        <div className="tab-content" id="myTabContent">
          {showReport === REPORT_TYPE[0] ?
            (props.queryData.trackerInventoryReportData && props.queryData.trackerInventoryReportData.length > 0) ?
              <ReportTable paginationtype={"scroll"} rowdata={props.queryData.trackerInventoryReportData} columndata={_createInventoryObject}></ReportTable>
              : _drawNoData
            :<div></div>}
          {showReport === REPORT_TYPE[1] ?
            (props.queryData.trackerSearchKeyReportData && props.queryData.trackerSearchKeyReportData.length> 0) ?
              <ReportTable paginationtype={"scroll"} rowdata={props.queryData.trackerSearchKeyReportData} columndata={_createSearchKeyObject}></ReportTable>
              : _drawNoData
            : <div></div> }
        </div>
      </React.Fragment>
    )
  }

  const _openSearchDiv = () => {
    setShowSearchDiv(true);
  };

  const _closeSearchDiv = () => {
    setShowSearchDiv(false);
  };

  const _renderSearchModal = () => {
    return (
      <Modal
        visible={showSearchDiv}
        width="70%"
        height="70%"
        effect="fadeInRight"
        onClickAway={_closeSearchDiv}
      >
        <div className="modalForm queryFormModal">
          <div className="row">
            <div className="col-xs-12 col-md-6 alignLeft">Report Search Fields</div>
            <div className="col-xs-12 col-md-6 alignRight">
              <a onClick={_closeSearchDiv} className="closeIcon">
                X
              </a>
            </div>
          </div>
          <div className="title_devide" />
          {_renderSearchForm()}
        </div>
      </Modal>
    );
  };


  const _renderSearchBtn = () => {
    return (
      <button
        type="submit"
        className="page-header-btn icon_btn display-inline"
        onClick={event => {
          if (event) event.preventDefault();
          _openSearchDiv();
        }}
      >
        Advanced Filter
      </button>
    );
  };

  const hideErrorAlertModal = (e, refresh) => {
    e.preventDefault();
    props.dispatch(crudReset());
    props.dispatch(resetAction());
  };

  const _getReport = (reportType, records, headers) => {
    if(typeof records !== "undefined" && records.length > 0){
      let tablerow = []
      switch(reportType) {
        case REPORT_TYPE[0]:
          tablerow = records.map(e => [e.productcode, e.title, e.stock, e.stopsell])
          break;
        case REPORT_TYPE[1]:
          tablerow = records.map(e => [e.storeid, e.searchkey, e.result])
          break;
        default:
      }
      const colheader = headers.map(e => ({ label: e.text, key: e.dataField }))
      tablerow.unshift(colheader.map(e=>e.label));
      return tablerow;
    }
  }

  const {
    servererror: serverError,
    errormsg: queryError,
    trackerInventoryReportData,
    trackerSearchKeyReportData
  } = props.queryData;
  let errormsg = "";
  let refresh = false;
  if (serverError) {
    errormsg = serverError;
    refresh = false;
  }
  if (queryError) {
    errormsg = queryError;
    refresh = true;
  }
  return (
   <React.Fragment>
    {showSearchDiv && _renderSearchModal()}
      <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">Sales Tracker Report
              <img
                title="Refresh"
                onClick={_validateAndSearch}
                className="refreshtree"
                src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
              />
              </h2>
            </div>
            <div className="col-md-3 text-right noPadding noMargin">
             {showReport === REPORT_TYPE[0] ?
              <ExportButton
                title={REPORT_TYPE[0]}
                tabledata ={_getReport(REPORT_TYPE[0], trackerInventoryReportData, tableColsReport1)}
              />
              :
              <ExportButton
              title={REPORT_TYPE[1]}
              tabledata ={_getReport(REPORT_TYPE[1], trackerSearchKeyReportData, tableColsReport2)}
             />
             }
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
                <div className="row">
                  <div className="col-lg-3 col-sm-12 noPadding noMargin">
                    <h3 className="page-title">Reports</h3>
                  </div>
                  <div className="col-lg-9 col-sm-12 noPadding noMargin alignRight">
                    {_renderSearchBtn()}
                  </div>
                </div>
                <div className="title_devide" />
                <div className="invalid-feedback">{errormsg}</div>
                 {_renderTable()}
              </form>
            </div>
          </div>
          <ConfirmAlert
            show={errormsg}
            handleClose={event => hideErrorAlertModal(event, refresh)}
            children={
              <div
                style={
                  errormsg
                    ? { padding: "2em", color: "red", fontWeight: "500" }
                    : { padding: "2em", color: "red" }
                }
              >
                {errormsg ? `${errormsg}.` : ""}
              </div>
            }
          />
      </div>
    </React.Fragment>
  );

}
SalesTrackerReport.propTypes = {};

const mapStateToProps = state => {
  return {
    queryData: state.queryData
  };
};

export default connect(mapStateToProps)(SalesTrackerReport);

import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { searchGeneral, deleteItem, crudReset } from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import Loading from "../../../components/Loading";
import { Link, useHistory } from "react-router-dom";
import { canView, canWrite, permissions } from "../../../helpers/Util";
import PrimeTable from "../../../components/PrimeTable";
import PreviewImage from "../../../components/ImageComponents/PreviewImage";
import EditImage from "../../../components/ImageComponents/EditImage";
import DeleteImage from "../../../components/ImageComponents/DeleteImage";

const UserRoles = (props) => {
    const { dispatch, queryData, crudData, loginData } = props;
    const history = useHistory();

    const companyId = localStorage.getItem("company_id");
    const group = "DE_ACCESS_USERROLES";
    const module = "ACCESS";
    const allPermissions = permissions(module, group);

    const [pageSize, setPageSize] = useState(10);
    const [currPage, setCurrPage] = useState(1);
    const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
    const [deleteRecId, setDeleteRecId] = useState("");
    const [message, setMessage] = useState("");

    const createFetchUrl = () => {
        return {
            body: {
                companyid: companyId,
                pagesize: pageSize,
                pageno: currPage,
            },
        };
    };

    const callFetch = useCallback(
        (fetchType) => {
            dispatch(
                searchGeneral(
                    "system/v1/user/role/search/fields",
                    createFetchUrl(fetchType),
                    "SEARCH_USERROLES_SUCCESS",
                    "SEARCH_USERROLES_FAILURE"
                )
            );
        },
        [companyId, pageSize, currPage, dispatch]
    );

    useEffect(() => {
        callFetch("SEARCH");
    }, [callFetch, loginData.language]);

    const deleteUserRole = (rowData) => {
        setMessage("User Role deleted successfully");
        setOpenDeletePrompt(true);
        setDeleteRecId(rowData.id);
    };

    const shdDeleteRec = (confirm) => {
        setOpenDeletePrompt(false);
        if (confirm) {
            dispatch(deleteItem("system/v1/user/role/delete/", deleteRecId));
        }
    };

    const hideAlertModal = (e) => {
        setMessage("");
        e.preventDefault();
        dispatch(crudReset());
        callFetch("ONLOAD");
    };

    const actionTemplate = (row) => {
        if (row.status === "Deleted") return null;

        return (
            <span>
                {canView(allPermissions) && (
                    <Link
                        to={{
                            pathname: "/ui/userroles/addNew",
                            state: { item: row, isEdit: false, isView: true },
                        }}
                    >
                        <PreviewImage />
                    </Link>
                )}
                {canWrite(allPermissions) && (
                    <Link
                        to={{
                            pathname: "/ui/userroles/addNew",
                            state: { item: row, isEdit: true },
                        }}
                    >
                        <EditImage />
                    </Link>
                )}
                {allPermissions.includes("Delete") && (
                    <DeleteImage handleClick={() => deleteUserRole(row)} />
                )}
            </span>
        );
    };

    const renderAddNewBtn = () => {
        if (!allPermissions.includes("Write")) return null;
        return (
            <Button
                label="Add New Role"
                icon="pi pi-plus"
                className="p-button-outlined"
                onClick={() => history.push("/ui/userroles/addNew")}
            />
        );
    };

    if (queryData.apiPending) {
        return <Loading />;
    }

    const columns = [
        { field: "rolecode", header: "Role Code" },
        { field: "title", header: "Role Name" },
        { field: "status", header: "Status" },
    ];

    return (
        <t>
            <div className="form_height">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <h1 className="page-header">
                            User Roles
                            <img
                                title="Refresh"
                                onClick={() => callFetch("ONLOAD")}
                                className="refreshtree"
                                src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                alt="Refresh"
                            />
                        </h1>
                    </div>
                    <div className="col-6 alignRight alignWithPageHeader">
                        {renderAddNewBtn()}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="formDiv">
                            <div className="invalid-feedback">{crudData.errormsg}</div>
                            <PrimeTable
                                list={queryData.userRoles}
                                columns={columns}
                                actionColumn={actionTemplate}
                                pageSizeChangecallback={(newPageSize) => {
                                    if (pageSize < newPageSize) setCurrPage(1);
                                    setPageSize(newPageSize);
                                    callFetch("PAGECLICK");
                                }}
                                pageChangeCallback={(newCurrPage) => {
                                    setCurrPage(newCurrPage);
                                    callFetch("PAGECLICK");
                                }}
                            />
                        </div>
                    </div>
                </div>
                <ConfirmAlert
                    show={openDeletePrompt}
                    isPrompt={true}
                    children={
                        <div style={{ color: "green", padding: "2em" }}>
                            Are you sure you want to delete this user role?
                            <div style={{ margin: "2em" }}>
                                <button
                                    className="btn btn-themes btn-rounded btn-sec"
                                    onClick={() => shdDeleteRec(false)}
                                >
                                    No
                                </button>
                                <button
                                    className="btn btn-themes btn-rounded"
                                    onClick={() => shdDeleteRec(true)}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    }
                />
                <ConfirmAlert
                    show={crudData.isSuccess}
                    handleClose={hideAlertModal}
                    children={
                        <div
                            style={
                                crudData.isSuccess
                                    ? { padding: "2em", color: "green" }
                                    : { padding: "2em", color: "red" }
                            }
                        >
                            {crudData.isSuccess ? message : ""}
                        </div>
                    }
                />
            </div>
        </t>
    );
};
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};
export default connect(mapStateToProps)(UserRoles);

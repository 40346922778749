import React, { useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import moment from "moment";

const DynamicDatePicker = ({
  columns,
  rows,
  handleDatePicker,
  handleInputChange,
  addRow,
  deleteRow,
}) => {
  const [tierOptions, setTierOptions] = useState([]);
  const [state, setState] = useState({});

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = () => {
    const URL = window?.config?.END_POINT;
    const authToken = localStorage.getItem("session");
    const myHeaders = new Headers({
      Authorization: authToken,
      "Content-Type": "application/json",
    });

    fetch(`${URL}cms/v1/prop/config/search/fields?propgroup=Segment`, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.length > 0) {
          const output = Object.entries(result[0].additionalfields).map(
            ([code, name]) => ({ name, code }),
          );
          setTierOptions(output);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleDateChange = (date, name, field, row) => {
    if (moment(date).isValid()) {
      handleDatePicker(date, field, row);
      setState((prevState) => ({ ...prevState, [name]: date }));
    }
  };

  const setTier = (selectedOption, index) => {
    const selectedCode = selectedOption?.code || ""; // Extracts code from the object
    handleInputChange("segments", selectedCode, index);
    setState((prevState) => ({ ...prevState, [`tiers_row_${index}`]: selectedCode }));
};


  const setBasePrice = (value, index) => {
    handleInputChange("baseprice", value, index);
    setState((prevState) => ({
      ...prevState,
      [`baseprice_row_${index}`]: value,
    }));
  };

  console.log("what is what", rows);

  return (
    <div className="dynamicDateTimePicker">
      <table>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            const rowId = `row_${row.id}`;
            return (
              <tr key={rowId} id={rowId}>
                {columns.map((column, index) => {
                  const col = column.dataFieldId.replace(/\s/g, "");
                  return (
                    <td key={`${rowId}_${index}`}>
                      {column.addRow && column.dataFieldId === "validfrom" ? (
                        <Calendar
                          value={state[`validfrom_${rowId}`] || row.validfrom}
                          onChange={(e) =>
                            handleDateChange(
                              e.value,
                              `validfrom_${rowId}`,
                              column.dataFieldId,
                              row,
                            )
                          }
                          dateFormat="dd-mm-yy"
                          showIcon
                        />
                      ) : column.addRow &&
                        column.dataFieldId === "validtill" ? (
                        <Calendar
                          value={state[`validtill_${rowId}`] || row.validtill}
                          onChange={(e) =>
                            handleDateChange(
                              e.value,
                              `validtill_${rowId}`,
                              column.dataFieldId,
                              row,
                            )
                          }
                          dateFormat="dd-mm-yy"
                          showIcon
                          minDate={state[`validfrom_${rowId}`] || row.validfrom}
                        />
                      ) : column.addRow && column.dataFieldId === "segments" ? (
                        <Dropdown
                          className="w-100"
                          value={tierOptions.find(option => option.code === (state[`tiers_row_${rowId}`] || row.segments)) || null}
                          options={tierOptions}
                          onChange={(e) => setTier(e.value, row.id)}
                          optionLabel="name"
                          placeholder="Select Tier"
                        />

                      ) : column.addRow &&
                        column.dataFieldId === "baseprice" ? (
                        <input
                          type="text"
                          className="form-control"
                          id="baseprice"
                          value={state[`baseprice_${rowId}`] || row.baseprice}
                          onChange={(event) =>
                            setBasePrice(event.target.value, row.id)
                          }
                        />
                      ) : col === "AD" ? (
                        <>
                          <img
                            className="addBtn1"
                            onClick={addRow}
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_add_blue.png`}
                          />
                          <img
                            className="addBtn2"
                            onClick={() => deleteRow(row)}
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                          />
                        </>
                      ) : (
                        row[col]
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicDatePicker;

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DatePicker from 'react-date-picker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import SimpleDropdown from '../../../components/SimpleDropdown';
import moment from 'moment';
import {
    createSalesPromo,
    updateSalesPromo,
    queryCategoryTree,
    getStoreById,
    promotionTGroupSearchByFields,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import StatusOptionComp from '../../../components/StatusOptionComp';
import { crudReset } from '../../../actions/actions';
import { produce } from 'immer';
import StoreSearchSingle from '../../system/users/StoreSearchSingle';
import Select, { components } from 'react-select';
import ReactSwitch from 'react-switch';
import AddPromoPurchaseGroup from './AddPromoPurchaseGroup';
import AddPromoTargetGroup from './AddPromoTargetGroup';
import Modal from 'react-awesome-modal';
import { formatDate } from '../../../helpers/Util';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{' '}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

class AddSalesPromotions extends React.PureComponent {
    promogrouplist = [
        { id: '', name: 'Select' },
        { id: 'Item', name: 'Item' },
    ];

    categorytype = [
        { id: '', name: 'Select' },
        { id: 'Category', name: 'Category' },
    ];

    itemfilter = [{ title: 'Most Expensive', value: 'unitprice' }];

    discountby = [{ title: 'Quantity', value: 'Qty' }];

    categorypoints = [
        { id: '', name: 'Select' },
        { id: 'Reward', name: 'Reward' },
    ];

    priceSegmentOptions = [
        { label: 'T1 - Standard Price', value: 'T1' },
        { label: 'T2 - Special Price', value: 'T2' },
        { label: 'T3 - Member Price', value: 'T3' },
    ];

    editModeStatus = [
        { name: 'Select', id: '' },
        { name: 'New', id: 'New' },
        { name: 'Pending For Approval', id: 'PendingApproval' },
    ];

    dayoptions = {
        mon: 'monday',
        tue: 'tuesday',
        wed: 'wednesday',
        thu: 'thursday',
        fri: 'friday',
        sat: 'saturday',
        sun: 'sunday',
    };

    customerTypeOptions = [
        { name: 'Select', id: '' },
        { name: 'Guest', id: 'Guest' },
        { name: 'Member', id: 'Member' },
    ];

    promoTypeOptions = [
        { name: 'Select', id: '' },
        { name: 'Item', id: 'Item' },
        { name: 'Cart', id: 'Cart' },
        { name: 'RTC', id: 'Rtc' },
        { name: 'Cart Tender', id: 'CartTender' },
        { name: 'Coupon', id: 'Coupon' },
        { name: 'Cart Coupon', id: 'CartCoupon' },
    ];

    promoGroupOptions = [
        { name: 'Select', id: '' },
        { name: 'By Percentage', id: 'Percent' },
        { name: 'By Amount', id: 'Value' },
        { name: 'Buy X Get Y', id: 'BuyGet' },
    ];

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            isEdit: false,
            isView: false,
            isViewInEdit: false,
            isForApproval: false,
            isPurchaseGroupExist: false,
            isTargetGroupExist: false,
            editPromoDefinition: false,
            viewPromoDefinition: false,
            addPromoDefinition: false,
            canViewPTG: true,
            editPromoPurchaseGroup: false,
            viewPromoPurchaseGroup: false,
            addPromoPurchaseGroup: false,
            editPromoTargetGroup: false,
            viewPromoTargetGroup: false,
            addPromoTargetGroup: false,
            purchasegroup: {},
            targetgroup: {},

            // Formrelated
            promocode: '',
            title: '',
            promotype: '',
            promotypevalue: '',
            promogroup: '',
            closedatearr: [],
            storeid: '',
            categorytype: 'Category',
            qtymin: '',
            qtymax: '',
            amountmax: '',
            amountmin: '',
            pointcategory: '',
            pointburn: '',
            taxondiscount: 'N',
            discountbatch: '',
            discountbatchvalue: 0,
            discountvalue: 0,
            discountcapamt: 0,
            memberfilter: [{ field: '', value: '', id: 0 }],
            customertype: '',
            memberonly: '',
            itemfilter: '',
            validfrom: '',
            validtill: '',
            activedays: [], //{}
            excludedates: [],
            valuebatch: '',
            closedates: '',
            status: 'New',
            sortorder: 1,
            priority: 0,
            priorityweight: 0,
            pricesegments: 'T1',
            excludediscount: 'N',
            additionalFields: {},
            storelabel: '',
            // Errors
            titleerror: '',
            promocodeerror: '',
            promogrouperror: '',
            promotypeerror: '',
            promotypevalueerror: '',
            discountcapamterror: '',
            validfromerror: '',
            validtillerror: '',
            storeidserror: '',
            customertypeerror: '',

            //selectedPriceSegments: ["T1"],
            selectedPriceSegments: {
                label: 'T1 - Standard Price',
                value: 'T1',
            }, //default
            isItBatch: false,
            isItTaxable: false,
            activeDays: 'WholeWeek', //default
            showWholeWeek: true, //default
            selectedDays: [], //["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
            showSpecificDays: false,
            byDays: {
                mon_starttime: '00:00',
                mon_endtime: '23:59',
                tue_starttime: '00:00',
                tue_endtime: '23:59',
                wed_starttime: '00:00',
                wed_endtime: '23:59',
                thu_starttime: '00:00',
                thu_endtime: '23:59',
                fri_starttime: '00:00',
                fri_endtime: '23:59',
                sat_starttime: '00:00',
                sat_endtime: '23:59',
                sun_starttime: '00:00',
                sun_endtime: '23:59',
            },

            openApprovalPrompt: false,
            openRejectionPrompt: false,
            approvalmessage: '',
        };
    }

    getPriceSegmentLabel = (value) => {
        const v = this.priceSegmentOptions.filter((e) => e.value == value);
        if (v.length > 0) {
            return v[0].label;
        } else {
            return '';
        }
    };

    handleBatchSwitch = (isItBatch) => {
        this.setState({ isItBatch: isItBatch });
    };

    handleDiscountTaxSwitch = (isItTaxable) => {
        this.setState({ isItTaxable: isItTaxable });
    };

    handleDiscountExcludeSwitch = (isExcluded) => {
        this.setState({ excludediscount: isExcluded ? 'Y' : 'N' });
    };

    handleActiveDaysSelection = (e) => {
        this.setState({ activeDays: e.target.value }, () => {
            if (this.state.activeDays === 'WholeWeek') {
                this.setState({
                    showWholeWeek: true,
                    showSpecificDays: false,
                    selectedDays: [
                        'mon',
                        'tue',
                        'wed',
                        'thu',
                        'fri',
                        'sat',
                        'sun',
                    ],
                });
            } else if (this.state.activeDays === 'SpecificDays') {
                this.setState({ showWholeWeek: false, showSpecificDays: true });
            }
        });
    };

    handlePriceSegmentsChange = (selected) => {
        this.setState({
            selectedPriceSegments: selected,
        });
        if (selected) {
            const pricesegments = selected.flatMap((e) => e.value).join(',');
            this.setState({ pricesegments });
        } else {
            this.setState({ pricesegments: '' });
        }
    };

    toDate = (dateStr) => {
        let totalStr = dateStr.split(' ');
        if (totalStr.length > 1) {
            var st = totalStr[0];
            var parts = st.split('-');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        } else {
            var parts = dateStr.split('-');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
    };

    getCreatePayload = () => {
        const {
            promocode,
            storeid,
            promogroup,
            title,
            promotype,
            promotypevalue,
            discountvalue,
            discountcapamt,
            discountbatchvalue,
            customertype,
            status,
            validfrom,
            validtill,
            pricesegments,
            priority,
            priorityweight,
            sortorder,
            excludediscount,
        } = this.state;
        let body = {
            promocode,
            storeid,
            promogroup,
            title,
            promotype,
            promotypevalue,
            taxondiscount: this.state.isItTaxable ? 'Y' : 'N',
            discountvalue,
            discountcapamt,
            discountbatch: this.state.isItBatch ? 'Y' : 'N',
            discountbatchvalue: this.state.isItBatch ? discountbatchvalue : 0,
            customertype,
            status,
            validfrom: formatDate(validfrom),
            validtill: formatDate(validtill),
            activedays: this._getActiveDays(),
            closedates:
                this.state.excludedates.length > 0
                    ? this.state.excludedates.join(',')
                    : '',
            pricesegments,
            priority,
            priorityweight,
            sortorder,
            additionalfields: this._getAdditionalFields(),
            excludediscount,
        };

        if (this._isEditMode() || this._isApprovalMode()) {
            body['id'] = this.state.id;
        }
        if (promocode.trim().length <= 0) {
            delete body['promocode'];
        }
        const payload = {
            body: body,
        };

        return payload;
    };

    _getAdditionalFields = () => {
        if (this.state.approvalmessage !== '') {
            return {
                ...this.state.additionalFields,
                message: this.state.approvalmessage,
            };
        } else {
            return { ...this.state.additionalFields };
        }
    };

    _getActiveDays = () => {
        if (this.state.selectedDays.length <= 0) {
            return [];
        }
        const activeDays = this.state.selectedDays.map((e) => ({
            day: e /*.substring(0,3)*/,
            opentime: this.getSelectedTime(e, 'start'),
            closetime: this.getSelectedTime(e, 'end'),
        }));
        return activeDays;
    };

    _renderApproveOrRejectActions = (approve) => {
        return (
            <React.Fragment>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={(e) => {
                        this._hideApproveOrRejectSalesPromoPrompt(e);
                        this._approveOrRejectSalesPromo(e, approve);
                    }}
                >
                    {approve ? `Approve` : `Reject`}
                </button>

                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={(e) => {
                        this._hideApproveOrRejectSalesPromoPrompt(e);
                    }}
                >
                    Cancel
                </button>
            </React.Fragment>
        );
    };

    _hideApproveOrRejectSalesPromoPrompt = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            openApprovalPrompt: false,
            openRejectionPrompt: false,
        });
    };

    _renderApproveOrRejectSalesPromoPrompt = () => {
        return (
            <Modal
                visible={
                    (this._isApprovalMode() && this.state.openApprovalPrompt) ||
                    this.state.openRejectionPrompt
                }
                width="40%"
                height="40%"
                effect="fadeInRight"
                onClickAway={() => {
                    this._hideApproveOrRejectSalesPromoPrompt();
                }}
            >
                <div className="modalForm storeSearchModalForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 alignLeft">
                            <div className="row sub-title">
                                Leave your comment
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <textarea
                                className="form-control"
                                id="enteredProducts"
                                value={this.state.approvalmessage}
                                onChange={(event) =>
                                    this.setState({
                                        approvalmessage: event.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        {this._renderApproveOrRejectActions(
                            this.state.openApprovalPrompt
                        )}
                    </div>
                </div>
            </Modal>
        );
    };

    _approveOrRejectSalesPromoPrompt = (e, approve) => {
        if (e) {
            e.preventDefault();
        }
        if (approve) {
        }
        this.setState({
            openApprovalPrompt: approve,
            openRejectionPrompt: !approve,
        });
    };

    _approveOrRejectSalesPromo = (e, approve) => {
        if (e) {
            e.preventDefault();
        }
        var status = this.state.status;
        if (approve) {
            status = 'Approved'; //TODO
            // status = 'Active'
        } else {
            status = 'Rejected';
        }
        this.setState({ status }, () => {
            this.props.dispatch(
                updateSalesPromo(this.getCreatePayload(), this.state.id)
            );
        });
    };

    _createSalesPromo = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateSalesPromo(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(createSalesPromo(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            titleerror: '',
            promocodeerror: '',
            promogrouperror: '',
            promotypeerror: '',
            promotypevalueerror: '',
            discountcapamterror: '',
            validfromerror: '',
            validtillerror: '',
            storeidserror: '',
            customertypeerror: '',
        });
    };

    containsSpecialCharacters(str) {
        var regex = /[!$%^&*()_+|~=`{}\[\]:";'<>?,.]/g;
        return regex.test(str);
    }

    validateForm = () => {
        this._clearErrorMessages();
        const {
            title,
            promocode,
            promogroup,
            promotype,
            promotypevalue,
            validfrom,
            validtill,
            storeid,
        } = this.state;
        let valid = true;
        if (title.trim() === '') {
            this.setState({ titleerror: 'Please enter a title' });
            valid = false;
        }

        if (
            promocode.trim().length > 0 &&
            this.containsSpecialCharacters(promocode.trim())
        ) {
            this.setState({
                promocodeerror: 'No Special characters allowed in promocode',
            });
            valid = false;
        }

        if (promotype.trim() === '') {
            this.setState({ promotypeerror: 'Please enter promo type' });
            valid = false;
        }

        if (
            (promotype === 'Coupon' || promotype === 'CartCoupon') &&
            promotypevalue.trim() === ''
        ) {
            this.setState({
                promotypevalueerror: 'Please enter promo type value',
            });
            valid = false;
        }

        if (promogroup.length === 0) {
            this.setState({ promogrouperror: 'Please select a promo group' });
            valid = false;
        }

        if (validfrom.toString().trim() === '') {
            this.setState({ validfromerror: 'Please select a from date' });
            valid = false;
        }
        if (validtill.toString().trim() === '') {
            this.setState({ validtillerror: 'Please enter to date' });
            valid = false;
        }
        if (storeid.trim() === '') {
            this.setState({ storeidserror: 'Please select store' });
            valid = false;
        }
        return valid;
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _isViewInEditMode = () => {
        return this.state.isViewInEdit;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _isApprovalMode = () => {
        return this.state.isForApproval;
    };

    _setTenant = (p1, p2) => {
        this.setState(
            {
                storeid: p1,
                storelabel: p2,
            },
            () => {
                this.state.storeidserror = '';
            }
        );
    };

    buttonHanlder = (value) => {
        var dateformat = [
            ('0' + value.getDate()).slice(-2) +
                '-' +
                ('0' + (value.getMonth() + 1)).slice(-2) +
                '-' +
                value.getFullYear(),
        ];
        this.setState((prevState) => ({
            closedatearr: [...prevState.closedatearr, ...dateformat],
        }));
    };

    textareaHandler = (val) => {
        const { closedatearr } = this.state;
        let filterdata = closedatearr.filter((item) => item !== val);
        this.setState({
            closedatearr: filterdata,
        });
    };

    _renderPromoDefinitionActionButtons = () => {
        const isEditMode = this._isEditMode();
        const isViewInEdit = this._isViewInEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => {
                            this._createSalesPromo(e);
                        }}
                    >
                        Save
                    </button>
                )}

                {isEditMode && !isViewInEdit && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => this._createSalesPromo(e)}
                    >
                        Update
                    </button>
                )}

                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={(e) => this._hidePromoDefinition(e)}
                >
                    Cancel
                </button>
            </React.Fragment>
        );
    };

    _renderMainButton = () => {
        if (this._isApprovalMode()) {
            return (
                <React.Fragment>
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => {
                            this._approveOrRejectSalesPromoPrompt(e, true);
                        }}
                    >
                        Approve
                    </button>

                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => {
                            this._approveOrRejectSalesPromoPrompt(e, false);
                        }}
                    >
                        Reject
                    </button>

                    <Link
                        to="/ui/sales-promotions"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Cancel
                    </Link>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Link
                    to="/ui/sales-promotions"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Close
                </Link>
            </React.Fragment>
        );
    };

    _renderDoubleColSubSecDivider = () => {
        return <div className="doubleCol-subsec-divider"></div>;
    };

    _renderSubSecDivider = () => {
        return <div className="subsec-divider"></div>;
    };

    _renderPromoBasicDetails = () => {
        return (
            <React.Fragment>
                {/* Store Selection */}
                <div className="row sub-title">Basic Information</div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="storecodes"
                            className="floatLeft required"
                        >
                            Store Id
                        </label>
                        {this._isViewInEditMode() && (
                            <input
                                type="text"
                                id="status"
                                className="form-control"
                                value={`${this.state.storeid} - ${this.state.storelabel}`}
                                disabled={true}
                            />
                        )}
                        {!this._isViewInEditMode() && (
                            <StoreSearchSingle
                                value={this.state.storelabel}
                                onSelectStore={this._onSelectStore}
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.storeidserror}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="promocode"
                            className="floatLeft required"
                        >
                            Promo Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="promoname"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                            disabled={this._isViewInEditMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.titleerror}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="promocode" className="floatLeft">
                            Promo Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="promocode"
                            value={this.state.promocode}
                            onChange={(event) =>
                                this.setState({ promocode: event.target.value })
                            }
                            disabled={this._isViewInEditMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.promocodeerror}
                        </div>
                    </div>
                </div>

                {/* Promo Group */}
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="promogroup"
                            className="floatLeft required"
                        >
                            Promo Group
                        </label>
                        {this._isViewInEditMode() && (
                            <input
                                type="text"
                                id="promogroup"
                                className="form-control"
                                value={this.state.promogroup}
                                disabled={true}
                            />
                        )}
                        {!this._isViewInEditMode() && (
                            <SimpleDropdown
                                id="promogroup"
                                listoptions={this.promoGroupOptions}
                                selectedOption={this.state.promogroup}
                                callbackChangeFilter={(value) => {
                                    this.setState({ promogroup: value });
                                }}
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.promogrouperror}
                        </div>
                    </div>
                </div>

                {/* Promo Type Type */}
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="promotype"
                            className="floatLeft required"
                        >
                            Promo Type
                        </label>
                        {this._isViewInEditMode() && (
                            <input
                                type="text"
                                id="promotype"
                                className="form-control"
                                value={this.state.promotype}
                                disabled={true}
                            />
                        )}
                        {!this._isViewInEditMode() && (
                            <StatusOptionComp
                                statusoption={this.promoTypeOptions}
                                selectedOption={this.state.promotype}
                                callbackChangeFilter={(value) => {
                                    this.setState({
                                        promotype: value,
                                        promotypevalueerror: '',
                                    });
                                }}
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.promotypeerror}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="promotypevalue" className="floatLeft">
                            Promo Type Value
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="promotypevalue"
                            value={this.state.promotypevalue}
                            onChange={(e) =>
                                this.setState({
                                    promotypevalue: e.target.value,
                                })
                            }
                            readOnly={
                                this.state.promotype !== 'Coupon' &&
                                this.state.promotype !== 'CartCoupon'
                                    ? true
                                    : false
                            }
                            disabled={this._isViewInEditMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.promotypevalueerror}
                        </div>
                    </div>
                </div>

                {/* Customer Type */}
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="customertype" className="floatLeft">
                            Customer Type
                        </label>
                        {this._isViewInEditMode() && (
                            <input
                                type="text"
                                id="customertype"
                                className="form-control"
                                value={this.state.customertype}
                                disabled={true}
                            />
                        )}
                        {!this._isViewInEditMode() && (
                            <SimpleDropdown
                                id="customertype"
                                listoptions={this.customerTypeOptions}
                                selectedOption={this.state.customertype}
                                callbackChangeFilter={(value) => {
                                    this.setState({ customertype: value });
                                }}
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.customertypeerror}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderDiscountDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Discount Details</div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="validfrom"
                            className="floatLeft required"
                        >
                            Discount Value
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="discountval"
                            value={this.state.discountvalue}
                            onChange={(event) =>
                                this.setState({
                                    discountvalue: event.target.value,
                                })
                            }
                            disabled={this._isViewInEditMode()}
                        />
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="validfrom"
                            className="floatLeft required"
                        >
                            Discount Cap Amount
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="discountcapamt"
                            value={this.state.discountcapamt}
                            onChange={(event) =>
                                this.setState({
                                    discountcapamt: event.target.value,
                                })
                            }
                            disabled={this._isViewInEditMode()}
                        />
                    </div>
                    <div className="invalid-feedback">
                        {this.state.discountcapamterror}
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label
                            htmlFor="taxondiscount"
                            className="floatLeft switch-label"
                        >
                            Tax On Discount
                        </label>
                        <ReactSwitch
                            checked={this.state.isItTaxable}
                            onChange={this.handleDiscountTaxSwitch}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="discount-tax-switch"
                            disabled={this._isViewInEditMode()}
                        />
                    </div>

                    <div className="form-group col-lg-4 col-sm-12">
                        <label
                            htmlFor="isitbatch"
                            className="floatLeft switch-label"
                        >
                            Batch
                        </label>
                        <ReactSwitch
                            checked={this.state.isItBatch}
                            onChange={this.handleBatchSwitch}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="batch-switch"
                            disabled={this._isViewInEditMode()}
                        />
                    </div>

                    {this.state.isItBatch && (
                        <div className="form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="batchvalue"
                                className="floatLeft switch-label"
                            >
                                Batch Value
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="discountbatchvalue"
                                value={this.state.discountbatchvalue}
                                onChange={(e) =>
                                    this.setState({
                                        discountbatchvalue: e.target.value,
                                    })
                                }
                                disabled={this._isViewInEditMode()}
                            ></input>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    };

    _saveExcludedDates = (d) => {
        if (
            this.state.excludedates.length > 0 &&
            this.state.excludedates.includes(formatDate(d))
        ) {
            return;
        }
        this.setState(() => ({
            excludedates: [...this.state.excludedates, formatDate(d)],
        }));
    };

    _renderExcludedDates = () => {
        if (this.state.excludedates.length <= 0) {
            return;
        }

        var contentareastyle = {
            display: 'inline-flex',
            flexFlow: 'wrap',
            border: '1px solid #dcdcdc',
            padding: '10px',
            overflowY: 'scroll',
            maxHeight: '150px',
        };

        return (
            <div className="row" style={{ marginTop: '0.6em' }}>
                <div style={contentareastyle}>
                    {this.state.excludedates.map((s) => {
                        return (
                            <div
                                style={{
                                    padding: '2px 2px',
                                    margin: '2px 2px',
                                    borderRadius: '4px',
                                    background: '#347bf6',
                                }}
                            >
                                <label style={{ color: 'white' }}>{s}</label>
                                {!this._isViewInEditMode() && (
                                    <img
                                        title="delete"
                                        className="tableImage"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                                        onClick={(e) =>
                                            this._removeExcludedDates(e, s)
                                        }
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    _removeExcludedDates = (e, s) => {
        if (e) {
            e.preventDefault();
        }
        let excludedates = this.state.excludedates;
        excludedates = excludedates.filter((e) => e != s);
        this.setState({ excludedates: excludedates });
    };

    _renderPromotionPeriod = () => {
        const { closedatearr } = this.state;
        let checkempty = closedatearr.filter((e) => e !== '');
        //let closedates = [...new Set(checkempty)];
        var contentareastyle = {
            display: 'inline-flex',
            flexFlow: 'wrap',
            border: '1px solid #dcdcdc',
            padding: '10px',
            overflowY: 'scroll',
            maxHeight: '200px',
            float: 'left',
        };
        return (
            <React.Fragment>
                <div className="row sub-title">Promotion Period</div>
                <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <label
                                    htmlFor="validfrom"
                                    className="floatLeft required"
                                >
                                    Start Date
                                </label>
                                <DatePicker
                                    minDate={
                                        this._isEditMode()
                                            ? this.state.validfrom
                                            : new Date()
                                    }
                                    onChange={(value) => {
                                        this.setState({ validfrom: value });
                                    }}
                                    value={this.state.validfrom}
                                    disabled={this._isViewInEditMode()}
                                />
                                <div className="invalid-feedback">
                                    {this.state.validfromerror}
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <label
                                    htmlFor="validtill"
                                    className="floatLeft required"
                                >
                                    End Date
                                </label>
                                <DatePicker
                                    minDate={this.state.validfrom}
                                    onChange={(value) => {
                                        this.setState({ validtill: value });
                                    }}
                                    value={this.state.validtill}
                                    disabled={this._isViewInEditMode()}
                                />
                                <div className="invalid-feedback">
                                    {this.state.validtillerror}
                                </div>
                            </div>
                            <div className="form-group col-lg-6 col-sm-6">
                                <label
                                    htmlFor="excludedate"
                                    className="floatLeft"
                                >
                                    Promotion Exclude Date
                                </label>
                                <DatePicker
                                        minDate={
                                            !this._isEditMode()
                                                ? new Date()
                                                : this.state.validfrom
                                        }
                                        onChange={(value) => {
                                            this._saveExcludedDates(value);
                                        }}
                                        disabled={this._isViewInEditMode()}
                                    />
                                    {this._renderExcludedDates()}
                            </div>
                        </div>

                <div className="row" style={{ marginTop: '20px'}}>
                    <div className='form-group col-lg-12 col-sm-12'>
                        <label
                            htmlFor="activeDays"
                            className="floatLeft required label2"
                        >
                            Active Days
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <div className="row">
                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="wholeweek"
                                    className="floatLeft"
                                >
                                    <input
                                        type="radio"
                                        value="WholeWeek"
                                        onChange={(e) =>
                                            this.handleActiveDaysSelection(e)
                                        }
                                        checked={
                                            this.state.activeDays ===
                                            'WholeWeek'
                                        }
                                        disabled={this._isViewInEditMode()}
                                    />
                                    Whole Week
                                </label>
                            </div>
                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="specificdays"
                                    className="floatLeft"
                                >
                                    <input
                                        type="radio"
                                        value="SpecificDays"
                                        onChange={(e) =>
                                            this.handleActiveDaysSelection(e)
                                        }
                                        checked={
                                            this.state.activeDays ===
                                            'SpecificDays'
                                        }
                                        disabled={this._isViewInEditMode()}
                                    />
                                    Specific Days
                                </label>
                            </div>
                        </div>
                        {/* <br /> */}
                        {this._renderForWholeWeek()}
                        {this._renderFoSpecificDays()}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    handleSelectDays = (e, elem) => {
        if (this.state.selectedDays.includes(elem)) {
            if (!e.target.checked) {
                this.setState({
                    selectedDays: this.state.selectedDays.filter(
                        (e) => e !== elem
                    ),
                });
            }
        } else {
            if (e.target.checked) {
                this.setState({
                    selectedDays: [...this.state.selectedDays, elem],
                });
            }
        }
    };

    handleTimePickerChange(time, name) {
        let timeformat = moment(time).format('HH:mm');
        //let updatelastordertime = this.handleTime(name, timeformat);
        this.setState(
            {
                byDays: {
                    ...this.state.byDays,
                    [name]: timeformat,
                },
            },
            () => {
                this._updateActiveDays(name, timeformat);
            }
        );
    }

    _updateActiveDays = (name, timeformat) => {
        var activeDay = this.state.activedays.filter(
            (e) => e.day === name.substring(0, 3)
        );
        if (activeDay) {
            if (name === activeDay.day + '_starttime') {
                activeDay.opentime = timeformat;
            } else if (name === activeDay.day + '_endtime') {
                activeDay.closetime = timeformat;
            }
        } else {
            activeDay = {};
            activeDay.day = name.substring(0, 3);
            if (name === activeDay.day + '_starttime') {
                activeDay.opentime = timeformat;
            } else if (name === activeDay.day + '_endtime') {
                activeDay.closetime = timeformat;
            }
        }
    };

    _renderFoSpecificDays = () => {
        if (!this.state.showSpecificDays) {
            return;
        }

        let days = Object.values(this.dayoptions);
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-sm-4 label2">Day</div>
                    <div className="col-lg-3 col-sm-3 label2">Start Time</div>
                    <div className="col-lg-3 col-sm-3 label2">End Time</div>
                </div>
                {days.map((elem) => (
                    <div className="row">
                        <div className="col-lg-1 col-sm-1 floatLeft">
                            <input
                                type="checkbox"
                                checked={this.state.selectedDays.includes(
                                    elem.substring(0, 3)
                                )}
                                onChange={(e) =>
                                    this.handleSelectDays(
                                        e,
                                        elem.substring(0, 3)
                                    )
                                }
                                /*checked={this.isDayActive(elem)}*/ disabled={this._isViewInEditMode()}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            {elem}
                        </div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            <TimePicker
                                value={
                                    moment(
                                        this.state.byDays[
                                            elem.substring(0, 3) + '_starttime'
                                        ],
                                        'HH:mm'
                                    ).isValid()
                                        ? moment(
                                              this.state.byDays[
                                                  elem.substring(0, 3) +
                                                      '_starttime'
                                              ],
                                              'HH:mm'
                                          )
                                        : ''
                                }
                                name={elem + '_starttime'}
                                onChange={(time) =>
                                    this.handleTimePickerChange(
                                        time,
                                        elem.substring(0, 3) + '_starttime'
                                    )
                                }
                                showSecond={false}
                                disabled={this._isViewInEditMode()}
                            />
                            {/* <TimePicker value={moment(this.getTime(elem, true, false), "HH:mm").isValid() ? moment(this.getTime(elem, true, false), "HH:mm") : ''} name={elem + "_starttime"} onChange={(time) => this.handleTimePickerChange(time, elem.substring(0, 3) + "_starttime")} showSecond={false} /> */}
                        </div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            <TimePicker
                                value={
                                    moment(
                                        this.state.byDays[
                                            elem.substring(0, 3) + '_endtime'
                                        ],
                                        'HH:mm'
                                    ).isValid()
                                        ? moment(
                                              this.state.byDays[
                                                  elem.substring(0, 3) +
                                                      '_endtime'
                                              ],
                                              'HH:mm'
                                          )
                                        : ''
                                }
                                name={elem + '_endtime'}
                                onChange={(time) =>
                                    this.handleTimePickerChange(
                                        time,
                                        elem.substring(0, 3) + '_endtime'
                                    )
                                }
                                showSecond={false}
                                disabled={this._isViewInEditMode()}
                            />
                        </div>
                    </div>
                ))}
            </React.Fragment>
        );
    };

    isDayActive = (day) => {
        const shortDay = Object.keys(this.dayoptions).find(
            (key) => this.dayoptions[key] === day
        );
        if (shortDay === 'undefined') {
            return false;
        }

        if (this.state.activedays) {
            var dayO = this.state.activedays.filter((e) => e.day === shortDay);
            if (dayO) {
                return true;
            }
        }
        return false;
    };

    getSelectedTime = (day, startOrEnd) => {
        return this.state.byDays[
            day /*.substring(0, 3)*/ + '_' + startOrEnd + 'time'
        ].replace(':', '');
    };

    getTime = (day, start, end) => {
        const shortDay = Object.keys(this.dayoptions).find(
            (key) => this.dayoptions[key] === day
        );
        if (shortDay === 'undefined') {
            return '';
        }

        if (this.state.activedays) {
            var dayO = this.state.activedays.filter((e) => e.day === shortDay);
            if (dayO) {
                if (start) {
                    return dayO.opentime;
                } else if (end) {
                    return dayO.closetime;
                } else {
                    return '';
                }
            }
        }

        if (start) {
            return this.state.byDays[day.substring(0, 3) + '_starttime'];
        } else if (end) {
            return this.state.byDays[day.substring(0, 3) + '_endtime'];
        }
        return '';
    };

    _updateSelectedDays = (activedays) => {
        activedays.forEach((e) => {});
        return activedays.map((e) => e.day);
    };

    _allDaysSelected = (activedays) => {
        return activedays.length == 7;
    };

    _renderForWholeWeek = () => {
        if (!this.state.showWholeWeek) {
            return;
        }

        let days = Object.values(this.dayoptions);
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-sm-3 label2">Day</div>
                    <div className="col-lg-3 col-sm-3 label2">Start Time</div>
                    <div className="col-lg-3 col-sm-3 label2">End Time</div>
                </div>
                {days.map((elem) => (
                    <div className="row" id={elem}>
                        <div className="col-lg-1 col-sm-3 floatLeft">
                            <input
                                type="checkbox"
                                disabled={true}
                                checked={true}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            {elem}
                        </div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            <TimePicker
                                value={
                                    moment(
                                        this.state.byDays[
                                            elem.substring(0, 3) + '_starttime'
                                        ],
                                        'HH:mm'
                                    ).isValid()
                                        ? moment(
                                              this.state.byDays[
                                                  elem.substring(0, 3) +
                                                      '_starttime'
                                              ],
                                              'HH:mm'
                                          )
                                        : ''
                                }
                                name={elem + '_starttime'}
                                onChange={(time) =>
                                    this.handleTimePickerChange(
                                        time,
                                        elem.substring(0, 3) + '_starttime'
                                    )
                                }
                                showSecond={false}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            <TimePicker
                                value={
                                    moment(
                                        this.state.byDays[
                                            elem.substring(0, 3) + '_endtime'
                                        ],
                                        'HH:mm'
                                    ).isValid()
                                        ? moment(
                                              this.state.byDays[
                                                  elem.substring(0, 3) +
                                                      '_endtime'
                                              ],
                                              'HH:mm'
                                          )
                                        : ''
                                }
                                name={elem + '_endtime'}
                                onChange={(time) =>
                                    this.handleTimePickerChange(
                                        time,
                                        elem.substring(0, 3) + '_endtime'
                                    )
                                }
                                showSecond={false}
                            />
                        </div>
                    </div>
                ))}
            </React.Fragment>
        );
    };

    _renderOtherPropDetails = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="pricesegments" className="floatLeft">
                            Price Segments
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div
                        className="form-group col-lg-12 col-sm-12"
                        style={{ marginTop: '0em' }}
                    >
                        {this._isViewInEditMode() && (
                            <label
                                htmlFor="view_pricesegment"
                                className="floatLeft label3"
                            >
                                {this.state.pricesegments}
                            </label>
                        )}
                        {!this._isViewInEditMode() && (
                            <Select
                                options={this.priceSegmentOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                    Option,
                                }}
                                onChange={this.handlePriceSegmentsChange}
                                allowSelectAll={true}
                                value={this.state.selectedPriceSegments}
                            />
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="priority" className="floatLeft">
                            Priority
                        </label>
                        <input
                            type="number"
                            min="0"
                            id="priority"
                            className="form-control"
                            onChange={(e) =>
                                this.setState({ priority: e.target.value })
                            }
                            value={this.state.priority}
                            disabled={this._isViewInEditMode()}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Weightage
                        </label>
                        <input
                            type="number"
                            min="0"
                            id="priorityweight"
                            className="form-control"
                            onChange={(e) =>
                                this.setState({
                                    priorityweight: e.target.value,
                                })
                            }
                            value={this.state.priorityweight}
                            disabled={this._isViewInEditMode()}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label
                            htmlFor="excludediscount"
                            className="floatLeft switch-label"
                        >
                            Exclude Discount
                        </label>
                        <ReactSwitch
                            checked={this.state.excludediscount === 'Y'}
                            onChange={this.handleDiscountExcludeSwitch}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="batch-switch"
                            disabled={this._isViewInEditMode()}
                        />
                    </div>
                </div>

                {this._renderDoubleColSubSecDivider()}

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        {this._isViewInEditMode() && (
                            <input
                                type="text"
                                id="status"
                                className="form-control"
                                value={this.state.status}
                                disabled={true}
                            />
                        )}
                        {!this._isViewInEditMode() && (
                            <StatusOptionComp
                                statusoption={this.editModeStatus}
                                selectedOption={this.state.status}
                                callbackChangeFilter={(value) => {
                                    this.setState({ status: value });
                                }}
                            />
                        )}
                    </div>
                </div>

                {this._renderDoubleColSubSecDivider()}

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label
                            htmlFor="sortorder"
                            className="floatLeft required"
                        >
                            Sort Order
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="sortorder"
                            value={this.state.sortorder}
                            min="0"
                            onChange={(event) =>
                                this.setState({ sortorder: event.target.value })
                            }
                            disabled={this._isViewInEditMode()}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    _editPromoDefinition = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            editPromoDefinition: true,
            viewPromoDefinition: false,
            addPromoDefinition: false,
        });
    };

    _addPromoDefinition = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            editPromoDefinition: true,
            viewPromoDefinition: false,
            addPromoDefinition: false,
        });
    };

    _renderPromoDefinitionSummary = () => {
        if (this.state.viewPromoDefinition) {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-8 col-sm-12 noPadding noMargin">
                            <form className="splitFrmDiv text-center ">
                                <div className="row sub-title">
                                    <div className="form-group col-lg-11 col-sm-12">
                                        Basic Information
                                    </div>
                                    {(this._isEditMode() ||
                                        this._isViewInEditMode()) &&
                                        !this.state.editPromoTargetGroup && (
                                            <div className="form-group col-lg-1 col-sm-12">
                                                <img
                                                    title="Edit"
                                                    className="tableImage"
                                                    src={
                                                        this._isViewInEditMode()
                                                            ? `${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`
                                                            : `${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`
                                                    }
                                                    onClick={(e) =>
                                                        this._editPromoDefinition()
                                                    }
                                                />
                                            </div>
                                        )}
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label
                                            htmlFor="view_store_l"
                                            className="floatLeft"
                                        >
                                            Store:
                                        </label>
                                        <label
                                            htmlFor="view_storeid"
                                            className="floatLeft label3"
                                        >
                                            {this.state.storeid}
                                        </label>
                                    </div>
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label
                                            htmlFor="view_promocode_l"
                                            className="floatLeft"
                                        >
                                            Promo Code:
                                        </label>
                                        <label
                                            htmlFor="view_promocode"
                                            className="floatLeft label3"
                                        >
                                            {this.state.promocode}
                                        </label>
                                    </div>
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label
                                            htmlFor="view_promoname_l"
                                            className="floatLeft"
                                        >
                                            Promotion Name:
                                        </label>
                                        <label
                                            htmlFor="view_promoname"
                                            className="floatLeft label3"
                                        >
                                            {this.state.title}
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label
                                            htmlFor="view_promogroup_l"
                                            className="floatLeft"
                                        >
                                            Promo Group:
                                        </label>
                                        <label
                                            htmlFor="view_promogroup"
                                            className="floatLeft label3"
                                        >
                                            {this._getPromoGroupValue(
                                                this.state.promogroup
                                            )}
                                        </label>
                                    </div>
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label
                                            htmlFor="view_promotype_l"
                                            className="floatLeft"
                                        >
                                            Promo Type:
                                        </label>
                                        <label
                                            htmlFor="view_promotype"
                                            className="floatLeft label3"
                                        >
                                            {this.state.promotype}
                                        </label>
                                    </div>
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label
                                            htmlFor="view_promoperiod_l"
                                            className="floatLeft"
                                        >
                                            Promotion Period:
                                        </label>
                                        <label
                                            htmlFor="view_promoname"
                                            className="floatLeft label3"
                                        >
                                            {formatDate(this.state.validfrom)} -{' '}
                                            {formatDate(this.state.validtill)}
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label
                                            htmlFor="view_pricesegment_l"
                                            className="floatLeft"
                                        >
                                            Price Segments:
                                        </label>
                                        <label
                                            htmlFor="view_pricesegment"
                                            className="floatLeft label3"
                                        >
                                            {this.state.pricesegments}
                                        </label>
                                    </div>
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label
                                            htmlFor="view_promostatus_l"
                                            className="floatLeft"
                                        >
                                            Promo Status:
                                        </label>
                                        <label
                                            htmlFor="view_promostatus"
                                            className="floatLeft label3"
                                        >
                                            {this.state.status}
                                        </label>
                                    </div>
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label
                                            htmlFor="view_promopriority_l"
                                            className="floatLeft"
                                        >
                                            Promotion Priority:
                                        </label>
                                        <label
                                            htmlFor="view_promopriority"
                                            className="floatLeft label3"
                                        >
                                            {this.state.priority}
                                        </label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    _getPromoGroupValue = (promogroup) => {
        var promoGroup = this.promoGroupOptions.filter(
            (e) => e.id === promogroup
        );
        if (promoGroup && promoGroup.length > 0) {
            return promoGroup[0].name;
        }
        return '';
    };

    _hidePromoDefinition = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (this.state.promocode === '') {
            this.setState({
                addPromoDefinition: true,
                editPromoDefinition: false,
            });
        } else {
            this.setState({
                editPromoDefinition: false,
                addPromoDefinition: false,
            });
            this.setState({ viewPromoDefinition: true });
            if (this.state.promotype === 'Rtc') {
                this.setState({ canViewPTG: false });
            }
        }
        window.scrollTo(0, 0);
    };

    _renderAddNewPromoDefinition = () => {
        if (this.state.addPromoDefinition) {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-8 col-sm-12 noPadding noMargin">
                            <form className="splitFrmDiv ">
                                <div className="row sub-title">
                                    Sales Promotion
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-6 col-sm-12">
                                        <button
                                            className="page-header-btn icon_btn display-inline"
                                            onClick={(event) =>
                                                this._addPromoDefinition(event)
                                            }
                                        >
                                            <img
                                                title="edit"
                                                className="tableImage"
                                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
                                            />
                                            Create New Promotion
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    _editPromoPurchaseGroup = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            editPromoPurchaseGroup: true,
            viewPromoPurchaseGroup: false,
            addPromoPurchaseGroup: false,
            isPurchaseGroupExist: false,
        });
    };

    _addPromoPurchaseGroup = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            editPromoPurchaseGroup: true,
            viewPromoPurchaseGroup: false,
            addPromoPurchaseGroup: false,
            isPurchaseGroupExist: false,
        });
    };

    _renderPurchaseGroupSummary = () => {
        if (this.state.viewPromoPurchaseGroup) {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-8 col-sm-12 noPadding noMargin">
                            <form className="splitFrmDiv text-center ">
                                <div className="row sub-title">
                                    <div className="form-group col-lg-11 col-sm-12">
                                        Eligible Purchase Group
                                    </div>
                                    {this._isEditMode() && (
                                        <div className="form-group col-lg-1 col-sm-12">
                                            <img
                                                title="Edit"
                                                className="tableImage"
                                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                                                onClick={(e) =>
                                                    this._editPromoPurchaseGroup()
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label
                                            htmlFor="promocode"
                                            className="floatLeft"
                                        >
                                            Group Code:
                                        </label>
                                        <label
                                            htmlFor="promocode"
                                            className="floatLeft"
                                        >
                                            {
                                                this.state.purchasegroup
                                                    .promopurchasecode
                                            }
                                        </label>
                                    </div>
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label
                                            htmlFor="promocode"
                                            className="floatLeft"
                                        >
                                            Group Code:
                                        </label>
                                        <label
                                            htmlFor="promocode"
                                            className="floatLeft"
                                        >
                                            {
                                                this.state.purchasegroup
                                                    .validatelevel
                                            }
                                        </label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    _hidePurchaseGroup = (purchasegroup) => {
        this.setState({ editPromoPurchaseGroup: false });
        if (purchasegroup.id !== '') {
            this.setState({
                viewPromoPurchaseGroup: true,
                purchasegroup: purchasegroup,
            });
        } else {
            this.setState({ addPromoPurchaseGroup: true });
        }
    };

    _renderAddNewPurchaseGroup = () => {
        if (this.state.addPromoPurchaseGroup) {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-8 col-sm-12 noPadding noMargin">
                            <form className="splitFrmDiv">
                                <div className="row sub-title">
                                    Eligible Purchase Group
                                </div>
                                <div className="row">
                                    {/* <div className="form-group col-lg-6 col-sm-12"> */}
                                    <label
                                        htmlFor="addppgtitle"
                                        className="floatLeft required"
                                    >
                                        you can create purchase group in
                                        category or product
                                    </label>
                                    {/* </div> */}
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-6 col-sm-12">
                                        <button
                                            className="page-header-btn icon_btn display-inline"
                                            onClick={(event) =>
                                                this._addPromoPurchaseGroup(
                                                    event
                                                )
                                            }
                                            disabled={
                                                this.state.promocode === ''
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <img
                                                title="edit"
                                                className="tableImage"
                                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
                                            />
                                            Create Purchase Group
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    _renderEditPurchaseGroup = () => {
        if (this.state.editPromoPurchaseGroup) {
            const { categoryTree } = this.props.queryData;
            let lCategoryTree = JSON.parse(JSON.stringify(categoryTree));
            const nodes = [lCategoryTree].map(this.rename);
            return (
                <React.Fragment>
                    <AddPromoPurchaseGroup
                        storeid={this.state.storeid}
                        promocode={this.state.promocode}
                        nodes={nodes}
                        purhcasegroupcode={this.state.purchasegroup}
                        hide={this._hidePurchaseGroup}
                    />
                </React.Fragment>
            );
        }
    };

    _editPromoTargetGroup = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            editPromoTargetGroup: true,
            viewPromoTargetGroup: false,
            addPromoTargetGroup: false,
        });
    };

    _addPromoTargetGroup = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            editPromoTargetGroup: true,
            viewPromoTargetGroup: false,
            addPromoTargetGroup: false,
        });
    };

    _hideTargetGroup = (targetgroup) => {
        this.setState({ editPromoTargetGroup: false });

        if (targetgroup.id !== '') {
            this.setState({
                viewPromoTargetGroup: true,
                targetgroup: targetgroup,
            });
        } else {
            this.setState({ addPromoTargetGroup: true });
        }
    };

    _renderAddNewTargetGroup = () => {
        if (this.state.canViewPTG && this.state.addPromoTargetGroup) {
            return (
                <React.Fragment>
                    <div className="row rowBottom">
                        <div className="col-md-8 col-sm-12 noPadding noMargin">
                            <form className="splitFrmDiv">
                                <div className="row sub-title">
                                    Target Group
                                </div>
                                <div className="row">
                                    <label
                                        htmlFor="storecodes"
                                        className="floatLeft required"
                                    >
                                        you can create product group in product
                                    </label>
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-6 col-sm-12">
                                        <button
                                            className="page-header-btn icon_btn display-inline"
                                            onClick={(event) =>
                                                this._addPromoTargetGroup(event)
                                            }
                                            disabled={
                                                this.state.promocode === '' ||
                                                this.state.purchasegroup.id ===
                                                    'undefined' ||
                                                this.state.purchasegroup.id ===
                                                    '' ||
                                                this.state.editPromoDefinition
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <img
                                                title="edit"
                                                className="tableImage"
                                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
                                            />
                                            Create Promo Target Group
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    _renderTargetGroupSummary = () => {
        if (this.state.canViewPTG && this.state.viewPromoTargetGroup) {
            return (
                <React.Fragment>
                    <div className="row rowBottom">
                        <div className="col-md-8 col-sm-12 noPadding noMargin">
                            <form className="splitFrmDiv text-center ">
                                <div className="row sub-title">
                                    <div className="form-group col-lg-11 col-sm-12">
                                        Target Group
                                    </div>
                                    {(this._isEditMode() ||
                                        this._isViewInEditMode()) &&
                                        !this.state.editPromoDefinition && (
                                            <div className="form-group col-lg-1 col-sm-12">
                                                <img
                                                    title="Edit"
                                                    className="tableImage"
                                                    src={
                                                        this._isViewInEditMode()
                                                            ? `${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`
                                                            : `${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`
                                                    }
                                                    onClick={(e) =>
                                                        this._editPromoTargetGroup()
                                                    }
                                                />
                                            </div>
                                        )}
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label
                                            htmlFor="promocode"
                                            className="floatLeft"
                                        >
                                            Purchase Group Code:
                                        </label>
                                        <label
                                            htmlFor="promocode"
                                            className="floatLeft label3"
                                        >
                                            {
                                                this.state.targetgroup
                                                    .promopurchasecode
                                            }
                                        </label>
                                    </div>
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label
                                            htmlFor="promocode"
                                            className="floatLeft"
                                        >
                                            Title:
                                        </label>
                                        <label
                                            htmlFor="promocode"
                                            className="floatLeft label3"
                                        >
                                            {this.state.targetgroup.title}
                                        </label>
                                    </div>
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label
                                            htmlFor="promocode"
                                            className="floatLeft"
                                        >
                                            Product Selection:
                                        </label>
                                        <label
                                            htmlFor="promocode"
                                            className="floatLeft label3"
                                        >
                                            {
                                                this.state.targetgroup
                                                    .productselection
                                            }
                                        </label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    rename = (obj) => {
        for (var prop in obj) {
            if (Array.isArray(obj[prop])) {
                if (obj[prop].length > 0) {
                    obj.showCheckbox = false;
                }
                obj[prop] = obj[prop].map(this.rename);
            }

            if (prop === 'categorycode') {
                obj.value = obj[prop];
                obj.icon = '';
            } else if (prop === 'title') {
                obj.label = obj[prop];
                obj['subtitle'] = obj['categorycode'];
            } else if (prop === 'subcategories') {
                obj.children = obj[prop];
            }
            delete obj[prop];
        }

        return obj;
    };

    _renderEditTargetGroup = () => {
        if (this.state.canViewPTG && this.state.editPromoTargetGroup) {
            const { categoryTree } = this.props.queryData;
            let lCategoryTree = JSON.parse(JSON.stringify(categoryTree));
            const nodes = [lCategoryTree].map(this.rename);

            return (
                <React.Fragment>
                    <AddPromoTargetGroup
                        storeid={this.state.storeid}
                        storelabel={this.state.storelabel}
                        promocode={this.state.promocode}
                        nodes={nodes}
                        promotargetgroup={this.state.targetgroup}
                        hide={this._hideTargetGroup}
                        isViewInEdit={this.state.isViewInEdit}
                    />
                </React.Fragment>
            );
        }
    };

    _renderEditPromoDefinition = () => {
        if (this.state.editPromoDefinition) {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-8 col-sm-12 noPadding noMargin">
                            {/* <form className="splitFrmDiv text-center "> */}
                            <form className="splitFrmDiv">
                                {this._renderPromoBasicDetails()}
                                {this._renderSubSecDivider()}
                                {this._renderDiscountDetails()}
                                {this._renderSubSecDivider()}
                                {this._renderPromotionPeriod()}
                                {this._renderPromoDefinitionActionButtons()}
                                {/* {this._renderMainButton()} */}
                            </form>
                        </div>
                        <div className="col-md-3 col-sm-12 doubleCol">
                            <form
                                className="splitFrmDivSec"
                                style={{ marginTop: '2em' }}
                            >
                                {this._renderOtherPropDetails()}
                            </form>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    componentWillReceiveProps = () => {
        const { promotionstargetgroups } = this.props.queryData;
        const prevStateTargetGroup = this.state.targetgroup;

        if (promotionstargetgroups && promotionstargetgroups.length > 0) {
            if (prevStateTargetGroup !== promotionstargetgroups[0]) {
                this.setState({
                    targetgroup: promotionstargetgroups[0],
                    canViewPTG: this.state.promotype !== 'Rtc',
                    viewPromoTargetGroup: true,
                    addPromoTargetGroup: false,
                });
            }
        } else {
            if (this._isEditMode() && !this.state.editPromoTargetGroup) {
                this.setState({
                    canViewPTG: this.state.promotype != 'Rtc',
                    addPromoTargetGroup: true,
                });
            }
        }
    };

    componentDidMount = () => {
        this.props.queryData.promotionspurchasegroups = [];
        this.props.queryData.promotionstargetgroups = [];
        this.props.dispatch(queryCategoryTree({ body: {} }, 'PD01'));
    };

    componentWillMount = () => {
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;
            const isViewInEdit = this.props.location.state.isViewInEdit;
            const isForApproval = this.props.location.state.isForApproval;
            const storelabel = localStorage.getItem('storelabel');
            this.setState({ storelabel });

            if (rec) {
                const {
                    id,
                    promocode,
                    storeid,
                    title,
                    promotype,
                    promotypevalue,
                    promogroup,
                    qtymax,
                    qtymin,
                    amountmax,
                    amountmin,
                    pointcategory,
                    pointburn,
                    //discounton,
                    taxondiscount,
                    discountvalue,
                    discountcapamt,
                    discountbatch,
                    discountbatchvalue,
                    customertype,
                    validfrom,
                    validtill,
                    closedates,
                    status,
                    sortorder,
                    additionalfields,
                    activedays,
                    pricesegments,
                    excludediscount,
                    priority,
                    priorityweight,
                } = rec;
                this.props.dispatch(getStoreById(storeid));
                this.props.dispatch(
                    promotionTGroupSearchByFields(
                        `storeid=${storeid}&promocode=${promocode}`
                    )
                );
                let defaultaddField = []; //[{ field: "", value: "", id: 0 }];
                let defaultmemField = [{ field: '', value: '', id: 0 }];
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.isViewInEdit = isViewInEdit;
                        draft.isForApproval = isForApproval;
                        draft.title = title ? title : '';
                        draft.promocode = promocode ? promocode : '';
                        draft.storeid = storeid ? storeid : '';
                        draft.promogroup = promogroup ? promogroup : '-1';
                        draft.promotype = promotype ? promotype : '';
                        draft.canViewPTG = promotype
                            ? promotype === 'Rtc'
                                ? false
                                : true
                            : true;
                        draft.promotypevalue = promotypevalue
                            ? promotypevalue
                            : '';
                        draft.qtymax = qtymax ? qtymax : 0;
                        draft.qtymin = qtymin ? qtymin : 0;
                        draft.amountmax = amountmax ? amountmax : 0;
                        draft.amountmin = amountmin ? amountmin : 0;
                        draft.pointcategory = pointcategory
                            ? pointcategory
                            : '';
                        draft.pointburn = pointburn ? pointburn : 0;
                        draft.taxondiscount = taxondiscount
                            ? taxondiscount
                            : 'N';
                        draft.discountvalue = discountvalue ? discountvalue : 0;
                        draft.discountcapamt = discountcapamt
                            ? discountcapamt
                            : 0;
                        draft.discountbatch = discountbatch
                            ? discountbatch
                            : 'N';
                        draft.isItBatch = discountbatch === 'Y';
                        draft.discountbatchvalue = discountbatchvalue
                            ? discountbatchvalue
                            : 0;
                        draft.customertype = customertype ? customertype : '';
                        draft.validfrom = validfrom
                            ? this.toDate(validfrom)
                            : '';
                        draft.validtill = validtill
                            ? this.toDate(validtill)
                            : '';
                        draft.closedates = closedates ? closedates : '';
                        draft.excludedates =
                            draft.closedates.trim().length > 0
                                ? draft.closedates.includes(',')
                                    ? draft.closedates.split(',')
                                    : [...new Array(draft.closedates)]
                                : [];
                        draft.sortorder = sortorder ? sortorder : 1;
                        draft.status = status ? status : '';
                        draft.activedays = activedays ? activedays : [];
                        draft.showWholeWeek = activedays
                            ? activedays.length === 7
                            : false;
                        draft.showSpecificDays = activedays
                            ? activedays.length !== 7
                            : true;
                        draft.activeDays = activedays
                            ? activedays.length === 7
                                ? 'WholeWeek'
                                : 'SpecificDays'
                            : 'SpecificDays';
                        draft.selectedDays = activedays
                            ? this._updateSelectedDays(activedays)
                            : [];

                        draft.isItTaxable =
                            taxondiscount === 'Y' ? true : false; //?  discounton.endsWith('Tax') ? true : false

                        const selectedPriceSegments =
                            pricesegments.trim().length > 0
                                ? pricesegments.includes(',')
                                    ? pricesegments.split(',')
                                    : [...new Array(pricesegments)]
                                : [];
                        if (selectedPriceSegments.length > 0) {
                            draft.selectedPriceSegments =
                                selectedPriceSegments.map((e) => ({
                                    label: this.getPriceSegmentLabel(e),
                                    value: e,
                                }));
                        } else {
                            draft.selectedPriceSegments = [];
                        }

                        draft.additionalFields = additionalfields;

                        draft.excludediscount =
                            excludediscount.trim() === ''
                                ? 'N'
                                : excludediscount;
                        draft.priority = priority;
                        draft.priorityweight = priorityweight;
                    })
                );

                if (isEdit) {
                    this.setState({
                        viewPromoDefinition: false,
                        editPromoDefinition: true,
                    });
                } else if (isView || isForApproval) {
                    this.setState({
                        viewPromoDefinition: true,
                        editPromoDefinition: false,
                    });
                }
            } else {
                this.setState({
                    addPromoDefinition: true,
                    addPromoPurchaseGroup: true,
                    addPromoTargetGroup: true,
                });
            }
        }
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.queryData.promotionstargetgroups = [];
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode()
                                    ? 'Edit Sales Promotion'
                                    : this._isViewMode() ||
                                        this._isViewInEditMode()
                                      ? 'View Sales Promotion'
                                      : 'Add Sales Promotion'}
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderEditPromoDefinition()}
                            {this._renderPromoDefinitionSummary()}
                            {this._renderAddNewPromoDefinition()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {this._renderEditTargetGroup()}
                            {this._renderTargetGroupSummary()}
                            {this._renderAddNewTargetGroup()}
                        </div>
                    </div>
                    <div className="row btn-container form-button" style={{ zIndex: 1}}>
                        <div className="col-sm-12 col-lg-12">
                            {this._renderMainButton()}
                        </div>
                    </div>

                    {this._renderApproveOrRejectSalesPromoPrompt()}
                    {this.renderAlertMessage(isSuccess, !isSuccess)}

                    <ConfirmAlert
                        show={isSuccess && this._isApprovalMode()}
                        handleClose={this.hideModal}
                        to="/ui/sales-promotions"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this._isEditMode() || this._isApprovalMode()
                                    ? 'Promotion updated successfully'
                                    : 'Promotion created successfully'}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }

    renderAlertMessage = (isSuccess, isFailure) => {
        if (this._isApprovalMode()) {
            return;
        }
        this.setState(
            {
                formError: true,
                formErrorMsg:
                    (isSuccess && this._isEditMode()) || this._isApprovalMode()
                        ? 'Promotion updated successfully'
                        : isSuccess
                          ? 'Promotion created successfully'
                          : isFailure
                            ? 'Failed'
                            : '',
            },
            () => {
                this.setState({
                    formError: false,
                    formErrorMsg: '',
                });

                if (isSuccess) {
                    const { data } = this.props.queryData;
                    this._hidePromoDefinition();

                    this.props.crudData.isSuccess = false;
                    this.props.crudData.isFailure = false;
                }
            }
        );
    };
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddSalesPromotions);

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    createArticleInfoApi,
    updateArticleInfoApi,
    getStoreById,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import SimpleSelect from '../../../components/SimpleSelect';
import ImagePick from '../../../components/ImagePick';
import StatusComp from '../../../components/StatusComp';
import { crudReset } from '../../../actions/actions';
import { produce } from 'immer';
import Filter from './../../../components/Filter';
import StoreSearchSingle from '../../system/users/StoreSearchSingle';
class AddProductAddon extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            isEdit: false,
            isView: false,
            showTenantCodeModal: false,
            tenantCode: '',
            tenantCountry: '',
            tenantCity: '',
            tenantCodeDesc: '',
            showTenantCity: false,
            showTenantCode: false,
            // Formrelated
            articlecode: '',
            storeid: '',
            articletitle: '',
            groupid: '',
            title: '',
            title_long: '',
            description: '',
            articlecode: '',
            images: {},
            sortorder: 1,
            language: '',
            langlist: {},
            storelabel: '',

            // Errors
            storeiderror: '',
            articletitleerror: '',
            groupiderror: '',
            titleerror: '',
            title_longerror: '',
            descriptionerror: '',
        };
    }

    getCreatePayload = () => {
        const {
            storeid,
            title_long,
            description,
            articlecode,
            groupid,
            title,
            language,
            sortorder,
            status,
            images,
        } = this.state;
        let body = {
            title: title,
            language: language,
            storeid: storeid,
            articlecode,
            publishmode: 'manual',
            articletype: 'Product Addon Groups',
            status: status,
            sortorder: sortorder,
            fields: {
                groupid,
                title,
                title_long,
                description,
            },
            images: {
                icon: images.image1 || '',
            },
        };

        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }
        const payload = {
            body: body,
        };
        return payload;
    };

    _createProductAddon = () => {
        if (this.validateForm()) {
            if (this.state.isEdit === true) {
                this.props.dispatch(
                    updateArticleInfoApi(
                        this.getCreatePayload(),
                        this.state.id,
                    ),
                );
            } else {
                this.props.dispatch(
                    createArticleInfoApi(this.getCreatePayload()),
                );
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };
    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            storeiderror: '',
            articletitleerror: '',
            groupiderror: '',
            titleerror: '',
            title_longerror: '',
            descriptionerror: '',
            sortordererror: '',
        });
    };
    validateForm = () => {
        this._clearErrorMessages();
        const {
            groupid,
            title,
            title_long,
            description,
            sortorder,
        } = this.state;
        let valid = true;
        if (title.trim() === '') {
            this.setState({ titleerror: 'Title is required.' });
            valid = false;
        }
        if (groupid.trim() === '') {
            this.setState({ groupiderror: 'Group id is required.' });
            valid = false;
        }
        if (title_long.trim() === '') {
            this.setState({ title_longerror: 'Title long is required.' });
            valid = false;
        }
        if (description.trim() === '') {
            this.setState({ descriptionerror: 'Description is required.' });
            valid = false;
        }
        if (sortorder === '') {
            this.setState({ sortordererror: 'sortorder is required.' });
            valid = false;
        }
        return valid;
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <React.Fragment>
                    <Link
                        to="/ui/product-addon"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </React.Fragment>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => {
                            this._createProductAddon();
                        }}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={this._createProductAddon}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/product-addon"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };
    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 });
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    componentWillReceiveProps = () => {
        const { languageConfig } = this.props.queryData;
        if (languageConfig.length > 0) {
            const list = languageConfig[0].additionalfields;
            this.setState({
                langlist: list,
            });
        }
        if (this._isEditMode() || this._isViewMode()) {
            const { storeArray } = this.props.queryData;
            if (storeArray) {
                storeArray.storeid === this.state.storeid
                    ? this.setState({ storelabel: storeArray.title })
                    : this.setState({ storelabel: this.state.storelabel });
            }
            this.setState({
                language: this.props.history.location.state.item.language,
            });
        } else {
            const defLanguage = this.props.location.state.language;
            this.setState({ language: defLanguage });
        }
    };

    _renderMainFormDetails = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="storeid" className="floatLeft">
                            Store
                        </label>
                        <StoreSearchSingle
                            value={this.state.storelabel}
                            onSelectStore={this._onSelectStore}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="articlecode" className="floatLeft">
                            Article Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="articlecode"
                            value={this.state.articlecode}
                            readOnly={true}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    createObjectArray = (max) => {
        var result = [{id: "", name: 'Select'}];
        for (var i = 1; i <= max; i++) {
            result.push({ id: `g${i}`, name: `g${i}` });
        }
        return result;
    };

    _renderGeneralInformation = () => {
        const { groupiderror, titleerror, title_longerror, descriptionerror } =
            this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="groupid" className="floatLeft">
                            Group ID
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={this.createObjectArray(30)}
                            selectedOption={this.state.groupid}
                            callbackChangeFilter={(event) => {
                                this.setState({ groupid: event.target.value });
                            }}
                            id="groupid"
                        />
                        <div className="invalid-feedback">{groupiderror}</div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft">
                            Title
                        </label>
                        <input
                            type="text"
                            className={
                                titleerror === ''
                                    ? 'form-control'
                                    : 'error-control'
                            }
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">{titleerror}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title_long" className="floatLeft">
                            Title Long
                        </label>
                        <input
                            type="text"
                            className={
                                title_longerror === ''
                                    ? 'form-control'
                                    : 'error-control'
                            }
                            id="title_long"
                            value={this.state.title_long}
                            onChange={(event) =>
                                this.setState({
                                    title_long: event.target.value,
                                })
                            }
                        />
                        <div className="invalid-feedback">
                            {title_longerror}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title_long" className="floatLeft">
                            Description
                        </label>
                        <input
                            type="text"
                            className={
                                descriptionerror === ''
                                    ? 'form-control'
                                    : 'error-control'
                            }
                            id="description"
                            value={this.state.description}
                            onChange={(event) =>
                                this.setState({
                                    description: event.target.value,
                                })
                            }
                        />
                        <div className="invalid-feedback">
                            {descriptionerror}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
    setImages = (images) => {
        this.setState({ images: images });
    };
    _renderImageDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Icon Image </div>

                <div className="row">
                    <div className="col-12">
                        <ImagePick
                            setImages={this.setImages}
                            images={this.state.images}
                            limit={1}
                        ></ImagePick>
                    </div>
                </div>
            </React.Fragment>
        );
    };
    _renderStatusDetails = () => {
        const { languageConfig } = this.props.queryData;
        return (
            <React.Fragment>
                <div className="row sub-title">Actions</div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="sortorder" className="floatLeft">
                            Language
                        </label>
                        <SimpleSelect
                            objArray={this.state.langlist}
                            callbackChangeFilter={(value) => {
                                this.setState({ language: value });
                            }}
                            selectedOption={this.state.language}
                            id="language"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusComp
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label
                            htmlFor="sortorder"
                            className="floatLeft required"
                        >
                            Sort Order
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="sortorder"
                            value={this.state.sortorder}
                            onChange={(event) =>
                                this.setState({ sortorder: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.sortordererror}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div className="grid rowBottom">
                    <div className="col-9">
                        <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                            {this._renderMainFormDetails()}
                        </div>
                        <div
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderGeneralInformation()}
                        </div>
                        <div
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderImageDetails()}
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                            {this._renderStatusDetails()}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    componentWillMount = () => {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage });
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;
            if (rec) {
                const {
                    id,
                    title,
                    articlecode,
                    status,
                    sortorder,
                    fields,
                    storeid,
                    images,
                } = rec;
                this.props.dispatch(getStoreById(storeid));
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.articletitle = title || '';
                        draft.articlecode = articlecode || '';
                        draft.storeid = storeid ? storeid : '';
                        //draft.status = status ? status : [];
                        draft.sortorder = sortorder ? sortorder : 0;

                        draft.description = fields.description || '';
                        draft.groupid = fields.groupid || '';
                        draft.title = fields.title || '';
                        draft.title_long = fields.title_long || '';

                        draft.images = {
                            image1:
                                rec.hasOwnProperty('images') === true
                                    ? images.icon
                                    : '',
                        };
                    }),
                );
            }
        }
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode()
                                    ? 'Edit Product Add-On'
                                    : this._isViewMode()
                                      ? 'View Product Add-On'
                                      : 'Add Product Add-On'}
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderFormArea()}
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/product-addon"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this._isEditMode() === true
                                    ? 'Product Addon updated successfully'
                                    : 'Product Addon created successfully'}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddProductAddon);

import React from 'react';
import DatePicker from 'react-date-picker';
import { produce } from 'immer';
import {
    crudReset,
    resetAction,
    posMasterDetailsReport,
    queryPropertyConfigByFields,
    storeOpenSearchByFields,
    storeTerminalsSearchByFields,
} from '../../actions/actions';
import {
    formatDate,
    formatLanguageArray,
    permissions,
    sortTableDateTime,
} from '../../helpers/Util';

import 'react-accessible-accordion/dist/fancy-example.css';
import { connect } from 'react-redux';
import ConfirmAlert from '../../components/ConfirmAlert';
import StoreSearchSingle from '../system/users/StoreSearchSingle';
import {
    SALES_DETAILS_REPORT,
    SEARCH_PROP_CONFIG_FAILURE,
    SEARCH_PROP_CONFIG_FIELDS,
    SEARCH_PROP_CONFIG_SUCCESS,
    SEARCH_STORE_OPEN_FAILURE_SILENT,
    SEARCH_STORE_OPEN_SUCCESS_SILENT,
    SEARCH_STORE_TERMINALS_FAILURE_SILENT,
    SEARCH_STORE_TERMINALS_SUCCESS_SILENT,
} from '../../constants/constants';
import MultiSelect from '../../components/MultiSelect';
import ExportAndDownloadReport from './ExportAndDownloadReport';
import ReportWarning from '../../components/ReportWarning';
import PrimeTable from '../../components/PrimeTable';
import { Column } from 'primereact/column';

class SalesDetailsReport extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.group = 'DE_REPORTS_SALES_DETAILS';
        this.module = 'REPORTS';
        this.allPermissions = [];

        this.requestBody = {};
        this.currPage = 1;
        this.pageSize = 10;

        this.state = {
            storeid: '',
            trxno: '',
            invoiceno: '',
            signonid: '',
            storesessionid: '',
            usercode: '',
            terminalid: '',
            orderid: '',
            trxdate: '',
            storedate: new Date(),
            date_from: new Date(),
            date_till: new Date(),
            trxtype: '',
            trxtypes: {},
            storesessions: [{ name: 'Select', id: '' }],
            terminals: [{ name: 'Select', id: '' }],
            params: {},
            showExportReport: false,

            s_storelabel: '',
            pageSize: this.pageSize,
            currPage: this.currPage,
            // fromDate: new Date(),
            // toDate: new Date(),
            order_id: '',
            date_type: 'trxdate',
            searchbtn: false,
            searchfieldbtn: false,
            storelabel: '',
            cartdata: [],
            storeiderror: '',
            storesessionerror: '',
            isSearched: false,
        };
    }

    toDate = (dateStr) => {
        const [day, month, year] = dateStr.split('-');
        return new Date(year, month - 1, day);
    };

    createFetchUrl = (fetchType) => {
        const { pageSize, currPage, storeid, date_from, date_till } = this.state;

        const queryParams = [];
        if (fetchType === 'ONLOAD') {
            queryParams.push(`storeid=${storeid}`);
            // queryParams.push(`storedate=${formatDate(storedate)}`);
            queryParams.push(`date_from=${formatDate(date_from)}`);
            queryParams.push(`date_till=${formatDate(date_till)}`);
            queryParams.push(`pagesize=${this.pageSize}`);
            queryParams.push(`pageno=${this.currPage}`);

            return queryParams.join('&');
        } else {
            queryParams.push(`storeid=${storeid}`);
            //queryParams.push(`storedate=${formatDate(storedate)}`);
            queryParams.push(`date_from=${formatDate(date_from)}`);
            queryParams.push(`date_till=${formatDate(date_till)}`);
        }

        this.requestBody['pagesize'] = pageSize;
        this.requestBody['pageno'] = currPage;

        for (const [key, value] of Object.entries(this.requestBody)) {
            queryParams.push(`${key}=${value}`);
        }
        return queryParams.join('&');
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            posMasterDetailsReport(this.createFetchUrl(fetchType))
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch(triggerPoint);
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
            default:
        }
    };

    componentWillMount() {
        this.setState(
            {
                storeid: localStorage.getItem('storeid'),
                s_storelabel: localStorage.getItem('storelabel'),
            },
            () => {
                //this.fetchStoreSessions();
                this.fetchStoreTerminals();
                this.callFetch('ONLOAD');
            }
        );

        //reset the existing report data
        this.props.queryData.posMasterDetailsReport = [];

        this.props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propgroup: 'SALES_TRXTYPES',
                    },
                },
                SEARCH_PROP_CONFIG_FIELDS,
                SEARCH_PROP_CONFIG_SUCCESS,
                SEARCH_PROP_CONFIG_FAILURE
            )
        );

        this.allPermissions = permissions(this.module, this.group);
    }

    fetchStoreTerminals = () => {
        const { storeid } = this.state;

        this.props.queryData.storeTerminals = [];
        if (storeid && storeid !== '') {
            const queryParams = [];
            queryParams.push(`storeid=${storeid}`);
            queryParams.push(`devicegroup=terminal`);
            this.props.dispatch(
                storeTerminalsSearchByFields(
                    queryParams.join('&'),
                    SEARCH_STORE_TERMINALS_SUCCESS_SILENT,
                    SEARCH_STORE_TERMINALS_FAILURE_SILENT
                )
            );
        }
    };

    fetchStoreSessions = () => {
        const { storeid, date_from, date_till } = this.state;
        //reset before query
        this.props.queryData.storeSessions = [];
        if (storeid && storeid !== '' && date_from !== '') {
            const queryParams = [];
            queryParams.push(`storeid=${storeid}`);
            queryParams.push(`date_from=${formatDate(date_from)}`);
            queryParams.push(`date_till=${formatDate(date_till)}`);
            this.props.dispatch(
                storeOpenSearchByFields(
                    queryParams.join('&'),
                    SEARCH_STORE_OPEN_SUCCESS_SILENT,
                    SEARCH_STORE_OPEN_FAILURE_SILENT
                )
            );
        }
    };

    componentDidUpdate = (prevProps) => {
        const { storeSessions, storeTerminals } = this.props.queryData;
        const {
            storeSessions: prevstoreSessions,
            storeTerminals: prevstoreTerminals,
        } = prevProps.queryData;

        if (storeSessions !== prevstoreSessions) {
            if (storeSessions && storeSessions.length > 0) {
                var sessions = [{ name: 'Select', id: '' }];
                var returnedSessions = storeSessions.map((e) => ({
                    name: `${e.trxdate} (${e.status})`,
                    id: e.trxval,
                }));
                this.setState({
                    storesessions: sessions.concat(returnedSessions),
                });
            } else {
                this.setState({ storesessions: [{ name: 'Select', id: '' }] });
            }
        }

        if (storeTerminals !== prevstoreTerminals) {
            if (storeTerminals && storeTerminals.length > 0) {
                var terminals = [{ name: 'Select', id: '' }];
                var returnedTerminals = storeTerminals.map((e) => ({
                    name: e.terminalid,
                    id: e.terminalid,
                }));
                this.setState({
                    terminals: terminals.concat(returnedTerminals),
                });
            } else {
                this.setState({ terminals: [{ name: 'Select', id: '' }] });
            }
        }
    };

    componentWillReceiveProps = () => {
        const { storeSessions, storeTerminals, propConfigs } =
            this.props.queryData;

        if (storeSessions && storeSessions.length > 0) {
            var sessions = [{ name: 'Select', id: '' }];
            var returnedSessions = storeSessions.map((e) => ({
                name: `${e.trxdate} (${e.status})`,
                id: e.trxval,
            }));
            this.setState({ storesessions: sessions.concat(returnedSessions) });
        }

        if (storeTerminals && storeTerminals.length > 0) {
            var terminals = [{ name: 'Select', id: '' }];
            var returnedTerminals = storeTerminals.map((e) => ({
                name: e.terminalid,
                id: e.terminalid,
            }));
            this.setState({ terminals: terminals.concat(returnedTerminals) });
        }

        if (
            propConfigs &&
            propConfigs.length > 0 &&
            propConfigs[0].additionalfields
        ) {
            this.setState({ trxtypes: propConfigs[0].additionalfields }); //propConfigs[0].additionalfields.values().join(',')
        }
    };

    _setTenant = (p1, p2) => {
        this.setState(
            { storeid: p1, s_storelabel: p2, search_value: p1 },
            () => {
                localStorage.setItem('storeid', p1);
                localStorage.setItem('storelabel', p2);
                //this.fetchStoreSessions();
                this.fetchStoreTerminals();
            }
        );
        this.setState({ storeiderror: '' });
    };

    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());

        //this.fetchStoreSessions();
        this.fetchStoreTerminals();
    };

    _renderExportReportBtn = () => {
        return (
            <button
                type="submit"
                className="btn btn-themes btn-rounded"
                onClick={(e) => {
                    this.viewExportReport(e);
                }}
            >
                Export/Download Report
            </button>
        );
    };

    viewExportReport = (row) => {
        this.setState(
            {
                params: {
                    storeid: this.state.storeid,
                    s_storelabel: this.state.s_storelabel,
                    trxtypes: this.state.trxtypes,
                },
            },
            () => {
                this.setState({ showExportReport: true });
            }
        );
    };

    _validateAndSearch = (event) => {
        if (event) event.preventDefault();

        const { storeid, trxno, usercode, terminalid, trxtype, date_from, date_till } =
            this.state;

        this.requestBody = {};
        const queryParams = [];
        if (this.state.storeid === '' || this.state.storeid === null) {
            this.setState({ storeiderror: 'Please select the store' });
            return;
        } else {
            this.setState({ storeiderror: '' });
            this.requestBody['storeid'] = storeid;
            queryParams.push(`storeid=${storeid}`);
        }
        if (terminalid !== '') {
            this.requestBody['terminalid'] = terminalid;
            queryParams.push(`terminalid=${terminalid}`);
        }

        if (trxtype !== '') {
            this.requestBody['trxtype'] = trxtype;
            queryParams.push(`trxtype=${trxtype}`);
        }

        if (usercode !== '') {
            this.requestBody['usercode'] = usercode;
            queryParams.push(`usercode=${usercode}`);
        }

        if (trxno !== '') {
            this.requestBody['trxno'] = trxno;
            queryParams.push(`trxno=${trxno}`);
        }

        //queryParams.push(`storedate=${formatDate(storedate)}`);
        queryParams.push(`date_from=${formatDate(date_from)}`);
        queryParams.push(`date_till=${formatDate(date_till)}`);

        queryParams.push(`pagesize=${this.pageSize}`);
        queryParams.push(`pageno=${this.currPage}`);

        this.setState({ isSearched: true, pageSize: this.pageSize });
        this.props.dispatch(posMasterDetailsReport(queryParams.join('&')));
    };

    _hideExport = () => {
        this.props.queryData.reportexport = {};
        this.setState({ showExportReport: false });
    };

    _renderSearchCiteria = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-3 col-sm-12 ">
                        <label htmlFor="storeid" className="floatLeft required">
                            Select Store
                        </label>
                        <StoreSearchSingle
                            value={this.state.s_storelabel}
                            onSelectStore={this._onSelectStore}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>
                    <div className="form-group col-lg-3 col-sm-12">
                        <label
                            htmlFor="date_from"
                            className="floatLeft required"
                        >
                            Date From
                        </label>
                        <DatePicker
                            onChange={(value) =>
                                this.setState({ date_from: value }, () => {
                                    if (
                                        this.state.date_from >
                                        this.state.date_till
                                    ) {
                                        this.setState({
                                            date_till: this.state.date_from,
                                        });
                                    }
                                    this.fetchStoreSessions();
                                })
                            }
                            value={this.state.date_from}
                            maxDate={new Date()}
                            format="d/M/yy"
                        />
                    </div>

                    <div className="form-group col-lg-3 col-sm-12">
                        <label 
                            htmlFor="toDate" 
                            className="floatLeft required"
                        >
                            Date To
                        </label>
                        <DatePicker
                            minDate={this.state.date_from}
                            onChange={(value) =>
                                this.setState({ date_till: value })
                            }
                            value={this.state.date_till}
                            maxDate={new Date()}
                            format="d/M/yy"
                        />
                    </div>
                    <div className="form-group col-lg-3 col-sm-4">
                        <label htmlFor="terminalid" className="floatLeft">
                            Terminal Id
                        </label>
                        <div className="row">
                            <select
                                name="terminalid"
                                className="simpleSearchSelect2"
                                value={this.state.terminalid}
                                onChange={(event) =>
                                    this.setState({
                                        terminalid: event.target.value,
                                    })
                                }
                            >
                                {this.state.terminals.length !== 0 &&
                                    this.state.terminals.map(
                                        (option, index) => {
                                            const { name, id } = option;
                                            return (
                                                <option key={index} value={id}>
                                                    {name}
                                                </option>
                                            );
                                        }
                                    )}
                            </select>
                        </div>
                    </div>
                    

                    <div className="form-group col-lg-3 col-sm-4">
                        <label htmlFor="storesessionid" className="floatLeft">
                            Store Session
                        </label>
                        <div className="row">
                            <select
                                name="storesession"
                                className="simpleSearchSelect2"
                                value={this.state.storesessionid}
                                onChange={(event) =>
                                    this.setState({
                                        storesessionid: event.target.value,
                                    })
                                }
                            >
                                {this.state.storesessions.length !== 0 &&
                                    this.state.storesessions.map(
                                        (option, index) => {
                                            const { name, id } = option;
                                            return (
                                                <option key={index} value={id}>
                                                    {name}
                                                </option>
                                            );
                                        }
                                    )}
                            </select>
                        </div>
                        <div className="invalid-feedback">
                            {this.state.storesessionerror}
                        </div>
                    </div>
                    <div className="form-group col-lg-3 col-sm-4">
                        <label htmlFor="trxtype" className="floatLeft">
                            Trx Type
                        </label>
                        <div className="row">
                            <MultiSelect
                                filterOptions={formatLanguageArray(
                                    this.state.trxtypes
                                )}
                                setSelectedFieldValues={(value) => {
                                    this.setState({ trxtype: value });
                                }}
                                id="trxtype"
                                itemsSelected={this.state.trxtype}
                                label="Select Trx Type"
                            />
                        </div>
                    </div>

                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="usercode" className="floatLeft">
                            User Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="usercode"
                            value={this.state.usercode}
                            onChange={(event) => {
                                this.setState({ usercode: event.target.value });
                            }}
                        />
                    </div>

                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="trxno" className="floatLeft">
                            Transaction No
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="trxno"
                            value={this.state.trxno}
                            onChange={(event) => {
                                this.setState({ trxno: event.target.value });
                            }}
                        />
                    </div>

                    <div className="form-group col-1">
                        <img
                            alt="search"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_search_blue.png`}
                            onClick={(event) => this._validateAndSearch(event)}
                            style={{ height: '2.2em', width: '2.5em', cursor: 'pointer'}}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    pageSizeChangecallback = (pageSize) => {
        this.setState(
            produce(this.state, (draft) => {
                if (
                    draft.pageSize <
                    pageSize
                ) {
                    draft.currPage = 1;
                }
                draft.pageSize = pageSize;
                draft.isSearched = false
            }),
            () => {
                this.callQueryApiForFetch(
                    'PAGECLICK'
                );
            }
        );
    }

    voidBodyTemplate = (row) => {
        const { trxtype, voidno, retuno} = row
        if (retuno > 0) {
            return retuno
        }
        if (voidno > 0) {
            return voidno
        }
    };

    render() {
        const {
            posMasterDetailsReport,
            servererror: serverError,
            errormsg: queryError,
        } = this.props.queryData;

        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }

        const columns = [
            { field: 'storeid', header: 'Store Id' },
            { field: 'storesessionid', header: 'Store Session Id' },
            { field: 'terminalid', header: 'Terminal Id' },
            { field: 'usercode', header: 'User Id' },
            { field: 'trxdate', header: 'Trx Date' },
            { field: 'trxno', header: 'Trx No' },
        ];

        if (this.state.showExportReport) {
            return (
                <React.Fragment>
                    <ExportAndDownloadReport
                        params={this.state.params}
                        _hideExport={this._hideExport}
                        // tasktype='pos_master_details'
                        reporttype={SALES_DETAILS_REPORT}
                    />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="form_height">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <h2 className="page-header">Sales Report</h2>
                            </div>
                            <div className="col-lg-6 col-sm-12 alignRight alignWithPageHeader">
                                {this._renderExportReportBtn()}
                            </div>
                        </div>
                        <div className="row form-container">
                            <div className="col">
                                <form
                                    className="frmDiv"
                                    onSubmit={this._validateAndSearch}
                                >
                                    <div className="row">
                                        {this._renderSearchCiteria()}
                                    </div>
                                    <div className="title_devide" />
                                    <div className="invalid-feedback">
                                        {errormsg}
                                    </div>
                                    <ReportWarning />
                                    <PrimeTable
                                        tablePageSize={this.pageSize}
                                        isSearched={this.state.isSearched}
                                        list={posMasterDetailsReport}
                                        columns={columns}
                                        actionColumn={this.actionTemplate}
                                        linkColumn={this.linkTemplate}
                                        pageSizeChangecallback={(pageSize) => this.pageSizeChangecallback(pageSize)}
                                        pageChangeCallback={(currPage) => {
                                            this.setState(
                                                {
                                                    currPage: currPage,
                                                },
                                                () => {
                                                    this.callQueryApiForFetch(
                                                        'PAGECLICK'
                                                    );
                                                }
                                            );
                                        }}
                                        lastChildLocation={true}
                                    >
                                        <Column
                                            field="trxtype"
                                            header="Trx Type"
                                        />
                                        <Column
                                            field="invoiceno"
                                            header="Invoice No"
                                        />
                                        <Column
                                            field="filters"
                                            header="Return/Void No"
                                            body={this.voidBodyTemplate}
                                        />
                                        
                                        <Column
                                            field="amount"
                                            header="Amount"
                                        />
                                    </PrimeTable>
                                </form>
                            </div>
                        </div>
                        <ConfirmAlert
                            show={errormsg}
                            handleClose={(event) =>
                                this.hideErrorAlertModal(event, refresh)
                            }
                            children={
                                <div
                                    style={
                                        errormsg
                                            ? {
                                                  padding: '2em',
                                                  color: 'red',
                                                  fontWeight: '500',
                                              }
                                            : { padding: '2em', color: 'red' }
                                    }
                                >
                                    {errormsg ? `${errormsg}.` : ''}
                                </div>
                            }
                        />
                    </div>
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(SalesDetailsReport);

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import {
    searchGeneral,
    sendEipMessage,
    loadingApi,
} from '../../../actions/actions';
import * as CONSTANTS from '../../../constants/constants';
import ViewEipMessage from './ViewEipMessage';
import ExportEipMessage from './ExportEipMessage';

const eipStatusOptions = [
    { name: 'Select', code: '' },
    { name: 'Error', code: 'error' },
    { name: 'Send', code: 'send' },
    { name: 'Success', code: 'success' },
];

const booleanOptions = [
    { name: 'Select', code: '' },
    { name: 'Y', code: 'Y' },
    { name: 'N', code: 'N' },
];

const EipMessage = (props) => {
    const { eipMessages, errormsg, apiPending } = props.queryData;
    const [selectedRecords, setSelectedRecords] = useState(null);
    const [first, setFirst] = useState(0);
    const [tableRows, setRows] = useState(1000);
    const [pageSize, setPageSize] = useState(1000);
    const [currentPage, setCurrentPage] = useState(1);
    const [date_from, setDateFrom] = useState(new Date());
    const [filterData, setFilterData] = useState();
    const [showMessageDetail, setShowMessageDetail] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [dialogVisible, setDialogVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [eipMessageList, setEipMessageList] = useState([]);
    const [showExportReport, setShowExportReport] = useState(false);
    

    useEffect(() => {
        if (eipMessages.length > 0) {
            setEipMessageList(eipMessages);
        }
    }, [eipMessages]);

    useEffect(() => {
        if (errormsg) {
            setEipMessageList([]);
        }
    }, [errormsg]);

    useEffect(() => {
        if (apiPending) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [apiPending]);

    const renderPaginationTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 1000, value: 1000 },
                { label: 500, value: 500 },
                { label: 300, value: 300 },
                { label: 200, value: 200 },
                { label: 100, value: 100 },
            ];

            return (
                <React.Fragment>
                    <span
                        className="mx-1"
                        style={{
                            color: 'var(--text-color)',
                            userSelect: 'none',
                        }}
                    >
                        Items per page:{' '}
                    </span>
                    <Dropdown
                        value={pageSize}
                        options={dropdownOptions}
                        onChange={(e) => handlePageSizeChange(e)}
                    />
                </React.Fragment>
            );
        },
        CurrentPageReport: () => {
            return (
                <div>
                    <button
                        type="button"
                        className={classNames({
                            'p-paginator-prev p-paginator-element p-link': true,
                            'p-disabled': currentPage == 1,
                        })}
                        disabled={currentPage == 1}
                        aria-label="Previous Page"
                        onClick={handlePrevPage}
                    >
                        <span className="p-paginator-icon pi pi-angle-left"></span>
                        <span role="presentation" className="p-ink"></span>
                    </button>
                    {currentPage}
                    <button
                        type="button"
                        className={classNames({
                            'p-paginator-prev p-paginator-element p-link': true,
                            'p-disabled': eipMessages.length < pageSize,
                        })}
                        aria-label="Next Page"
                        onClick={handleNextPage}
                    >
                        <span className="p-paginator-icon pi pi-angle-right"></span>
                        <span role="presentation" className="p-ink"></span>
                    </button>
                </div>
            );
        },
    };

    const callApi = (params) => {
        //setLoading(true)
        props.dispatch(loadingApi());
        props.dispatch(
            searchGeneral(
                'eip/v1/broker/consumer/offset/search/fields',
                params,
                CONSTANTS.SEARCH_EIP_MESSAGE_SUCCESS,
                CONSTANTS.SEARCH_EIP_MESSAGE_FAILURE
            )
        );
    };

    const handlePageSizeChange = (e) => {
        e.preventDefault();
        setPageSize(e.target.value);
        //setCurrentPage(1);
        setRows(e.target.value);
        const params = getParsedValue(e.target.value, currentPage);
        callApi(params);
    };

    const getParsedValue = (pageSize, pageNo) => {
        const parsedValues = {
            ...filterData,
            date_from: filterData.date_from
                ? moment(filterData.date_from).format('DD-MM-YYYY')
                : '',
            date_till: filterData.date_till
                ? moment(filterData.date_till).format('DD-MM-YYYY')
                : '',
            msgsend: filterData.msgsend ? filterData.msgsend : '',
            msgstatus: filterData.msgstatus ? filterData.msgstatus.code : '',
        };
        Object.keys(parsedValues).forEach((key) => {
            if (parsedValues[key] === '') {
                delete parsedValues[key];
            }
        });
        const requestBody = {
            body: {
                ...parsedValues,
                pagesize: pageSize,
                pageno: pageNo,
            },
        };
        return requestBody;
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        const params = getParsedValue(pageSize, currentPage + 1);
        callApi(params);
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        const params = getParsedValue(pageSize, currentPage + 1);
        callApi(params);
    };

    const clearEmptyParam = (data) => {
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });
    };

    const handleSearch = (data) => {
        setLoading(true);
        setDateFrom(data.date_from);

        if (data.date_till) {
            setDateFrom(data.date_till);
        }
        clearEmptyParam(data);
        setFilterData(data); //set search data into filter data
        const parsedValues = {
            ...data,
            msgstatus: data.msgstatus ? data.msgstatus.code : '',
            date_from: data.date_from
                ? moment(data.date_from).format('DD-MM-YYYY')
                : '',
            date_till: data.date_till
                ? moment(data.date_till).format('DD-MM-YYYY')
                : '',
        };
        clearEmptyParam(parsedValues);
        const requestBody = {
            body: {
                ...parsedValues,
                pagesize: pageSize,
                pageno: currentPage,
            },
        };
        callApi(requestBody);
    };

    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="flex">
                <img
                    title="View"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                    onClick={() => viewMessageDetails(rowData)}
                />
            </div>
        );
    };

    const viewMessageDetails = (rowData) => {
        setShowMessageDetail(true);
        setSelectedData(rowData);
    };

    const hideLogDetails = () => {
        setShowMessageDetail(false);
        setSelectedData({});
    };

    const handleSelectedRecord = (records) => {
        setSelectedRecords(records);
    };

    const handleResendMessage = () => {
        const ids = selectedRecords.map((record) => record.id).join(',');
        const requestBody = {
            ids: ids,
        };
        const payload = {
            body: requestBody,
        };
        props.dispatch(sendEipMessage(payload));
        setDialogVisible(true);
    };

    const renderDialogHeader = () => <></>;

    const handleCloseDialog = () => {
        setDialogVisible(false);
        setSelectedRecords(null);
    };

    const renderDialogFooter = () => {
        return (
            <div style={{ justifyContent: 'center', display: 'flex' }}>
                <Button
                    className="p-button-info"
                    type="button"
                    label="OK"
                    icon="pi pi-check"
                    onClick={handleCloseDialog}
                />
            </div>
        );
    };

    const renderAddNewBtn = () => {
        return (
            <Button
                label="Export"
                className="p-button-success"
                onClick={() => setShowExportReport(true)}
            />
        );
    };

    const renderView = () => {
        if (showMessageDetail) {
            return (
                <ViewEipMessage
                    hideLogDetails={hideLogDetails}
                    record={selectedData}
                />
            );
        }
        if (showExportReport) {
            return <ExportEipMessage hideExport={hideExport} />;
        }

        return renderListView();
    };

    const hideExport = () => setShowExportReport(false)

    const renderListView = () => {
        return (
            <>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-9 col-sm-12">
                            <h2 className="page-header">Eip Messages</h2>
                        </div>
                        <div className="col-lg-3 col-sm-12 alignRight alignWithPageHeader">
                            {renderAddNewBtn()}
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            <div className="frmDiv">
                                <FilterForm 
                                    handleSearch={handleSearch} 
                                    data={filterData}
                                />
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <DataTable
                                            className="lazy-loading"
                                            value={eipMessageList}
                                            showGridlines
                                            columnResizeMode="fit"
                                            responsiveLayout="scroll"
                                            paginatorClassName="justify-content-end"
                                            paginator
                                            paginatorTemplate={
                                                renderPaginationTemplate
                                            }
                                            first={first}
                                            rows={tableRows}
                                            selectionMode="checkbox"
                                            selection={selectedRecords}
                                            onSelectionChange={(e) =>
                                                handleSelectedRecord(e.value)
                                            }
                                            loading={loading}
                                            dataKey="id"
                                            onPage={onCustomPage}
                                            emptyMessage="Your search does not retrieve any data. Please search again using another criteria."
                                        >
                                            <Column
                                                selectionMode="multiple"
                                                headerStyle={{
                                                    width: '3em',
                                                }}
                                            />
                                            <Column
                                                field="msgid"
                                                header="Msg Id"
                                                sortable
                                            />
                                            <Column
                                                field="topic"
                                                header="Topic"
                                                sortable
                                            />
                                            <Column
                                                field="consumerid"
                                                header="Consumer Id"
                                            />
                                            <Column
                                                field="msgstatus"
                                                header="Msg Status"
                                            />
                                            <Column
                                                field="msgsend"
                                                header="Msg Send"
                                            />
                                            <Column
                                                field="createdon"
                                                header="Created On"
                                                sortable
                                            />
                                            <Column
                                                header="Actions"
                                                body={actionTemplate}
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {selectedRecords && selectedRecords.length > 0 && (
                    <div className="row btn-container form-button">
                        <div
                            className="col-sm-12 col-lg-12"
                            style={{ marginBottom: '6px', padding: '6px' }}
                        >
                            <Button
                                label="Resend"
                                icon="pi pi-send"
                                className="p-button p-button-success"
                                onClick={handleResendMessage}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {renderView()}
            <Dialog
                showHeader={false}
                contentClassName="warning-dialog-content"
                header={renderDialogHeader()}
                visible={dialogVisible}
                footer={renderDialogFooter()}
                onHide={handleCloseDialog}
            >
                <p style={{ fontWeight: '500', fontSize: '16px' }}>
                    Request submitted.
                </p>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

const FilterForm = (props) => {
    const { handleSearch, data } = props;
    const initialValues = {
        msgid: '',
        date_from: new Date(),
        date_till: new Date(),
        msgstatus: eipStatusOptions.find((status) => status.code == 'error'),
        topic: '',
        consumerid: '',
        msgsend: ''
    };
    const formik = useFormik({
        initialValues: {
            msgid: data && data.msgid ? data.msgid : '',
            date_from: new Date(),
            date_till: new Date(),
            msgstatus: eipStatusOptions.find(
                (status) => status.code == 'error'
            ),
            topic: '',
            consumerid: data && data.consumerid ? data.consumerid : '',
            msgsend: data && typeof data.msgsend === 'string'
                ? booleanOptions.find((s) => s.code === data.msgsend)
                : ''
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                msgsend: values.msgsend ? values.msgsend.code : '',
            };
            handleSearch(data);
        },
    });

    const handleClear = () => {
        formik.resetForm();
        handleSearch(formik.values);
    };

    useEffect(() => {
        handleSearch(initialValues);
    }, []);

    const renderFormArea = () => {
        return (
            <>
                <div className="row p-fluid formgrid grid w-100">
                    <div className="field col-3 md:col-3">
                        <label htmlFor="msgid">Msg Id</label>
                        <InputText
                            id="msgid"
                            type="text"
                            name="msgid"
                            value={formik.values.msgid}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="date_from">Date From</label>
                        <Calendar
                            id="date_from"
                            value={formik.values.date_from}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="date_till">Date Till</label>
                        <Calendar
                            id="date_till"
                            value={formik.values.date_till}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            minDate={formik.values.date_from}
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="msgstatus">Message Status</label>
                        <Dropdown
                            id="msgstatus"
                            name="msgstatus"
                            value={formik.values.msgstatus}
                            onChange={formik.handleChange}
                            options={eipStatusOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="msgsend">Message Send</label>
                        <Dropdown
                            id="msgsend"
                            name="msgsend"
                            value={formik.values.msgsend}
                            onChange={formik.handleChange}
                            options={booleanOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="topic">Topic</label>
                        <InputText
                            id="topic"
                            type="text"
                            name="topic"
                            value={formik.values.topic}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="msgid">Consumer Id</label>
                        <InputText
                            id="consumerid"
                            type="text"
                            name="consumerid"
                            value={formik.values.consumerid}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div
                        className="field col-3 md:col-3"
                        style={{ display: 'flex', alignItems: 'flex-end' }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Button
                                type="submit"
                                label="Search"
                                className="p-button-success mr-4"
                            />
                            {/* <Button
                                type="button"
                                label="Clear"
                                className="p-button-secondary"
                                onClick={handleClear}
                            /> */}
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

export default connect(mapStateToProps)(EipMessage);

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import { renderConfigInMultiSelect } from '../../../helpers/Util';
import {
    createAddMapStore,
    queryCategoryTree,
    searchGeneral,
} from '../../../actions/actions';
import MultiSelect from '../../../components/MultiSelect';
import ConfirmAlert from '../../../components/ConfirmAlert';
import Filter from './../../../components/Filter';
import { crudReset } from '../../../actions/actions';
import Autocomplete from './../../../components/Autocomplete';

import { renderTaxValues } from './../../../helpers/Util';
import StoreSearchSingle from '../../system/users/StoreSearchSingle';

const tieroptions = {
    T1: 'Public Price',
    T2: 'Special Menu',
    T3: 'Member Price',
};

class AddMapStore extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            showProdModal: false,
            // Formrelated
            storeid: '',
            productcode: '',
            title: '',
            qrcode: '',
            barcode: '',
            baseprice: '',
            promoinfo: '',
            producttag: '',
            searchkeys: '',
            similarpcodes: '',
            recommendedpcodes: '',
            storeproductaddonids: '',
            taxcategory: 'TX0',
            categorycode: '',
            status: 'Active',
            tiers: ['T1'],
            sortorder: '',
            storelabel: '',
            // Errors
            productcodeerror: '',
            categorycodeerror: '',
            titleerror: '',
            sortordererror: '',
            tiererror: '',
            tierOptions: {}
        };
    }

    componentDidMount = () => {
        this.props.dispatch(queryCategoryTree({ body: {} }, 'PD01'));
        this.fetchApi()
    };

    getCategoryDetail = (category) => {
        const { categoryTree } = this.props.queryData;
        if (categoryTree.children) {
            let getcategory = [];
            for (let i = 0; i < categoryTree.children.length; i++) {
                getcategory.push({
                    ...categoryTree.children[i],
                });
            }
            const mergeproduct = [].concat.apply(
                ...(getcategory || []).map((o) => o.children)
            );
            const getcode = mergeproduct.filter(
                (e) => e.categorycode === category
            );
            const producttitle = getcode.length > 0 ? getcode[0].title : '';
            return producttitle;
        }
    };
    setTier = (value) => {
        this.setState({
            tiers: value,
        });
    };

    getCreatePayload = () => {
        const {
            storeid,
            qrcode,
            barcode,
            productcode,
            title,
            categorycode,
            baseprice,
            promoinfo,
            producttag,
            searchkeys,
            similarpcodes,
            sortorder,
            recommendedpcodes,
            storeproductaddonids,
            status,
            tiers,
            taxcategory,
            sku,
        } = this.state;

        let body = {
            additionalfields: {
                title: title,
            },
            productcode,
            barcode,
            qrcode,
            sku,
            segments: tiers.toString(),
            categorycodes: categorycode,
            baseprice,
            promoinfo,
            producttag,
            searchkeys,
            similarpcodes,
            sortorder,
            recommendedpcodes,
            storeproductaddonids,
            taxcategory,
            storeid: storeid,
            status: status,
        };
        // if (this._isEditMode()) {
        body['id'] = this.state.id;
        //}
        const payload = {
            body: body,
        };
        return payload;
    };

    _createProductInformation = () => {
        if (this.validateForm()) {
            this.props.dispatch(createAddMapStore(this.getCreatePayload()));
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            storeiderror: '',
            productcodeerror: '',
            titleerror: '',
            sortordererror: '',
            tiererror: '',
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const { storeid, productcode, title, sortorder, tiers } = this.state;
        let valid = true;

        if (storeid.trim() === '') {
            this.setState({ storeiderror: 'StoreID is required.' });
            valid = false;
        }
        if (productcode.trim() === '') {
            this.setState({ productcodeerror: 'Product Code is required.' });
            valid = false;
        }
        if (title.trim() === '') {
            this.setState({ titleerror: 'Article Title is required.' });
            valid = false;
        }
        if (sortorder === '') {
            this.setState({ sortordererror: 'Sortorder is required.' });
            valid = false;
        }
        if (tiers && tiers.length === 0) {
            this.setState({ tiererror: 'Select a tier' });
            valid = false;
        }
        return valid;
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.clearAllFields();
    };

    clearAllFields() {
        this.setState({
            storeid: '',
            storelabel: '',
            productcode: '',
            title: '',
            qrcode: '',
            barcode: '',
            baseprice: '',
            promoinfo: '',
            producttag: '',
            searchkeys: '',
            similarpcodes: '',
            recommendedpcodes: '',
            storeproductaddonids: '',
            categorycode: '',
            status: 'Active',
            tiers: [],
            sortorder: '',
            // Errors
            productcodeerror: '',
            categorycodeerror: '',
            titleerror: '',
        });
    }

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _renderMainButton = () => {
        const checkprop = this.props.location.state;
        return (
            <React.Fragment>
                {
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => {
                            this._createProductInformation();
                        }}
                    >
                        Save
                    </button>
                }

                <Link
                    to={{
                        pathname: '/ui/map-store-product',
                        state: {
                            storeid: checkprop.storeid,
                            storelabel: checkprop.storelabel,
                            categories: checkprop.categories,
                            categorycode: checkprop.categorycode,
                            status: checkprop.status,
                        },
                    }}
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 });
    };

    componentWillMount = () => {
        this.props.dispatch(
            searchGeneral(
                '/sales/v1/category/search',
                {
                    body: {
                        search_field: 'catgroup',
                        search_condi: 'eq',
                        search_value: 'TAX',
                    },
                },
                'TAX_VALUE_SEARCH_SUCCESS',
                'TAX_VALUE_SEARCH_FAILURE'
            )
        );
    };

    fetchApi = () => {
        const URL = window?.config?.END_POINT;
        const authToken = localStorage.getItem("session");
        const myHeaders = new Headers({
          Authorization: authToken,
          "Content-Type": "application/json",
        });
    
        fetch(`${URL}cms/v1/prop/config/search/fields?propgroup=Segment`, {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.length > 0) {
              this.setState({
                tierOptions : result[0].additionalfields
              })
            }
          })
          .catch((error) => console.error(error));
      };

    _renderMainFormDetails = () => {
        const { storeiderror, titleerror, productcodeerror } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="storeid" className="floatLeft required">
                            Store
                        </label>
                        <StoreSearchSingle
                            value={this.state.storelabel}
                            onSelectStore={this._onSelectStore}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="productcode"
                            className="floatLeft required"
                        >
                            Product Code
                        </label>
                        <input
                            type="text"
                            defaultValue={this.state.productcode}
                            className="addoninput productcodemodal"
                            readOnly={true}
                            onClick={this._showProductModal}
                        />
                        <div className="invalid-feedback">
                            {productcodeerror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft required">
                            Title
                        </label>
                        <input
                            type="text"
                            className={
                                titleerror === ''
                                    ? 'form-control'
                                    : 'error-control'
                            }
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">{titleerror}</div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="categorycode" className="floatLeft">
                            Category Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="categorycode"
                            value={this.state.categorycode}
                            readOnly={true}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    _showProductModal = (event) => {
        this.setState({
            showProdModal: true,
        });
        if (event) event.preventDefault();
    };
    _closeProductModal = (event) => {
        this.setState({ showProdModal: false });
        if (event) event.preventDefault();
    };
    _updateProdCode = (param, param1, param2) => {
        this.setState(
            { productcode: param, title: param1, categorycode: param2 },
            () => this._closeProductModal()
        );
    };
    _renderProductModal = () => {
        return (
            <Modal
                visible={this.state.showProdModal}
                width="50%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeProductModal()}
            >
                <div className="suggestionForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-12 alignRight">
                            <a
                                onClick={this._closeProductModal}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row" style={{ height: '15em' }}>
                        <Autocomplete
                            updateTitle={this._updateProdCode}
                        ></Autocomplete>
                    </div>
                </div>
            </Modal>
        );
    };

    _renderGeneralInformation = () => {
        const { titleerror } = this.state;
        const { taxValue } = this.props.queryData;
        let taxvalues = [];
        taxvalues = renderTaxValues(taxValue);
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="barcode" className="floatLeft">
                            Barcode
                        </label>
                        <input
                            type="text"
                            id="barcode"
                            value={this.state.barcode}
                            onChange={(event) =>
                                this.setState({ barcode: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="barcode" className="floatLeft">
                            SKU
                        </label>
                        <input
                            type="text"
                            id="sku"
                            value={this.state.sku}
                            onChange={(event) =>
                                this.setState({ sku: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="qrcode" className="floatLeft">
                            QRcode
                        </label>
                        <input
                            type="text"
                            id="qrcode"
                            value={this.state.qrcode}
                            onChange={(event) =>
                                this.setState({ qrcode: event.target.value })
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="producttag" className="floatLeft">
                            Product Tag
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="producttag"
                            value={this.state.producttag}
                            onChange={(event) => {
                                this.setState({
                                    producttag: event.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="baseprice" className="floatLeft">
                            Base Price
                        </label>
                        <input
                            type="text"
                            id="baseprice"
                            value={this.state.baseprice}
                            onChange={(event) =>
                                this.setState({ baseprice: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="taxcategory" className="floatLeft">
                            Tax Value
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={taxvalues}
                            selectedOption={this.state.taxcategory}
                            callbackChangeFilter={(event) => {
                                this.setState({
                                    taxcategory: event.target.value,
                                });
                            }}
                            id="taxcategory"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label
                            htmlFor="recommendedpcodes"
                            className="floatLeft"
                        >
                            Recommended Products
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="recommendedpcodes"
                            value={this.state.recommendedpcodes}
                            onChange={(event) => {
                                this.setState({
                                    recommendedpcodes: event.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="searchkeys" className="floatLeft">
                            Search Keys
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="searchkeys"
                            value={this.state.searchkeys}
                            onChange={(event) => {
                                this.setState({
                                    searchkeys: event.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="similarpcodes" className="floatLeft">
                            Similar Product Codes
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="similarpcodes"
                            value={this.state.similarpcodes}
                            onChange={(event) => {
                                this.setState({
                                    similarpcodes: event.target.value,
                                });
                            }}
                        />
                    </div>
                </div>

                {/* <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="similarpcodes" className="floatLeft">
              Similar Product Code
            </label>
            <input
              type="text"
              id="similarpcodes"
              value={this.state.similarpcodes}
              onChange={event => this.setState({ similarpcodes: event.target.value })}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="recommendedpcodes" className="floatLeft">
              Recommended Product Code
            </label>
            <input
              type="text"
              id="recommendedpcodes"
              value={this.state.recommendedpcodes}
              onChange={event => this.setState({ recommendedpcodes: event.target.value })}
            />
          </div>
        </div> */}

                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="promoinfo" className="floatLeft">
                            Promo Info
                        </label>
                        <input
                            type="text"
                            id="promoinfo"
                            value={this.state.promoinfo}
                            onChange={(event) =>
                                this.setState({ promoinfo: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label
                            htmlFor="storeproductaddonid"
                            className="floatLeft"
                        >
                            Store Product Addon Id
                        </label>
                        <input
                            type="text"
                            id="storeproductaddonids"
                            value={this.state.storeproductaddonids}
                            onChange={(event) =>
                                this.setState({
                                    storeproductaddonids: event.target.value,
                                })
                            }
                        />
                    </div>

                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="tier" className="floatLeft required">
                            Tier Class
                        </label>
                        <MultiSelect
                            filterOptions={renderConfigInMultiSelect(
                                this.state.tierOptions
                            )}
                            setSelectedFieldValues={this.setTier}
                            id="tiers"
                            label="Select Tiers"
                            itemsSelected={this.state.tiers}
                        />
                        <div className="invalid-feedback">
                            {this.state.tiererror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label
                            htmlFor="sortorder"
                            className="floatLeft required"
                        >
                            Sort Order
                        </label>
                        <input
                            className="form-control"
                            id="sortorder"
                            type="number"
                            min="0"
                            value={this.state.sortorder}
                            onChange={(event) => {
                                this.setState({
                                    sortorder: event.target.value,
                                });
                            }}
                        />
                        <div className="invalid-feedback">
                            {this.state.sortordererror}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv">
                            {this._renderMainFormDetails()}
                        </form>
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderGeneralInformation()}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    render() {
        const { isSuccess } = this.props.crudData;
        const checkprops = this.props.location.state;
        const { categorycode, storeid, storelabel } = this.state;
        const categories =
            categorycode.length > 0 ? this.getCategoryDetail(categorycode) : '';
        const isEmpty = Object.values(checkprops).every(
            (x) => x === null || x === ''
        );
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                Map Product To Store
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderFormArea()}
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to={
                            typeof checkprops == 'undefined' ||
                            isEmpty == true ||
                            checkprops.categories !== categories
                                ? {
                                      pathname: '/ui/map-store-product',
                                      state: {
                                          storeid: storeid,
                                          storelabel: storelabel,
                                          categories: categories,
                                          categorycode: categorycode,
                                          status: 'New',
                                      },
                                  }
                                : {
                                      pathname: '/ui/map-store-product',
                                      state: {
                                          storeid: checkprops.storeid,
                                          storelabel: checkprops.storelabel,
                                          categories: checkprops.categories,
                                          categorycode: checkprops.categorycode,
                                          status: 'New',
                                      },
                                  }
                        }
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                Store Product Mapped successfully!
                            </div>
                        }
                    />
                </div>
                {this.state.showProdModal && this._renderProductModal()}
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddMapStore);

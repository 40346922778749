import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import {
    canDelete,
    canView,
    canWrite,
    permissions,
} from '../../../../helpers/Util';
import {
    searchGeneral,
    crudReset,
    deleteStoreTag,
    storeSearchByFields,
    resetAction,
    storeTagsSearchByFields,
    queryPropertyConfigByFields,
} from '../../../../actions/actions';
import * as CONSTANTS from '../../../../constants/constants';
import PrimeStoreSelector from '../../../../components/PrimeStoreSelector';
import BreadCrumbWrapper from '../../../../components/BreadCrumbWrapper';
import ConfirmAlert from '../../../../components/ConfirmAlert';

const items = [
    { label: 'Stores', path: '/ui/stores' },
    { label: 'Store Options', path: '/ui/storetenders' },
];

const tagTypeOptions = [
    { name: 'Select', id: '' },
    { name: 'Default Properties', id: 'storeprops' },
    { name: 'Delivery Type', id: 'deliverytype' },
    { name: 'Receipt Header', id: 'receiptheader' },
    { name: 'Receipt Footer', id: 'receiptfooter' },
    { name: 'Topup Receipt Header', id: 'topupreceiptheader' },
    { name: 'Topup Receipt Footer', id: 'topupreceiptfooter' },
    { name: 'Invoice Header', id: 'invoiceheader' },
    { name: 'Tax Report Header', id: 'taxreportheader' },
    { name: 'Topup Report Header', id: 'topupreportheader' },
    { name: 'Rabbit Point Rule', id: 'rabbitpointrule' },
    { name: 'NINE SAP Config', id: 'NINE_SAP_CONFIG' },
    { name: 'Tax Report Template', id: 'taxreporttemplate' },
    { name: 'Topup Report Template', id: 'topupreporttemplate' },
    { name: 'Splash/logo', id: 'Splash/logo' },
];

const statusOptions = [
    { name: 'Select', id: '' },
    { name: 'New', id: 'New' },
    { name: 'Active', id: 'Active' },
    { name: 'Deleted', id: 'Deleted' },
    { name: 'Inactive', id: 'Inactive' },
];

class StoreOptionList extends React.PureComponent {
    taggroup = 'storeprops';
    alltagtypes = tagTypeOptions
        .map((t) => t.id)
        .filter((s) => s !== '')
        .join(',');
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.group = 'DE_STOREMGMT_STORE_OPTIONS';
        this.module = 'STOREMGMT';
        this.allPermissions = [];

        this.requestBody = {}; //current one
        this.currPage = 1;
        this.pageSize = 10;

        this.state = {
            currPage: this.currPage,
            pageSize: this.pageSize,
            showSearchModal: false,
            query: {},
            openDeleteprompt: false,
            delete_rec_id: '',
            message: '',
            action: '',

            storeid: '',
            storelabel: '',
            terminalid: '',
            tagtype: '',
            status: '',
            selectedStore: {},
            storesList: [],

            //errors
            storeiderror: '',

            //search box filter
            globalFilterValue: '',
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
        };
    }

    cellFormatter = (row) => {
        if (row.status === 'Deleted') {
            return;
        }

        return (
            <span>
                {canView(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/storeoptions/addNew',
                            state: {
                                item: row,
                                isEdit: false,
                                isView: true,
                            },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                        />
                    </Link>
                )}

                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/storeoptions/addNew',
                            state: {
                                item: row,
                                isEdit: true,
                            },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                        />
                    </Link>
                )}

                {canDelete(this.allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this._deleteTerminal(row)}
                    />
                )}
            </span>
        );
    };

    // API Calls
    createFetchUrl = (fetchType) => {
        const { pageSize, currPage, storeid, tagtype } = this.state;
        const requestBody = {
            body: {
                taggroup: this.taggroup,
                tagtype: tagtype === '' ? this.alltagtypes : tagtype,
                storeid: storeid,
                pagesize: pageSize,
                pageno: currPage,
            },
        };
        this.requestBody['storeid'] = storeid;
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                'system/v1/store/tag/search/fields',
                this.createFetchUrl(fetchType),
                CONSTANTS.SEARCH_STORE_TAGS_SUCCESS,
                CONSTANTS.SEARCH_STORE_TAGS_FAILURE,
            ),
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
        }
    };

    checkStoreIdSelected = () => {
        const storeid = localStorage.getItem('storeid');
        const storelabel = localStorage.getItem('storelabel');
        if (storeid && storeid !== '' && storelabel && storelabel !== '') {
            this.callQueryApiForFetch('ONLOAD');
        } else {
            this.setState({ storeiderror: 'Select store' });
        }
    };

    componentDidUpdate = (prevProps) => {
        const { stores, masterTrxGrpTypes } = this.props.queryData;
        const { stores: prevStores, masterTrxGrpTypes: prevOptions } =
            prevProps.queryData;

        let filteredStores = [];

        if (stores !== prevStores) {
            if (stores && stores.length > 0) {
                const options = stores.map((e) => ({
                    label: '(' + e.storeid + ') ' + e.title,
                    value: e.storeid,
                }));
                this.setState({ storesList: options });
                if (this.state.storeid != '') {
                    const _selectedStores = stores.filter(
                        (e) => e.storeid == this.state.storeid,
                    );
                    if (_selectedStores && _selectedStores.length > 0) {
                        const selectedStore = _selectedStores.map((e) => ({
                            label: '(' + e.storeid + ') ' + e.title,
                            value: e.storeid,
                        }));
                        this.setState({ selectedStore: selectedStore });
                    }
                }
            } else {
                this.setState({ storesList: [], selectedStore: {} });
            }
        }

        if (
            masterTrxGrpTypes !== prevOptions &&
            masterTrxGrpTypes &&
            masterTrxGrpTypes.length > 0 &&
            masterTrxGrpTypes[0].additionalfields
        ) {
            const output = Object.entries(
                masterTrxGrpTypes[0].additionalfields,
            ).map(([name, id]) => ({ name, id }));

            // Add the default "Select" option
            output.unshift({ name: 'Select', id: '' });
            this.setState({ tagTypeOptions: output });
        }
    };

    componentWillMount() {
        this.props.queryData.storeTags = [];
        this._searchStores();
        const storeid = localStorage.getItem('storeid');
        const storelabel = localStorage.getItem('storelabel');
        if (storeid && storeid !== '' && storelabel && storelabel !== '') {
            this.setState({ storeid: storeid, storelabel: storelabel }, () =>
                this.callQueryApiForFetch('ONLOAD'),
            );
        } else {
            this.state.storeiderror = 'Select store';
        }
        this.allPermissions = permissions(this.module, this.group);
        this.props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propcode: 'STORE_OPTION_TAG_TYPES',
                    },
                },
                CONSTANTS.SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES,
                CONSTANTS.SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_SUCCESS,
                CONSTANTS.SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_FAILURE,
            ),
        );
    }

    _searchStores = () => {
        this.props.dispatch(storeSearchByFields(`companyid=${this.companyId}`));
    };

    _renderAddNewBtn = () => {
        const { history } = this.props;
        if (!canWrite(this.allPermissions)) {
            return;
        }

        return (
            <Button
                label="Add Store Config"
                icon="pi pi-plus"
                className="p-button-outlined"
                disabled={!this.state.storeid}
                severity="info"
                onClick={() => history.push('/ui/storeoptions/addNew')}
            />
        );
    };

    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(deleteStoreTag(this.state.delete_rec_id));
        }
    };

    hideAlertModal = (e) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };

    hideErrorAlertModal = (e) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
        this._searchStores();
    };

    _deleteTerminal = (rowData) => {
        this.setState({ message: 'Config deleted', action: 'Delete' });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };

    _handleStoreSelection = (selected) => {
        this.setState({ selectedStore: selected });
        if (selected.label.indexOf('(') !== -1) {
            const storeid = selected.value; // selected.substring(0, selected.indexOf('-'))
            const storeLabel = selected.label.substring(
                selected.label.indexOf('-1') + selected.value.length + 4,
                selected.label.length,
            );
            this._setStore(storeid, storeLabel);
        }
    };

    columns = [
        { field: 'storeid', header: 'Store Id' },
        { field: 'title', header: 'Title' },
        { field: 'taggroup', header: 'Tag Group' },
        { field: 'tagtype', header: 'Tag Type' },
        { field: 'tagtypevalue', header: 'Tag Type Value' },
        { field: 'status', header: 'Status' },
    ];

    handlePrevPage = () => {
        const { currPage } = this.state;
        this.setState({ currPage: currPage - 1 }, () => {
            this.callQueryApiForFetch('PAGECLICK');
        });
    };

    handleNextPage = () => {
        const { currPage } = this.state;
        this.setState({ currPage: currPage + 1 }, () => {
            this.callQueryApiForFetch('PAGECLICK');
        });
    };

    handlePageSizeChange = (e) => {
        this.setState({ pageSize: e.target.value }, () => {
            this.callQueryApiForFetch('PAGECLICK');
        });
    };

    renderPaginationTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
            ];

            return (
                <React.Fragment>
                    <span
                        className="mx-1"
                        style={{
                            color: 'var(--text-color)',
                            userSelect: 'none',
                        }}
                    >
                        Items per page:{' '}
                    </span>
                    <Dropdown
                        value={this.state.pageSize}
                        options={dropdownOptions}
                        onChange={(e) => this.handlePageSizeChange(e)}
                    />
                </React.Fragment>
            );
        },
        CurrentPageReport: () => {
            const { storeTags } = this.props.queryData;

            return (
                <div>
                    <button
                        type="button"
                        className={classNames({
                            'p-paginator-prev p-paginator-element p-link': true,
                            'p-disabled': this.state.currPage == 1,
                        })}
                        disabled={this.state.currPage == 1}
                        aria-label="Previous Page"
                        onClick={this.handlePrevPage}
                    >
                        <span className="p-paginator-icon pi pi-angle-left"></span>
                        <span role="presentation" className="p-ink"></span>
                    </button>
                    <button
                        type="button"
                        className={classNames({
                            'p-paginator-prev p-paginator-element p-link': true,
                            'p-disabled':
                                storeTags &&
                                storeTags.length < this.state.pageSize,
                        })}
                        aria-label="Next Page"
                        onClick={this.handleNextPage}
                    >
                        <span className="p-paginator-icon pi pi-angle-right"></span>
                        <span role="presentation" className="p-ink"></span>
                    </button>
                </div>
            );
        },
    };

    onCustomPage = (event) => {
        // setFirst(event.first);
        // setRows(event.rows);
    };

    renderSearchHeader = () => {
        const { globalFilterValue } = this.state;
        return (
            <div
                className="flex"
                style={{ justifyContent: 'flex-end', marginBottom: '20px' }}
            >
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            value={globalFilterValue}
                            onChange={this.onGlobalFilterChange}
                            placeholder="Search"
                        />
                    </span>
                </div>
            </div>
        );
    };

    onGlobalFilterChange = (e) => {
        const value = e.target.value;
        const { filters } = this.state;
        let _filters = { ...filters };
        _filters['global'].value = value;
        this.setState({ filters: _filters, globalFilterValue: value });
    };

    render() {
        const {
            errormsg: queryError,
            servererror: serverError,
            storeTags,
        } = this.props.queryData;

        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-5 col-sm-12">
                            <h2 className="page-header">
                                Store Options
                                <img
                                    title="Refresh"
                                    onClick={() => {
                                        this.checkStoreIdSelected();
                                    }}
                                    className="refreshtree"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                    alt="RefreshButton"
                                />
                            </h2>
                        </div>
                        <div className="form-group col-lg-3 col-sm-12 alignWithPageHeader">
                            <PrimeStoreSelector
                                storeList={this.state.storesList}
                                handleStoreSelection={
                                    this._handleStoreSelection
                                }
                                selectedStore={this.state.selectedStore}
                                required
                            />
                        </div>
                        <div className="col-lg-4 col-sm-12 alignRight alignWithPageHeader">
                            {this._renderAddNewBtn()}
                        </div>
                    </div>

                    <div className="row form-container">
                        <div className="col">
                            <div
                                className="frmDiv"
                                style={{ paddingTop: '10px' }}
                            >
                                <BreadCrumbWrapper items={items} />
                                <StoreOptionFilterForm
                                    handleSearch={this._searchStoreOptions}
                                    handleClear={this._clearFiltersAndResults}
                                    typeOptions={this.state.tagTypeOptions}
                                />
                                <div className="title_devide" />
                                {this.renderSearchHeader()}
                                <DataTable
                                    value={storeTags}
                                    showGridlines
                                    columnResizeMode="fit"
                                    responsiveLayout="scroll"
                                    paginatorClassName="justify-content-end"
                                    paginatorTemplate={
                                        this.renderPaginationTemplate
                                    }
                                    paginator
                                    first={0}
                                    rows={10}
                                    onPage={this.onCustomPage}
                                    emptyMessage="Your search does not retrieve any data. Please search again using another criteria."
                                    filters={this.state.filters}
                                >
                                    {this.columns.map((column) => (
                                        <Column
                                            key={column.field}
                                            field={column.field}
                                            header={column.header}
                                            sortable
                                        />
                                    ))}
                                    <Column
                                        header="Actions"
                                        body={this.cellFormatter}
                                    />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {isSuccess ? this.state.message : ''}
                            </div>
                        }
                    />

                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div className="deleteAlertDiv">
                                Are you sure you want to delete this config?
                                <div style={{ margin: '2em' }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec deletBtn"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded deletBtn"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />

                    <ConfirmAlert
                        show={errormsg}
                        handleClose={(event) => this.hideErrorAlertModal(event)}
                        children={
                            <div
                                style={
                                    errormsg
                                        ? {
                                              padding: '2em',
                                              color: 'red',
                                              fontWeight: '500',
                                          }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {errormsg ? `${errormsg}.` : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }

    _searchStoreOptions = (data) => {
        const { storeid, terminalid } = this.state;

        const { status, tagtype } = data;

        this.requestBody = {};
        const queryParams = [];
        if (storeid !== '') {
            queryParams.push(`storeid=${storeid}`);
            this.setState({ storeiderror: '' });
            this.requestBody['storeid'] = storeid;
        } else {
            this.setState({ storeiderror: 'Select store' });
            return;
        }

        if (terminalid !== '') {
            queryParams.push(`terminalid=${terminalid}`);
            this.requestBody['terminalid'] = terminalid;
        }

        if (tagtype !== '') {
            queryParams.push(`tagtype=${tagtype}`);
            this.requestBody['tagtype'] = tagtype;
        } else {
            queryParams.push(`tagtype=${this.alltagtypes}`);
            this.requestBody['tagtype'] = this.alltagtypes;
        }

        if (status !== '') {
            queryParams.push(`status=${status}`);
            this.requestBody['status'] = status;
        }

        //validate the filters
        if (queryParams.length <= 0) {
            return;
        }

        queryParams.push(`taggroup=${this.taggroup}`);
        queryParams.push(`pagesize=${this.pageSize}`);
        queryParams.push(`pageno=${this.currPage}`);
        //query
        this.props.dispatch(storeTagsSearchByFields(queryParams.join('&')));
    };

    _clearFiltersAndResults = () => {
        //clear the search results
        const queryParams = [];
        const { storeid } = this.state;
        if (storeid !== '') {
            queryParams.push(`storeid=${storeid}`);
            this.setState({ storeiderror: '' });
            this.requestBody['storeid'] = storeid;
        } else {
            this.setState({ storeiderror: 'Select store' });
            return;
        }
        queryParams.push(`taggroup=${this.taggroup}`);
        queryParams.push(`tagtype=${this.alltagtypes}`);
        queryParams.push(`pagesize=${this.pageSize}`);
        queryParams.push(`pageno=${this.currPage}`);
        this.props.dispatch(storeTagsSearchByFields(queryParams.join('&')));
    };

    _setStore = (p1, p2) => {
        this.setState(
            { storeid: p1, storelabel: p2, search_value: p1, storeiderror: '' },
            () => {
                localStorage.setItem('storeid', p1);
                localStorage.setItem('storelabel', p2);
            },
        );
    };
}

const StoreOptionFilterForm = (props) => {
    const { handleSearch, handleClear, typeOptions } = props;
    const formik = useFormik({
        initialValues: {
            tagtype: '',
            status: '',
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                tagtype: values.tagtype ? values.tagtype.id : '',
                status: values.status ? values.status.id : '',
            };
            handleSearch(data);
        },
    });
    const handleReset = () => {
        formik.resetForm();
        handleClear();
    };
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="row p-fluid formgrid grid w-100">
                <div className="p-form-group field col-3 md:col-3 mb-2">
                    <label htmlFor="storeid">Tag Type</label>
                    <Dropdown
                        id="tagtype"
                        name="tagtype"
                        value={formik.values.tagtype}
                        onChange={formik.handleChange}
                        options={typeOptions}
                        optionLabel="name"
                        placeholder="Select"
                    />
                </div>
                <div className="p-form-group field col-3 md:col-3 mb-2">
                    <label htmlFor="storeid">Status</label>
                    <Dropdown
                        id="status"
                        name="status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        options={statusOptions}
                        optionLabel="name"
                        placeholder="Select"
                    />
                </div>
                <div
                    className="field col-3 md:col-3 mb-2"
                    style={{ display: 'flex' }}
                >
                    <div style={{ marginTop: '28px', display: 'flex' }}>
                        <Button
                            type="submit"
                            label="Search"
                            className="mr-4"
                        />
                        <Button
                            type="reset"
                            label="Clear"
                            className="p-button-secondary"
                            onClick={handleReset}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(StoreOptionList);

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { searchGeneral, loadingApi, storeSearchByFields } from '../../actions/actions';
import * as CONSTANTS from '../../constants/constants';
import PrimeTable from '../../components/PrimeTable';
import PrimeStoreSelector from '../../components/PrimeStoreSelector';
import RefreshIcon from '../../components/ImageComponents/RefreshIcon';

const statusSelectConfigOptions = [
    { name: 'Select', code: '' },
    { name: 'Active', code: 'Active' },
    { name: 'Loaded', code: 'Loaded' },
    { name: 'Paid', code: 'Paid' },
  ];

const invoiceTypeOptions = [
{ name: 'Select', code: '' },
{ name: 'B2B', code: 'b2b' },
{ name: 'Direct', code: 'direct' }
];

const CollectionInvoiceList = (props) => {
    const { eipMessages, errormsg, apiPending, stores } = props.queryData;
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [date_from, setDateFrom] = useState(new Date());
    const [filterData, setFilterData] = useState();
    const [loading, setLoading] = useState(true);
    const [eipMessageList, setEipMessageList] = useState([]);
    const [storeList, setStoreList] = useState([]);

    const companyId = localStorage.getItem('company_id');

    useEffect(() => {
        props.dispatch(storeSearchByFields(`companyid=${companyId}`));
    }, []);
    

    useEffect(() => {
        if (eipMessages.length > 0) {
            setEipMessageList(eipMessages);
        }
    }, [eipMessages]);

    useEffect(() => {
        const options = stores.map((e) => ({
            label: '(' + e.storeid + ') ' + e.title,
            value: e.storeid,
        }));
        setStoreList(options);
    }, [stores]);

    useEffect(() => {
        if (errormsg) {
            setEipMessageList([]);
        }
    }, [errormsg]);

    useEffect(() => {
        if (apiPending) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [apiPending]);

    const callApi = (params) => {
        //setLoading(true)

        props.dispatch(loadingApi());
        props.dispatch(
            searchGeneral(
                'ext-ns/v1/collection/invoice',
                params,
                CONSTANTS.SEARCH_EIP_MESSAGE_SUCCESS,
                CONSTANTS.SEARCH_EIP_MESSAGE_FAILURE
            )
        );
    };

    const initLoad = () => {
        const requestBody = {
            body: {
                pagesize: pageSize,
                pageno: currentPage,
            },
        };
        callApi(requestBody)
    }

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        const params = getParsedValue(size, currentPage);
        callApi(params);
    };

    const handlePageChange = (pageNo) => {
        setCurrentPage(pageNo);
        const params = getParsedValue(pageSize, pageNo);
        callApi(params);
    };

    const getParsedValue = (pageSize, pageNo) => {
        const parsedValues = {
            ...filterData,
            date_from: filterData.date_from
                ? moment(filterData.date_from).format('DD-MM-YYYY')
                : '',
            date_till: filterData.date_till
                ? moment(filterData.date_till).format('DD-MM-YYYY')
                : '',
            msgsend: filterData.msgsend ? filterData.msgsend : '',
            status: filterData.status ? filterData.status.code : '',
        };
        Object.keys(parsedValues).forEach((key) => {
            if (parsedValues[key] === '') {
                delete parsedValues[key];
            }
        });
        const requestBody = {
            body: {
                ...parsedValues,
                pagesize: pageSize,
                pageno: pageNo,
            },
        };
        return requestBody;
    };

    const clearEmptyParam = (data) => {
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });
    };

    const handleSearch = (data) => {
        setLoading(true);
        setDateFrom(data.date_from);

        if (data.date_till) {
            setDateFrom(data.date_till);
        }
        clearEmptyParam(data);
        setFilterData(data); //set search data into filter data
        const parsedValues = {
            ...data,
            status: data.status ? data.status.code : '',
            invoicetype: data.invoicetype ? data.invoicetype.code : '',
            invoicedate: data.invoicedate
                ? moment(data.invoicedate).format('DD-MM-YYYY')
                : '',
            date_from: data.date_from
                ? moment(data.date_from).format('DD-MM-YYYY')
                : '',
            date_till: data.date_till
                ? moment(data.date_till).format('DD-MM-YYYY')
                : '',
        };
        clearEmptyParam(parsedValues);
        const requestBody = {
            body: {
                ...parsedValues,
                pagesize: pageSize,
                pageno: currentPage,
            },
        };
        callApi(requestBody);
    };

    const actionTemplate = (row) => {
        return (
            <div className="flex">
                <Link
                    to={{
                        pathname: '/ui/sales-invoice/detail',
                        state: {
                            item: row,
                            isView: true,
                        },
                    }}
                >
                    <img
                        title="View"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                    />
                </Link>
                
            </div>
        );
    };

    const customerTemplate = (row) => {
        const { customerid, customername } = row;
        return `(${customerid}) ${customername}`
    };

    const columns = [
        { field: 'storeid', header: 'Store Id', sortable: true },
        { field: 'invoicetype', header: 'Invoice Type' },
        { field: 'invoiceid', header: 'Invoice Id', sortable: true },
        { field: 'customername', header: 'Customer', template: customerTemplate }
    ];
    
    const renderListView = () => {
        return (
            <>
                <div className="form_height px-4">
                    <div className="grid justify-content-center mb-4">
                        <div className="col-9">
                            <h2 className="page-header">
                                Sales Invoices
                                <RefreshIcon handleClick={initLoad} />
                            </h2>
                        </div>
                        <div className="col-3 alignWithPageHeader" style={{ textAlign: 'right'}}>
                            {/* {renderAddNewBtn()} */}
                        </div>
                    </div>
                    <div className="frmDiv">
                        <FilterForm
                            handleSearch={handleSearch}
                            data={filterData}
                            storeList={storeList}
                        />
                        <PrimeTable
                            list={eipMessageList}
                            columns={columns}
                            actionColumn={actionTemplate}
                            pageSizeChangecallback={handlePageSizeChange}
                            pageChangeCallback={handlePageChange}
                        >
                            <Column
                                field="invoicedate"
                                header="Invoice Date"
                            />
                            <Column
                                field="invoiceamount"
                                header="Total Amount"
                            />
                            <Column
                                field="paymentamount"
                                header="Paid Amount"
                            />
                             <Column
                                field="status"
                                header="Status"
                            />
                            <Column
                                field="lastupdated"
                                header="Last Updated"
                            />
                        </PrimeTable>
                    </div>
                </div>
            </>
        );
    };

    return <>{renderListView()}</>;
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

const FilterForm = (props) => {
    const { handleSearch, data } = props;
    const [storeid, setStoreid] = useState(localStorage.getItem('storeid'));
    const [selectedStore, setSelectedStore] = useState({});
    const initialValues = {
        invoiceid: '',
        status: statusSelectConfigOptions.find((status) => status.code == 'active'),
        date_from: new Date(),
        date_till: new Date(),
        date_type: 'lastupdated',
    };
    const formik = useFormik({
        initialValues: {
            invoiceid: data && data.invoiceid ? data.invoiceid : '',
            status: statusSelectConfigOptions.find((status) => status.code == 'active'),
            invoicedate: new Date(),
            date_from: new Date(),
            date_till: new Date(),
            date_type: 'lastupdated',
            invoicetype: ''
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                storeid: storeid,
            };
            const filteredData = Object.fromEntries(
                Object.entries(data).filter(
                    ([_, value]) =>
                        value !== undefined && value !== '' && value !== null
                )
            );
            handleSearch(filteredData);
        },
    });

    const handleStoreSelection = (selectedStore) => {
        setStoreid(selectedStore.value);
        setSelectedStore(selectedStore);
    };

    const renderFormArea = () => {
        return (
            <>
                <div className="p-fluid formgrid grid w-100 mb-4">
                    <div className="field col-3">
                        <label htmlFor="storeid">Store Id</label>
                        <PrimeStoreSelector
                            storeList={props.storeList}
                            handleStoreSelection={handleStoreSelection}
                            selectedStore={selectedStore}
                            required={false}
                        />
                    </div>
                    {/* <div className="field col-3">
                        <label htmlFor="invoicedate">Invoice Date</label>
                        <Calendar
                            id="invoicedate"
                            value={formik.values.invoicedate}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                        />
                    </div> */}
                    <div className="field col-3">
                        <label htmlFor="date_from">Date From</label>
                        <Calendar
                            id="date_from"
                            value={formik.values.date_from}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            maxDate={formik.values.date_till}
                        />
                    </div>
                    <div className="field col-3">
                        <label htmlFor="date_till">Date To</label>
                        <Calendar
                            id="date_till"
                            value={formik.values.date_till}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            minDate={formik.values.date_from}
                        />
                    </div>
                    <div className="field col-3">
                        <label htmlFor="invoiceid">Invoice Id</label>
                        <InputText
                            id="invoiceid"
                            type="text"
                            name="invoiceid"
                            value={formik.values.invoiceid}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="status">Invoice Type</label>
                        <Dropdown
                            id="invoicetype"
                            name="invoicetype"
                            value={formik.values.invoicetype}
                            onChange={formik.handleChange}
                            options={invoiceTypeOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="status">Status</label>
                        <Dropdown
                            id="status"
                            name="status"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            options={statusSelectConfigOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div
                        className="field col-3 md:col-3"
                        style={{ display: 'flex', alignItems: 'flex-end' }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Button
                                type="submit"
                                label="Search"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

export default connect(mapStateToProps)(CollectionInvoiceList);

import React, { useState, useEffect } from "react";
import * as Yup from "yup"; // For validation
import axios from "axios";
import moment from "moment";
import ReactJson from "react-json-view";
import { connect } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";
import { Tag } from 'primereact/tag';
import {
    searchGeneral,
    sendEipMessage,
    loadingApi,
} from "../../../actions/actions";
import * as CONSTANTS from "../../../constants/constants";
import ViewEipMessage from "./ViewEipMessage";
import ExportEipMessage from "./ExportEipMessage";
import PrimeTable from "../../../components/PrimeTable";

const IntegrationLog = (props) => {
    const { eipMessages, errormsg, apiPending } = props.queryData;
    const [pageSize, setPageSize] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [date_from, setDateFrom] = useState(new Date());
    const [filterData, setFilterData] = useState();
    const [showMessageDetail, setShowMessageDetail] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [loading, setLoading] = useState(true);
    const [logList, setLogList] = useState([]);
    const [showExportReport, setShowExportReport] = useState(false);

    useEffect(() => {
        if (eipMessages.length > 0) {
            setLogList(eipMessages);
        }
    }, [eipMessages]);

    useEffect(() => {
        if (errormsg) {
            setLogList([]);
        }
    }, [errormsg]);

    useEffect(() => {
        if (apiPending) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [apiPending]);

    const handleReset = () => {
        setLogList([])
    }

    const getData = (params) => {
        const defaultParams = {
            page: currentPage,
            size: pageSize,
        };

        const finalParams = { ...defaultParams, ...params }; // Merge defaults with passed params
        const queryParams = new URLSearchParams(finalParams).toString();
        let config = {
            method: "get",
            url: `http://localhost:9090/ext-apilogger/filter/allrecords?${queryParams}`,
            headers: {
                Authorization: "7550935cd2bc97f0307afb2aa204e245",
            },
        };

        axios
            .request(config)
            .then((response) => {
                setLogList(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const clearEmptyParam = (data) => {
        Object.keys(data).forEach((key) => {
            if (data[key] === "") {
                delete data[key];
            }
        });
    };

    const handleSearch = (data) => {
        setLoading(true);
        clearEmptyParam(data);
        setFilterData(data); //set search data into filter data
        const parsedValues = {
            ...data
        };
        clearEmptyParam(parsedValues);
        const requestBody = {
            ...parsedValues,
        };
        getData(requestBody);
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="flex">
                <img
                    title="View"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                    onClick={() => viewMessageDetails(rowData)}
                />
            </div>
        );
    };

    const viewMessageDetails = (rowData) => {
        setShowMessageDetail(true);
        setSelectedData(rowData);
    };

    const hideLogDetails = () => {
        setShowMessageDetail(false);
        setSelectedData({});
    };

    const renderView = () => {
        if (showMessageDetail) {
            return (
                <ViewExtApiLog
                    hideLogDetails={hideLogDetails}
                    record={selectedData}
                />
            );
        }

        if (showExportReport) {
            return <ExportEipMessage hideExport={hideExport} />;
        }

        return renderListView();
    };

    const hideExport = () => setShowExportReport(false);

    const pageSizeChangecallback = () => {};

    const pageChangeCallback = () => {};

    const customerTemplate = (row) => {
        const { status } = row;
        return `${status}`
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag className="text-base" value={rowData.status} severity={getSeverity(rowData.status)} />;
    };

    const columns = [
        { field: "id", header: "Id" },
        { field: "type", header: "Type" },
        { field: "subType", header: "Sub Type" },
        { field: "url", header: "Url" },
        { field: "storeId", header: "Store Id" },
        { field: 'statusTemplate', header: 'Status', template: statusBodyTemplate }
    ];

    const getSeverity = (str) => {
        let status = str.toLowerCase()
        switch (status) {
            case 'error':
                return 'danger';

            case 'success':
                return 'success';

            case 'new':
                return 'info';

            case 'failed':
                return 'warning';

            case 'fail':
                return 'warning';
            break;
            default:
                return "info"
        }
    };


    const renderListView = () => {
        return (
            <>
                <div className="form_height">
                    <div className="flex px-4 mt-4">
                        <h2 className="text-3xl font-normal c-brown">
                            Integration Logs
                        </h2>
                    </div>
                    <div className="p-fluid formgrid grid br-2 bg-white p-4 border-round-xl shadow-1 m-4">
                        <FilterForm
                            handleSearch={handleSearch}
                            data={filterData}
                            handleReset={handleReset}
                        />
                        <PrimeTable
                            list={logList}
                            columns={columns}
                            actionColumn={actionTemplate}
                            pageSizeChangecallback={pageSizeChangecallback}
                            pageChangeCallback={pageChangeCallback}
                            tablePageSize={50}
                        />
                    </div>
                </div>
            </>
        );
    };

    return <>{renderView()}</>;
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

const FilterForm = ({ handleSearch, data, handleReset }) => {
    const [logTypes, setLogTypes] = useState([]);
    const [logSubTypes, setLogSubTypes] = useState([]);
    // Formik setup with validation schema
    const formik = useFormik({
        initialValues: {
            type: "",
            subtype: "",
            date_from: "",
            date_till: "",
        },
        validationSchema: Yup.object({
            type: Yup.object().required("Type is required"),
        }),
        onSubmit: (values) => {
            const data = {
                type: values?.type?.code,
                subtype: values?.subtype?.code || "",
                date_from: values?.date_from
                    ? moment(values.date_from).format("DD-MM-YYYY")
                    : "",
                date_till: values?.date_till
                    ? moment(values.date_till).format("DD-MM-YYYY")
                    : "",
            };
            handleSearch(data);
        },
    });

    useEffect(() => {
        fetchLogTypes();
    }, []);

    useEffect(() => {
        if (data?.type) {
            formik.setFieldValue(
                "type",
                logTypes.find((log) => log.code == data.type),
            );
        }
    }, [logTypes, data]);

    // Fetch log types from API
    const fetchLogTypes = async () => {
        try {
            const config = {
                method: "get",
                url: "http://localhost:9090/ext-apilogger/types",
                headers: {
                    Authorization:
                        "kuswna0s52b2c3e25809bfe4aef80064d627440c33ec02db23167c1a0a896be0a82b01209ylr630y",
                },
            };
            const response = await axios.request(config);
            if (response.data.length > 0) {
                setLogTypes(transformArray(response.data));
            }
        } catch (error) {
            console.error("Error fetching log types:", error);
        }
    };

    const handleChangeType = (e) => {
        formik.setFieldValue("type", e.value);
        fetchLogSubTypes(e.value);
    };

    const fetchLogSubTypes = async (type) => {
        try {
            const config = {
                method: "get",
                url: `http://localhost:9090/ext-apilogger/subtypes?type=${type.code}`,
                headers: {
                    Authorization:
                        "kuswna0s52b2c3e25809bfe4aef80064d627440c33ec02db23167c1a0a896be0a82b01209ylr630y",
                },
            };
            const response = await axios.request(config);
            if (response.data.length > 0) {
                setLogSubTypes([
                    { name: "Select", code: "" },
                    ...transformArray(response.data),
                ]);
            }
        } catch (error) {
            console.error("Error fetching log types:", error);
        }
    };

    const transformArray = (inputArray) =>
        inputArray.map((item) => ({ name: item, code: item }));

    const handleClear = () => {
        formik.resetForm();
        handleReset()
    };

    const renderFormArea = () => (
        <div className="p-fluid formgrid grid w-100 mb-4">
            <div className="field col-3 md:col-3">
                <label htmlFor="type">Type</label>
                <Dropdown
                    id="type"
                    name="type"
                    value={formik.values.type}
                    onChange={handleChangeType}
                    options={logTypes}
                    optionLabel="name"
                    placeholder="Select"
                />
                {formik.touched.type && formik.errors.type && (
                    <small className="p-error">{formik.errors.type}</small>
                )}
            </div>
            <div className="field col-3 md:col-3">
                <label htmlFor="subType">Sub Type</label>
                <Dropdown
                    id="subtype"
                    name="subtype"
                    value={formik.values.subtype}
                    onChange={formik.handleChange}
                    options={logSubTypes}
                    optionLabel="name"
                    placeholder="Select"
                    disabled={!formik.values.type}
                />
            </div>
            <div className="field col-3 md:col-3">
                <label htmlFor="date_from">Date From</label>
                <Calendar
                    id="date_from"
                    value={formik.values.date_from}
                    onChange={formik.handleChange}
                    dateFormat="dd-mm-yy"
                />
            </div>
            <div className="field col-3 md:col-3">
                <label htmlFor="date_till">Date Till</label>
                <Calendar
                    id="date_till"
                    value={formik.values.date_till}
                    onChange={formik.handleChange}
                    dateFormat="dd-mm-yy"
                    minDate={formik.values.date_from}
                />
            </div>
            <div className="flex field ml-3">
                <Button
                    type="submit"
                    label="Search"
                    className="mr-4"
                />
                <Button
                    type="button"
                    label="Clear"
                    severity="secondary"
                    className="mr-4"
                    onClick={handleClear}
                />
            </div>
        </div>
    );

    return (
        <form onSubmit={formik.handleSubmit} className="w-100">
            {renderFormArea()}
        </form>
    );
};

export default connect(mapStateToProps)(IntegrationLog);

const ViewExtApiLog = (props) => {
    const { record, hideLogDetails } = props;
    const renderMainButton = () => {
        return (
            <>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    onClick={() => {
                        hideLogDetails();
                    }}
                >
                    Cancel
                </button>
            </>
        );
    };

    return (
        <>
            <div className="form_height">
                <div className="flex px-4 mt-4">
                    <h2 className="text-3xl font-normal c-brown">
                        Integration Log Detail
                    </h2>
                </div>

                <div className="grid px-3">
                    <div className="col-9">
                        <div className="p-fluid formgrid grid br-2 bg-white p-4 border-round-xl shadow-1 mt-2">
                            <div className="grid w-full">
                                <div className="col-4">
                                    <label className="floatLeft">Type</label>
                                    <InputText
                                        id="type"
                                        value={record.type}
                                        readOnly
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <label className="floatLeft">
                                        Sub Type
                                    </label>
                                    <InputText
                                        id="subType"
                                        value={record.subType}
                                        readOnly
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <label className="floatLeft">Url</label>
                                    <InputText
                                        id="url"
                                        value={record.url}
                                        readOnly
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <label className="floatLeft">
                                        Store Id
                                    </label>
                                    <InputText
                                        id="url"
                                        value={record?.storeId || ""}
                                        readOnly
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <label className="floatLeft">
                                        Terminal Id
                                    </label>
                                    <InputText
                                        id="url"
                                        value={record?.terminalId || ""}
                                        readOnly
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <label className="floatLeft">
                                        Order Id
                                    </label>
                                    <InputText
                                        id="url"
                                        value={record?.orderId || ""}
                                        readOnly
                                    />
                                </div>
                                <div className="col-12 mb-4">
                                    <label className="">Request</label>
                                    <JsonViewer
                                        data={record.request}
                                        collapsed={false}
                                    />
                                </div>
                                <div className="col-12 mb-4">
                                    <label className="">Response</label>
                                    <JsonViewer
                                        data={record.response}
                                        collapsed={true}
                                    />
                                </div>
                            </div>
                            <div className="gird w-full"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row btn-container form-button">
                <div className="col-sm-12 col-lg-12">{renderMainButton()}</div>
            </div>
        </>
    );
};

const JsonViewer = (props) => {
    const { data, collapsed } = props;
    const isValidJsonString = (str) => {
        try {
            JSON.parse(str);
            return true;
        } catch (error) {
            return false;
        }
    };
    return (
        <>
            {isValidJsonString(data) && (
                <div className="json-view">
                    <ReactJson
                        src={JSON.parse(data)}
                        name={false}
                        iconStyle="square"
                        collapsed={collapsed}
                        collapseStringsAfterLength={true}
                        enableClipboard={false}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        theme="summerfruit:inverted"
                    />
                </div>
            )}
            {!isValidJsonString(data) && (
                <InputText id="subType" value={data} readOnly />
            )}
        </>
    );
};

import React from "react";
import { connect } from "react-redux";
import {
    updateCouponRedemptionRule,
    createCouponRedemptionRule,
    queryCategoryTree,
    getStoreById,
    crudReset,
    searchCouponByFields
} from "../../../actions/actions";

import { produce } from "immer";
import StoreSearchSingle from "../../system/users/StoreSearchSingle";
import Modal from "react-awesome-modal";
import CheckboxTree from "react-checkbox-tree-enhanced";
import ProductSearch from "./SubComponents/ProductSearch";
import ConfirmAlert from "../../../components/ConfirmAlert";
import { Link } from "react-router-dom";
import { AutoComplete } from 'primereact/autocomplete';

class AddCouponRedemptionRule extends React.PureComponent {
    constructor(props) {
        super(props);
        this.savedOnce = false;
        this.state = {
            id: "",
            isEdit: false,
            isView: false,
            isForApproval: false,
            validatelevel: "Category",
            showCategoryConfig: true, //default
            showProductConfig: false,
            showProductGroupConfig: false,
            showCategorySelection: false,
            showCategoryInput: false,
            showProductCodeForExclusion: false,
            showProductCodeSelection: false,
            showProductInput: false,
            selectedCategories: [],
            expandedCategories: [],
            newCategories: [],
            enteredCategories: "",
            selectedProducts: [],
            enteredProducts: "",
            expandCategoryForProductsList: [],
            selectCategoryForProductsList: [],
            exlcudedProducts: [],
            couponslist: [],
            couponcodeObj: '',
            filteredCouponCodes: null,

            // Formrelated
            promocode: "",
            title: "",
            promotype: "",
            promogroup: "",
            couponcode: "",

            qtymin: 0,
            usedqtymax: 0,
            amountmax: 0,
            amountmin: 0,
            pointcategory: "",
            pointburn: "",

            storeid: "",
            storelabel: "",
            status: "Active",
            sortorder: 1,
            priority: 0,

            // Errors
            titleerror: "",
            storeiderror: "",
            validatelevelerror: "",
            validateonvalueerror: "",
        };
    }

    handleValidationLevelSelection = (e) => {
        this.setState({ validatelevel: e.target.value }, () => {
            if (this.state.validatelevel === "Category") {
                this.setState({
                    showCategoryConfig: true,
                    showProductConfig: false,
                    showProductGroupConfig: false,
                });
            } else if (this.state.validatelevel === "Product") {
                this.setState({
                    showCategoryConfig: false,
                    showProductConfig: true,
                    showProductGroupConfig: false,
                });
            } else if (this.state.validatelevel === "ProductMix") {
                this.setState({
                    showCategoryConfig: false,
                    showProductConfig: false,
                    showProductGroupConfig: true,
                });
            }
        });
    };

    getCreatePayload = () => {
        const {
            storeid,
            title,
            couponcode,
            newCategories,
            categorycodesexcluded,
            selectedProducts,
            // productcodesexcluded,
            exlcudedProducts,
            validatelevel,
            amountmin,
            amountmax,
            qtymin,
            usedqtymax,
            sortorder,
            status,
        } = this.state;

        let body = {
            storeid,
            title,
            couponcode: this.state.couponcodeObj ? this.state.couponcodeObj.id : "",
            categorycodes:
                validatelevel == "Category"
                    ? newCategories.length > 0
                        ? newCategories.join(",")
                        : ""
                    : "",
            categorycodesexcluded,
            productcodes:
                validatelevel == "Category"
                    ? ""
                    : selectedProducts.length > 0
                    ? selectedProducts.join(",")
                    : "",
            productcodesexcluded:
                validatelevel == "Category"
                    ? exlcudedProducts.length > 0
                        ? exlcudedProducts.join(",")
                        : ""
                    : "",
            validatelevel,
            amountmin: parseInt(amountmin) || 0,
            amountmax: parseInt(amountmax) || 0,
            qtymin: parseInt(qtymin) || 0,
            usedqtymax: parseInt(usedqtymax) || 0,
            sortorder,
            status,
        };

        if (this._isEditMode()) {
            body["id"] = this.state.id;
        }
        const payload = {
            body: body,
        };
        return payload;
    };

    _createCouponRedemptionRule = (e) => {
        e.preventDefault();
        // console.log(this.getCreatePayload())
        // return
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateCouponRedemptionRule(
                        this.getCreatePayload(),
                        this.state.id
                    )
                );
            } else {
                this.props.dispatch(
                  createCouponRedemptionRule(this.getCreatePayload())
                );
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: "Clear all the errors to continue",
            });
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: "",
            //titleerror: "",
            validatelevelerror: "",
            validateonvalueerror: "",
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const {
            title
        } = this.state;

        let valid = true;

        if (title.trim() === "") {
            this.setState({ titleerror: "required" });
            valid = false;
        }
        return valid;
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        } else {
            return <div />;
        }
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _isApprovalMode = () => {
        return this.state.isForApproval;
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <>
                    <Link
                        to="/ui/coupon-redemption-rules"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </>
            );
        }
        const isEditMode = this._isEditMode();
        return (
            <>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => {
                            this._createCouponRedemptionRule(e);
                        }}
                    >
                        Save
                    </button>
                )}

                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => this._createCouponRedemptionRule(e)}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/coupon-redemption-rules"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </>
        );
    };

    _renderAddCategoriesActionButton = () => {
        return (
            <React.Fragment>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={() => {
                        this._updateSelectedCategories();
                    }}
                >
                    Add
                </button>

                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={this.hideAddCategoryControl}
                >
                    Cancel
                </button>
            </React.Fragment>
        );
    };

    _renderAddProductsActionButton = () => {
        return (
            <React.Fragment>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={() => {
                        this.state.validatelevel === "Category"
                            ? this._updateExcludedProducts()
                            : this._updateEnteredProducts();
                    }}
                >
                    Add
                </button>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={this.hideAddProductControl}
                >
                    Cancel
                </button>
            </React.Fragment>
        );
    };

    _removeSelectedCataegory = (e, s) => {
        if (e) {
            e.preventDefault();
        }
        //let selectedCategories = this.state.selectedCategories
        let selectedCategories = this.state.newCategories;
        selectedCategories = selectedCategories.filter((e) => e != s);
        this.setState({ selectedCategories: selectedCategories });
        this.saveSelectedCategories(selectedCategories);
    };

    _renderSelectedCategories = () => {
        if (this.state.newCategories && this.state.newCategories.length <= 0) {
            return;
        }

        let contentareastyle = {
            display: "inline-flex",
            flexFlow: "wrap",
            border: "1px solid #dcdcdc",
            padding: "10px",
            overflowY: "scroll",
            maxHeight: "150px",
        };

        return (
            <div className="row" style={{ marginTop: "0.6em" }}>
                <div style={contentareastyle}>
                    {this.state.newCategories.map((s) => {
                        return (
                            <div
                                style={{
                                    padding: "2px 2px",
                                    margin: "2px 2px",
                                    borderRadius: "4px",
                                    background: "#347bf6",
                                }}
                                key={s}
                            >
                                <label style={{ color: "white" }}>{s}</label>
                                {!this._isViewMode() && (
                                    <img
                                        title="delete"
                                        className="tableImage"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                                        onClick={(e) =>
                                            this._removeSelectedCataegory(e, s)
                                        }
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    _removeAddedProducts = (e, s) => {
        if (e) {
            e.preventDefault();
        }
        let selectedProducts = this.state.selectedProducts;
        selectedProducts = selectedProducts.filter((e) => e != s);
        this.setState({ selectedProducts: selectedProducts });
    };

    _renderAddedProducts = () => {
        if (
            this.state.selectedProducts &&
            this.state.selectedProducts.length <= 0
        ) {
            return;
        }

        let contentareastyle = {
            display: "inline-flex",
            flexFlow: "wrap",
            border: "1px solid #dcdcdc",
            padding: "10px",
            overflowY: "scroll",
            maxHeight: "150px",
        };

        return (
            <div className="row" style={{ marginTop: "0.6em" }}>
                <div style={contentareastyle}>
                    {this.state.selectedProducts.map((s) => {
                        return (
                            <div
                                style={{
                                    padding: "2px 2px",
                                    margin: "2px 2px",
                                    borderRadius: "4px",
                                    background: "#347bf6",
                                }}
                            >
                                <label style={{ color: "white" }}>{s}</label>
                                {!this._isViewMode() && (
                                    <img
                                        title="delete"
                                        className="tableImage"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                                        onClick={(e) =>
                                            this._removeAddedProducts(e, s)
                                        }
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    _removeExcludedProducts = (e, s) => {
        if (e) {
            e.preventDefault();
        }
        let exlcudedProducts = this.state.exlcudedProducts;
        exlcudedProducts = exlcudedProducts.filter((e) => e != s);
        this.setState({ exlcudedProducts: exlcudedProducts });
    };

    _renderExcludedProducts = () => {
        if (
            this.state.exlcudedProducts &&
            this.state.exlcudedProducts.length <= 0
        ) {
            return;
        }

        let contentareastyle = {
            display: "inline-flex",
            flexFlow: "wrap",
            border: "1px solid #dcdcdc",
            padding: "10px",
            overflowY: "scroll",
            maxHeight: "150px",
        };

        return (
            <div className="row" style={{ marginTop: "0.6em" }}>
                <div style={contentareastyle}>
                    {this.state.exlcudedProducts.map((s) => {
                        return (
                            <div
                                style={{
                                    padding: "2px 2px",
                                    margin: "2px 2px",
                                    borderRadius: "4px",
                                    background: "#347bf6",
                                }}
                            >
                                <label style={{ color: "white" }}>{s}</label>
                                {!this._isViewMode() && (
                                    <img
                                        title="delete"
                                        className="tableImage"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                                        onClick={(e) =>
                                            this._removeExcludedProducts(e, s)
                                        }
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    _renderCategoryConfiguration = () => {
        const { categoryTree } = this.props.queryData;
        let lCategoryTree = JSON.parse(JSON.stringify(categoryTree));
        const nodes = [lCategoryTree].map(this.rename);

        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-lg-5 col-sm-12 noPadding noMargin promoPeriods">
                        <div className="row promoPeriods">
                            <label htmlFor="storecodes" className="floatLeft">
                                Category
                            </label>
                        </div>
                        <div
                            className="promoPeriods"
                            style={{
                                minHeight: 150,
                                margin: "1em",
                                background: "#fff",
                            }}
                        >
                            {this._renderSelectedCategories()}
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <button
                                    className="page-header-btn icon_btn display-inline"
                                    onClick={(event) =>
                                        this.showAddCategoryControl(event)
                                    }
                                    disabled={this._isViewMode()}
                                >
                                    <img
                                        title="edit"
                                        className="tableImage"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
                                    />
                                    Add Category Code
                                </button>
                            </div>
                            <div className="col-lg-6 col-sm-12 noPadding noMargin">
                                <button
                                    className="page-header-btn icon_btn display-inline"
                                    onClick={(event) =>
                                        this.showCategorySelectrionTree(event)
                                    }
                                    disabled={this._isViewMode()}
                                >
                                    <img
                                        title="edit"
                                        className="tableImage"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
                                    />
                                    {`Select Category`}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-sm-12" />
                    <div className="col-lg-6 col-sm-12 noPadding noMargin promoPeriods">
                        <div className="row">
                            <label htmlFor="storecodes" className="floatLeft">
                                Exclude Product Codes
                            </label>
                        </div>
                        <div
                            className="promoPeriods"
                            style={{
                                minHeight: 150,
                                margin: "1em",
                                background: "#fff",
                            }}
                        >
                            {this._renderExcludedProducts()}
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <button
                                    className="page-header-btn icon_btn display-inline"
                                    onClick={(event) =>
                                        this.showAddProductControl(event)
                                    }
                                    disabled={this._isViewMode()}
                                >
                                    <img
                                        title="edit"
                                        className="tableImage"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
                                    />
                                    Add Product Code
                                </button>
                            </div>
                            <div className="col-lg-6 col-sm-12 noPadding noMargin">
                                <ProductSearch
                                    storeid={this.state.storeid}
                                    nodes={nodes}
                                    onSelectedProducts={
                                        this._updateSelectedExcludedProducts
                                    }
                                    validateStoreSelection={
                                        this.validateStoreSelection
                                    }
                                    disabled={this._isViewMode()}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="invalid-feedback">
                        {this.state.validateonvalueerror}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderProductConfiguration = () => {
        const { categoryTree } = this.props.queryData;
        let lCategoryTree = JSON.parse(JSON.stringify(categoryTree));
        const nodes = [lCategoryTree].map(this.rename);

        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-lg-12 col-sm-12 noPadding noMargin promoPeriods">
                        <div className="row">
                            <label htmlFor="storecodes" className="floatLeft">
                                Product Codes
                            </label>
                        </div>
                        <div
                            className="promoPeriods"
                            style={{
                                minHeight: 150,
                                margin: "1em",
                                background: "#fff",
                            }}
                        >
                            {this._renderAddedProducts()}
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-sm-12 noPadding noMargin" />
                            <div className="col-lg-3 col-sm-12 noPadding noMargin" />
                            <div className="col-lg-3 col-sm-12 noPadding noMargin">
                                <button
                                    className="page-header-btn icon_btn display-inline"
                                    onClick={(event) =>
                                        this.showAddProductControl(event)
                                    }
                                    disabled={this._isViewMode()}
                                >
                                    <img
                                        title="edit"
                                        className="tableImage"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
                                    />
                                    Add Product Code
                                </button>
                            </div>
                            <div className="col-lg-3 col-sm-12 noPadding noMargin">
                                <ProductSearch
                                    storeid={this.state.storeid}
                                    nodes={nodes}
                                    onSelectedProducts={
                                        this._updateSelectedProducts
                                    }
                                    validateStoreSelection={
                                        this.validateStoreSelection
                                    }
                                    disabled={this._isViewMode()}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="invalid-feedback">
                        {this.state.validateonvalueerror}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderProductGroupConfiguration = () => {
        const { categoryTree } = this.props.queryData;
        let lCategoryTree = JSON.parse(JSON.stringify(categoryTree));
        const nodes = [lCategoryTree].map(this.rename);

        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-lg-12 col-sm-12 noPadding noMargin promoPeriods">
                        <div className="row">
                            <label htmlFor="storecodes" className="floatLeft">
                                Product Codes
                            </label>
                        </div>
                        <div
                            className="promoPeriods"
                            style={{
                                minHeight: 150,
                                margin: "1em",
                                background: "#fff",
                            }}
                        >
                            {this._renderAddedProducts()}
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-sm-12 noPadding noMargin" />
                            <div className="col-lg-3 col-sm-12 noPadding noMargin" />
                            <div className="col-lg-3 col-sm-12 noPadding noMargin">
                                <button
                                    className="page-header-btn icon_btn display-inline"
                                    onClick={(event) =>
                                        this.showAddProductControl(event)
                                    }
                                    disabled={this._isViewMode()}
                                >
                                    <img
                                        title="edit"
                                        className="tableImage"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
                                    />
                                    Add Product Code
                                </button>
                            </div>
                            <div className="col-lg-3 col-sm-12 noPadding noMargin">
                                <ProductSearch
                                    storeid={this.state.storeid}
                                    nodes={nodes}
                                    onSelectedProducts={
                                        this._updateSelectedProducts
                                    }
                                    validateStoreSelection={
                                        this.validateStoreSelection
                                    }
                                    disabled={this._isViewMode()}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="invalid-feedback">
                        {this.state.validateonvalueerror}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    showAddProductControl = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (this.validateStoreSelection()) {
            this.setState({ showProductInput: true }, () => {});
        }
    };

    hideAddProductControl = () => {
        this.setState({ enteredProducts: "" });
        this.setState({ showProductInput: false });
    };

    showAddCategoryControl = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (this.validateStoreSelection()) {
            this.setState({ showCategoryInput: true }, () => {});
        }
    };

    hideAddCategoryControl = () => {
        this.setState({ showCategoryInput: false });
    };

    _updateSelectedProducts = (selectedProducts) => {
        var newlyAddedProducts = selectedProducts.filter(
            (e) => !this.state.selectedProducts.includes(e)
        );
        if (newlyAddedProducts.length <= 0) {
            return;
        }
        this.setState(
            {
                selectedProducts:
                    this.state.selectedProducts.concat(selectedProducts),
            },
            () => {
                // this.saveSelectedProducts(this.state.selectedProducts)
            }
        );
    };

    _updateSelectedExcludedProducts = (selectedProducts) => {
        var newProducts = selectedProducts.filter(
            (e) => !this.state.exlcudedProducts.includes(e)
        );
        if (newProducts.length <= 0) {
            return;
        }

        this.setState(
            {
                exlcudedProducts:
                    this.state.exlcudedProducts.concat(newProducts),
            },
            () => {
                // this.setState({ enteredProducts: "" })
                //this.saveSelectedProductListForExclusion(this.state.exlcudedProducts)
            }
        );
    };

    _updateEnteredProducts = () => {
        if (this.state.enteredProducts === "") {
            this.hideAddProductControl();
            return;
        }
        var selectedProducts = this.state.enteredProducts.split(",");
        var newlyAddedProducts = selectedProducts.filter(
            (e) => !this.state.selectedProducts.includes(e)
        );
        if (newlyAddedProducts.length <= 0) {
            this.hideAddProductControl();
            return;
        }
        this.setState(
            {
                selectedProducts:
                    this.state.selectedProducts.concat(newlyAddedProducts),
            },
            () => {
                this.hideAddProductControl();
            }
        );
    };

    _updateExcludedProducts = () => {
        if (this.state.enteredProducts === "") {
            this.hideAddProductControl();
            return;
        }
        var selectedProducts = this.state.enteredProducts.split(",");
        selectedProducts = selectedProducts.map((e) => e.trim());
        var newlyAddedProducts = selectedProducts.filter(
            (e) => !this.state.exlcudedProducts.includes(e)
        );
        if (newlyAddedProducts.length <= 0) {
            // this.setState({ enteredProducts: "" })
            this.hideAddProductControl();
            return;
        }
        this.setState(
            {
                exlcudedProducts:
                    this.state.exlcudedProducts.concat(newlyAddedProducts),
            },
            () => {
                // this.setState({ enteredProducts: "" })
                this.hideAddProductControl();
                //this.saveSelectedProductListForExclusion(this.state.exlcudedProducts)
            }
        );
    };

    _updateSelectedCategories = () => {
        if (this.state.enteredCategories === "") {
            this.hideAddCategoryControl();
            return;
        }
        var selectedCategories = this.state.enteredCategories.split(",");
        selectedCategories = selectedCategories.map((e) => e.trim());
        //var newlyAddedCategories = selectedCategories.filter(e => !this.state.selectedCategories.includes(e))
        var newlyAddedCategories = selectedCategories.filter(
            (e) => !this.state.newCategories.includes(e)
        );
        if (newlyAddedCategories.length <= 0) {
            this.setState({ enteredCategories: "" }); //reset
            this.hideAddCategoryControl();
            return;
        }
        this.setState(
            {
                selectedCategories:
                    this.state.selectedCategories.concat(newlyAddedCategories),
                newCategories:
                    this.state.newCategories.concat(newlyAddedCategories),
            },
            () => {
                this.setState({ enteredCategories: "" }); //reset
                this.hideAddCategoryControl();
            }
        );
    };

    validateStoreSelection = () => {
        if (this.state.storeid !== "") {
            return true;
        } else {
            this.setState({ storeiderror: "select store" });
            return false;
        }
    };

    showCategorySelectrionTree = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (this.validateStoreSelection()) {
            this.setState({ showCategorySelection: true }, () => {
                // this.props.dispatch(queryCategoryTree({ body: {} }, "PD01"));
            });
        }
    };

    hideCategorySelectionTree = () => {
        this.setState({ showCategorySelection: false });
    };

    hideProductCodeSelectionTree = () => {
        this.setState({
            showProductCodeForExclusion: false,
            showProductCodeSelection: false,
        });
    };

    onCheckCategory = (selectedCategories) => {
        this.setState({
            newCategories: selectedCategories,
            selectedCategories: selectedCategories,
        });
    };

    onExpandCategory = (expanded) => {
        this.setState({ expandedCategories: expanded });
    };

    saveSelectedCategories = (e) => {
        this.setState({ newCategories: e });
        this.savedOnce = true;
    };

    rename = (obj) => {
        for (var prop in obj) {
            if (Array.isArray(obj[prop])) {
                if (obj[prop].length > 0) {
                    obj.showCheckbox = false;
                }
                obj[prop] = obj[prop].map(this.rename);
            }

            if (prop === "categorycode") {
                obj.value = obj[prop];
                obj.icon = "";
            } else if (prop === "title") {
                obj.label = obj[prop];
                obj["subtitle"] = obj["categorycode"];
            } else if (prop === "subcategories") {
                obj.children = obj[prop];
            }
            delete obj[prop];
        }

        return obj;
    };

    _renderAddProductControl = () => {
        return (
            <Modal
                visible={this.state.showProductInput}
                width="70%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this.hideAddProductControl()}
            >
                <div className="modalForm storeSearchModalForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 alignLeft">
                            <div className="row sub-title">Product Codes</div>
                        </div>
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a
                                onClick={this.hideAddProductControl}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <textarea
                                className="form-control"
                                id="enteredProducts"
                                value={this.state.enteredProducts}
                                onChange={(event) =>
                                    this.setState({
                                        enteredProducts: event.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        {this._renderAddProductsActionButton()}
                    </div>
                </div>
            </Modal>
        );
    };

    _renderAddCategoryControl = () => {
        return (
            <Modal
                visible={this.state.showCategoryInput}
                width="70%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this.hideAddCategoryControl()}
            >
                <div className="modalForm storeSearchModalForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 alignLeft">
                            <div className="row sub-title">
                                Sales Categories
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a
                                onClick={this.hideAddCategoryControl}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <textarea
                                className="form-control"
                                id="cataegories"
                                value={this.state.enteredCategories}
                                onChange={(event) =>
                                    this.setState({
                                        enteredCategories: event.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        {this._renderAddCategoriesActionButton()}
                    </div>
                </div>
            </Modal>
        );
    };

    _renderCategorySelectionTree = () => {
        const { categoryTree } = this.props.queryData;
        let lCategoryTree = JSON.parse(JSON.stringify(categoryTree));
        const nodes = [lCategoryTree].map(this.rename);

        return (
            <Modal
                visible={this.state.showCategorySelection}
                width="70%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this.hideCategorySelectionTree()}
            >
                <div className="modalForm storeSearchModalForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 alignLeft">
                            <div className="row sub-title">
                                Sales Categories
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a
                                onClick={this.hideCategorySelectionTree}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            {nodes.length > 0 && (
                                <div className="customOl">
                                    <CheckboxTree
                                        nodes={nodes}
                                        checked={this.state.selectedCategories}
                                        expanded={this.state.expandedCategories}
                                        onCheck={this.onCheckCategory}
                                        onExpand={this.onExpandCategory}
                                        icons={{
                                            check: (
                                                <span className="rct-icon rct-icon-check" />
                                            ),
                                        }}
                                        nativeCheckboxes={true}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    _renderMinMaxConfiguration = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-3 col-sm-12">
                        <label
                            htmlFor="amountmin"
                            className="floatLeft required"
                        >
                            Amount Min
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="promoname"
                            value={this.state.amountmin}
                            onChange={(event) =>
                                this.setState({ amountmin: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.amountminerror}
                        </div>
                    </div>
                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="qtymin" className="floatLeft required">
                            Quantity Min
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="promoname"
                            value={this.state.qtymin}
                            onChange={(event) =>
                                this.setState({ qtymin: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.qtyminerror}
                        </div>
                    </div>
                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="usedqtymax" className="floatLeft required">
                          Used Quantity Max
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="promoname"
                            value={this.state.usedqtymax}
                            onChange={(event) =>
                                this.setState({ usedqtymax: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.usedqtymaxerror}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _onSelectStore = (selectedStores) => {
        this.setState({
            storeid: selectedStores.value,
            storelabel: selectedStores.label,
        });
        if (selectedStores.value !== "") {
            this.setState({ storeiderror: "" });
        }
    };

    searchCouponCode = (event) => {
        const { couponslist } = this.state
        setTimeout(() => {
            let _filteredCouponCodes;
            if (!event.query.trim().length) {
                _filteredCouponCodes = [...couponslist];
            }
            else {
                _filteredCouponCodes = couponslist.filter((country) => {
                    return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            this.setState({ filteredCouponCodes: _filteredCouponCodes})
            //setFilteredCountries(_filteredCouponCodes);
        }, 250);
    }

    _renderMainDefinition = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">
                {this._isEditMode()
                                    ? "Edit Coupon Redemption Rule"
                                    : this._isViewMode()
                                    ? "View Coupon Redemption Rule"
                                    : "Add Coupon Redemption Rule"}
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="storecodes"
                            className="floatLeft"
                        >
                            Store Id
                        </label>
                        <StoreSearchSingle
                            value={this.state.storelabel}
                            onSelectStore={this._onSelectStore}
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="promoname"
                            className="floatLeft required"
                        >
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="targetgroupname"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.titleerror}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="couponcode" className="floatLeft w-100">
                            Coupon Code
                        </label>
                        <AutoComplete 
                            className="w-100"
                            value={this.state.couponcodeObj} 
                            suggestions={this.state.filteredCouponCodes} 
                            completeMethod={this.searchCouponCode} 
                            field="name" 
                            dropdown 
                            forceSelection 
                            onChange={(e) =>
                                this.setState({ couponcodeObj: e.target.value})
                            }
                            aria-label="Coupon Code" 
                            dropdownAriaLabel="Select Coupon Code" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-sm-12 noPadding noMargin">
                        <div className="row">
                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="validatelevel"
                                    className="floatLeft"
                                >
                                    Validation Level
                                </label>
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12 pl-0">
                                        <label
                                            htmlFor="category"
                                            className="floatLeft"
                                        >
                                            <input
                                                type="radio"
                                                value="Category"
                                                onChange={(e) =>
                                                    this.handleValidationLevelSelection(
                                                        e
                                                    )
                                                }
                                                checked={
                                                    this.state
                                                        .showCategoryConfig ===
                                                    true
                                                }
                                                disabled={this._isViewMode()}
                                                style={{ padding: "2 0" }}
                                            />
                                            Category
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-sm-12 pl-0">
                                        <label
                                            htmlFor="product"
                                            className="floatLeft"
                                        >
                                            <input
                                                type="radio"
                                                value="Product"
                                                onChange={(e) =>
                                                    this.handleValidationLevelSelection(
                                                        e
                                                    )
                                                }
                                                checked={
                                                    this.state
                                                        .showProductConfig ===
                                                    true
                                                }
                                                disabled={this._isViewMode()}
                                            />
                                            Product
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-sm-12 pl-0">
                                        <label
                                            htmlFor="productGroup"
                                            className="floatLeft"
                                        >
                                            <input
                                                type="radio"
                                                value="ProductMix"
                                                onChange={(e) =>
                                                    this.handleValidationLevelSelection(
                                                        e
                                                    )
                                                }
                                                checked={
                                                    this.state
                                                        .showProductGroupConfig ===
                                                    true
                                                }
                                                disabled={this._isViewMode()}
                                            />
                                            Product Group
                                        </label>
                                    </div>
                                </div>
                                <div className="invalid-feedback">
                                    {this.state.validatelevelerror}
                                </div>
                            </div>
                        </div>
                        {this.state.showCategoryConfig &&
                            this._renderCategoryConfiguration()}
                        {this.state.showProductConfig &&
                            this._renderProductConfiguration()}
                        {this.state.showProductGroupConfig &&
                            this._renderProductGroupConfiguration()}
                        {this._renderMinMaxConfiguration()}
                        {/* </form> */}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    UNSAFE_componentWillReceiveProps = () => {
        if (this._isEditMode() || this._isViewMode()) {
            const { storeArray } = this.props.queryData;
            if (storeArray) this.setState({ storelabel: storeArray.title });
        }
        const { couponsList } = this.props.queryData;
        if (couponsList && couponsList.length > 0) {
            let returnedData = couponsList.map((e) => ({
                name: e.title + ' - ' + e.couponcode,
                id: e.couponcode,
            }));
            this.setState({
                couponslist: [].concat(returnedData),
            });
            if (this.props.location && this.props.location.state) {
                const rec = this.props.location.state.item;
                const { couponcode } = rec
                const couponObj = returnedData.find(data => data.id == couponcode)
                this.setState({ couponcodeObj: couponObj })
            }

        } else {
            this.setState({ couponslist: [{ name: 'Select', id: '' }] });
        }
        
    };

    componentDidMount = () => {
        this.props.dispatch(queryCategoryTree({ body: {} }, "PD01"));
    };

    componentWillMount = () => {
        this.props.dispatch(
            searchCouponByFields({ body: { status: 'Active' } })
        );
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;

            if (rec) {
                const {
                    id,
                    storeid,
                    title,
                    couponcode,
                    categorycodes,
                    categorycodesexcluded,
                    productcodes,
                    productcodesexcluded,
                    validatelevel,
                    amountmin,
                    amountmax,
                    usedqtymax,
                    qtymin,
                    status,
                    sortorder,
                } = rec;
                if (storeid) {
                  this.props.dispatch(getStoreById(storeid));
                }
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : "";
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.title = title ? title : "";
                        draft.storeid = storeid ? storeid : "";
                        // draft.couponcode = couponcode
                        //     ? couponcode
                        //     : "";
                        draft.categorycodes = categorycodes
                            ? categorycodes
                            : "";
                        draft.newCategories =
                            categorycodes.trim().length > 0
                                ? categorycodes.includes(",")
                                    ? categorycodes.split(",")
                                    : [...new Array(categorycodes)]
                                : [];
                        draft.categorycodesexcluded = categorycodesexcluded
                            ? categorycodesexcluded
                            : "";
                        draft.productcodes = productcodes ? productcodes : "";
                        draft.selectedProducts =
                            productcodes.trim().length > 0
                                ? productcodes.includes(",")
                                    ? productcodes.split(",")
                                    : [...new Array(productcodes)]
                                : [];
                        draft.productcodesexcluded = productcodesexcluded
                            ? productcodesexcluded
                            : "";
                        draft.exlcudedProducts = productcodesexcluded
                            ? productcodesexcluded.trim().length > 0
                                ? productcodesexcluded.includes(",")
                                    ? productcodesexcluded.split(",")
                                    : [...new Array(productcodesexcluded)]
                                : []
                            : [];
                        draft.validatelevel = validatelevel
                            ? validatelevel
                            : "";

                        draft.showCategoryConfig = false;
                        draft.showProductConfig = false;
                        draft.showProductGroupConfig = false;

                        if (validatelevel != "") {
                            if (validatelevel === "Category") {
                                draft.showCategoryConfig = true;
                            } else if (validatelevel === "Product") {
                                draft.showProductConfig = true;
                            } else if (validatelevel === "ProductMix") {
                                draft.showProductGroupConfig = true;
                            }
                        }
                        draft.amountmax = amountmax ? amountmax : 0;
                        draft.amountmin = amountmin ? amountmin : 0;
                        draft.usedqtymax = usedqtymax ? usedqtymax : 0;
                        draft.qtymin = qtymin ? qtymin : 0;
                        draft.sortorder = sortorder ? sortorder : 1;
                        draft.status = status ? status : "Active";
                    })
                );
            }
        }
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <>
                {this.state.showCategorySelection &&
                    this._renderCategorySelectionTree()}
                {this.state.showCategoryInput &&
                    this._renderAddCategoryControl()}
                {this.state.showProductInput && this._renderAddProductControl()}

                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv">
                            {this._renderErrorMessage()}
                            {this._renderMainDefinition()}
                            {this._renderMainButton()}
                        </form>
                    </div>
                </div>
                <ConfirmAlert
                    show={isSuccess}
                    handleClose={this.hideModal}
                    to="/ui/coupon-redemption-rules"
                    children={
                        <div style={{ padding: "2em", color: "green" }}>
                            {this._isEditMode()
                                ? "Coupon redemption rule updated successfully"
                                : "Coupon redemption rule created successfully"}
                        </div>
                    }
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddCouponRedemptionRule);

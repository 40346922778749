import produce from 'immer';
import React from 'react';
import DatePicker from 'react-date-picker';
import { connect } from 'react-redux';
import {
    crudReset,
    validate,
    queryPropertyConfigByFields,
    searchGeneral,
    storeTerminalsSearchByFields,
    exportReport,
    runDataValidation,
} from '../../actions/actions';
import ConfirmAlert from '../../components/ConfirmAlert';
import SimpleTable from '../../components/SimpleTable';
import {
    filterFormatter,
    formatDate,
    formatLanguageArrayWithName,
    permissions,
} from '../../helpers/Util';
import * as CONSTANTS from '../../constants/constants';
import StoreSearchSingle from '../system/users/StoreSearchSingle';
import MultiSelect from '../../components/MultiSelect';

class DataValidation extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.module = 'REPORTS';
        this.group = 'DE_REPORTS_DATA_VALIDATION';
        this.allPermissions = [];

        this.requestBody = {}; //current one
        this.currPage = 1;
        this.pageSize = 10;

        this.state = {
            currPage: this.currPage,
            pageSize: this.pageSize,

            language: 'en', //default
            langlist: {},
            terminals: [{ name: 'Select', id: '' }],

            storeid: '',
            terminalid: '',
            trxdate: '',

            message: '',
            action: '',
            storedate: new Date(),
            date_till: '',
            tableCols: [
                {
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                },
                {
                    dataField: 'status',
                    text: 'Status',
                    sort: true,
                },
                {
                    dataField: 'additionalfields',
                    text: 'Task Grup',
                    sort: false,
                    formatter: this._taskGroupFormatter,
                },
                {
                    dataField: 'additionalfields',
                    text: 'Task Type',
                    sort: false,
                    formatter: this._taskTypeFormatter,
                },
                {
                    dataField: 'additionalfields',
                    text: 'Filters',
                    sort: false,
                    formatter: this._filterFormatter,
                },
                {
                    dataField: 'createdon',
                    text: 'Executed On',
                    sort: true,
                },
                {
                    dataField: 'lastupdated',
                    text: 'Completed On',
                    sort: true,
                },
                {
                    dataField: 'lastupdatedby',
                    text: 'Executed by',
                    sort: true,
                },
                {
                    dataField: 'additionalfields',
                    text: 'Results',
                    sort: false,
                    formatter: this._resultFormatter,
                },
                {
                    dataField: '',
                    text: 'Actions',
                    formatter: this.cellFormatter,
                    formatExtraData: {
                        action: 'ED',
                    },
                    classes: () => {
                        return 'lastTd';
                    },
                },
            ],

            pagesize: this.pageSize,

            storeiderror: '',
            terminaliderror: '',
            storedateerror: '',
            taskgrouperror: '',

            taskgroup: '',
            taskgroups: {},
        };
    }

    _filterFormatter = (cell, row, rowIndex, formatExtraData) => {
        return filterFormatter(cell, row, rowIndex, formatExtraData, [
            'pagesize',
            'pageno',
            'filepath',
            'report',
            'result',
            'taskgroup',
            'taskcode',
        ]);
    };

    _resultFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (row.additionalfields && row.additionalfields.result) {
            return row.additionalfields.result;
        }
        return '';
    };

    _taskGroupFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (row.additionalfields && row.additionalfields.taskgroup) {
            return row.additionalfields.taskgroup;
        }
        return '';
    };

    _taskTypeFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (row.additionalfields && row.additionalfields.taskcode) {
            return row.additionalfields.taskcode;
        }
        return '';
    };

    cellFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (row.status !== 'exported') {
            return;
        }
        if (row.additionalfields['filepath'] === undefined) {
            return;
        }
        return (
            <span>
                <img
                    title="Download"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/list_download.svg`}
                    onClick={() => this.download(row)}
                />
            </span>
        );
    };

    download = (row) => {
        var link = document.createElement('a');
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            //var url =  localStorage.getItem('imagepath') + "/media" + row.title;
            var url =
                localStorage.getItem('imagepath') +
                row.additionalfields['filepath'];
            console.log('url::', url);
            link.setAttribute('href', url);

            var exportedFilenmae = row.tasktype + '.csv' || 'export.csv';
            link.setAttribute('download', exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    // API Calls
    createFetchUrl = (fetchType) => {
        const { pageSize, currPage } = this.state;

        if (fetchType === 'ONLOAD') {
            const requestBody = {
                body: {
                    pagesize: pageSize,
                    pageno: currPage,
                    tasktype: this.getTaskType(),
                },
            };
            console.log('...requestBody for ONLOAD::', requestBody);
            return requestBody;
        }

        console.log('tasktype:::', this.requestBody['tasktype']);
        if (this.requestBody['tasktype'] === undefined) {
            this.requestBody['tasktype'] = this.getTaskType();
        }

        const requestBody = {
            body: { ...this.requestBody, pagesize: pageSize, pageno: currPage },
        };

        console.log('...requestBody::', requestBody);
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                '/system/v1/jobtask/search/fields',
                this.createFetchUrl(fetchType),
                CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS,
                CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE
            )
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        // var newState;
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
        }
    };

    componentWillMount() {
        this.setState(
            {
                storeid: localStorage.getItem('storeid'),
                s_storelabel: localStorage.getItem('storelabel'),
            },
            () => {
                this.fetchStoreTerminals();
            }
        );

        this.callQueryApiForFetch('ONLOAD');

        this.props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propgroup: 'DATA_VALIDATION_TASKGROUPS',
                    },
                },
                CONSTANTS.SEARCH_PROP_CONFIG_DATA_VALIDATION_TASKGROUPS,
                CONSTANTS.SEARCH_PROP_CONFIG_DATA_VALIDATION_TASKGROUPS_SUCCESS,
                CONSTANTS.SEARCH_PROP_CONFIG_DATA_VALIDATION_TASKGROUPS_FAILURE
            )
        );

        this.allPermissions = permissions(this.module, this.group);
    }

    componentDidUpdate = (prevProps) => {
        const { storeTerminals } = this.props.queryData;
        const { storeTerminals: prevstoreTerminals } = prevProps.queryData;

        if (storeTerminals !== prevstoreTerminals) {
            if (storeTerminals && storeTerminals.length > 0) {
                var terminals = [{ name: 'Select', id: '' }];
                var returnedTerminals = storeTerminals.map((e) => ({
                    name: e.terminalid,
                    id: e.terminalid,
                }));
                this.setState({
                    terminals: terminals.concat(returnedTerminals),
                });
            } else {
                this.setState({ terminals: [{ name: 'Select', id: '' }] });
            }
        }
    };

    componentWillReceiveProps = () => {
        const { languageConfig, storeTerminals } = this.props.queryData;

        if (languageConfig.length > 0) {
            const list = languageConfig[0].additionalfields;
            this.setState({
                langlist: list,
            });
        }

        if (storeTerminals && storeTerminals.length > 0) {
            var terminals = [{ name: 'Select', id: '' }];
            var returnedTerminals = storeTerminals.map((e) => ({
                name: e.terminalid,
                id: e.terminalid,
            }));
            this.setState({ terminals: terminals.concat(returnedTerminals) });
        }
    };

    hideAlertModal = (e) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.queryData.reportexport = {};
        this.callQueryApiForFetch('ONLOAD');
    };

    fetchStoreTerminals = () => {
        const { storeid } = this.state;

        this.props.queryData.storeTerminals = [];
        if (storeid && storeid !== '') {
            const queryParams = [];
            queryParams.push(`storeid=${storeid}`);
            queryParams.push(`devicegroup=terminal`);
            this.props.dispatch(
                storeTerminalsSearchByFields(
                    queryParams.join('&'),
                    CONSTANTS.SEARCH_STORE_TERMINALS_SUCCESS_SILENT,
                    CONSTANTS.SEARCH_STORE_TERMINALS_FAILURE_SILENT
                )
            );
        }
    };

    validateAndExecute = (e) => {
        if (e) e.preventDefault();
        if (this.validate()) {
            this.props.dispatch(runDataValidation(this.getPayload()));
        }
    };

    validate = (e) => {
        if (e) e.preventDefault();

        const { taskgroup, storeid, terminalid, storedate } = this.state;

        // this.requestBody = {}
        const queryParams = [];

        // console.log('storeid::', storeid)
        if (storeid === '' || storeid === null) {
            this.setState({ storeiderror: 'Please select the store' });
            return false;
        } else {
            this.setState({ storeiderror: '' });
            // this.requestBody['storeid'] = storeid
            queryParams.push(`storeid=${storeid}`);
        }

        if (terminalid === '') {
            this.setState({ terminaliderror: 'Please select the terminal' });
            return false;
        } else {
            this.setState({ terminaliderror: '' });
            if (terminalid !== '') {
                // this.requestBody['terminalid'] = terminalid
                queryParams.push(`terminalid=${terminalid}`);
            }
        }

        if (taskgroup === '') {
            this.setState({ taskgrouperror: 'Please select the task group' });
            return false;
        } else {
            this.setState({ taskgrouperror: '' });
            if (taskgroup !== '') {
                queryParams.push(`taskgroup=${taskgroup}`);
            }
        }

        if (storedate === '') {
            this.setState({ storedateerror: 'Please select the store date' });
            return false;
        } else {
            this.setState({ storedateerror: '' });
            queryParams.push(`storedate=${formatDate(storedate)}`);
        }

        //query
        this.setState({ message: 'Request submitted.' });
        //this.props.dispatch(exportReport(this.exportReportUrl(), queryParams.join('&')))

        return true;
    };

    getPayload = () => {
        const { taskgroup, storeid, terminalid, storedate } = this.state;

        console.log('taskgroups::', taskgroup);

        //return

        const data = [];
        const taskgroups = taskgroup; //.split(',')
        for (var i = 0; i < taskgroups.length; i++) {
            data.push({
                storeid: storeid,
                terminalid: terminalid,
                storedate: formatDate(storedate),
                taskgroup: taskgroups[i],
            });
        }

        let body = {
            tasks: data,
        };

        const payload = {
            body: body,
        };

        console.log('payload::', payload);

        return payload;
    };

    reportTitle = () => {
        return 'Data Validation';
    };

    exportReportUrl = () => {
        return '/eip/v1/dx/report/validate/data';
    };

    getTaskType = () => {
        return 'store-data-validation';
    };

    _renderSearchCiteria = () => {
        console.log('_renderSearchCriteria.....');

        const { dataValidationTaskGroups } = this.props.queryData;

        if (
            dataValidationTaskGroups &&
            dataValidationTaskGroups.length > 0 &&
            dataValidationTaskGroups[0].additionalfields
        ) {
            this.setState({
                taskgroups: dataValidationTaskGroups[0].additionalfields,
            });
        }

        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-2 col-sm-12 ">
                        <label htmlFor="storeid" className="floatLeft required">
                            Select Store
                        </label>
                        <StoreSearchSingle
                            value={this.state.s_storelabel}
                            onSelectStore={this._onSelectStore}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>

                    <div className="form-group col-lg-2 col-sm-12">
                        <label
                            htmlFor="storeDate"
                            className="floatLeft required"
                        >
                            Store Date
                        </label>
                        <DatePicker
                            onChange={(value) =>
                                this.setState({
                                    storedate: value,
                                    storedateerror: '',
                                })
                            }
                            value={this.state.storedate}
                            format="d/M/yy"
                        />
                        <div className="invalid-feedback">
                            {this.state.storedateerror}
                        </div>
                    </div>

                    <div className="form-group col-lg-2 col-sm-12">
                        <label
                            htmlFor="terminalid"
                            className="floatLeft required"
                        >
                            Terminal Id
                        </label>
                        <div className="row">
                            <select
                                name="terminalid"
                                className="simpleSearchSelect2"
                                value={this.state.terminalid}
                                onChange={(event) =>
                                    this.setState({
                                        terminalid: event.target.value,
                                    })
                                }
                            >
                                {this.state.terminals.length !== 0 &&
                                    this.state.terminals.map(
                                        (option, index) => {
                                            const { name, id } = option;
                                            return (
                                                <option key={index} value={id}>
                                                    {name}
                                                </option>
                                            );
                                        }
                                    )}
                            </select>
                        </div>
                        <div className="invalid-feedback">
                            {this.state.terminaliderror}
                        </div>
                    </div>

                    <div className="form-group col-lg-2 col-sm-4">
                        <label
                            htmlFor="taskgroup"
                            className="floatLeft required"
                        >
                            Task Group
                        </label>
                        <div className="row">
                            <MultiSelect
                                filterOptions={formatLanguageArrayWithName(
                                    this.state.taskgroups
                                )}
                                setSelectedFieldValues={(value) => {
                                    this.setState({ taskgroup: value });
                                }}
                                id="taskgroup"
                                itemsSelected={this.state.taskgroup}
                                label="Select Group"
                            />
                        </div>
                        <div className="invalid-feedback">
                            {this.state.taskgrouperror}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div
                        className="col-lg-3 col-sm-12 btn-container1"
                        style={{ marginTop: '1em' }}
                    >
                        <button
                            type="submit"
                            onClick={(e) => this.validateAndExecute(e)}
                            className="btn btn-themes btn-rounded"
                        >
                            Export
                        </button>
                    </div>
                </div>

                <div className="form-group col-lg-7 col-sm-3 noPadding" />
            </React.Fragment>
        );
    };

    _setTenant = (p1, p2) => {
        this.setState(
            { storeid: p1, s_storelabel: p2, search_value: p1 },
            () => {
                localStorage.setItem('storeid', p1);
                localStorage.setItem('storelabel', p2);

                this.fetchStoreTerminals();
            }
        );
        this.setState({ storeiderror: '' });
    };

    _onSelectStore = (selectedStores) => {
        console.log(
            'selected stores from storesearch:;',
            selectedStores,
            this.state.storeid
        );
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    render() {
        const { jobtasklogs } = this.props.queryData;

        const { isSuccess } = this.props.crudData;

        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <h2 className="page-header">
                                {this.reportTitle()}
                                <img
                                    title="Refresh"
                                    onClick={() => {
                                        this.callQueryApiForFetch('ONLOAD');
                                    }}
                                    className="refreshtree"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                />
                            </h2>
                        </div>
                    </div>

                    <div className="row form-container">
                        <div className="col">
                            <form className="frmDiv">
                                <div className="row">
                                    {this._renderSearchCiteria()}
                                </div>

                                <div className="title_devide" />
                                <SimpleTable
                                    columns={this.state.tableCols}
                                    rows={jobtasklogs}
                                    pageChangeCallback={(currPage) => {
                                        this.setState(
                                            {
                                                currPage: currPage,
                                            },
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                    callback={(pageSize) => {
                                        this.setState(
                                            produce(this.state, (draft) => {
                                                if (draft.pageSize < pageSize) {
                                                    draft.currPage = 1;
                                                }
                                                draft.pageSize = pageSize;
                                            }),
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                    withPageSize={this.state.pageSize}
                                    withCurrPage={this.state.currPage}
                                    withPageFeedback={true}
                                />
                            </form>
                        </div>
                    </div>

                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                Submitted successfully
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }

    _searchJobTaskLogs = (e) => {
        if (e) {
            e.preventDefault();
        }
        console.log('_search Logs');

        console.log('state::', this.state);

        this.requestBody = {};
        const queryParams = [];

        queryParams.push(`tasktype=${this.getTaskType()}`);
        this.requestBody['tasktype'] = this.getTaskType();

        queryParams.push(`pagesize=${this.pageSize}`);
        queryParams.push(`pageno=${this.currPage}`);

        console.log('requstBody::', this.requestBody);
        //query
        // this.props.dispatch(exportedCouponTransactions(queryParams.join('&')))
    };

    _clearFiltersAndResults = (e) => {
        if (e) {
            e.preventDefault();
        }
        //clear all the filters
        this.setState({
            id: '',
            memberid: '',
            storeid: '',
            status: '',
            title: '',
            couponcode: '',

            storeiderror: '',
            couponcodeerror: '',
        });
        //clear the search results
    };
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DataValidation);

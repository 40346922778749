import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Toast } from 'primereact/toast';
import { produce } from 'immer';
import DatePicker from 'react-date-picker';
import Modal from 'react-awesome-modal';
import { InputNumber } from 'primereact/inputnumber';
import {
    crudReset,
    searchGeneral,
    queryCategoryTree,
    createItem,
    updateItem,
    queryPropertyConfig,
    queryPropertyConfigByFields
} from '../../../actions/actions';
import StatusComp from '../../../components/StatusComp';
import SimpleSelect from './../../../components/SimpleSelect';
import ConfirmAlert from '../../../components/ConfirmAlert';
import DynamicDesc from '../../../components/DynamicDesc';
import MultiSelect from '../../../components/MultiSelect';
import DynamicProductGroup from '../../../components/DynamicProductGroup';
import Filter from './../../../components/Filter';
import {
    renderFilterForProdSpec,
    convertDate,
    getPropertyValues,
    renderConfigInMultiSelect,
    treemodalObj,
    checkPropertyForMatch,
    uniqueArrayObject,
    renderCouponOptionsFilter,
    formatDate,
    formatLanguageArray,
} from './../../../helpers/Util';
import CategoryTree from '../../../components/CategoryTree';
import DynamicTableAddONs from '../../../components/DynamicTableAddONs';
import SimpleImageInput from './../../../components/SimpleImageInput';
import Loading from './../../../components/Loading';
import DropdownTree from './../../../components/DropdownTree';
import * as CONSTANTS from '../../../constants/constants';
import ProductTypeSelector from './ProductTypeSelector';

class AddNewProduct extends React.Component {
    deliveryperiod = [
        { title: 'Minute', value: 'MI' },
        { title: 'Hours', value: 'HH' },
        { title: 'Day', value: 'DD' },
    ];
    constructor(props) {
        super(props);

        this.specialdiscounts = {
            SC: 'Senior Citizen',
            PWD: 'PWD',
            SP: 'SP',
            NAAC: 'NAAC',
        };

        this.specialdiscounttypes = Object.keys(this.specialdiscounts);

        this.state = {
            id: '',
            isEdit: false,
            isView: false,
            showPropModal: false,
            showSimilarModal: false,
            showRecommendModal: false,
            showCategoryModal: false,
            language: '',
            // Formrelated
            productgroup: '',
            productcode: '',
            categories: '',
            ptype: '',
            brand: '',
            title: '',
            itemsalestype: '',
            itemsalestypes: [],
            dynamicFields: [],
            pstock: '',
            pstopsell: 'N',
            rfid: '',
            categorycodes: '',
            similarcategorycode: [],
            similartreeobj: [],
            recommendtreeobj: [],
            categorytreeobj: [],
            selectedsimilarcode: [],
            selectedrecommendcode: [],
            recommendedcategorycode: [],
            recommendedpcodes: '',
            searchkeys: '',
            similarpcodes: '',
            sproductcode: '',
            barcode: '',
            sku: '',
            producttag: '',
            productspecFields: [],
            updatedDynamicFields: [],
            currency: '',
            priceinfo: '',
            quantity: '',
            taxinfo: '',
            expirydate: new Date(),
            uom: '',
            unit: '',
            volume: '',
            length: '',
            images: {},
            productimage2: '',
            productimage1: '',
            productlogo: '',
            recommendedcategorytitle: '',
            similarcategorytitle: '',
            productsize: '',
            productcolor: '',
            productweight: '',
            sortorder: 1,
            status: 'Active',
            variantFields: {},
            formError: false,
            formErrorMsg: '',
            dynamicDesc: [{ title: '', desc: '', id: 0 }],
            deliverynearby: '',
            bulkyitem: '',
            deliveryperiod: '',
            deliveryperiodvalue: '',
            orderbefore: '',
            // Errors
            // productlogoerror: "",
            productcodeerror: '',
            ptypeerror: '',
            pgruoperror: '',
            titleerror: '',
            descerror: '',
            categoryerror: '',
            rfiderror: '',
            barcodeerror: '',
            skuerror: '',
            stopsellerror: '',
            sortordererror: '',
            deliveryerror: '',
            addons: [],

            specialDiscounts: {},
            specialDiscount: {},
            specialdiscounts: [],
        };
    }
    fetchaddongroupinfo = () => {
        const requestBody = {
            body: {
                articletype: 'Product Addon Groups',
                language: 'en',
                search_field: 'status',
                search_condi: 'eq',
                search_value: 'Active',
            },
        };

        this.props.dispatch(
            searchGeneral(
                'cms/v1/article-workflow/search',
                requestBody,
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE',
            ),
        );
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handeleScroll); // remove brackets ()
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handeleScroll);
    }

    callFetch = (fetchType) => {
        if (fetchType === 'ONLOAD') {
            this.props.dispatch(queryCategoryTree({ body: {} }, 'PD01'));
        }
    };

    componentWillReceiveProps = (newProps) => {
        // this.setState({ treeData: newProps.queryData.categoryTree });
        this._setDynamicFields(this.state.ptype);
        const {
            categoryCodeArray,
            similarcategoryTree,
            recommendcategoryTree,
            masterTrxGrpTypes
        } = this.props.queryData;
        if (this._isEditMode()) {
            if (categoryCodeArray[0])
                this.setState({ categories: categoryCodeArray[0].title });
        }
        if (this.state.similarcategorycode.length > 0) {
            const newArrayOfObj = [similarcategoryTree].map(this.rename);
            var treelist = treemodalObj(newArrayOfObj);
            var snewlist = checkPropertyForMatch(
                treelist,
                this.state.similarcategorycode,
                'categorycode',
            );
            var stitles = getPropertyValues(
                treelist,
                this.state.similarcategorycode,
                'title',
                'categorycode',
            );
            this.setState({
                similartreeobj: snewlist,
                similarcategorytitle: stitles,
            });
        }
        if (this.state.recommendedcategorycode.length > 0) {
            const newArrayOfObj = [recommendcategoryTree].map(this.rename);
            var treelist = treemodalObj(newArrayOfObj);
            var rnewlist = checkPropertyForMatch(
                treelist,
                this.state.recommendedcategorycode,
                'categorycode',
            );
            var rtitles = getPropertyValues(
                treelist,
                this.state.recommendedcategorycode,
                'title',
                'categorycode',
            );
            this.setState({
                recommendtreeobj: rnewlist,
                recommendedcategorytitle: rtitles,
            });
        }

        const { specialDiscounts } = this.props.queryData;

        let _specialDiscounts = {};
        let _specialDiscount = {};
        if (
            specialDiscounts === undefined ||
            specialDiscounts['subcategories'] === undefined ||
            specialDiscounts['subcategories'].length <= 0
        ) {
        } else {
            specialDiscounts.subcategories
                .filter(
                    (e) =>
                        e.catgroup !== undefined &&
                        this.specialdiscounttypes.includes(e.catgroup),
                )
                .forEach((e) => {
                    let existing = [];
                    if (_specialDiscounts[[e.catgroup]] !== undefined) {
                        existing = _specialDiscounts[[e.catgroup]];
                    }

                    existing.push({ value: e.categorycode, label: e.title });

                    _specialDiscounts[[e.catgroup]] = existing;

                    //update the specialDiscount
                    if (this._isEditMode()) {
                        if (
                            this.state.specialdiscounts.includes(e.categorycode)
                        ) {
                            _specialDiscount[[e.catgroup]] = e.categorycode;
                        }
                    }
                });
        }

        this.setState({
            specialDiscounts: _specialDiscounts,
            specialDiscount: _specialDiscount,
        });

        const { itemSalesTypes } = this.props.queryData;

        if (
            itemSalesTypes &&
            itemSalesTypes.length > 0 &&
            itemSalesTypes[0].additionalfields
        ) {
            this.setState({
                itemsalestypes: itemSalesTypes[0].additionalfields,
            });
        }

        if (
            masterTrxGrpTypes &&
            masterTrxGrpTypes.length > 0 &&
            masterTrxGrpTypes[0].additionalfields
        ) {
            const trxGrpTypesKeys = Object.keys(
                masterTrxGrpTypes[0].additionalfields
            );
            const obj = trxGrpTypesKeys.reduce((accumulator, value) => {
                return { ...accumulator, [value]: value };
            }, {});
            this.setState({ trxgroups: obj });
            this.setState({
                reportTypesArr: masterTrxGrpTypes[0].additionalfields,
            });
        }
    };

    getTreeArray = (arrlist) => {
        const newArrayOfObj = [arrlist].map(this.rename);
        var treelist = treemodalObj(newArrayOfObj);
        return treelist;
    };

    componentDidUpdate = (prevProps) => {
        const {
            propconfigList,
            similarcategoryTree,
            recommendcategoryTree,
            categoryTree,
        } = this.props.queryData;
        const {
            propconfigList: prevpropconfigList,
            categoryTree: prevcategoryTree,
            similarcategoryTree: prevsimilarcategoryTree,
            recommendcategoryTree: prevrecommendcategoryTree,
        } = prevProps.queryData;
        if (this._isEditMode()) {
            const rec1 = this.props.location.state.item;
            if (propconfigList !== prevpropconfigList) {
                if (rec1.hasOwnProperty('productspec') === true) {
                    const getspec = propconfigList.filter((cc) =>
                        rec1.productspec.some(
                            (i) => i.propcode === cc.propcode,
                        ),
                    );
                    if (getspec.length > 0) {
                        let newprodspec = this.getPropArr(getspec);
                        this.setState({
                            productspecFields: newprodspec,
                        });
                    }
                }
            }
        }
        if (categoryTree !== prevcategoryTree) {
            const newArrayOfObj = this.getTreeArray(categoryTree);
            this.setState({
                categorytreeobj: newArrayOfObj,
            });
        }
        if (similarcategoryTree !== prevsimilarcategoryTree) {
            const newArrayOfObj = this.getTreeArray(similarcategoryTree);
            this.setState({
                similartreeobj: newArrayOfObj,
            });
        }
        if (recommendcategoryTree !== prevrecommendcategoryTree) {
            const newArrayOfObj = this.getTreeArray(recommendcategoryTree);
            this.setState({
                recommendtreeobj: newArrayOfObj,
            });
        }

        const { specialDiscounts } = this.props.queryData;
        const { specialDiscounts: prevspecialDiscounts } = prevProps.queryData;

        if (specialDiscounts !== prevspecialDiscounts) {
            let _specialDiscounts = {};
            let _specialDiscount = {};
            if (
                specialDiscounts === undefined ||
                specialDiscounts['subcategories'] === undefined ||
                specialDiscounts['subcategories'].length <= 0
            ) {
            } else {
                specialDiscounts.subcategories
                    .filter(
                        (e) =>
                            e.catgroup !== undefined &&
                            this.specialdiscounttypes.includes(e.catgroup),
                    )
                    .forEach((e) => {
                        let existing = [];
                        if (_specialDiscounts[[e.catgroup]] !== undefined) {
                            existing = _specialDiscounts[[e.catgroup]];
                        }

                        existing.push({
                            value: e.categorycode,
                            label: e.title,
                        });

                        _specialDiscounts[[e.catgroup]] = existing;

                        //update the specialDiscount
                        if (this._isEditMode()) {
                            if (
                                this.state.specialdiscounts.includes(
                                    e.categorycode,
                                )
                            ) {
                                _specialDiscount[[e.catgroup]] = e.categorycode;
                            }
                        }
                    });
            }

            this.setState({
                specialDiscounts: _specialDiscounts,
                specialDiscount: _specialDiscount,
            });

            const { itemSalesTypes } = this.props.queryData;
            const { itemSalesTypes: previtemSalesTypes } = prevProps.queryData;

            if (itemSalesTypes !== previtemSalesTypes) {
                if (
                    itemSalesTypes &&
                    itemSalesTypes.length > 0 &&
                    itemSalesTypes[0].additionalfields
                ) {
                    this.setState({
                        itemsalestypes: itemSalesTypes[0].additionalfields,
                    });
                }
            }
        }
    };

    rename = (obj) => {
        for (var prop in obj) {
            if (Array.isArray(obj[prop])) {
                obj[prop] = obj[prop].map(this.rename);
            }

            if (prop === 'subcategories') {
                obj.children = obj[prop];
                delete obj[prop];
            }
            obj['expanded'] = true;

            if (prop === 'title') {
                obj['subtitle'] = obj['categorycode'];
            }
        }

        return obj;
    };

    formatAdditionalFields = (additionalfields) => {
        if (additionalfields) {
            let array = [];
        for (let [key, value] of Object.entries(additionalfields)) {
            const rowLength = additionalfields.length;
            let g = { title: key, desc: value, id: rowLength };
            array.push(g);
        }
        return array;
        }
        return ''
    };

    componentWillMount = () => {
        this.callFetch('ONLOAD');
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage });
        this.props.dispatch(
            queryPropertyConfig({
                body: { status: 'Active', language: defLanguage },
            }),
        );
        this.props.dispatch(
            queryPropertyConfig(
                { body: { status: 'Active', propcode: 'ITEM_SALES_TYPES' } },
                CONSTANTS.SEARCH_ITEMSALESTYPE_PROPCONFIG,
                CONSTANTS.SEARCH_ITEMSALESTYPE_PROPCONFIG_SUCCESS,
                CONSTANTS.SEARCH_ITEMSALESTYPE_PROPCONFIG_FAILURE,
            ),
        );

        this.props.dispatch(
            searchGeneral(
                'sales/v1/category/categorytree/PD01',
                { body: {} },
                'CATEGORYTREE_SIMILARCODE_SUCCESS',
                'CATEGORYTREE_SIMILARCODE_FAILURE',
            ),
        );

        this.props.dispatch(
            searchGeneral(
                'sales/v1/category/categorytree/PD01',
                { body: {} },
                'CATEGORYTREE_RECOMMENDCODE_SUCCESS',
                'CATEGORYTREE_RECOMMENDCODE_FAILURE',
            ),
        );
        this.props.dispatch(
            searchGeneral(
                'cms/v1/prop/config/search/fields',
                {
                    body: { propgroup: 'product features', status: 'Active' },
                },
                'SEARCH_PRODUCT_FEATURE_SUCCESS',
                'SEARCH_PRODUCT_FEATURE_FAILURE',
            ),
        );
        this.props.dispatch(
            searchGeneral(
                'cms/v1/prop/config/search/fields',
                {
                    body: {
                        propgroup: 'size',
                        language: defLanguage,
                        status: 'Active',
                    },
                },
                'SEARCH_SIZECONFIG_SUCCESS',
                'SEARCH_SIZECONFIG_FAILURE',
            ),
        );
        this.props.dispatch(
            searchGeneral(
                'cms/v1/prop/config/search/fields',
                {
                    body: {
                        propgroup: 'color',
                        language: defLanguage,
                        status: 'Active',
                    },
                },
                'SEARCH_COLORCONFIG_SUCCESS',
                'SEARCH_COLORCONFIG_FAILURE',
            ),
        );
        this.props.dispatch(
            searchGeneral(
                '/sales/v1/category/search',
                {
                    body: {
                        search_field: 'catgroup',
                        search_condi: 'eq',
                        search_value: 'TAX',
                    },
                },
                'TAX_VALUE_SEARCH_SUCCESS',
                'TAX_VALUE_SEARCH_FAILURE',
            ),
        );

        this.props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propcode: 'PRODUCT_TYPES',
                    },
                },
                CONSTANTS.SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES,
                CONSTANTS.SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_SUCCESS,
                CONSTANTS.SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_FAILURE
            )
        );

        this.fetchaddongroupinfo();
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;
            if (rec) {
                const {
                    id,
                    title,
                    itemsalestype,
                    status,
                    sortorder,
                    language,
                    additionalfields,
                    addongroups,
                    images,
                    categorycodes,
                    pstock,
                    pstopsell,
                    deliveryperiodvalue,
                    deliveryperiod,
                    deliverynearby,
                    bulkyitem,
                    productdimensions,
                    orderbefore,
                    specialdiscounts,
                } = rec;
                let scode = [],
                    rcode = [];
                if (typeof rec.similarcategorycode !== 'undefined') {
                    scode = rec.similarcategorycode.split(',');
                }
                if (typeof rec.recommendedcategorycode !== 'undefined') {
                    rcode = rec.recommendedcategorycode.split(',');
                }
                this.props.dispatch(
                    searchGeneral(
                        'sales/v1/category/search',
                        {
                            body: {
                                search_field: 'categorycode',
                                search_condi: 'eq',
                                search_value: categorycodes,
                            },
                        },
                        'SEARCH_BY_CC_SUCCESS',
                        'SEARCH_BY_CC_FAILURE',
                    ),
                );
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.status = status ? status : '';
                        draft.title = title ? title : '';
                        draft.itemsalestype = itemsalestype
                            ? itemsalestype
                            : '';
                        draft.language = language ? language : '';
                        draft.sortorder = sortorder ? sortorder : 1;
                        draft.productgroup = rec.productgroup
                            ? rec.productgroup
                            : '';
                        draft.ptype = rec.ptype ? rec.ptype : '';
                        draft.brand = rec.brand ? rec.brand : '';
                        draft.productcode = rec.productcode
                            ? rec.productcode
                            : '';

                        draft.rfid = rec.rfid ? rec.rfid : '';
                        draft.pstopsell = pstopsell ? pstopsell : 'N';
                        draft.pstock = pstock ? pstock : '';
                        draft.categorycodes = rec.categorycodes
                            ? rec.categorycodes
                            : '';
                        draft.similarcategorycode = rec.similarcategorycode
                            ? scode
                            : [];
                        draft.recommendedcategorycode =
                            rec.recommendedcategorycode ? rcode : [];
                        draft.recommendedpcodes = rec.recommendedpcodes
                            ? rec.recommendedpcodes
                            : '';
                        draft.searchkeys = rec.searchkeys ? rec.searchkeys : '';
                        draft.similarpcodes = rec.similarpcodes
                            ? rec.similarpcodes
                            : '';
                        draft.sproductcode = rec.sproductcode
                            ? rec.sproductcode
                            : '';
                        draft.barcode = rec.barcode ? rec.barcode : '';
                        draft.sku = rec.sku ? rec.sku : '';
                        draft.producttag = rec.producttag ? rec.producttag : '';
                        draft.currency = rec.currency ? rec.currency : '';
                        draft.priceinfo = rec.priceinfo ? rec.priceinfo : '';
                        draft.quantity = rec.quantity ? rec.quantity : '';
                        draft.taxinfo = rec.taxinfo ? rec.taxinfo : '';
                        draft.expirydate = rec.expirydate
                            ? convertDate(rec.expirydate)
                            : '';
                        draft.uom = rec.uom ? rec.uom : '';
                        draft.productsize = rec.productsize
                            ? rec.productsize.split(',')
                            : '';
                        draft.productcolor = rec.productcolor
                            ? rec.productcolor.split(',')
                            : '';
                        draft.productweight = rec.productweight
                            ? rec.productweight
                            : '';
                        draft.productlogo = images ? images.productlogo : '';
                        draft.productimage1 = images
                            ? images.productimage1
                            : '';
                        draft.productimage2 = images
                            ? images.productimage2
                            : '';

                        draft.deliveryperiod = rec.hasOwnProperty(
                            'deliveryperiod',
                        )
                            ? deliveryperiod
                            : '';
                        draft.deliveryperiodvalue = rec.hasOwnProperty(
                            'deliveryperiodvalue',
                        )
                            ? deliveryperiodvalue
                            : '';
                        draft.deliverynearby = rec.hasOwnProperty(
                            'deliverynearby',
                        )
                            ? deliverynearby
                            : '';
                        draft.bulkyitem = rec.hasOwnProperty('bulkyitem')
                            ? bulkyitem
                            : '';
                        draft.unit = rec.hasOwnProperty('productdimensions')
                            ? productdimensions.unit
                            : '';
                        draft.width = rec.hasOwnProperty('productdimensions')
                            ? productdimensions.width
                            : '';
                        draft.height = rec.hasOwnProperty('productdimensions')
                            ? productdimensions.height
                            : '';
                        draft.length = rec.hasOwnProperty('productdimensions')
                            ? productdimensions.length
                            : '';
                        draft.volume = rec.hasOwnProperty('productdimensions')
                            ? productdimensions.volume
                            : '';

                        draft.orderbefore = rec.hasOwnProperty('orderbefore')
                            ? orderbefore
                            : '';

                        draft.dynamicDesc =
                        additionalfields && additionalfields.length === 'undefined' ||
                        additionalfields && additionalfields.length === 0
                                ? ''
                                : this.formatAdditionalFields(additionalfields);

                        if (window.config.SPECIAL_DISCOUNTS_ENABLED) {
                            let _specialdiscounts = specialdiscounts.trim();
                            draft.specialdiscounts =
                                _specialdiscounts !== undefined &&
                                _specialdiscounts !== ''
                                    ? _specialdiscounts.split(',')
                                    : [];
                        }
                    }),
                );
                if (addongroups) {
                    let data = addongroups;
                    var addonData = Object.keys(data)
                        .filter((key) => {
                            return data[key];
                        })
                        .map((key, index) => {
                            data[key].id = index;
                            return data[key];
                        });
                    if (addongroups.length === 0)
                        addonData = [];
                    this.setState({ addons: addonData });
                }
            }
        }

        if (window.config.SPECIAL_DISCOUNTS_ENABLED) {
            this.props.dispatch(
                queryCategoryTree(
                    '',
                    'SPECIALDISCOUNT',
                    '',
                    CONSTANTS.SPECIAL_DISCOUNT_TREE_SEARCH,
                    CONSTANTS.SPECIAL_DISCOUNT_SEARCH_SUCCESS,
                    CONSTANTS.SPECIAL_DISCOUNT_SEARCH_FAILURE,
                ),
            );
        }
    };
    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger w-100" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger w-100" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    getCreatePayload = () => {
        const {
            productgroup,
            ptype,
            brand,
            title,
            itemsalestype,
            productcode,
            rfid,
            pstock,
            pstopsell,
            categorycodes,
            recommendedpcodes,
            searchkeys,
            similarpcodes,
            sproductcode,
            barcode,
            sku,
            producttag,
            priceinfo,
            quantity,
            taxinfo,
            expirydate,
            uom,
            unit,
            productlogo,
            productimage1,
            productimage2,
            productsize,
            productcolor,
            productweight,
            sortorder,
            status,
            variantFields,
            addons,
            productspecFields,
            similarcategorycode,
            recommendedcategorycode,
            deliveryperiodvalue,
            deliveryperiod,
            bulkyitem,
            deliverynearby,
            width,
            height,
            length,
            volume,
            orderbefore,
            specialDiscount,
        } = this.state;
        let prspecs = productspecFields.map((specs) => ({
            propcode: specs.value,
        }));
        let body = {
            sproductcode,
            brand,
            ptype,
            sku,
            title,
            itemsalestype,
            categorycodes,
            similarcategorycode: similarcategorycode.join(),
            recommendedcategorycode: recommendedcategorycode.join(),
            status,
            productgroup,
            barcode,
            rfid,
            pstopsell,
            pstock,
            quantity,
            taxinfo,
            uom,
            productsize: productsize.toString(),
            productcolor: productcolor.toString(),
            productweight,
            priceinfo,
            producttag,
            searchkeys,
            recommendedpcodes,
            similarpcodes,
            additionalfields: {},
            expirydate: formatDate(expirydate),
            sortorder,
            productspec: prspecs,
            deliveryperiod,
            deliveryperiodvalue,
            deliverynearby,
            bulkyitem,
            orderbefore,
            addongroups:
                ptype !== 'MERCHANDISE_Addon'
                    ? addons.length === 1 && addons[0].groupid === ''
                        ? []
                        : addons
                    : [],

            specialdiscounts: window.config.SPECIAL_DISCOUNTS_ENABLED
                ? Object.values(specialDiscount).length > 0
                    ? Object.values(specialDiscount).join(',')
                    : ' '
                : null,
        };
        if (ptype === 'MERCHANDISE')
            Object.assign(body.additionalfields, variantFields);
        if (ptype !== 'MERCHANDISE_Addon')
            body['images'] = {
                productlogo: productlogo,
                productimage1: productimage1,
                productimage2: productimage2,
            };
        if (productcode !== '') {
            body['productcode'] = productcode;
        }
        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }
        if (unit === 'vol' || unit === 'kg' || unit === 'lt' || unit === 'ml') {
            body['productdimensions'] = {
                unit,
                volume: volume || '',
            };
        }
        if (unit !== 'vol' && unit !== 'kg' && unit !== 'lt' && unit !== 'ml') {
            body['productdimensions'] = {
                unit,
                width: parseInt(width) || '',
                height: parseInt(height) || '',
                length: parseInt(length) || '',
            };
        }
        const payload = {
            body: body,
        };
        return payload;
    };

    _createProduct = () => {
        if (this.validateForm()) {
            if (!this._isEditMode()) {
                this.props.dispatch(
                    createItem(
                        'sales/v1/product/save',
                        this.getCreatePayload(),
                    ),
                );
            } else {
                this.props.dispatch(
                    updateItem(
                        'sales/v1/product/update',
                        this.getCreatePayload(),
                    ),
                );
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };
    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            productcodeerror: '',
            ptypeerror: '',
            pgruoperror: '',
            titleerror: '',
            categoryerror: '',
            rfiderror: '',
            barcodeerror: '',
            skuerror: '',
            stopsellerror: '',
            sortordererror: '',
            deliveryerror: '',
        });
    };
    validateForm = () => {
        this._clearErrorMessages();
        const {
            productgroup,
            ptype,
            title,
            categorycodes,
            productcode,
            pstopsell,
            sortorder,
        } = this.state;
        let valid = true;
        var regex = /^$|^[0-9a-zA-Z\_]+$/;
        let checkcode = window && window.config.editProductCode;
        if (checkcode === true) {
            if (regex.test(productcode) === false) {
                this.setState({
                    productcodeerror: 'Please enter valid product code',
                });
                valid = false;
            }
        }
        if (!this._isEditMode() && productgroup.trim() === '') {
            this.setState({ pgruoperror: 'Product Group is required.' });
            valid = false;
        }
        if (ptype.trim() === '') {
            this.setState({ ptypeerror: 'Product Type is required.' });
            valid = false;
        }
        if (title.trim() === '') {
            this.setState({ titleerror: 'Title is required.' });
            valid = false;
        }
        if (pstopsell === '') {
            this.setState({ stopsellerror: 'Stop Sell is required' });
            valid = false;
        }
        if (sortorder === '') {
            this.setState({ sortordererror: 'sortorder is required.' });
            valid = false;
        }
        if (ptype !== 'MERCHANDISE_Addon') {
            if (categorycodes.trim() === '') {
                this.setState({ categoryerror: 'Category is required.' });
                valid = false;
            }
        }
        return valid;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <>
                    <Link
                        to="/ui/product-catalog"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={this._createProduct}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={this._createProduct}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/product-catalog"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </>
        );
    };
    _setDynamicFields = () => {
        const { prodSpecList } = this.props.queryData;
        let ptype = this.state.ptype;
        if (prodSpecList.length !== 0 && ptype) {
            let dynamicList =
                prodSpecList.length !== undefined &&
                prodSpecList.filter((el) => el.categorycode === ptype);

            if (dynamicList && dynamicList[0] && dynamicList[0].description) {
                let desclist =
                    dynamicList[0].description &&
                    dynamicList[0].description.split(',');
                this._formatFieldLabels(desclist);
            }
        }
    };
    _formatFieldLabels = (array) => {
        let newArray = array.map((item) => {
            let g = { title: item };
            return g;
        });
        this.setState({ dynamicFields: newArray });
    };

    _renderProdType = () => {
        const { masterTrxGrpTypes } = this.props.queryData
        let productTypes = []
        if (
            masterTrxGrpTypes &&
            masterTrxGrpTypes.length > 0 &&
            masterTrxGrpTypes[0].additionalfields
        ) {
            const trxGrpTypesKeys = Object.keys(
                masterTrxGrpTypes[0].additionalfields
            );
            const obj = trxGrpTypesKeys.reduce((accumulator, value) => {
                return { ...accumulator, [value]: value };
            }, {});
            const output = Object.entries(obj).reduce((result, [name, code]) => {
                result.push({ name, code, title: name, id: code });
                return result;
              }, [{ name: 'Select', code: '', id: '-1' }]);
            productTypes = output
        }
        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-8 col-sm-12 ">
                        <label
                            htmlFor="prodType"
                            className="floatLeft required"
                        >
                            Product Type
                        </label>

                        <Filter
                            cssClass="form-control"
                            // filterOptions={
                            //     prodType
                            //         ? renderFilterForProdSpec(prodType)
                            //         : []
                            // }
                            filterOptions={productTypes}
                            callbackChangeFilter={(event) => {
                                let value = event.target.value;
                                this.setState({ ptype: value }, () => {
                                    this.setState({
                                        dynamicDesc: [
                                            { title: '', desc: '', id: 0 },
                                        ],
                                    });
                                    this._setDynamicFields();
                                });
                            }}
                            selectedOption={this.state.ptype}
                            id="ptype"
                        />
                        <div className="invalid-feedback">
                            {this.state.ptypeerror}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    objectArr = (list) => {
        if (list.length > 0) {
            return list.reduce(function (acc, item) {
                acc[item.propcode] = item.propvalue;
                return acc;
            }, {});
        }
    };

    setProductColor = (value) => {
        this.setState({
            productcolor: value,
        });
    };

    setProductSize = (value) => {
        this.setState({
            productsize: value,
        });
    };

    _renderMainFormDetails = () => {
        const { sizePropList, colorPropList } = this.props.queryData;
        let sizelist = this.objectArr(sizePropList);
        let colorlist = this.objectArr(colorPropList);

        return (
            <>
                <div className="row sub-title">Product Details</div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="productgroup"
                            className={cx({
                                floatLeft: true,
                                required: !this._isEditMode(),
                            })}
                        >
                            Product Group
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="productgroup"
                            value={this.state.productgroup}
                            onChange={(event) =>
                                this.setState({
                                    productgroup: event.target.value,
                                })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.pgruoperror}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        {this._isEditMode() === false ? (
                            <div>
                                <label
                                    htmlFor="productcode"
                                    className="floatLeft required"
                                >
                                    Product Code
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="productcode"
                                    value={this.state.productcode}
                                    onChange={(event) =>
                                        this.setState({
                                            productcode: event.target.value,
                                        })
                                    }
                                />
                            </div>
                        ) : (
                            <div>
                                <label
                                    htmlFor="productcode"
                                    className="floatLeft"
                                >
                                    Product Code
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="productcode"
                                    value={this.state.productcode}
                                    readOnly={true}
                                />{' '}
                            </div>
                        )}
                        <div className="invalid-feedback">
                            {this.state.productcodeerror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft required">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.titleerror}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="itemsalestype" className="floatLeft">
                            Product Sales Type
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={formatLanguageArray(
                                this.state.itemsalestypes,
                                true,
                            )}
                            selectedOption={this.state.itemsalestype}
                            callbackChangeFilter={(event) => {
                                this.setState({
                                    itemsalestype: event.target.value,
                                });
                            }}
                            id="itemsalestype"
                            label="Select Sales Type"
                        />
                    </div>
                </div>
                {this.state.ptype !== 'MERCHANDISE_Addon' && (
                    <>
                        <div className="row">
                            <div className="form-group col-lg-6 col-sm-12">
                                <label htmlFor="brand" className="floatLeft">
                                    Brand
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="brand"
                                    value={this.state.brand}
                                    onChange={(event) =>
                                        this.setState({
                                            brand: event.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="productcolor"
                                    className="floatLeft"
                                >
                                    Color
                                </label>
                                {colorPropList.length > 0 ? (
                                    <MultiSelect
                                        filterOptions={renderConfigInMultiSelect(
                                            colorlist,
                                        )}
                                        setSelectedFieldValues={
                                            this.setProductColor
                                        }
                                        id="color"
                                        label="select color"
                                        itemsSelected={this.state.productcolor}
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="productcolor"
                                        value={this.state.productcolor}
                                        onChange={(event) =>
                                            this.setState({
                                                productcolor:
                                                    event.target.value,
                                            })
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="productweight"
                                    className="floatLeft"
                                >
                                    Weight
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="productweight"
                                    value={this.state.productweight}
                                    onChange={(event) =>
                                        this.setState({
                                            productweight: event.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="productsize"
                                    className="floatLeft"
                                >
                                    Size
                                </label>
                                {sizePropList.length > 0 ? (
                                    <MultiSelect
                                        filterOptions={renderConfigInMultiSelect(
                                            sizelist,
                                        )}
                                        setSelectedFieldValues={
                                            this.setProductSize
                                        }
                                        id="size"
                                        label="select size"
                                        itemsSelected={this.state.productsize}
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="productsize"
                                        value={this.state.productsize}
                                        onChange={(event) =>
                                            this.setState({
                                                productsize: event.target.value,
                                            })
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };
    _renderVariants = () => {
        const { dynamicFields, dynamicDesc } = this.state;

        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="productweight" className="floatLeft">
                            Additional Fields (Choose product type to add
                            additional fields)
                        </label>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-12 col-md-12">
                        <DynamicDesc
                            setDesc={(value) => {
                                this.setState({ variantFields: value });
                            }}
                            dynamicDesc={dynamicDesc}
                            variantField={dynamicFields}
                            isEdit={this._isEditMode()}
                        ></DynamicDesc>
                    </div>
                </div>
            </>
        );
    };

    _getCategoryTitle = (categorycodes) => {
        const splittedCodes = categorycodes.split(',');
    };

    _renderOrg = () => {
        const { categoryTree } = this.props.queryData;
        const newArrayOfObj = [categoryTree].map(this.rename);
        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label
                            htmlFor="categorycodes"
                            className="floatLeft required"
                        >
                            Category
                        </label>
                        <CategoryTree
                            treeData={newArrayOfObj}
                            categoryTree={categoryTree}
                            setCategoryCode={(code, title) => {
                                this.setState({
                                    categorycodes: code,
                                    categories: title,
                                });
                            }}
                            value={this.state.categories}
                        ></CategoryTree>
                        <div className="invalid-feedback">
                            {this.state.categoryerror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label
                            htmlFor="similarcategorycode"
                            className="floatLeft"
                        >
                            Similar Category
                        </label>
                        <input
                            type="text"
                            id="similarcategorycode"
                            value={this.state.similarcategorytitle}
                            onClick={this._showSimilarModal}
                            readOnly
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label
                            htmlFor="recommendedcategorycode"
                            className="floatLeft"
                        >
                            Recommended Category
                        </label>
                        <input
                            type="text"
                            id="recommendedcategorycode"
                            value={this.state.recommendedcategorytitle}
                            onClick={this._showRecommendModal}
                            readOnly
                        />
                    </div>
                </div>
                {this.state.ptype !== 'MERCHANDISE_Addon' && (
                    <>
                        <div className="row">
                            <div className="form-group col-lg-12 col-sm-12">
                                <label htmlFor="rfid" className="floatLeft">
                                    RFID
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="rfid"
                                    value={this.state.rfid}
                                    onChange={(event) =>
                                        this.setState({
                                            rfid: event.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-12 col-sm-12">
                                <label
                                    htmlFor="producttag"
                                    className="floatLeft"
                                >
                                    Product Tag
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="producttag"
                                    value={this.state.producttag}
                                    onChange={(event) =>
                                        this.setState({
                                            producttag: event.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-12 col-sm-12">
                                <label
                                    htmlFor="searchkeys"
                                    className="floatLeft"
                                >
                                    Search Keys
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="searchkeys"
                                    value={this.state.searchkeys}
                                    onChange={(event) =>
                                        this.setState({
                                            searchkeys: event.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-12 col-sm-12">
                                <label
                                    htmlFor="similarpcodes"
                                    className="floatLeft"
                                >
                                    Similar Product Codes
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="similarpcodes"
                                    value={this.state.similarpcodes}
                                    onChange={(event) =>
                                        this.setState({
                                            similarpcodes: event.target.value,
                                        })
                                    }
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-12 col-sm-12">
                                <label
                                    htmlFor="recommendedpcodes"
                                    className="floatLeft"
                                >
                                    Product Recommended
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="recommendedpcodes"
                                    value={this.state.recommendedpcodes}
                                    onChange={(event) =>
                                        this.setState({
                                            recommendedpcodes:
                                                event.target.value,
                                        })
                                    }
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-12 col-sm-12">
                                <label
                                    htmlFor="pstopsell"
                                    className="floatLeft required"
                                >
                                    Stop Sell
                                </label>
                                <SimpleSelect
                                    objArray={{ Y: 'Yes', N: 'No' }}
                                    callbackChangeFilter={(value) =>
                                        this.setState({ pstopsell: value })
                                    }
                                    selectedOption={this.state.pstopsell}
                                    id="pstopsell"
                                />
                                <div className="invalid-feedback">
                                    {this.state.stopsellerror}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-12 col-sm-12">
                                <label htmlFor="pstock" className="floatLeft">
                                    Product in Stock
                                </label>
                                <SimpleSelect
                                    objArray={{ Y: 'Yes', N: 'No' }}
                                    callbackChangeFilter={(value) =>
                                        this.setState({ pstock: value })
                                    }
                                    selectedOption={this.state.pstock}
                                    disabled={true}
                                    id="pstock"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-12 col-sm-12">
                                <label
                                    htmlFor="expirydate"
                                    className="floatLeft"
                                >
                                    Expiry Date
                                </label>

                                <DatePicker
                                    onChange={(value) => {
                                        this.setState({ expirydate: value });
                                    }}
                                    value={this.state.expirydate}
                                />
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

    handleDeliveryNearby = (value) => {
        if (value === '0') {
            this.setState({ deliverynearby: '' });
        } else {
            this.setState({ deliverynearby: value });
        }
    };

    handleBulkyItem = (value) => {
        if (value === '0') {
            this.setState({ bulkyitem: '' });
        } else {
            this.setState({ bulkyitem: value });
        }
    };

    handleSpecialDiscountsSelection = (e, group) => {
        if (e.target.value === 'none') {
            let specialDiscount = { ...this.state.specialDiscount };
            delete specialDiscount[[group]];
            this.setState({ specialDiscount });
            return;
        }

        let specialDiscount = { ...this.state.specialDiscount };
        specialDiscount[[group]] = e.target.value;
        this.setState({ specialDiscount });
    };

    _renderSpecialDiscDetails = () => {
        return (
            <>
                <div className="row sub-title">Special Discount Details</div>

                {Object.keys(this.state.specialDiscounts).map((e, i) => (
                    <div key={i}>
                        <div className="row">
                            <div className="form-group col-lg-12 col-sm-12">
                                <label htmlFor={e} className="floatLeft">
                                    {this.specialdiscounts[[e]]}
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-2 col-sm-12">
                                <label htmlFor="none" className="floatLeft">
                                    <input
                                        type="radio"
                                        value="none"
                                        onChange={(event) =>
                                            this.handleSpecialDiscountsSelection(
                                                event,
                                                e,
                                            )
                                        }
                                        checked={
                                            this.state.specialDiscount[[e]] ===
                                            undefined
                                        }
                                        disabled={this._isViewMode()}
                                    />
                                    None
                                </label>
                            </div>
                            {this.state.specialDiscounts[[e]].length > 0 &&
                                this.state.specialDiscounts[[e]].map((disc) => (
                                    <div
                                        className="form-group col-lg-2 col-sm-12"
                                        key={disc.value}
                                    >
                                        <label
                                            htmlFor={disc.value}
                                            className="floatLeft"
                                        >
                                            <input
                                                type="radio"
                                                value={disc.value}
                                                onChange={(event) =>
                                                    this.handleSpecialDiscountsSelection(
                                                        event,
                                                        e,
                                                    )
                                                }
                                                checked={
                                                    this.state.specialDiscount[
                                                        [e]
                                                    ] === disc.value
                                                }
                                                disabled={this._isViewMode()}
                                            />
                                            {disc.label}
                                        </label>
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
            </>
        );
    };

    _renderInventory = () => {
        return (
            <>
                <div className="row sub-title">More Details</div>
                <div className=" row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="sproductcode" className="floatLeft">
                            Supplier Product Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="sproductcode"
                            value={this.state.sproductcode}
                            onChange={(event) =>
                                this.setState({
                                    sproductcode: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="barcode" className="floatLeft">
                            Bar Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="barcode"
                            value={this.state.barcode}
                            onChange={(event) =>
                                this.setState({ barcode: event.target.value })
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="sku" className="floatLeft">
                            Product Sku
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="sku"
                            value={this.state.sku}
                            onChange={(event) =>
                                this.setState({ sku: event.target.value })
                            }
                        />
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="uom" className="floatLeft">
                            Unit Of Measurement
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="uom"
                            value={this.state.uom}
                            onChange={(event) =>
                                this.setState({ uom: event.target.value })
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="deliveryperiod" className="floatLeft">
                            Delivery Period
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={renderCouponOptionsFilter(
                                this.deliveryperiod,
                            )}
                            selectedOption={this.state.deliveryperiod}
                            callbackChangeFilter={(event) => {
                                this.setState({
                                    deliveryperiod: event.target.value,
                                });
                            }}
                            id="deliveryperiod"
                        />
                        <div className="invalid-feedback">
                            {this.state.deliveryerror}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="deliveryperiodvalue"
                            className="floatLeft"
                        >
                            Delivery Period Value
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="deliveryperiodvalue"
                            value={this.state.deliveryperiodvalue}
                            onChange={(event) =>
                                this.setState({
                                    deliveryperiodvalue: parseInt(
                                        event.target.value,
                                    ),
                                })
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-sm-12 col-lg-6">
                        <label htmlFor="deliverynearby " className="floatLeft">
                            Delivery NearBy
                        </label>
                        <SimpleSelect
                            objArray={{ Y: 'Yes', N: 'No' }}
                            callbackChangeFilter={(value) =>
                                this.handleDeliveryNearby(value)
                            }
                            selectedOption={this.state.deliverynearby}
                            id="deliverynearby"
                        />
                    </div>
                    <div className="form-group col-sm-12 col-lg-6">
                        <label htmlFor="bulkyitem" className="floatLeft">
                            Bulky Item
                        </label>
                        <SimpleSelect
                            objArray={{ Y: 'Yes', N: 'No' }}
                            callbackChangeFilter={(value) =>
                                this.handleBulkyItem(value)
                            }
                            selectedOption={this.state.bulkyitem}
                            id="bulkyitem"
                        />
                    </div>
                </div>

                <div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="orderbefore" className="floatLeft">
                            Order Before
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="orderbefore"
                            min="0"
                            value={this.state.orderbefore}
                            onChange={(event) =>
                                this.setState({
                                    orderbefore: parseInt(event.target.value),
                                })
                            }
                        />
                    </div>
                </div>
            </>
        );
    };

    _renderDimensionDetails = () => {
        const { unit, volume, length } = this.state;
        return (
            <>
                <div className="row sub-title">
                    Product Dimensions/Volume Details
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="length" className="floatLeft">
                            Unit
                        </label>
                        <SimpleSelect
                            objArray={{
                                cm: 'centimeters',
                                kg: 'kilogram(1 kg=1000 cu cm)',
                                lt: 'liter (1 lt=1000 ml)',
                            }}
                            callbackChangeFilter={(value) =>
                                this.setState({ unit: value })
                            }
                            selectedOption={this.state.unit}
                            id="unit"
                        />
                    </div>
                    {['vol', 'kg', 'lt', 'ml'].includes(unit) ? (
                        <div className="form-group col-lg-6 col-sm-12">
                            <label htmlFor="volume" className="floatLeft">
                                Volume
                            </label>
                            {/* <input
                                type="number"
                                className="form-control"
                                id="volume"
                                value={volume}
                                min="0"
                                onChange={(event) =>
                                    this.setState({
                                        volume: event.target.value,
                                    })
                                }
                            /> */}
                            <InputNumber
                                className='w-100'
                                value={volume}
                                onValueChange={(e) => this.setState({
                                    volume: e.value
                                })}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                decimalSeparator="."
                            />
                        </div>
                    ) : (
                        <div className="form-group col-lg-6 col-sm-12">
                            <label htmlFor="length" className="floatLeft">
                                Length
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="length"
                                name="length"
                                value={length}
                                min="0"
                                onChange={(event) =>
                                    this.setState({
                                        length: event.target.value,
                                    })
                                }
                            />
                        </div>
                    )}
                </div>
                {(this.state.unit !== 'vol' && this.state.unit !== 'kg') &&
                this.state.unit !== 'lt' && this.state.unit !== 'ml' ? (
                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label htmlFor="width" className="floatLeft">
                                Width
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="width"
                                value={this.state.width}
                                min="0"
                                onChange={(event) =>
                                    this.setState({ width: event.target.value })
                                }
                            />
                        </div>
                        <div className="form-group col-lg-6 col-sm-12">
                            <label htmlFor="height" className="floatLeft">
                                Height
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="height"
                                min="0"
                                value={this.state.height}
                                onChange={(event) =>
                                    this.setState({
                                        height: event.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </>
        );
    };

    _renderImageDetails = () => {
        return (
            <>
                <div className="row sub-title">Images</div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="productlogo" className="floatLeft">
                            Product Logo
                        </label>
                        <SimpleImageInput
                            placeholder="Product Logo.."
                            setImage={(value) => {
                                this.setState({ productlogo: value });
                            }}
                            value={this.state.productlogo}
                        ></SimpleImageInput>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="productimage1" className="floatLeft">
                            Product Image 1
                        </label>
                        <SimpleImageInput
                            placeholder="Product Image 1"
                            setImage={(value) => {
                                this.setState({ productimage1: value });
                            }}
                            value={this.state.productimage1}
                        ></SimpleImageInput>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="productimage2" className="floatLeft">
                            Product Image 2
                        </label>
                        <SimpleImageInput
                            placeholder="Product Image 2"
                            setImage={(value) => {
                                this.setState({ productimage2: value });
                            }}
                            value={this.state.productimage2}
                        ></SimpleImageInput>
                    </div>
                </div>
            </>
        );
    };
    setImages = (images) => {
        this.setState({ images: images });
    };
    _renderStatusDetails = () => {
        return (
            <>
                <div className="row sub-title">Actions</div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusComp
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                            customStatus={{
                                Active: 'Active',
                                New: 'New',
                                Updated: 'Updated',
                                Deleted: 'Deleted',
                                Inactive: 'Inactive',
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label
                            htmlFor="sortorder"
                            className="floatLeft required"
                        >
                            Sort Order
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="sortorder"
                            value={this.state.sortorder}
                            min="0"
                            onChange={(event) =>
                                this.setState({ sortorder: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.sortordererror}
                        </div>
                    </div>
                </div>
            </>
        );
    };
    handleAddonAddRow = (e) => {
        if (e) e.preventDefault();
        const rowLength = this.state.addons.length;
        const grplength = this._getGroupsCount();
        let lastele = '';
        let sortorder = 1;
        let id = 0;
        if (rowLength < grplength) {
            if (rowLength !== 0) {
                lastele = this.state.addons[rowLength - 1];
                sortorder = parseInt(lastele.sortorder + 1);
                id = lastele.id + 1;
            }

            const item = {
                groupid: '',
                title: '',
                title_long: '',
                description: '',
                sortorder: parseInt(sortorder),
                multiselect: '',
                customizable: 'N',
                id: id,
            };
            this.setState(
                {
                    addons: [...this.state.addons, item],
                },
                () => {
                    console.log('Rows After Add', this.state.addons);
                },
            );
        } else {
            this.toast.show({
                severity: 'error',
                summary: '',
                detail: `Maximum of ${rowLength} is allowed`,
                life: 3000,
            });
        }
    };

    handleAddonInputChange = (event, dataField, row) => {
        let obj;
        const groupsadded = this.state.addons.map((addon) => {
            return addon.groupid;
        });
        const groupssortadded = this.state.addons.map((addon) => {
            return addon.sortorder;
        });
        const groupscustomadded = this.state.addons.map((addon) => {
            return addon.customizable;
        });
        if (dataField === 'groupid') {
            if (groupsadded.indexOf(event.target.value) >= 0) {
                this.toast.show({
                    severity: 'error',
                    summary: '',
                    detail: `Group already added !`,
                    life: 3000,
                });
                return;
            }
        }
        if (dataField === 'sortorder') {
            if (groupssortadded.indexOf(parseInt(event.target.value)) >= 0) {
                this.toast.show({
                    severity: 'error',
                    summary: '',
                    detail: `Sort order should be unique !`,
                    life: 3000,
                });
                return;
            }
        }
        if (dataField === 'customizable' && event.target.value === 'Y') {
            if (groupscustomadded.indexOf(event.target.value) >= 0) {
                this.toast.show({
                    severity: 'error',
                    summary: '',
                    detail: `Only one Addon Group can be customizable...!`,
                    life: 3000,
                });
                return;
            }
        }
        if (dataField === 'groupid') {
            const restdata = this._getDataFromGroups(event.target.value);

            obj = {
                id: row.id,
                [dataField]: event.target.value,
                sortorder: parseInt(row.sortorder),
                multiselect: 'Y',
                description: restdata[0].description || '',
                title: restdata[0].title || '',
                title_long: restdata[0].title_long || '',
                customizable: 'N',
            };
        } else {
            if (dataField === 'sortorder') {
                obj = {
                    id: row.id,
                    [dataField]: parseInt(event.target.value) || 0,
                };
            } else {
                obj = {
                    id: row.id,
                    [dataField]: event.target.value,
                };
            }
        }

        this.setState(
            produce((draft) => {
                if (draft.addons.length === 0) {
                    draft.addons.push(obj);
                } else {
                    let found = false;
                    draft.addons.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.addons.push(obj);
                    }
                }
            }),
            () => {
                console.log(this.state.addons);
            },
        );
    };
    deleteAddonRow = (row) => {
        let rowId = row.id;

        const updateValues = this.state.addons.filter(
            (value) => rowId !== value.id,
        );
        this.setState({ addons: updateValues }, () => {});
    };
    _getDataFromGroups = (groupid) => {
        const { articleFlowData } = this.props.queryData;
        const groups =
            articleFlowData &&
            articleFlowData.map((article) => {
                return article.fields;
            });
        const data = groups.filter((group) => {
            return groupid === group.groupid;
        });
        return data;
    };
    _getGroupsCount = () => {
        const { articleFlowData } = this.props.queryData;
        const groups =
            articleFlowData &&
            articleFlowData.map((article) => {
                return article.fields;
            });

        return groups.length;
    };
    _getGroupCombo = () => {
        const { articleFlowData } = this.props.queryData;
        const groups =
            articleFlowData &&
            articleFlowData.map((article) => {
                return {
                    id: article.fields && article.fields.groupid,
                    name: article.fields && article.fields.title,
                };
            });

        return groups;
    };
    _renderGroupingDetails = () => {
        const groups = this._getGroupCombo();

        return (
            <div className="row">
                <div className="form-group col-lg-12 col-sm-12">
                    <DynamicTableAddONs
                        columns={[
                            {
                                dataFieldId: 'groupid',
                                label: 'Addon Group',
                                addon: true,
                                group: true,
                            },
                            {
                                dataFieldId: 'title',
                                label: 'Title',
                                addRow: true,
                                disabled: true,
                            },
                            {
                                dataFieldId: 'description',
                                label: 'Description',
                                addRow: true,
                                disabled: true,
                            },
                            {
                                dataFieldId: 'sortorder',
                                label: 'Sort Order',
                                addRow: true,
                            },
                            {
                                dataFieldId: 'multiselect',
                                label: 'MultiSelect',
                                check: true,
                            },
                            {
                                dataFieldId: 'customizable',
                                label: 'Customizable',
                                check: true,
                            },
                            {
                                dataFieldId: 'AD',
                                label: '',
                            },
                        ]}
                        groups={groups}
                        rows={this.state.addons}
                        deleteRow={this.deleteAddonRow}
                        addRow={this.handleAddonAddRow}
                        handleInputChange={this.handleAddonInputChange}
                    />
                </div>
            </div>
        );
    };

    _showPropModal = (event) => {
        this.setState({
            showPropModal: true,
        });
        if (event) event.preventDefault();
    };

    _closePropModal = (event) => {
        this.setState({ showPropModal: false });
        if (event) event.preventDefault();
    };

    _showSimilarModal = (event) => {
        this.setState({
            showSimilarModal: true,
        });
        if (event) event.preventDefault();
    };

    _closeSimilarModal = (event) => {
        this.setState({ showSimilarModal: false });
        if (event) event.preventDefault();
    };

    _showRecommendModal = (event) => {
        this.setState({
            showRecommendModal: true,
        });
        if (event) event.preventDefault();
    };

    _closeRecommendModal = (event) => {
        this.setState({ showRecommendModal: false });
        if (event) event.preventDefault();
    };

    _showCategoryModal = (event) => {
        this.setState({
            showCategoryModal: true,
        });
        if (event) event.preventDefault();
    };

    _closeCategoryModal = (event) => {
        this.setState({ showCategoryModal: false });
        if (event) event.preventDefault();
    };

    getPropArr = (objlist) => {
        const proparr = Object.values(objlist);
        const newList = proparr.map((i) => ({
            group: i.propgroup,
            label: i.propvalue,
            value: i.propcode,
        }));
        return newList;
    };

    setProductSpecs = (values) => {
        this.setState({
            productspecFields: values,
        });
    };

    textareaHandler = (val) => {
        const { productspecFields } = this.state;
        let filterdata = productspecFields.filter((item) => item.value !== val);
        this.setState({
            productspecFields: filterdata,
        });
    };

    _renderProductModal = () => {
        const { propconfigList, prodFeatureList } = this.props.queryData;
        const prodconfigList = prodFeatureList
            .map((e) => e.additionalfields)
            .filter((item) => item && item !== undefined);
        const uniqueproplist = propconfigList.filter(({ propgroup }) =>
            prodconfigList.find((obj) =>
                Object.values(obj).includes(propgroup),
            ),
        );
        const newpropconfig = this.getPropArr(uniqueproplist);
        return (
            <Modal
                visible={this.state.showPropModal}
                width="70%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closePropModal()}
            >
                <div className="comboform">
                    <div className="row">
                        <div className="col-lg-12 col-xs-12 col-md-12 alignRight">
                            <a
                                onClick={this._closePropModal}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-12 groupdiv">
                            <DynamicProductGroup
                                showProp={true}
                                propoptions={newpropconfig}
                                setProductSpecs={this.setProductSpecs}
                                hidePropModal={this._closePropModal}
                                productspecs={this.state.productspecFields}
                            ></DynamicProductGroup>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    _renderProductDescription = () => {
        var contentareastyle = {
            display: 'inline-flex',
            flexFlow: 'wrap',
            border: '1px solid #dcdcdc',
            padding: '10px',
            overflowY: 'scroll',
            maxHeight: '200px',
        };
        const { productspecFields } = this.state;
        return (
            <>
                <div className="row sub-title">Product Features</div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <div className="row">
                            <button
                                style={{ marginTop: '1em' }}
                                className="addNewRow"
                                onClick={this._showPropModal}
                            >
                                Add New
                            </button>
                        </div>
                        {this.state.productspecFields.length > 0 ? (
                            <div class="row">
                                <div
                                    style={contentareastyle}
                                    className="col-lg-12 col-sm-12 floatLeft noMargin noPadding"
                                >
                                    {productspecFields.map((specvalue) => {
                                        return (
                                            <div
                                                className="text-content"
                                                key={specvalue.value}
                                            >
                                                <input
                                                    type="text"
                                                    value={specvalue.label}
                                                    className="inputfield"
                                                    readOnly
                                                />
                                                <button
                                                    className="btn-primary btntext"
                                                    type="button"
                                                    onClick={() =>
                                                        this.textareaHandler(
                                                            specvalue.value,
                                                        )
                                                    }
                                                >
                                                    x
                                                </button>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    handleSimilarCode = (currentNode, selectedNodes) => {
        const newList = uniqueArrayObject(selectedNodes);
        this.setState(
            {
                selectedsimilarcode: newList,
            },
            () => {
                this.handleSubmit1();
            },
        );
    };

    handleSubmit1 = () => {
        const { selectedsimilarcode, similartreeobj } = this.state;
        if (selectedsimilarcode.length > 0) {
            const similarcode = [
                ...new Set(selectedsimilarcode.map((e) => e.title)),
            ];
            const categorycodes = [
                ...new Set(selectedsimilarcode.map((e) => e.categorycode)),
            ];
            const getsimilarcode = [
                ...new Set(selectedsimilarcode.map((e) => e.id)),
            ];
            this.setState(
                {
                    similarcategorytitle: similarcode.toString(),
                    similarcategorycode: categorycodes,
                },
                () => {
                    var newtree = checkPropertyForMatch(
                        similartreeobj,
                        getsimilarcode,
                    );
                    this.setState({
                        similartreeobj: newtree,
                    });
                },
            );
        } else {
            const { categoryTree } = this.props.queryData;
            const newArrayOfObj = [categoryTree].map(this.rename);
            var treelist = treemodalObj(newArrayOfObj);
            this.setState({
                similarcategorycode: [],
                similarcategorytitle: '',
                similartreeobj: treelist,
            });
        }
    };

    handleRecommendCode = (currentNode, selectedNodes) => {
        const newList = uniqueArrayObject(selectedNodes);
        this.setState(
            {
                selectedrecommendcode: newList,
            },
            () => {
                this.handleSubmit2();
            },
        );
    };

    handleSubmit2 = () => {
        const { selectedrecommendcode, recommendtreeobj } = this.state;
        if (selectedrecommendcode.length > 0) {
            const recommendcode = [
                ...new Set(selectedrecommendcode.map((e) => e.title)),
            ];
            const categorycodes = [
                ...new Set(selectedrecommendcode.map((e) => e.categorycode)),
            ];
            const getrecommendcode = [
                ...new Set(selectedrecommendcode.map((e) => e.id)),
            ];
            this.setState(
                {
                    recommendedcategorytitle: recommendcode.toString(),
                    recommendedcategorycode: categorycodes,
                },
                () => {
                    var newtree = checkPropertyForMatch(
                        recommendtreeobj,
                        getrecommendcode,
                    );
                    this.setState({
                        recommendtreeobj: newtree,
                    });
                },
            );
        } else {
            const { categoryTree } = this.props.queryData;
            const newArrayOfObj = [categoryTree].map(this.rename);
            var treelist = treemodalObj(newArrayOfObj);
            this.setState({
                recommendedcategorycode: [],
                recommendedcategorytitle: '',
                recommendtreeobj: treelist,
            });
        }
    };
    _renderSimilarModal = () => {
        return (
            <Modal
                visible={this.state.showSimilarModal}
                width="70%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeSimilarModal()}
            >
                <div className="comboform">
                    <div className="row">
                        <div className="col-lg-12 col-xs-12 col-md-12 alignRight">
                            <a
                                onClick={this._closeSimilarModal}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-lg-12 col-12 similarcode groupdiv"
                            style={{ height: '12em' }}
                        >
                            <DropdownTree
                                data={this.state.similartreeobj}
                                onChange={this.handleSimilarCode}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    _renderRecommendModal = () => {
        return (
            <Modal
                visible={this.state.showRecommendModal}
                width="70%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeRecommendModal()}
            >
                <div className="comboform">
                    <div className="row">
                        <div className="col-lg-12 col-xs-12 col-md-12 alignRight">
                            <a
                                onClick={this._closeRecommendModal}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-lg-12 col-12 recommendcode groupdiv"
                            style={{ height: '12em' }}
                        >
                            <DropdownTree
                                data={this.state.recommendtreeobj}
                                onChange={this.handleRecommendCode}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    handleCategory = (currentNode, selectedNodes) => {
        this.setState(
            {
                categorycodes: currentNode.categorycode,
                categories: currentNode.title,
            },
            () => {
                this._closeCategoryModal();
            },
        );
    };

    _renderCategoryModal = () => {
        return (
            <Modal
                visible={this.state.showCategoryModal}
                width="70%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeCategoryModal()}
            >
                <div className="comboform">
                    <div className="row">
                        <div className="col-lg-12 col-xs-12 col-md-12 alignRight">
                            <a
                                onClick={this._closeCategoryModal}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-lg-12 col-12 categorycode groupdiv"
                            style={{ height: '12em' }}
                        >
                            <DropdownTree
                                mode="radioSelect"
                                data={this.state.categorytreeobj}
                                onChange={this.handleCategory}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    _renderFormArea = () => {
        const { ptype } = this.state;
        let showaddons = window && window.config.showProductAddons;
        return (
            <>
                <div className="grid px-4">
                    <div className="col-9">
                        {/* <div>
                            <ProductTypeSelector />
                        </div> */}
                        <form className="splitFrmDiv text-center mainHeader">
                            {this._renderProdType()}
                        </form>
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderMainFormDetails()}
                        </form>
                        {ptype !== 'MERCHANDISE_Addon' && (
                            <form
                                className="splitFrmDiv text-center"
                                style={{ marginTop: '2em' }}
                            >
                                {this._renderImageDetails()}
                            </form>
                        )}
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderProductDescription()}
                        </form>
                        {ptype !== 'MERCHANDISE_Addon' && (
                            <form
                                className="splitFrmDiv text-center"
                                style={{ marginTop: '2em' }}
                            >
                                {this._renderDimensionDetails()}
                            </form>
                        )}
                        {ptype !== 'MERCHANDISE_Addon' && (
                            <form
                                className="splitFrmDiv text-center"
                                style={{ marginTop: '2em' }}
                            >
                                {this._renderInventory()}
                            </form>
                        )}

                        {window.config.SPECIAL_DISCOUNTS_ENABLED && (
                            <form
                                className="splitFrmDiv text-center"
                                style={{ marginTop: '2em' }}
                            >
                                {this._renderSpecialDiscDetails()}
                            </form>
                        )}
                    </div>
                    <div className="col-3">
                        <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                            {this._renderOrg()}
                        </div>
                        <div
                            className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderStatusDetails()}
                        </div>
                    </div>
                    {showaddons === true && (
                        <div className="col-12">
                            <div
                                className="splitFrmDiv text-center mt-4"
                            >
                                {this._renderGroupingDetails()}
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    };
    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const { isSuccess } = this.props.crudData;
        if (this.props.queryData.apiPending) {
            return <Loading />;
        }
        return (
            <>
                <Toast ref={(el) => (this.toast = el)} />
                <div className="form_height">
                    <div className="grid w-100 px-6 mb-4">
                        <h2 className="page-header">
                            {this._isEditMode()
                                ? 'Edit Product Details'
                                : this._isViewMode()
                                    ? 'View Product Details'
                                    : 'Add New Product'}
                        </h2>
                    </div>
                    <div className='grid px-4 w-100 mx-2'>
                        {this._renderErrorMessage()}
                    </div>
                    
                    {this._renderFormArea()}
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/product-catalog"
                        children={
                            <div
                                className="alert alert-success"
                                style={{ padding: '2em' }}
                            >
                                {this._isEditMode()
                                    ? 'Product Information updated successfully'
                                    : 'Product Information created successfully'}
                            </div>
                        }
                    />
                </div>
                <div className="row btn-container form-button" id="myBtnCont">
                    <div className="col-sm-12 col-lg-12">
                        {this._renderMainButton()}
                    </div>
                </div>
                {this.state.showPropModal && this._renderProductModal()}
                {this.state.showSimilarModal && this._renderSimilarModal()}
                {this.state.showRecommendModal && this._renderRecommendModal()}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddNewProduct);

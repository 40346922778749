import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    crudReset,
    getStoreById,
    createDeliverySlot,
    updateDeliverySlot,
    deliverytypeConfig,
    searchUserProfile,
    resetAction
} from "../../../actions/actions";
import StatusComp from "../../../components/StatusComp";
import ConfirmAlert from "../../../components/ConfirmAlert";
import { produce } from "immer";
import TenantCode from "../../../components/TenantCode";
import Filter from "../../../components/Filter";
import SimpleSelect from "../../../components/SimpleSelect";
import Loading from "../../../components/Loading";
import SimpleTable_Radio from "../../../components/SimpleTable_Radio";
import { formatDate, objtoarr, renderCouponOptionsFilter, renderOptionsFilter } from "../../../helpers/Util";
import DatePicker from "react-date-picker";
import Modal from "react-awesome-modal";
import StoreSearchSingle from "../../system/users/StoreSearchSingle";

class AddDeliverySlot extends React.PureComponent {
    qtytype = [
        { title: "Available", value: "Avl" },
        { title: "Booked", value: "Bok" }
    ]
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            isEdit: false,
            showMemberDiv: false,
            // Formrelated
            storeid: "",
            title: "",
            slotcode: "",
            memberid: "",
            slotgroup: "",            
            slotbookdate: "",
            trxdate: "",
            orderid: "",      
            trxgroup: "",
            trxtype: "",
            trxtypevalue: "",
            membergroup: "",
            qtytype: "",
            qty:"",
            selected: "",
            status: "Active",
            checkedrow: "",
            smemberid: "",
            firstname: "",
            lastname: "",
            emailid: "",
            tableColsMem: [
                {
                  dataField: "memberid",
                  text: "Member Id",
                  sort: true
                },
                {
                  dataField: "userid",
                  text: "User Id",
                  sort: true
                },
                {
                  dataField: "firstname",
                  text: "First Name",
                  sort: true
                },
                {
                  dataField: "lastname",
                  text: "Last Name",
                  sort: true
                },
                {
                  dataField: "emailid",
                  text: "Email",
                  sort: true
                },
                {
                  dataField: "status",
                  text: "Status",
                  sort: true
                }
              ],
            // Errors
            memberiderror: "",
            storeiderror: "",
            membergrouperror: ""
        };
    }

    componentDidMount = () => {
        this.props.dispatch(searchUserProfile({body: {status: "New"}}));
    }

    callFetchMem = () => {
        const  { membergroup, firstname, lastname, emailid, smemberid } = this.state;
        this.props.dispatch(searchUserProfile({
             body: 
              {
                status: "New", 
                membergroup,
                firstname,
                lastname,
                emailid,
                memberid: smemberid
              }        
        }));
    }

    callFetch = () => {
        const  { storeid } = this.state;
        this.props.dispatch(deliverytypeConfig({body: { storeid, propvalue: "Time", propgroup: "OPS" }}));
    }

    componentWillMount = () => {
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            if (rec) {
                const {
                    id,
                    title,
                    slotcode,
                    storeid,
                    memberid,
                    slotgroup,
                    slotbookdate,
                    trxdate,
                    orderid,
                    trxgroup,
                    trxtype,
                    trxtypevalue,
                    qtytype,
                    qty,
                    status
                } = rec;
                this.props.dispatch(deliverytypeConfig({body: { storeid, propvalue: "Time", propgroup: "OPS" }}));
                this.props.dispatch(getStoreById(storeid));
                let defaultField = [{ field: "", value: "", id: 0 }];
                let bdate = this.toDate(slotbookdate);
                let tdate = this.toDate(trxdate);
                this.setState(
                    produce(draft => {
                        draft.id = id ? id : "";
                        draft.isEdit = isEdit;
                        draft.title = title ? title : "";
                        draft.storeid = storeid ? storeid : "";
                        draft.slotcode = slotcode ? slotcode : "";
                        draft.memberid = memberid ? memberid : "";
                        draft.slotgroup = slotgroup ? slotgroup : "";
                        draft.slotbookdate = slotbookdate ? bdate : "";
                        draft.trxdate = trxdate ? tdate : "";
                        draft.orderid = orderid ? orderid : "";
                        draft.trxgroup = trxgroup ? trxgroup : "";
                        draft.trxtype = trxtype ? trxtype : "";
                        draft.trxtypevalue = trxtypevalue ? trxtypevalue : "";
                        draft.qtytype = qtytype ? qtytype : "";
                        draft.qty = qty ? qty : "";
                        draft.status = status ? status : "";
                    })
                );
            }
        }
    };

    toDate = dateStr => {
        let totalStr = dateStr.split(" ");
        if (totalStr.length > 1) {
            var st = totalStr[0];
            var parts = st.split("-");
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
        else{
            var parts = dateStr.split("-");
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
    };

    _renderErrorMessage = () => {
        const { isSuccess, errorMessage } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errorMessage && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };
    getCreatePayload = () => {
        const {
            storeid,
            title,
            slotcode,
            memberid,
            slotgroup,
            slotbookdate,
            status,
            trxdate,
            orderid,
            trxgroup,
            trxtype,
            trxtypevalue,
            qtytype,
            qty
        } = this.state;
        
        let body = {
            storeid,
            title,
            slotcode,
            memberid,
            slotgroup,
            slotbookdate: formatDate(slotbookdate),
            status,
            trxdate: formatDate(trxdate),
            orderid,
            trxgroup,
            trxtype,
            trxtypevalue,
            qtytype,
            qty
        };
        if (this._isEditMode()) {
            body["id"] = this.state.id;
        }
        const payload = {
            body: body
        };
        return payload;
    };

    _createDS = () => {
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateDeliverySlot(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(createDeliverySlot(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: "Please clear all the errors to continue !!!"
            });
            window.scrollTo(0, 0);
        }
    };
    _clearErrorMessages = () => {
        this.setState({
            memberiderror: "",
            membergrouperror: "",
            formError: false,
            formErrorMsg: ""
        });
    };
    validateForm = () => {
        this._clearErrorMessages();
        const {
            memberid,
            storeid,
            membergroup
        } = this.state;
        let valid = true;
        if (storeid.trim() === "") {
            this.setState({
                storeiderror: "Store id is required."
            });
            valid = false;
        }
        if (memberid.trim() === "") {
            this.setState({
                memberiderror: "Please enter a member id"
            });
            valid = false;
        }      
        return valid;
    };

    validateModal = () =>{
        this._clearErrorMessages();
        const { membergroup } = this.state;
        let valid = true;
        if (membergroup.trim() === "") {
            this.setState({
                membergrouperror: "Please enter a member group"
            });
            valid = false;
        }
        return valid;
    }
    _isEditMode = () => {
        return this.state.isEdit;
    };
    _renderMainButton = () => {
        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => {
                            this._createDS();
                        }}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={this._createDS}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/operation-slot"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 },()=>{
            this.callFetch();
        });
    };

    componentWillReceiveProps = (prevProps) => {
        if (this._isEditMode()) {
            const { storeArray } = this.props.queryData;
            const { storeid } = this.state;
            if (storeArray) {
                storeArray.storeid === storeid ? this.setState({ storelabel: storeArray.title }) :
                    this.setState({ storelabel: this.state.storelabel })
            };       
        }
    };

    handlebookdateChange = value => {
        this.setState({
            slotbookdate: value
        });
    };

    handletrxdateChange = value => {
        this.setState({
            trxdate: value
        })
    }

    slotGroupOptions = (arrobj) => {
        let values = arrobj.map((e) => Object.values(e.additionalfields)).flat();
        return values.reduce((p, c) => {
            p = { ...p, [c]: c };
            return p;
        }, {});
    }

    _openMemberDiv = () => {
        this.setState({ showMemberDiv: true });
    };
    _closeMemberDiv = () => {
        this.setState({ showMemberDiv: false });
    }; 
    handleOnSelect = (row, isSelect) => {
        const  { membergroup } = this.state;
        let settitle = membergroup+"-"+ row.firstname.replace(/\b\w/, v => v.toUpperCase());
        if (isSelect) {
            this.setState(() => ({
                selected: row.memberid,
                checkedrow: row.id,
                title: settitle
            }));
        } else {
            this.setState(() => ({
                selected: ""
            }));
        }
    };
    
    _renderMemberModal = () => {
        return (
          <Modal
            visible={this.state.showMemberDiv}
            width="90%"
            height="80%"
            effect="fadeInRight"
            onClickAway={() => this._closeMemberDiv()}
          >
            <div className="modalForm queryFormModal">
              <div className="row">
                <div className="col-xs-12 col-md-6 alignLeft">Member Search</div>
                <div className="col-xs-12 col-md-6 alignRight">
                  <a onClick={this._closeMemberDiv} className="closeIcon">
                    X
                  </a>
                </div>
              </div>
              <div className="title_devide" />
              {this._renderMemberForm()}
            </div>
          </Modal>
        );
      };

     removeDulpicates = (arr) => {
         return Object.values(arr.reduce((acc,cur)=>Object.assign(acc,{[cur.value]:cur}),{}))
     }

     showTable = () => {
         const { memberByGroup } = this.props.queryData;
         return(
             <React.Fragment>
                 <div className="row">
                     <div className="form-group col-sm-12 col-lg-12">
                         <SimpleTable_Radio
                             columns={this.state.tableColsMem}
                             rows={memberByGroup}
                             handleOnSelect={this.handleOnSelect}
                             checkedrow={this.state.checkedrow}
                             callback={() => {
                                 this.callFetchMem();
                             }}
                         />
                     </div>
                 </div>
             </React.Fragment>
         )
     }

  
      _renderMemberForm = () => {
        const { membergroup, showMemberDiv } = this.state;
        const { memberByGroup } = this.props.queryData;
        let grouplist = memberByGroup.map(e=>({title: e.membergroup, value: e.membergroup})).filter(i=>i.title.length !== 0);
        let mgroup = this.removeDulpicates(grouplist);
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12 ">
                        <label htmlFor="membergroup" className="floatLeft">
                            Member Group
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={renderCouponOptionsFilter(mgroup)}
                            selectedOption={this.state.membergroup}
                            callbackChangeFilter={event => {
                                this.setState({ membergroup: event.target.value },()=>{
                                    if(this.validateModal()===true){
                                        this.callFetchMem();
                                    }
                                })
                            }}
                            id="mgroup"
                        />
                        <div className="invalid-feedback">{this.state.membergrouperror}</div>
                      </div>
                      <div className="form-group col-lg-4 col-sm-12 ">
                            <label htmlFor="firstname" className="floatLeft">
                                First Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="firstname"
                                value={this.state.firstname}
                                onChange={event =>
                                    this.setState({ firstname: event.target.value })
                                }
                            />
                      </div> 
                      <div className="form-group col-lg-4 col-sm-12 ">
                            <label htmlFor="lastname" className="floatLeft">
                                Last Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="lastname"
                                value={this.state.lastname}
                                onChange={event =>
                                    this.setState({ lastname: event.target.value })
                                }
                            />
                      </div>                      
                </div>
                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12 ">
                        <label htmlFor="emailid" className="floatLeft">
                            Email ID
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="emailid"
                            value={this.state.emailid}
                            onChange={event =>
                                this.setState({ emailid: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12 ">
                        <label htmlFor="smemberid" className="floatLeft">
                           Member Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="smemberid"
                            value={this.state.smemberid}
                            onChange={event =>
                                this.setState({ smemberid: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12 noPadding">
                         <img
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_search_blue.png`}
                           onClick={event => {
                                if (event) event.preventDefault();
                                this.callFetchMem();
                            }} 
                            className="searchBtn"
                        /> 
                    </div>
                    {this.state.membergroup && this.showMemberTable()}
                </div>            
            </React.Fragment>
        );
    };

    showMemberTable = () => {
        return(
            <React.Fragment>
                <div>
                    <div>{this.showTable()}</div>
                    <div className="row btn-modal form-button">
                        <div className="col-sm-12 col-lg-12">
                            <button
                                type="submit"
                                className="btn btn-themes btn-rounded"
                                onClick={event => {
                                    if (event) event.preventDefault();
                                    this.setState({
                                        memberid: this.state.selected
                                    },()=>{
                                        this._closeMemberDiv();
                                    })
                                }}
                            >
                                Submit
                            </button>
                            <button
                                type="submit"
                                className="btn btn-themes btn-rounded"
                                onClick={event => {
                                    if (event) event.preventDefault();     
                                    this._closeMemberDiv();
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>            
                </div>
            </React.Fragment>
        )
    }

    _renderMainFormDetails = () => {
        const { deliverytypeList, memberByGroup } = this.props.queryData;
        let dlist = this.slotGroupOptions(deliverytypeList);
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12 ">
                        <label htmlFor="storeid" className="floatLeft required">
                            Store
                        </label>
                        <TenantCode
                            value={this.state.storelabel}
                            setTenantCode={this._setTenant}
                        />
                         <div className="invalid-feedback">{this.state.storeiderror}</div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="slotcode" className="floatLeft">
                            Slot Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="slotcode"
                            value={this.state.slotcode}
                            readOnly={true}
                        />
                    </div>                         
                </div>

                <div className="row">
                   <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="memberid" className="floatLeft required">
                           Member Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="memberid"
                            value={this.state.memberid}
                            onClick={event => {
                                if (event) event.preventDefault();
                                this._openMemberDiv();
                            }}
                        />      
                       <div className="invalid-feedback">{this.state.memberiderror}</div>    
                    </div>     
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            readOnly={true}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="slotgroup" className="floatLeft">
                            Slot Group 
                        </label>
                        <SimpleSelect
                            objArray={dlist}
                            callbackChangeFilter={value =>
                                this.setState({ slotgroup: value })
                            }
                            selectedOption={this.state.slotgroup}
                            id="slotgroup"
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="slotbookdate" className="floatLeft">
                            Slot Book Date
                        </label>
                        <DatePicker
                            minDate={this._isEditMode() ? this.state.slotbookdate : new Date()}
                            onChange={this.handlebookdateChange}
                            value={this.state.slotbookdate}
                        />
                    </div>
                </div>             
           </React.Fragment>
        );
    };

    
    _renderOtherFormDetails = () => {
        return (
            <React.Fragment>
               <div className="row">
                   <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="orderid" className="floatLeft">
                            Order Id
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="orderid"
                            value={this.state.orderid}
                            min="0"
                            onChange={event => this.setState({ orderid: event.target.value })}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="trxdate" className="floatLeft">
                          Transaction Date
                        </label>
                        <DatePicker
                            //disabled={true}
                            minDate={!this._isEditMode() ? new Date() : this.state.trxdate}
                            onChange={this.handletrxdateChange}
                            value={this.state.trxdate}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="trxgroup" className="floatLeft">
                          Transaction Group
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="trxgroup"
                            value={this.state.trxgroup}
                            //readOnly={true}
                            onChange={event => this.setState({ trxgroup: event.target.value })}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="trxtype" className="floatLeft">
                           Transaction Type
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="trxtype"
                            value={this.state.trxtype}
                            //readOnly={true}
                            onChange={event => this.setState({ trxtype: event.target.value })}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="trxtypevalue" className="floatLeft">
                           Transaction Type Value
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="trxtypevalue"
                            value={this.state.trxtypevalue}
                            //readOnly={true}
                            onChange={event => this.setState({ trxtype: event.target.value })}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="qtytype" className="floatLeft">
                           Quantity Type
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={renderOptionsFilter(this.qtytype)}
                            selectedOption={this.state.qtytype}
                            callbackChangeFilter={event => {
                                this.setState({ qtytype: event.target.value })
                            }}
                            id="qtytype"
                            //disabled={true}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="qty" className="floatLeft">
                           Quantity
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="qty"
                            value={this.state.qty}
                            //readOnly={true}
                            onChange={event => this.setState({ qty: event.target.value })}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }

    _renderStatusDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Actions</div>

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusComp
                            selectedOption={this.state.status}
                            callbackChangeFilter={value => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
                {/* <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="sortorder" className="floatLeft">
                            Sort Order
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="sortorder"
                            value={this.state.sortorder}
                            onChange={event =>
                                this.setState({ sortorder: event.target.value })
                            }
                        />
                    </div>
                </div> */}
            </React.Fragment>
        );
    };

    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv text-center">
                            {this._renderMainFormDetails()}
                        </form>  
                        <form className="splitFrmDiv text-center" style={{ marginTop: "2em" }}>
                            {this._renderOtherFormDetails()}
                        </form>  
                    </div>
                    <div className="col-md-3 col-sm-12 doubleCol">
                        <form className="splitFrmDivSec text-center">
                            {this._renderStatusDetails()}
                        </form>                        
                    </div>
                </div>
            </React.Fragment>
        );
    };
    hideModal = e => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const {
            memberByGroup,
            errormsg: queryError,
            servererror: serverError
        } = this.props.queryData;
        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = "";
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }
      
        // if (this.props.queryData.apiPending) {
        //     return <Loading />;
        // }
        return (
            <React.Fragment>
                {this.state.showMemberDiv && this._renderMemberModal()}
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode()
                                    ? "Edit Operation Slot"
                                    : "Add Operation Slot"}
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderFormArea()}
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/operation-slot"
                        children={
                            <div style={{ padding: "2em", color: "green" }}>
                                {this._isEditMode()
                                    ? "Operation Slot updated successfully"
                                    : "Operation Slot created successfully"}
                            </div>
                        }
                    />                 
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData
    };
};

export default connect(mapStateToProps)(AddDeliverySlot);

import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import {
    searchGeneral,
    crudReset,
} from "../../../actions/actions";
import Loading from "../../../components/Loading";
import RefreshIcon from "../../../components/ImageComponents/RefreshIcon";
import formik from "../../../hooks/useFormik";
import * as Yup from "yup";

const defaultSearchInfo = {
    search_field: "emailid",
    search_condi: "like",
    search_value: "",
};

const validationSchema = Yup.object().shape({});

const IssueMemberCoupons = (props) => {
    const { queryData, crudData, dispatch, location } = props;
    const [selected, setSelected] = useState([]);

    const [pages, setPages] = useState(10);
    const [currPage, setCurrPage] = useState(1);

    const initSearchInfo = useMemo(
        () => location?.state?.searchInfo || defaultSearchInfo,
        [location],
    );

    const initSearchedInfo = useMemo(
        () => ({
            ...defaultSearchInfo,
            ...location?.state?.searchedInfo,
        }),
        [location],
    );

    useEffect(() => {
        callFetch();
    }, []);

    const callFetch = (param) => {
        if (param?.pagesize) {
            setPages(param.pagesize);
        }
        if (param?.pageno) {
            setCurrPage(param.pageno);
        }

        const requestBody = {
            body: {
                pageno: param?.pageno || currPage,
                pagesize: param?.pagesize || pages,
                ...searchedInfo,
            },
        };

        dispatch(
            searchGeneral(
                "crm/v1/member/search",
                requestBody,
                "SEARCH_MEMBER_PROFILE_SUCCESS",
                "SEARCH_MEMBER_PROFILE_FAILURE",
            ),
        );
    };

    const renderIssueCouponBtn = () => {
        if (selected.length === 0) {
            return (
                <Button
                    disabled
                    label="Issue Coupon"
                    icon="pi pi-gift"
                    className="p-button-outlined p-button-success"
                />
            );
        }
        return (
            <Link
                to={{
                    pathname: "/ui/member-coupon/issue-coupon",
                    state: {
                        param: selected,
                    },
                }}
            >
                <Button
                    label="Issue Coupon"
                    icon="pi pi-gift"
                    className="p-button-outlined p-button-success"
                />
            </Link>
        );
    };

    const [searchedInfo, setSearchedInfo] = useState(initSearchedInfo);

    const submitForm = (values, { setSubmitting }) => {
        const updatedSearchInfo = {
            search_field: values.search_field?.id || "",
            search_condi: values.search_condi,
            search_value: values.search_value,
            currPage: 1,
        };
        setSearchedInfo((prev) => ({ ...prev, ...updatedSearchInfo }));
        setSubmitting(false);
    };

    const fetchResults = useCallback(() => {
        callFetch();
    }, [searchedInfo]);

    useEffect(fetchResults, [searchedInfo]);

    const handleClear = () => {
        const resetValues = {
            search_field: "emailid",
            search_condi: "like",
            search_value: "",
        };

        // Clear the formik values
        Object.keys(resetValues).forEach((key) =>
            setFieldValue(key, resetValues[key]),
        );

        setSearchedInfo({
            ...resetValues,
            pageSize: 10,
            currPage: 1,
        });

        const requestBody = {
            body: resetValues,
        };
        dispatch(
            searchGeneral(
                `crm/v1/member/search`,
                requestBody,
                "SEARCH_MEMBER_PROFILE_SUCCESS",
                "SEARCH_MEMBER_PROFILE_FAILURE",
            ),
        );
    };

    const { values, handleSubmit, handleChange, isSubmitting, setFieldValue } =
        formik(initSearchInfo, validationSchema, submitForm);

    const renderSearchCriteria = () => (
        <div className="p-fluid formgrid grid w-100">
            <div className="field col-3">
                <label htmlFor="search_field">Search By</label>
                <Dropdown
                    name="search_field"
                    value={values.search_field}
                    onChange={handleChange}
                    options={[
                        { name: "Select", id: "" },
                        { name: "Customer Name", id: "companyname" },
                        { name: "Customer Code", id: "memberid" },
                        { name: "Email", id: "emailid" },
                        { name: "Store Id", id: "storeid" },
                        { name: "Salesperson", id: "salespersonname" },
                        { name: "Collector", id: "collectorname" },
                        { name: "Status", id: "status" },
                    ]}
                    optionLabel="name"
                    className="w-full"
                />
            </div>
            <div className="field col-3">
                <label htmlFor="search_value">Search Value</label>
                <InputText
                    id="search_value"
                    name="search_value"
                    value={values.search_value}
                    onChange={handleChange}
                />
            </div>
            <div
                className="field"
                style={{ display: "flex", marginTop: "28px" }}
            >
                <Button
                    type="submit"
                    label="Search"
                    className="mr-4"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                />
                <Button
                    type="button"
                    label="Cancel"
                    className="p-button-secondary"
                    onClick={handleClear}
                />
            </div>
        </div>
    );

    const renderIssueCoupon = () => {
        const { userProfileList, errormsg: queryError } = queryData;

        return (
            <>
                <div className="grid mb-2 w-full">
                    <form className="w-full" onSubmit={handleSubmit}>
                        {renderSearchCriteria()}
                    </form>
                </div>
                <div className="grid w-full">
                    <div className="col-lg-8 col-sm-12 noPadding noMargin chatBtnDiv">
                        <img
                            className="chatBtn"
                            src={`${process.env.PUBLIC_URL}/assets/icons/chat.png`}
                            alt="Chat Icon"
                        />
                        Select the customer ids to issue coupon
                    </div>
                    <div className="ml-auto">
                        {renderIssueCouponBtn()}
                    </div>
                </div>
                <div className="title_devide" />
                <IssueCouponTable
                    userProfileList={userProfileList}
                    handleSelectMember={handleSelectedMember}
                    selected={selected}
                    callApi={callFetch}
                />
            </>
        );
    };

    const handleSelectedMember = ids => {
        console.log('from child', ids)
        setSelected(ids)
    }

    if (!queryData.userProfileList && !queryData.errormsg) {
        return <Loading />;
    }

    if (!queryData.userProfileList || queryData.apiPending) {
        return <Loading />;
    }

    return (
        <>
            <div className="form_height">
                <div className="grid px-4 align-items-center my-2 pt-4">
                    <div>
                        <h2
                            className="text-3xl font-normal"
                            style={{ color: "#212121" }}
                        >
                            Customer Coupon
                            <RefreshIcon handleClick={() => callFetch()} />
                        </h2>
                    </div>
                </div>

                <div className="grid">
                    <div className="p-fluid formgrid grid br-2 bg-white p-4 border-round-xl shadow-1 m-4">{renderIssueCoupon()}</div>
                </div>
            </div>
        </>
    );
};

const IssueCouponTable = ({ userProfileList, handleSelectMember, selected, callApi }) => {
    const [first, setFirst] = useState(0);
    const [tableRows, setRows] = useState(10);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRecords, setSelectedRecords] = useState([]);

    const handlePageSizeChange = (e) => {
        const newSize = e.target.value;
        setPageSize(newSize);
        setRows(newSize);
        callApi({ pageno: currentPage, pagesize: newSize });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        callApi({ pageno: newPage, pagesize: pageSize });
    };

    const handleSelectedRecord = (records) => {
        const combined = [...selectedRecords, ...records]
        const totalSelection = {
            [currentPage]: records
        }
        //console.log('totla', totalSelection)
        setSelectedRecords(records);
        const ids = records.map((record) => record.memberid).join(",");
        handleSelectMember(ids);
    };

    // const handleSelectedRecord = (newSelection) => {
    //     setSelectedRecords((prevSelection) => {
    //         // Convert previous selections into a Map for easy lookup
    //         const selectionMap = new Map(prevSelection.map(item => [item.id, item]));
    
    //         // Ensure all new selections are added
    //         newSelection.forEach(item => selectionMap.set(item.id, item));
    
    //         // Check for unselected items (items previously selected but now missing in newSelection)
    //         prevSelection.forEach(item => {
    //             if (!newSelection.some(newItem => newItem.id === item.id)) {
    //                 selectionMap.delete(item.id); // Remove if it's unselected
    //             }
    //         });
    
    //         const updatedSelection = Array.from(selectionMap.values());
    
    //         // Generate selected member IDs
    //         const ids = updatedSelection.map(record => record.memberid).join(",");
    //         handleSelectMember(ids);
    
    //         console.log("Updated selection:", updatedSelection);
    //         return updatedSelection;
    //     });
    // };
    

    const renderPaginationTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
            ];
            return (
                <>
                    <span
                        className="mx-1"
                        style={{
                            color: "var(--text-color)",
                            userSelect: "none",
                        }}
                    >
                        Items per page:
                    </span>
                    <Dropdown
                        value={pageSize}
                        options={dropdownOptions}
                        onChange={handlePageSizeChange}
                    />
                </>
            );
        },
        CurrentPageReport: () => (
            <div>
                <button
                    type="button"
                    className={classNames({
                        "p-paginator-prev p-paginator-element p-link": true,
                        "p-disabled": currentPage === 1,
                    })}
                    disabled={currentPage === 1}
                    aria-label="Previous Page"
                    onClick={() => handlePageChange(currentPage - 1)}
                >
                    <span className="p-paginator-icon pi pi-angle-left"></span>
                </button>
                {currentPage}
                <button
                    type="button"
                    className={classNames({
                        "p-paginator-next p-paginator-element p-link": true,
                        "p-disabled": userProfileList.length < pageSize,
                    })}
                    disabled={userProfileList.length < pageSize}
                    aria-label="Next Page"
                    onClick={() => handlePageChange(currentPage + 1)}
                >
                    <span className="p-paginator-icon pi pi-angle-right"></span>
                </button>
            </div>
        ),
    };

    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    return (
        <DataTable
            className="lazy-loading w-full"
            value={userProfileList}
            showGridlines
            columnResizeMode="fit"
            responsiveLayout="scroll"
            paginatorClassName="justify-content-end"
            paginator
            paginatorTemplate={renderPaginationTemplate}
            first={first}
            rows={tableRows}
            selectionMode="checkbox"
            selection={selectedRecords}
            onSelectionChange={(e) => handleSelectedRecord(e.value)}
            dataKey="id"
            onPage={onCustomPage}
            emptyMessage="Your search does not retrieve any data. Please search again using another criteria."
        >
            <Column
                selectionMode="multiple"
                headerStyle={{
                    width: "3em",
                }}
            />
            <Column field="memberid" header="Customer Code" />
            <Column field="companyname" header="Customer Name" />
            <Column field="firstname" header="First Name" />
            <Column field="lastname" header="Last Name" />
            <Column field="contactno" header="Contact No" />
            <Column field="emailid" header="Email" />
            <Column field="storeid" header="Store Id" />
            <Column field="status" header="Status" />
        </DataTable>
    );
};


const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(IssueMemberCoupons);

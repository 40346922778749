import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import {
    storeGroupSearchByFields,
    storeSearchByFields,
} from "../../../actions/actions";
import * as CONSTANTS from "../../../constants/constants";
import StoreSelection from "./StoreSelection";

const StoreSearchSingle = (props) => {
    const { onSelectStore, value, disabled, dispatch, queryData } = props;
    const { storeGroups, storeSearchStores } = queryData;

    const companyId = localStorage.getItem("company_id");

    const [showStoreSearchModal, setShowStoreSearchModal] = useState(false);
    const [selectedStoreGroup, setSelectedStoreGroup] = useState("");
    const [selectedStores, setSelectedStores] = useState("");
    const [showStoreResults, setShowStoreResults] = useState(false);
    const [storeSelectionError, setStoreSelectionError] = useState("");
    const [filteredStores, setFilteredStores] = useState([])

    useEffect(() => {
        const queryParam = `status=Active&companyid=${companyId}`;
        dispatch(storeGroupSearchByFields(queryParam));
    }, [dispatch, companyId]);

    useEffect(() => {
        let result = storeSearchStores;

        const storeAccess = localStorage.getItem("store_access");
        if (storeAccess) {
            const stores = storeAccess.split(",");
            result = storeSearchStores.filter((e) =>
                stores.includes(e.storeid),
            );
        }
        setFilteredStores(result)
    }, [storeSearchStores])

    const openStoreSearchModal = (event) => {
        event.preventDefault();
        setShowStoreSearchModal(true);
    };

    const closeStoreSearchModal = (event) => {
        if (event) event.preventDefault();
        setShowStoreSearchModal(false);
        setShowStoreResults(false);
        setSelectedStoreGroup("");
        setSelectedStores("");
        setStoreSelectionError("");
        queryData.storeSearchStores = [];
    };

    const searchStores = (event) => {
        event.preventDefault();
        const query = `companyid=${companyId}&status=Active&groupid=${selectedStoreGroup?.code || ''}`;
        dispatch(
            storeSearchByFields(
                query,
                CONSTANTS.STORESEARCH_STORE_SUCCESS,
                CONSTANTS.STORESEARCH_STORE_FAILURE,
            ),
        );
        setShowStoreResults(true);
    };

    const updateSelectedStore = (selected) => {
        setSelectedStores(selected);
        if (selected !== "") {
            setStoreSelectionError("");
        }
    };

    const updateSelectedStores = (event) => {
        event.preventDefault();
        if (selectedStores === "") {
            setStoreSelectionError("Select Store");
            return;
        }
        onSelectStore(selectedStores);
        closeStoreSearchModal();
    };

    const storeGroupOnChange = (selectedValue) => {
        setSelectedStoreGroup(selectedValue);
    };

    const getStoreGroupOptions = () => {
        return [
            { code: "", name: "Select" },
            ...storeGroups.map((item) => ({
                name: item.title,
                code: item.groupid,
            })),
        ];
    };
    return (
        <>
            <div className="row">
                <div className="col-lg-12 noPadding noMargin">
                    <input
                        type="text"
                        className="treemodal"
                        id="tenant"
                        readOnly={true}
                        value={value || ""}
                        onClick={openStoreSearchModal}
                        disabled={disabled || false}
                    />
                </div>
            </div>
            <Dialog
                header="Store Search"
                visible={showStoreSearchModal}
                style={{ width: "50vw" }}
                onHide={() => {
                    if (!showStoreSearchModal) return;
                    setShowStoreSearchModal(false);
                }}
            >
                <div className="pb-6">
                <div className="flex align-items-end">
                    <div className="col-4 p-0">
                        <label className="col-form-label alignLeft">
                            Store Group
                        </label>
                        <Dropdown
                            className="w-full"
                            value={selectedStoreGroup}
                            onChange={(e) => storeGroupOnChange(e.value)}
                            options={getStoreGroupOptions()}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>

                    <div className="ml-4 pv-2">
                        <Button
                            className="mr-2"
                            type="submit"
                            label="Search"
                            severity="success"
                            onClick={searchStores}
                        />
                        <Button
                            type="submit"
                            label="Cancel"
                            severity="secondary"
                            onClick={closeStoreSearchModal}
                        />
                    </div>
                </div>

                {showStoreResults && (
                    <div className="mt-4">
                        <div className="w-full">
                            <label className="col-form-label">Stores</label>
                            <StoreSelection
                                    stores={filteredStores}
                                    updateSelectedStore={updateSelectedStore}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                />
                                <div className="invalid-feedback">
                                    {storeSelectionError}
                                </div>
                        </div>

                        <div className="flex mt-4">
                            <div className="ml-auto">
                                <Button
                                    className="mr-2"
                                    type="submit"
                                    label="Save"
                                    onClick={updateSelectedStores}
                                    severity="success"
                                />
                                <Button
                                    type="submit"
                                    label="Discard"
                                    severity="secondary"
                                    onClick={closeStoreSearchModal}
                                />
                            </div>
                        </div>
                    </div>
                )}
                </div>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
    };
};

export default connect(mapStateToProps)(StoreSearchSingle);

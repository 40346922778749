import React from "react";
import { connect } from "react-redux";
import MultiLineChart from "../Charts/MultiLineChart";
import HorizontalBarChart from "./../Charts/HorizontalBarChart";
import BarChart from "./../Charts/BarChart";
import {
    sortandrender,
    dateRange,
    getByMonth,
    toDate,
} from "../../helpers/Util";
import ChartOptions from "../../components/ChartOptions";
import ChartTable from "./../../components/ChartTable";
import {
    querySalesSummaryInfoByDate,
    querySalesSummaryInfoByMonth,
    querySalesSummaryInfoByYear,
    queryProductSalesSummary,
    queryMemberSalesSummary,
} from "../../actions/actions";
import StoreSearchSingle from "../system/users/StoreSearchSingle";

import DatePicker from "react-date-picker";

class SalesAnalytics extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem("company_id");
        this.group = "DE_REPORTS_SALES_ANALYTICS";
        this.module = "REPORTS";
        this.allPermissions = [];

        this.state = {
            showTableId: null,
            showTable: false,
            groupby: "",
            oversalesfromdate: new Date(),
            overallsalesdate: [new Date("Jan 17, 2020 0:00:00"), new Date()],
            dayoption: "-",
            storeid: "",
            storelabel: "",
            currency: "",
            date_from: new Date(),
            date_till: new Date(),
            status: "close",
            salebydate: "",
            salebyhour: "",
            salebyyear: "",
            salebymonth: "",
            topmembersale: "",
            topproductsale: "",
            //errors
            storeiderror: "",
            dayoptionerror: "",
            oversalesdateerror: "",
            //oversalesfromdateerror: "",
        };
    }

    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 }, () => {
            localStorage.setItem("storeid", p1);
            localStorage.setItem("storelabel", p2);
            this._validateAndSearch();
        });
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    setCurrency = () => {
        const { dataByCountry } = this.props.queryData;
        const { storeid } = this.state;
        if (dataByCountry.length > 0) {
            let getcnobj = dataByCountry.filter((e) => e.storeid === storeid);
            if (getcnobj.length > 0) {
                this.setState({
                    currency: getcnobj[0].basecur,
                });
            }
        }
    };

    getOverallSaletoDate = (oversalesfromdate, dayoption) => {
        var arr = [];
        Date.prototype.addDays = function (days) {
            this.setDate(this.getDate() + parseInt(days));
            return this;
        };
        if (dayoption === "+") {
            var tilladddate = new Date(oversalesfromdate).addDays(+15);
            arr.push({ datefrom: oversalesfromdate, dateto: tilladddate });
            return arr;
        }
        if (dayoption === "-") {
            var tillsubdate = new Date(oversalesfromdate).addDays(-15);
            arr.push({ datefrom: tillsubdate, dateto: oversalesfromdate });
            return arr;
        }
    };

    // API Calls
    createFetchUrlSale = (grp) => {
        const { overallsalesdate, storeid, status, date_from, date_till } =
            this.state;
        const requestBody = {
            body: {
                storeid: storeid,
                date_from: toDate(date_from),
                date_till: toDate(date_till),
                groupby: grp,
                status: status,
                pageno: 1,
                pagesize: 30,
            },
        };
        return requestBody;
    };

    createFetchUrl = () => {
        const { overallsalesdate, storeid, status, date_from, date_till } =
            this.state;
        const requestBody = {
            body: {
                storeid: storeid,
                //date_from: toDate(daterange[0].datefrom),
                //date_till: toDate(daterange[0].dateto),
                date_from: toDate(date_from),
                date_till: toDate(date_till),
                status: status,
                pageno: 1,
                pagesize: 30,
            },
        };
        return requestBody;
    };

    callFetch = () => {
        this.props.dispatch(
            querySalesSummaryInfoByDate(this.createFetchUrlSale("date")),
        );
        this.props.dispatch(
            querySalesSummaryInfoByMonth(this.createFetchUrlSale("month")),
        );
        this.props.dispatch(
            querySalesSummaryInfoByYear(this.createFetchUrlSale("month")),
        );
        this.props.dispatch(queryProductSalesSummary(this.createFetchUrl()));
        this.props.dispatch(queryMemberSalesSummary(this.createFetchUrl()));
    };

    handleTable = (e) => {
        e.preventDefault();
        this.setState({
            showTableId: e.target.id,
            showTable: true,
        });
    };

    handleChart = (e) => {
        e.preventDefault();
        this.setState({
            showTable: false,
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const { storeid, overallsalesdate, dayoption } = this.state;
        let valid = true;
        if (storeid.trim() === "") {
            this.setState({ storeiderror: "Please select store id" });
            valid = false;
        }
        if (dayoption.trim() === "") {
            this.setState({ dayoptionerror: "Please select dayoption" });
            valid = false;
        }
        return valid;
    };

    componentDidMount = () => {
        const storeid = localStorage.getItem("storeid");
        const storelabel = localStorage.getItem("storelabel");
        if (storeid && storeid !== "" && storelabel && storelabel !== "") {
            this.setState({ storeid: storeid, storelabel: storelabel }, () => {
                this._validateAndSearch();
            });
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        const {
            dataByCountry,
            saleSummaryByYear,
            saleSummaryByMonth,
            saleSummaryByDate,
            memberSaleSummary,
            productSaleSummary,
        } = this.props.queryData;
        const {
            saleSummaryByYear: prevsaleSummaryByYear,
            saleSummaryByMonth: prevsaleSummaryByMonth,
            saleSummaryByDate: prevsaleSummaryByDate,
            memberSaleSummary: prevmemberSaleSummary,
            productSaleSummary: prevproductSaleSummary,
        } = prevProps.queryData;
        if (dataByCountry.length > 0) {
            this.setCurrency();
        }

        const { storeid, storelabel } = this.state;
        if (storeid === "") {
            if (dataByCountry.length > 0) {
                this.setState(
                    {
                        storeid: dataByCountry[0].storeid,
                        storelabel: dataByCountry[0].title,
                    },
                    () => {
                        this._validateAndSearch();
                    },
                );
            }
        }

        if (prevsaleSummaryByDate !== saleSummaryByDate) {
            let bydate = this.changeObjkey(saleSummaryByDate);
            this.setState({ salebydate: bydate });
        }
        if (prevsaleSummaryByMonth !== saleSummaryByMonth) {
            let bymonth = this.changeObjkey(saleSummaryByMonth);
            this.setState({
                salebymonth: bymonth,
            });
        }
        if (prevsaleSummaryByYear !== saleSummaryByYear) {
            let renamekey = this.changeObjkey(saleSummaryByYear);
            let saleyear = this.removekeys(renamekey);
            let byyear = this.formatObject(saleyear);
            this.setState({
                salebyyear: byyear,
            });
        }
        if (prevmemberSaleSummary !== memberSaleSummary) {
            let renamekeymem = this.changeObjkey(memberSaleSummary);
            let topmem = sortandrender(renamekeymem);
            this.setState({ topmembersale: topmem });
        }
        if (prevproductSaleSummary !== productSaleSummary) {
            let renamekeyprod = this.changeObjkey(productSaleSummary);
            let topprod = sortandrender(renamekeyprod);
            this.setState({ topproductsale: topprod });
        }
    };

    _validateAndSearch = () => {
        if (this.validateForm() === true) {
            this.callFetch();
        } else {
            this.setState({
                formError: true,
                formErrorMsg: "Clear all the errors to continue",
            });
            window.scrollTo(0, 0);
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: "",
            storeiderror: "",
            saledate: "",
        });
    };

    _renderSelect = (formattedObjArray, selectedOption, dropDownChange) => {
        return (
            <select
                id="statusComp"
                className="form-control"
                value={selectedOption}
                onChange={dropDownChange}
            >
                {formattedObjArray !== undefined ? (
                    formattedObjArray.map((t, i) => (
                        <option key={i} value={t.id}>
                            {t.name}
                        </option>
                    ))
                ) : (
                    <option>No option</option>
                )}
            </select>
        );
    };

    _renderStore = () => {
        return (
            <div>
                <label htmlFor="storeiddate" className="floatLeft required">
                    Store
                </label>
                <StoreSearchSingle
                    value={this.state.storelabel}
                    onSelectStore={this._onSelectStore}
                />
                <div className="invalid-feedback">
                    {this.state.storeiderror}
                </div>
            </div>
        );
    };

    _selectOnChange = (event) => {
        this.setState(
            {
                dayoption: event.target.value,
            },
            () => {
                this._validateAndSearch();
            },
        );
    };

    changeObjkey = (obj) => {
        return obj.map(
            ({
                totalrec: totaltransactions,
                totalamount: salesamount,
                ...rest
            }) => ({ ...rest, salesamount, totaltransactions }),
        );
    };

    removekeys = (obj) => {
        const n = obj.map((e) => {
            delete e.month;
            return e;
        });
        return n;
    };

    formatObject = (obj) => {
        let newobj = obj.reduce((a, c) => {
            let found = a.find((el) => el.year === c.year);
            if (found) {
                found.salesamount += c.salesamount;
                found.totaltransactions += c.totaltransactions;
            } else {
                a.push(c);
            }
            return a;
        }, []);
        return newobj;
    };

    compare(a, b) {
        return b.salesamount - a.salesamount;
    }

    renderMemberSaleSummary = () => {
        const { topmembersale } = this.state;
        if (topmembersale.length > 0) {
            const labeldata = topmembersale.map((e) => e.memberid);
            const memobj = [
                {
                    label: "salesamount",
                    data: topmembersale.map((e) => e.salesamount),
                    backgroundColor: "#408EC1",
                },
            ];
            return this.renderBarChart(
                "topmembersale",
                labeldata,
                memobj,
                "member",
                "salesamount",
            );
        }
    };

    renderProductSaleSummary = () => {
        const { topproductsale } = this.state;
        if (topproductsale.length > 0) {
            const labeldata = topproductsale.map((e) => e.title);
            const probj = [
                {
                    label: "totaltransactions",
                    data: topproductsale.map((e) => e.totaltransactions),
                    backgroundColor: "#5587EA",
                },
                {
                    label: "salesamount",
                    data: topproductsale.map((e) => e.salesamount),
                    backgroundColor: "#5587EA",
                },
            ];
            return this.renderHorizontalChart(
                "topproductsale",
                labeldata,
                probj,
                "products",
                "totaltransactions / salesamount",
            );
        }
    };

    renderSaleSummaryYear = () => {
        const { salebyyear } = this.state;
        if (salebyyear.length > 0) {
            const labeldata = [...new Set(salebyyear.map((e) => e.year))];
            const objdata = [
                {
                    label: "totaltransactions",
                    data: salebyyear.map((e) => e.totaltransactions),
                    yAxisID: "y-axis-1",
                    backgroundColor: "#38495B",
                    fill: false,
                    borderWidth: 1,
                    borderColor: "#38495B",
                },
                {
                    label: "salesamount",
                    data: salebyyear.map((e) => e.salesamount),
                    yAxisID: "y-axis-2",
                    backgroundColor: "#2196F3",
                    fill: false,
                    borderWidth: 1,
                    borderColor: "#2196F3",
                },
            ];
            return this.renderMultiChart(
                "salesummaryyear",
                labeldata,
                objdata,
                "year",
                "totaltransactions / salesamount",
            );
        }
    };

    renderSaleSummaryMonth = () => {
        const { salebymonth } = this.state;
        if (salebymonth.length > 0) {
            const obj = getByMonth(salebymonth);
            const labeldata = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];
            const dataset1 = obj.map((e) => ({
                x: e.month,
                y: e.totaltransactions,
            }));
            const dataset2 = obj.map((e) => ({
                x: e.month,
                y: e.salesamount,
            }));
            const objdata = [
                {
                    label: "totaltransactions",
                    data: dataset1,
                    yAxisID: "y-axis-1",
                    backgroundColor: "#38495B",
                    fill: false,
                    borderWidth: 1,
                    borderColor: "#38495B",
                },
                {
                    label: "salesamount",
                    data: dataset2,
                    yAxisID: "y-axis-2",
                    backgroundColor: "#2196F3",
                    fill: false,
                    borderWidth: 1,
                    borderColor: "#2196F3",
                },
            ];
            return this.renderMultiChart(
                "salesummarymonth",
                labeldata,
                objdata,
                "month",
                "totaltransactions / salesamount",
            );
        }
    };

    renderSaleSummaryHour = () => {
        const { salebyhour } = this.state;
        if (salebyhour.length > 0) {
            const labeldata = [...new Set(salebyhour.map((e) => e.hour))];
            let dataset1 = salebyhour.map((e) => ({
                x: e.hour,
                y: e.totalrec,
            }));
            let dataset2 = salebyhour.map((e) => ({
                x: e.hour,
                y: e.totalamount,
            }));
            const objdata = [
                {
                    label: "totalrecords",
                    data: dataset1,
                },
                {
                    label: "totalamount",
                    data: dataset2,
                },
            ];
            return this.renderMultiChart(
                "salesummaryhour",
                labeldata,
                objdata,
                "hour",
                "totalrecords / totalamount",
            );
        }
    };

    renderSaleSummaryDate = () => {
        const { salebydate } = this.state;
        if (salebydate.length > 0) {
            const labeldata = salebydate.map((e) => e.trxdate);
            const objdata = [
                {
                    label: "totaltransactions",
                    data: salebydate.map((e) => e.totaltransactions),
                    yAxisID: "y-axis-1",
                    backgroundColor: "#38495B",
                    fill: false,
                    borderWidth: 1,
                    borderColor: "#38495B",
                },
                {
                    label: "salesamount",
                    data: salebydate.map((e) => e.salesamount),
                    yAxisID: "y-axis-2",
                    backgroundColor: "#2196F3",
                    fill: false,
                    borderWidth: 1,
                    borderColor: "#2196F3",
                },
            ];
            return this.renderMultiChart(
                "salesummarydate",
                labeldata,
                objdata,
                "date",
                "totaltransactions / salesamount",
            );
        }
    };

    renderMultiChart = (id, labeldata, linedata, xaxes, yaxes) => {
        const { currency } = this.state;
        return (
            <MultiLineChart
                id={id}
                data={linedata}
                color="#5487D8"
                labels={labeldata}
                xaxes={xaxes}
                yaxes={yaxes}
                currency={currency}
            />
        );
    };

    renderHorizontalChart = (id, labeldata, objdata, xaxes, yaxes) => {
        const { currency } = this.state;
        return (
            <HorizontalBarChart
                id={id}
                data={objdata}
                labels={labeldata}
                xaxes={xaxes}
                yaxes={yaxes}
                currency={currency}
            />
        );
    };

    renderBarChart = (id, labeldata, objdata, xaxes, yaxes) => {
        const { currency } = this.state;
        return (
            <BarChart
                id={id}
                data={objdata}
                labels={labeldata}
                xaxes={xaxes}
                yaxes={yaxes}
                currency={currency}
            />
        );
    };

    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div className="row noMargin noPadding">
                    <div className="form-group col-lg-4 col-sm-12 ">
                        {this._renderStore()}
                    </div>

                    <div className="form-group col-lg-3 col-sm-12">
                        <label
                            htmlFor="fromDate"
                            className="floatLeft required"
                        >
                            Date From
                        </label>
                        <DatePicker
                            onChange={(value) =>
                                this.setState({ date_from: value }, () => {
                                    if (
                                        this.state.date_from >
                                        this.state.date_till
                                    ) {
                                        this.setState({
                                            date_till: this.state.date_from,
                                        });
                                    }

                                    this._validateAndSearch();
                                })
                            }
                            value={this.state.date_from}
                            maxDate={new Date()}
                        />
                    </div>
                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="toDate" className="floatLeft required">
                            Date To
                        </label>
                        <DatePicker
                            minDate={this.state.date_from}
                            onChange={(value) =>
                                this.setState({ date_till: value }, () => {
                                    this._validateAndSearch();
                                })
                            }
                            value={this.state.date_till}
                            maxDate={new Date()}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderChartDiv1 = () => {
        return (
            <React.Fragment>
                <div className="row noMargin noPadding">
                    <div className="col-lg-12 col-sm-12">
                        <div className="chartLeft chartDiv">
                            <div className="row noPadding noMargin">
                                <div className="chartTitle col-lg-10 col-sm-8 alignLeft noPadding noMargin">
                                    Overall Sales by Date (
                                    {this.state.storelabel})
                                </div>
                                <div className="col-lg-2 col-sm-4 alignRight">
                                    <ChartOptions
                                        toggleTable={this.handleTable}
                                        toggleChart={this.handleChart}
                                        chartid="salesummarydate"
                                        tableimgid="salebydate"
                                        chartdata={this.state.salebydate}
                                    />
                                </div>
                            </div>
                            <div className="row noPadding noMargin">
                                <div className="title_devide"></div>
                                <div className="col-lg-12 col-sm-12">
                                    {this.state.showTable === true &&
                                    this.state.showTableId === "salebydate" &&
                                    this.state.salebydate.length !== 0 ? (
                                        <ChartTable
                                            tabledata={this.state.salebydate}
                                        />
                                    ) : (
                                        this.renderSaleSummaryDate()
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row noMargin noPadding">
                    <div className="col-lg-12 col-sm-12">
                        <div className="chartLeft chartDiv">
                            <div className="row noPadding noMargin">
                                <div className="chartTitle col-lg-10 col-sm-8 alignLeft noPadding noMargin">
                                    Overall Sales by Month (
                                    {this.state.storelabel})
                                </div>
                                <div className="col-lg-2 col-sm-4 alignRight">
                                    <ChartOptions
                                        toggleTable={this.handleTable}
                                        toggleChart={this.handleChart}
                                        chartid="salesummarymonth"
                                        tableimgid="salebymonth"
                                        chartdata={this.state.salebymonth}
                                    />
                                </div>
                            </div>
                            <div className="row noPadding noMargin">
                                <div className="title_devide"></div>
                                <div className="col-lg-12 col-sm-12">
                                    {this.state.showTable === true &&
                                    this.state.showTableId === "salebymonth" &&
                                    this.state.salebymonth.length !== 0 ? (
                                        <ChartTable
                                            tabledata={this.state.salebymonth}
                                        />
                                    ) : (
                                        this.renderSaleSummaryMonth()
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row noMargin noPadding">
                    <div className="col-lg-6 col-sm-12">
                        <div className="chartLeft chartDiv">
                            <div className="row noPadding noMargin">
                                <div className="chartTitle col-lg-8 col-sm-8 alignLeft noPadding noMargin">
                                    Overall Sales by Year <br></br>(
                                    {this.state.storelabel})
                                </div>
                                <div className="col-lg-4 col-sm-4 alignRight">
                                    <ChartOptions
                                        chartid="salesummaryyear"
                                        toggleTable={this.handleTable}
                                        toggleChart={this.handleChart}
                                        tableimgid="salebyyear"
                                        chartdata={this.state.salebyyear}
                                    />
                                </div>
                            </div>
                            <div className="row noPadding noMargin">
                                <div className="title_devide"></div>
                                <div className="col-lg-12 col-sm-12">
                                    {this.state.showTable === true &&
                                    this.state.showTableId === "salebyyear" &&
                                    this.state.salebyyear.length !== 0 ? (
                                        <ChartTable
                                            tabledata={this.state.salebyyear}
                                        />
                                    ) : (
                                        this.renderSaleSummaryYear()
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="chartRight chartDiv">
                            <div className="row noPadding noMargin">
                                <div className="chartTitle col-lg-8 col-sm-8 alignLeft noPadding noMargin">
                                    Top Performing Member Sales <br></br>(
                                    {this.state.storelabel})
                                </div>
                                <div className="col-lg-4 col-sm-4 alignRight">
                                    <ChartOptions
                                        chartid="topmembersale"
                                        toggleTable={this.handleTable}
                                        toggleChart={this.handleChart}
                                        tableimgid="membersale"
                                        chartdata={this.state.topmembersale}
                                    />
                                </div>
                            </div>
                            <div className="row noPadding noMargin">
                                <div className="title_devide"></div>
                                <div className="col-lg-12 col-sm-12">
                                    {this.state.showTable === true &&
                                    this.state.showTableId === "membersale" &&
                                    this.state.topmembersale.length !== 0 ? (
                                        <ChartTable
                                            tabledata={this.state.topmembersale}
                                        />
                                    ) : (
                                        this.renderMemberSaleSummary()
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderChartDiv2 = () => {
        return (
            <React.Fragment>
                <div className="row noPadding noMargin">
                    <div className="col-lg-12 col-sm-12">
                        <div className="chartLeft chartDiv">
                            <div className="row noPadding noMargin">
                                <div className="chartTitle col-lg-10 col-sm-8 alignLeft">
                                    Top Performing Product Sales (
                                    {this.state.storelabel})
                                </div>
                                <div className="col-lg-2 col-sm-4 alignRight">
                                    <ChartOptions
                                        chartid="topproductsale"
                                        toggleTable={this.handleTable}
                                        toggleChart={this.handleChart}
                                        tableimgid="productsale"
                                        chartdata={this.state.topproductsale}
                                    />
                                </div>
                            </div>
                            <div className="row noPadding noMargin">
                                <div className="title_devide"></div>
                                <div className="col-lg-12 col-sm-12">
                                    {this.state.showTable === true &&
                                    this.state.showTableId === "productsale" &&
                                    this.state.topproductsale.length !== 0 ? (
                                        <ChartTable
                                            tabledata={
                                                this.state.topproductsale
                                            }
                                        />
                                    ) : (
                                        this.renderProductSaleSummary()
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderMessage = () => {
        return (
            <React.Fragment>
                <div className="row noMargin noPadding">
                    <div className="col-lg-12 col-sm-12">
                        <div className="tableNoData">
                            Your search does not retrieve any data. Please
                            search again using another criteria.
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderForm = () => {
        console.log("renderForm");
        const {
            storeid,
            storelabel,
            topproductsale,
            topmembersale,
            salebymonth,
            salebydate,
            salebyyear,
        } = this.state;
        return (
            <div>
                <div className="row noMargin noPadding">
                    <div className="col-lg-12 col-sm-12">
                        <form className="splitFrmDiv">
                            {this._renderFormArea()}
                        </form>
                    </div>
                </div>
                <form>
                    {/* {storeid === "" || storelabel === "" ||  topproductsale.length === 0 || topmembersale.length === 0 || salebymonth.length === 0 || salebyyear.length === 0 || salebydate.length === 0 ? */}
                    {storeid === "" ||
                    storelabel === "" ||
                    (topproductsale.length === 0 &&
                        topmembersale.length === 0 &&
                        salebymonth.length === 0 &&
                        salebyyear.length === 0 &&
                        salebydate.length === 0) ? (
                        <div>{this._renderMessage()}</div>
                    ) : (
                        <div>
                            {this._renderChartDiv1()}
                            {this._renderChartDiv2()}
                        </div>
                    )}
                </form>
            </div>
        );
    };

    render() {
        return (
            <div className="form_height">
                <div className="row form-container">
                    <div className="col">{this._renderForm()}</div>
                </div>
            </div>
        );
    }
}

SalesAnalytics.propTypes = {};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        responses: state.crudReducer,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(SalesAnalytics);

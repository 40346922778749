import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { connect } from "react-redux";
import axios from "axios";
import { storeSearchByFields } from "../../actions/actions";
import { Toast } from "primereact/toast";
import Loading from "../../components/Loading";

const GrabFoodIntegration = ({ queryData, dispatch }) => {
    const toast = useRef(null);
    const { stores } = queryData;
    const [storeList, setStoreList] = useState([]);
    const [storeId, setStoreId] = useState();
    const [merchantID, setMerchantId] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    const BASE_URL = window?.config?.END_POINT;
    const EXT_URL = window?.config?.EXT_GRAB;
    const authToken = localStorage.getItem("session");

    useEffect(() => {
        const companyId = localStorage.getItem("company_id");
        dispatch(storeSearchByFields(`companyid=${companyId}`));
    }, [dispatch]);

    useEffect(() => {
        setStoreList(
            stores.map((store) => ({
                label: `(${store.storeid}) ${store.title}`,
                value: store.storeid,
            })),
        );
    }, [stores]);

    useEffect(() => {
        if (storeId?.length > 0) {
            fetchGrabNotification(storeId);
        }
    }, [storeId]);

    const fetchGrabNotification = async (storeId) => {
        try {
            const config = {
                method: "get",
                url: `${BASE_URL}/system/v1/store/tag/search/fields?storeid=${storeId}&tagtype=storeprops&taggroup=storeprops`,
                headers: {
                    Authorization: authToken,
                },
            };

            const response = await axios.request(config);
            if (response.status === 200) {
                const { additionalfields } = response.data[0] || {};
                const additionalConfigs = additionalfields?.additionalConfigs;

                if (additionalConfigs) {
                    try {
                        const { grabfood } = JSON.parse(additionalConfigs);
                        const { mapping_grabMerchantID } = grabfood;
                        setMerchantId(mapping_grabMerchantID);
                    } catch (parseError) {
                        showToast(
                            "error",
                            "Configuration Error",
                            "Invalid GrabFood configuration data.",
                        );
                    }
                } else {
                    showToast(
                        "info",
                        "Info",
                        "GrabFood configuration is not configured.",
                    );
                }
            } else {
                showToast(
                    "info",
                    "Info",
                    "Failed to fetch GrabFood configuration.",
                );
            }
        } catch (error) {
            console.error("Error fetching GrabFood notification:", error);
            showToast(
                "error",
                "Error",
                "Unable to send GrabFood notification.",
            );
        }
    };

    const handleClickNotify = () => sendNotification();

    const sendNotification = () => {
        setIsLoading(true)
        let data = JSON.stringify({
            merchantID: merchantID,
        });

        let config = {
            method: "post",
            url: `${EXT_URL}ext-grab/notify-grab`,
            headers: {
                "Content-Type": "application/json",
                Authorization: authToken,
            },
            data: data,
            timeout: 180000
        };

        axios
            .request(config)
            .then((response) => {
                setIsLoading(false)
                if (response.status === 200) {
                    showToast(
                        "success",
                        "Success",
                        "GrabFood menu updated successfully.",
                    );
                    setMerchantId("");
                    setStoreId("")
                }
            })
            .catch((error) => {
                if (error.code === 'ECONNABORTED') {
                    setIsLoading(false)
                    setMerchantId("");
                    setStoreId("")
                    showToast(
                        "error",
                        "Timeout",
                        "The request took too long to complete. Please try again."
                    );
                } else {
                    // Handle other errors
                    console.log(error);
                }
            });
    };

    // Helper function to show toast messages
    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail });
    };

    const handleChange = (e) => {
        const selectedId = e.value;
        setStoreId(selectedId);
    };

    return (
        <>
            {isLoading && (
                <Loading />
            )}
            <Toast ref={toast} />
            <div className="form_height">
                <Header title="Notify Grab Food Menu Update" />

                <div className="frmDiv mx-3 mt-3">
                    <div className="row p-fluid formgrid grid w-100">
                        <div className="p-form-group field col-3 md:col-3 mb-2">
                            <label htmlFor="storeid">Select Store</label>
                            <Dropdown
                                className="w-100"
                                id="storeid"
                                name="storeid"
                                value={storeId}
                                onChange={handleChange}
                                options={storeList}
                                optionLabel="label"
                                placeholder="Select store"
                                filter
                            />
                        </div>
                        <div className="field mb-2" style={{ display: "flex" }}>
                            <NotifyButton
                                handleClickNotify={handleClickNotify}
                                merchantID={merchantID}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    queryData: state.queryData,
});

export default connect(mapStateToProps)(GrabFoodIntegration);

// Reusable Components

const Header = ({ title }) => (
    <div className="m-4">
        <h2 className="text-3xl font-normal" style={{ color: "#212121" }}>
            {title}
        </h2>
    </div>
);

const NotifyButton = ({ handleClickNotify, merchantID }) => (
    <div style={{ marginTop: "28px" }}>
        <Button
            label="Notify"
            icon="pi pi-bell"
            severity="success"
            outlined
            onClick={handleClickNotify}
            disabled={merchantID.length === 0}
        />
    </div>
);

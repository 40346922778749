import React from "react";
import { connect } from "react-redux";
import ConfirmAlert from "../../../components/ConfirmAlert";
import { Link } from "react-router-dom";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import DatePicker from "react-date-picker";
import { produce } from "immer";
import DynamicDateTimePicker from "./../../../components/DynamicDateTimePicker";
import { toDate } from "../../../helpers/Util";
import {
    crudReset,
    updateStoreHours,
    getStoreById,
} from "../../../actions/actions";
import StoreSearchSingle from "../users/StoreSearchSingle";
class DisplayStoreHours extends React.PureComponent {
    dayoptions = {
        mon: "monday",
        tue: "tuesday",
        wed: "wednesday",
        thu: "thursday",
        fri: "friday",
        sat: "saturday",
        sun: "sunday",
    };

    constructor(props) {
        super(props);
        this.state = {
            storeid: "",
            storelabel: "",
            additionalFields: [
                {
                    pickdate: "",
                    starttime: "",
                    endtime: "",
                    id: 0,
                },
            ],
            lastordertime: {},
            bymonth: {},
            closedatearr: [],
            //errors
            storeiderror: "",
            closedateerror: "",
            tablecols: [
                { dataFieldId: "pickdate", label: "Date", addRow: true },
                { dataFieldId: "starttime", label: "Start Time", addRow: true },
                { dataFieldId: "endtime", label: "End Time", addRow: true },
                { dataFieldId: "AD", label: "" },
            ],
        };
    }

    componentDidUpdate = (prevProps) => {
        const { storeArray } = this.props.queryData;
        const { storeArray: prevStoreArray } = prevProps.queryData;
        if (prevStoreArray !== storeArray) {
            console.log("debug", storeArray);
            // let ordertime = storeArray.lastordertime;
            // let holiday = storeArray.lastordertime.closedate.length > 0 ?
            //  storeArray.lastordertime.closedate.split(",").map(e=>e) : [];
            // let closedates = [...new Set(holiday)];
            // this.setState({
            //     lastordertime: ordertime,
            //     closedatearr: closedates
            // },()=>{
            //     this.updateStateValues();
            // })
        }
    };

    updateStateValues = () => {
        const { lastordertime } = this.state;
        let result = Object.entries(lastordertime).reduce(
            (r, [k, v]) => {
                if (v === "") {
                    r.month[k + "_starttime"] = "";
                    r.month[k + "_endtime"] = "";
                    return r;
                }
                var [starttime, endtime] = v.split(",");
                if (k.length === 3) {
                    r.month[k + "_starttime"] = starttime;
                    r.month[k + "_endtime"] = endtime;
                } else if (k === "closedate") {
                    delete lastordertime[k];
                } else {
                    r.fields.push({
                        pickdate: moment(k, "DD-MM-YYYY", true).toDate(),
                        starttime: moment(starttime, "HH:mm"),
                        endtime: moment(endtime, "HH:mm"),
                        id: r.fields.length,
                    });
                }
                return r;
            },
            { month: {}, fields: [] },
        );
        if (Object.keys(result).length !== 0) {
            let addfields =
                result.fields.length > 0
                    ? result.fields
                    : [{ pickdate: "", starttime: "", endtime: "", id: 0 }];
            this.setState({
                additionalFields: addfields,
                bymonth: result.month,
            });
        }
    };

    validateAndSearch = () => {
        if (this.validateForm()) {
            this.updateStoreHours();
        } else {
            this.setState({
                formError: true,
                formErrorMsg: "Clear all the errors to continue",
            });
            window.scrollTo(0, 0);
        }
    };

    updateStoreHours = () => {
        this.props.dispatch(updateStoreHours(this.getCreatePayload()));
    };

    filterkeys(obj, arr) {
        for (const key of Object.keys(obj)) {
            if (!arr.includes(key)) {
                delete obj[key];
            }
        }
        return obj;
    }

    getCreatePayload = () => {
        const { lastordertime, storeid, closedatearr, additionalFields } =
            this.state;
        this.checkValidDates(lastordertime);

        const lastorderobj = this.filterkeys(
            lastordertime,
            Object.keys(this.dayoptions),
        );

        //add additional fields to lastorderobj
        let bydate = this.objectbydate(additionalFields);
        let timings = Object.assign({}, lastorderobj, bydate);

        //get id
        let getbystoreid = this.props.queryData.dataByCountry.filter(
            (e) => e.storeid === storeid,
        );
        let countryid = getbystoreid[0].id;

        // add closedate to lastorderobj
        timings.closedate = closedatearr.toString().replace(/^,|,$/g, "");

        const requestBody = {
            body: {
                storeid: storeid,
                id: countryid,
                lastordertime: timings,
            },
        };
        return requestBody;
    };

    objectbydate = (fields) => {
        const isEmpty = fields.filter(
            (e) => e.pickdate !== "" || e.starttime !== "" || e.endtime !== "",
        );
        if (isEmpty.length > 0) {
            return fields.reduce(
                (acc, cur) => ({
                    ...acc,
                    [toDate(cur.pickdate)]:
                        moment(cur.starttime).format("HH:mm") +
                        "," +
                        moment(cur.endtime).format("HH:mm"),
                }),
                {},
            );
        }
    };

    checkValidDates = (obj) => {
        for (var key in obj) {
            let arr = obj[key].split(",");
            if (arr.includes("Invalid date")) {
                obj[key] = "";
            }
        }
        return obj;
    };

    validateForm = () => {
        this._clearErrorMessages();
        const { storeid } = this.state;
        let valid = true;
        if (storeid.trim() === "") {
            this.setState({ storeiderror: "Please select store id" });
            valid = false;
        }
        return valid;
    };

    _clearErrorMessages = () => {
        this.setState({
            storeiderror: "",
            formError: false,
            formErrorMsg: "",
        });
    };

    handleTime(key, time) {
        const { lastordertime } = this.state;
        let keys = key.split("_");
        let hours = lastordertime[keys[0]].split(",");
        if (keys[1] === "starttime") {
            hours[0] = time;
        } else {
            hours[1] = time;
        }
        return {
            ...lastordertime,
            [keys[0]]: hours.join(","),
        };
    }

    handleChange(time, name) {
        let timeformat = moment(time).format("HH:mm");
        let updatelastordertime = this.handleTime(name, timeformat);
        this.setState(
            {
                bymonth: {
                    [name]: timeformat,
                },
                lastordertime: updatelastordertime,
            },
            () => {
                this.updateStateValues();
            },
        );
    }

    deleteRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.additionalFields.length;
        if (rowLength !== 1) {
            const updateValues = this.state.additionalFields.filter(
                (value) => rowId !== value.id,
            );
            this.setState({ additionalFields: updateValues });
        } else {
            this.setState({
                additionalFields: [
                    { pickdate: "", starttime: "", endtime: "", id: 0 },
                ],
            });
        }
    };

    handleAddRow = (e) => {
        if (e) e.preventDefault();
        const rowLength = this.state.additionalFields.length;
        let lastele = this.state.additionalFields[rowLength - 1];
        const item = {
            pickdate: "",
            starttime: "",
            endtime: "",
            id: lastele.id + 1,
        };
        this.setState(
            {
                additionalFields: [...this.state.additionalFields, item],
            },
            () => {
                console.log("Rows After Add", this.state.additionalFields);
            },
        );
    };

    handleInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]:
                dataField === "pickdate"
                    ? moment(event, "DD-MM-YYYY", true).toDate()
                    : moment(event, "HH:mm"),
        };
        this.setState(
            produce((draft) => {
                if (draft.additionalFields.length === 0) {
                    draft.additionalFields.push(obj);
                } else {
                    let found = false;
                    draft.additionalFields.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.additionalFields.push(obj);
                    }
                }
            }),
            () => {},
        );
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _renderMainButton = () => {
        return (
            <React.Fragment>
                {
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => {
                            this.validateAndSearch();
                        }}
                    >
                        Save
                    </button>
                }

                <Link
                    to="/ui/store-information"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    _setTenant = (p1, p2) => {
        console.log("p1", p1, p2);
        this.setState({ storeid: p1, storelabel: p2 }, () => {
            if (this.validateForm()) {
                this.props.dispatch(getStoreById(p1));
            }
        });
    };

    _onSelectStore = (selectedStores) => {
        console.log("here", selectedStores);
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    renderTimePicker = () => {
        const days = Object.entries(this.dayoptions);
        return (
            <>
                <div className="row">
                    <div className="col-lg-3 col-sm-3 label2">Day</div>
                    <div className="col-lg-3 col-sm-3 label2">Start Time</div>
                    <div className="col-lg-3 col-sm-3 label2">End Time</div>
                </div>
                {days.map(([shortDay, fullDay]) => (
                    <div className="row" key={shortDay}>
                        <div className="col-lg-3 col-sm-3 floatLeft">{fullDay}</div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            <TimePicker
                                value={
                                    this.state.bymonth[`${shortDay}_starttime`]
                                        ? moment(this.state.bymonth[`${shortDay}_starttime`], "HH:mm")
                                        : null
                                }
                                name={`${shortDay}_starttime`}
                                onChange={(time) =>
                                    this.handleChange(time, `${shortDay}_starttime`)
                                }
                                format="HH:mm"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            <TimePicker
                                value={
                                    this.state.bymonth[`${shortDay}_endtime`]
                                        ? moment(this.state.bymonth[`${shortDay}_endtime`], "HH:mm")
                                        : null
                                }
                                name={`${shortDay}_endtime`}
                                onChange={(time) =>
                                    this.handleChange(time, `${shortDay}_endtime`)
                                }
                                format="HH:mm"
                            />
                        </div>
                    </div>
                ))}
            </>
        );
    };

    buttonHanlder = (value) => {
        var dateformat = [
            ("0" + value.getDate()).slice(-2) +
                "-" +
                ("0" + (value.getMonth() + 1)).slice(-2) +
                "-" +
                value.getFullYear(),
        ];
        this.setState((prevState) => ({
            closedatearr: [...prevState.closedatearr, ...dateformat],
        }));
    };

    textareaHandler = (val) => {
        const { closedatearr } = this.state;
        let filterdata = closedatearr.filter((item) => item !== val);
        this.setState({
            closedatearr: filterdata,
        });
    };

    _renderFormArea = () => {
        const { closedatearr } = this.state;
        let checkempty = closedatearr.filter((e) => e !== "");
        let closedate = [...new Set(checkempty)];
        var contentareastyle = {
            display: "inline-flex",
            flexFlow: "wrap",
            border: "1px solid #dcdcdc",
            padding: "10px",
            overflowY: "scroll",
            maxHeight: "200px",
        };
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="storeid" className="floatLeft">
                            Store
                        </label>
                        <StoreSearchSingle
                            value={this.state.storelabel}
                            onSelectStore={this._onSelectStore}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>
                </div>
                <div className="form-group mx-2">
                    {this.renderTimePicker()}
                </div>
                <div className="row">
                    <div className="form-group col-lg-10 col-sm-12">
                        <DynamicDateTimePicker
                            columns={this.state.tablecols}
                            rows={this.state.additionalFields}
                            deleteRow={this.deleteRow}
                            addRow={this.handleAddRow}
                            handleInputChange={this.handleInputChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-10 col-sm-12">
                        <label htmlFor="sortorder" className="floatLeft label2">
                            Store Close Date / Holiday
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-4 col-sm-7">
                        <DatePicker
                            locale="en-GB"
                            onChange={(value) => {
                                this.setState({ closedate: value }, () => {
                                    this.buttonHanlder(value);
                                });
                            }}
                            value={new Date()}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-7 col-sm-12">
                        <div style={contentareastyle}>
                            {closedate.map((datevalue) => {
                                return (
                                    <div
                                        className="text-content"
                                        key={datevalue.toString()}
                                    >
                                        <input
                                            type="text"
                                            value={datevalue}
                                            className="inputfield"
                                            readOnly
                                        />
                                        <button
                                            className="btn-primary btntext"
                                            type="button"
                                            onClick={() =>
                                                this.textareaHandler(datevalue)
                                            }
                                        >
                                            x
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div style={{ height: "30px" }}></div>
            </React.Fragment>
        );
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-sm-9">
                            <h2 className="page-header">Store Hours</h2>
                        </div>
                    </div>
                    <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1 mx-3">
                            {this._renderErrorMessage()}
                            {this._renderFormArea()}
                        </div>
                    <div className="row form-container">
                        <div className="row btn-container form-button">
                            <div className="col-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>

                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/store-hours"
                        children={
                            <div className="alert alert-success">
                                Store Hours updated successfully!
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return { queryData: state.queryData, crudData: state.crudData };
};

export default connect(mapStateToProps)(DisplayStoreHours);

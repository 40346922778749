import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { connect } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import PrimeTable from "../../../components/PrimeTable";

const NetsuiteMappingList = (props) => {
    const { history } = props
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterData, setFilterData] = useState();
    const [loading, setLoading] = useState(true);
    const [logList, setLogList] = useState([]);

    const getData = (params) => {
        const defaultParams = {
            page: currentPage,
            size: pageSize,
        };

        const finalParams = { ...defaultParams, ...params }; // Merge defaults with passed params
        const queryParams = new URLSearchParams(finalParams).toString(); // Construct query string

        const config = {
            method: "get",
            url: `http://localhost:9004/ext-netsuite/v1/mapping/filter?${queryParams}`,
            headers: {
                Authorization: "7550935cd2bc97f0307afb2aa204e245",
            },
        };

        axios
            .request(config)
            .then((response) => {
                setLogList(response.data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const pageSizeChangecallback = (size) => {
        setPageSize(size);
        const currentPage = 1;
        getData({ page: currentPage, size, ...filterData });
    };

    const pageChangeCallback = (page) => {
        setCurrentPage(page);
        getData({ page, size: pageSize, ...filterData });
    };

    const clearEmptyParam = (data) => {
        Object.keys(data).forEach((key) => {
            if (data[key] === "") {
                delete data[key];
            }
        });
    };

    const handleSearch = (data) => {
        setLoading(true);
        clearEmptyParam(data);
        setFilterData(data); //set search data into filter data
        const parsedValues = {
            ...data,
        };
        clearEmptyParam(parsedValues);
        getData({ page: currentPage, size: pageSize, ...data });
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="flex">
                <img
                    title="View"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                />
            </div>
        );
    };

    const columns = [
        { field: "dx_product_code", header: "Dx Product Code" },
        { field: "dx_product_name", header: "Dx Product Name" },
        { field: "dx_addons", header: "Dx Addons" },
        { field: "dx_addons_name", header: "Dx Addons Name" },
        { field: "netsuite_product_code", header: "Netsuite Product Code" },
        { field: "netsuite_product_desc", header: "Netsuite Product Desc" },
        { field: "salesUnitId", header: "Sales Unit Id" },
    ];

    const renderListView = () => {
        return (
            <>
                <div className="form_height">
                    <div className="flex px-4 mt-4">
                        <h2 className="text-3xl font-normal c-brown">
                            Netsuite Product Mapping
                        </h2>
                        <div className="ml-auto">
                            <Button
                                icon="pi pi-plus"
                                label="Add New"
                                severity="info"
                                outlined
                                className="ml-2"
                                onClick={() =>
                                    history.push("/ui/extension/netsuite-mapping/configure")
                                }
                            />
                        </div>
                    </div>
                    <div className="p-fluid formgrid grid br-2 bg-white p-4 border-round-xl shadow-1 m-4">
                        <FilterForm
                            handleSearch={handleSearch}
                            data={filterData}
                        />
                        <PrimeTable
                            list={logList}
                            columns={columns}
                            actionColumn={actionTemplate}
                            pageSizeChangecallback={pageSizeChangecallback}
                            pageChangeCallback={pageChangeCallback}
                        />
                    </div>
                </div>
            </>
        );
    };

    return <>{renderListView()}</>;
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

const FilterForm = (props) => {
    const { handleSearch, data } = props;
    const initialValues = {
        dx_product_code: "",
        dx_product_name: "",
        netsuite_product_code: "",
    };
    const formik = useFormik({
        initialValues: {
            dx_product_code: "",
            dx_product_name: "",
            netsuite_product_code: "",
        },
        onSubmit: (values) => {
            const data = {
                ...values,
            };
            handleSearch(data);
        },
    });

    const handleClear = () => {
        formik.resetForm();
        handleSearch(formik.values);
    };

    useEffect(() => {
        handleSearch(initialValues);
    }, []);

    const renderFormArea = () => {
        return (
            <>
                <div className="p-fluid formgrid grid w-100">
                    <div className="field col-3 md:col-3">
                        <label htmlFor="type">Dx Product Code</label>
                        <InputText
                            id="dx_product_code"
                            type="text"
                            name="dx_product_code"
                            value={formik.values.dx_product_code}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="type">Dx Product Name</label>
                        <InputText
                            id="dx_product_name"
                            type="text"
                            name="dx_product_name"
                            value={formik.values.dx_product_name}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="type">Netsuite Product Code</label>
                        <InputText
                            id="netsuite_product_code"
                            type="text"
                            name="netsuite_product_code"
                            value={formik.values.netsuite_product_code}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div
                        className="field col-3 md:col-3"
                        style={{ display: "flex", alignItems: "flex-end" }}
                    >
                        <div style={{ display: "flex" }}>
                            <Button
                                type="submit"
                                label="Search"
                                className="p-button-success mr-4"
                            />
                            {/* <Button
                                type="button"
                                label="Clear"
                                className="p-button-secondary"
                                onClick={handleClear}
                            /> */}
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return (
        <form onSubmit={formik.handleSubmit} className="w-100">
            {renderFormArea()}
        </form>
    );
};

export default connect(mapStateToProps)(NetsuiteMappingList);

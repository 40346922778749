import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  createArticleInfoApi,
  updateArticleInfoApi,
  crudReset,
  getBeaconsList,
  getGeofenceList,
  getStoreById
} from "../../../actions/actions";
import StatusComp from "../../../components/StatusComp";
import SimpleSelect from "./../../../components/SimpleSelect";
import validator from "validator";
import ConfirmAlert from "../../../components/ConfirmAlert";
import { produce } from "immer";
import SimpleImageInput from "./../../../components/SimpleImageInput";
import { renderBeaconOrGeofenceList } from "./../../../helpers/Util";
import MultiSelect from "./../../../components/MultiSelect";
import StoreSearchSingle from "../../system/users/StoreSearchSingle";

class AddStoreInformation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      isEdit: false,
      isView: false,
      // Formrelated
      storeid: "",
      title: "",
      subtitle: "",
      desc: "",
      slogan: "",
      chaincode: "",
      redemptioncode: "",
      address1: "",
      address2: "",
      city: "",
      country: "",
      postal: "",
      contact: "",
      email: "",
      phone: "",
      fax: "",
      latitude: "",
      longitude: "",
      weblink: "",
      status: "New",
      articlegroup: "",
      beacons: "",
      geofences: "",

      sortorder: 0,
      language: "",
      storeLogo: "",
      storeBg: "",
      storeMap: "",
      langlist: {},
      // Errors
      tenantCodeError: "",
      titleError: "",
      descError: "",
      storeLogoError: "",
      emailError: "",
      postalCodeError: "",
      phoneError: "",
      locationerror: "",
      storehourserror: "",
      pickuphourserror: "",
      formError: "",
      formErrorMsg: "",
      storelabel: "",
      location: "",
      storehours: "",
      pickuphours: ""
    };
  }

  componentDidUpdate = (prevProps) => {
    const { languageConfig } = this.props.queryData;
    const { languageConfig: prevlanguageConfig } = prevProps.queryData;
    if(languageConfig !== prevlanguageConfig){
      if (languageConfig.length > 0) {
        const list = languageConfig[0].additionalfields
        this.setState({
          langlist: list
        })
      }
    }
  }

  componentWillMount = () => {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage });
    this.props.dispatch(
      getBeaconsList({ body: { language: defLanguage, status: "New,Active" } })
    );
    this.props.dispatch(
      getGeofenceList({ body: { language: defLanguage, status: "New,Active" } })
    );

    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      const isView = this.props.location.state.isView;
      
      if (rec) {
        const {
          id,
          title,
          language,
          storeid,
          status,
          sortorder,
          fields,
          images,
          articlegroup
        } = rec;
        this.props.dispatch(getStoreById(storeid));
        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.isView = isView;
            draft.title = title ? title : "";
            draft.language = language ? language : defLanguage;
            draft.articlegroup = articlegroup ? articlegroup : "";
            draft.storeid = storeid ? storeid : "";
            //draft.status = status ? status : [];
            draft.sortorder = sortorder ? sortorder : 0;

            draft.subtitle = fields.subtitle ? fields.subtitle : "";
            draft.desc = fields.desc ? fields.desc : "";
            draft.slogan = fields.slogan ? fields.slogan : "";
            draft.location = fields.location ? fields.location : "";
            draft.storehours = fields.storehours ? fields.storehours : "";
            draft.pickuphours = fields.pickuphours ? fields.pickuphours : "";
            draft.address1 = fields.address1 ? fields.address1 : "";
            draft.address2 = fields.address2 ? fields.address2 : "";
            draft.city = fields.city ? fields.city : "";
            draft.country = fields.country ? fields.country : "";
            draft.postal = fields.postal ? fields.postal : "";
            draft.email = fields.email ? fields.email : "";
            draft.contact = fields.contact ? fields.contact : "";
            draft.fax = fields.fax ? fields.fax : "";
            draft.latitude = fields.latitude ? fields.latitude : "";
            draft.longitude = fields.longitude ? fields.longitude : "";
            draft.beacons = fields.beacons ? fields.beacons.split(",") : "";
            draft.geofences = fields.geofences
              ? fields.geofences.split(",")
              : "";
            draft.weblink = fields.weblink ? fields.weblink : "";
            draft.storeLogo = rec.hasOwnProperty("images") === true ? images.storeLogo : "";
            draft.storeBg = rec.hasOwnProperty("images") === true ? images.storeBg : "";
            draft.storeMap = rec.hasOwnProperty("images") === true ? images.storeMap : "";
          })
        );
      }
    }
  };
  _renderErrorMessage = () => {
    const { isSuccess, errorMessage } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errorMessage && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };
  getCreatePayload = () => {
    const {
      storeid,
      title,
      subtitle,
      desc,
      slogan,
      location,
      storehours,
      pickuphours,
      address1,
      address2,
      city,
      country,
      postal,
      contact,
      email,
      fax,
      latitude,
      longitude,
      weblink,
      storeLogo,
      storeBg,
      storeMap,
      language,
      status,
      geofences,
      beacons,
      sortorder,
      articlegroup
    } = this.state;
    let body = {
      title: title,
      language: language,
      storeid: storeid,
      publishmode: "manual",
      articletype: "Store Information",
      articlegroup,
      status,
      sortorder,
      fields: {
        subtitle: subtitle,
        desc: desc,
        slogan: slogan,
        location: location,
        storehours: storehours,
        pickuphours,
        address1: address1,
        address2: address2,
        city: city,
        country: country,
        postal: postal,
        email: email,
        contact: contact,
        fax: fax,
        latitude: latitude,
        longitude: longitude,
        weblink: weblink,
        geofences: geofences.toString(),
        beacons: beacons.toString()
      },
      images: {
        storeLogo: storeLogo || "",
        storeBg: storeBg || "",
        storeMap: storeMap || ""
      }
    };
    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }
    const payload = {
      body: body
    };
    return payload;
  };
  setBeaconsSelected = value => {
    this.setState({
      beacons: value
    });
  };
  setGeoFenceSelected = value => {
    this.setState({
      geofences: value
    });
  };

  _createStore = () => {
    if (this.validateForm()) {
      if (this._isEditMode()) {
        this.props.dispatch(
          updateArticleInfoApi(this.getCreatePayload(), this.state.id)
        );
      } else {
        this.props.dispatch(createArticleInfoApi(this.getCreatePayload()));
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Please clear all the errors to continue !!!"
      });
      window.scrollTo(0, 0);
    }
  };
  _clearErrorMessages = () => {
    this.setState({
      tenantCodeError: "",
      descError: "",
      titleError: "",
      locationerror: "",
      pickuphourserror: "",
      storehourserror: "",
      storeLogoError: "",
      formError: false,
      formErrorMsg: ""
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const {
      storeid,
      title,
      desc,
      email,
      contact,
      storeLogo,
      storehours,
      location,
      pickuphours
    } = this.state;
    let valid = true;
    if (storeid.trim() === "") {
      this.setState({
        tenantCodeError: "Please enter store id"
      });
      valid = false;
    }
    if (title.trim() === "") {
      this.setState({
        titleError: "Please enter a title"
      });
      valid = false;
    }
    if (desc.trim() === "") {
      this.setState({
        descError: "Please enter a description"
      });
      valid = false;
    }
    if (location.trim() === "") {
      this.setState({
        locationerror: "Please enter location"
      });
      valid = false;
    }
    if (storehours.trim() === "") {
      this.setState({
        storehourserror: "Please enter store hours"
      });
      valid = false;
    }
    if (pickuphours.trim() === "") {
      this.setState({
        pickuphourserror: "Please enter pickup hours"
      });
      valid = false;
    }
    if (storeLogo.trim() === "") {
      this.setState({
        storeLogoError: "Please add a logo image"
      });
      valid = false;
    }
    if (email.trim() !== "" && !validator.isEmail(email)) {
      this.setState({ emailError: "Enter a valid email id" });
      valid = false;
    }
    if (contact.trim() !== "" && !validator.isNumeric(contact)) {
      this.setState({ phoneError: "Enter a valid phone number" });
      valid = false;
    }

    return valid;
  };
  _isEditMode = () => {
    return this.state.isEdit;
  };

  _isViewMode = () => {
    return this.state.isView;
  };

  _renderMainButton = () => {
    if (this._isViewMode()) {
      return (
        <React.Fragment>
          <Link
            to="/ui/store-information"
            className="btn btn-themes btn-rounded btn-sec link-sec-btn"
          >
            Close
          </Link>
        </React.Fragment>
      );
    }

    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => {
              this._createStore();
            }}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={this._createStore}
          >
            Update
          </button>
        )}

        <Link
          to="/ui/store-information"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link>
      </React.Fragment>
    );
  };
  
  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2 });
  };

  _onSelectStore = (selectedStores) => {
    this._setTenant(selectedStores.value, selectedStores.label)
  }

  componentWillReceiveProps = () => {
    if (this._isEditMode() || this._isViewMode()) {
      const { storeArray } = this.props.queryData;
      //if (storeArray) this.setState({ storelabel: storeArray.title });
      if (storeArray) {
        this.state.storeid ? this.setState({ storelabel: storeArray.title }) :
        this.setState({ storelabel: this.state.storelabel})        
      };
      this.setState({ language: this.props.history.location.state.item.language });
    }
    else {
      const defLanguage = this.props.location.state.language;
      this.setState({ language: defLanguage }); 
    }
  };

  _renderMainFormDetails = () => {
    const { beaconsList, geoFencesList } = this.props.queryData;
    return (
      <React.Fragment>
        <div className="row sub-title">Store Details</div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12 ">
            <label htmlFor="storeid" className="floatLeft required">
              Store
            </label>
            {/* <TenantCode
              value={this.state.storelabel}
              setTenantCode={this._setTenant}
            /> */}
            <StoreSearchSingle value={this.state.storelabel} onSelectStore={this._onSelectStore} />
            <div className="invalid-feedback">{this.state.tenantCodeError}</div>
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="articlegroup" className="floatLeft">
              Article Group
            </label>
            <input
              type="text"
              className="form-control"
              id="articlegroup"
              value={this.state.articlegroup}
              onChange={event => {
                this.setState({ articlegroup: event.target.value });
              }}
            />
          </div>   
          {/* <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="articlegroup" className="floatLeft">
              Article Group
            </label>
            <input
              type="text"
              className="form-control"
              id="articlegroup"
              value={this.state.articlegroup}
              onChange={event => this.setState({ articlegroup: event.target.value })}
            />
          </div> */}
        </div>

        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="title" className="floatLeft required">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={this.state.title}
              onChange={event => this.setState({ title: event.target.value })}
            />
            <div className="invalid-feedback">{this.state.titleError}</div>
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="subtitle" className="floatLeft">
              Sub Title
            </label>
            <input
              type="text"
              className="form-control"
              id="subtitle"
              value={this.state.subtitle}
              onChange={event =>
                this.setState({ subtitle: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="desc" className="floatLeft required">
              Description
            </label>
            <textarea
              className="form-control"
              id="desc"
              value={this.state.desc}
              onChange={event => this.setState({ desc: event.target.value })}
            />
            <div className="invalid-feedback">{this.state.descError}</div>
          </div>

          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="slogan" className="floatLeft">
              Slogan
            </label>

            <textarea
              className="form-control"
              id="slogan"
              value={this.state.slogan}
              onChange={event => this.setState({ slogan: event.target.value })}
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="chaincode" className="floatLeft">
              Chain Code
            </label>
            <input
              type="text"
              className="form-control"
              id="chaincode"
              value={this.state.chaincode}
              onChange={event =>
                this.setState({ chaincode: event.target.value })
              }
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="redemptioncode" className="floatLeft">
              Redemption Code
            </label>
            <input
              type="text"
              className="form-control"
              id="redemptioncode"
              value={this.state.redemptioncode}
              onChange={event =>
                this.setState({ redemptioncode: event.target.value })
              }
            />
            <div className="invalid-feedback">
              {this.state.redemptioncodeError}
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="beacons" className="floatLeft">
              Beacons
            </label>
            <MultiSelect
              filterOptions={renderBeaconOrGeofenceList(beaconsList)}
              setSelectedFieldValues={this.setBeaconsSelected}
              id="beacons"
              itemsSelected={this.state.beacons}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="geofences" className="floatLeft">
              GeoFences
            </label>
            <MultiSelect
              filterOptions={renderBeaconOrGeofenceList(geoFencesList)}
              setSelectedFieldValues={this.setGeoFenceSelected}
              id="geofences"
              itemsSelected={this.state.geofences}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  _renderContactInformation = () => {
    return (
      <React.Fragment>
        <div className="row sub-title">Contact Information</div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="address1" className="floatLeft">
              Address 1
            </label>
            <input
              type="text"
              className="form-control"
              id="address1"
              value={this.state.address1}
              onChange={event =>
                this.setState({ address1: event.target.value })
              }
            />
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="address2" className="floatLeft">
              Address 2
            </label>
            <input
              type="text"
              className="form-control"
              id="address2"
              value={this.state.address2}
              onChange={event =>
                this.setState({ address2: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="city" className="floatLeft">
              City
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              value={this.state.city}
              onChange={event => this.setState({ city: event.target.value })}
            />
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="country" className="floatLeft">
              Country
            </label>
            <input
              type="text"
              className="form-control"
              id="country"
              value={this.state.country}
              onChange={event => this.setState({ country: event.target.value })}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="postal" className="floatLeft">
              Postal Code
            </label>
            <input
              type="text"
              className="form-control"
              id="postal"
              value={this.state.postal}
              onChange={event => this.setState({ postal: event.target.value.replace(/[^0-9]/g, '' )})}
            />
            <div className="invalid-feedback">{this.state.postalCodeError}</div>
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="contact" className="floatLeft">
              Telephone
            </label>
            <input
              type="text"
              className="form-control"
              id="contact"
              value={this.state.contact}
              onChange={event => this.setState({ contact: event.target.value })}
            />
            <div className="invalid-feedback">{this.state.phoneError}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="email" className="floatLeft">
              Email
            </label>
            <input
              type="text"
              className="form-control"
              id="email"
              value={this.state.email}
              onChange={event => this.setState({ email: event.target.value })}
            />
            <div className="invalid-feedback">{this.state.emailError}</div>
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="fax" className="floatLeft">
              Fax
            </label>
            <input
              type="text"
              className="form-control"
              id="fax"
              value={this.state.fax}
              onChange={event => this.setState({ fax: event.target.value })}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="location" className="floatLeft required">
              Location
            </label>
            <input
              type="text"
              className="form-control"
              id="location"
              value={this.state.location}
              onChange={event =>
                this.setState({ location: event.target.value })
              }
            />
            <div className="invalid-feedback">{this.state.locationerror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="storehours" className="floatLeft required">
              Store Hours
            </label>
            <textarea
              className="form-control"
              id="storehours"
              value={this.state.storehours}
              onChange={event =>
                this.setState({ storehours: event.target.value })
              }
            />
            <div className="invalid-feedback">{this.state.storehourserror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="pickuphours" className="floatLeft required">
              Pick Up Hours
            </label>
            <textarea
              className="form-control"
              id="pickuphours"
              value={this.state.pickuphours}
              onChange={event =>
                this.setState({ pickuphours: event.target.value })
              }
            />
            <div className="invalid-feedback">
              {this.state.pickuphourserror}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  
  _renderGeneralInformation = () => {
    return (
      <React.Fragment>
        <div className="row sub-title">Links</div>
        <div className=" row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="latitude" className="floatLeft">
              Latitude
            </label>
            <input
              type="text"
              className="form-control"
              id="latitude"
              value={this.state.latitude}
              onChange={event =>
                this.setState({ latitude: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="longitude" className="floatLeft">
              Longitude
            </label>
            <input
              type="text"
              className="form-control"
              id="longitude"
              value={this.state.longitude}
              onChange={event =>
                this.setState({ longitude: event.target.value })
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="weblink" className="floatLeft">
              Web Link
            </label>
            <input
              type="text"
              className="form-control"
              id="weblink"
              value={this.state.weblink}
              onChange={event => this.setState({ weblink: event.target.value })}
            />
          </div>
        </div>
        {/*  <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="chopelink" className="floatLeft">
              Chope link
            </label>
            <input
              type="text"
              className="form-control"
              id="chopelink"
              value={this.state.chopelink}
              onChange={event =>
                this.setState({ chopelink: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="uberlink" className="floatLeft">
              UberEats Link
            </label>
            <input
              type="text"
              className="form-control"
              id="uberlink"
              value={this.state.uberlink}
              onChange={event =>
                this.setState({ uberlink: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="menulink" className="floatLeft">
              Menu Link
            </label>
            <input
              type="text"
              className="form-control"
              id="menulink"
              value={this.state.menulink}
              onChange={event =>
                this.setState({ menulink: event.target.value })
              }
            />
          </div> 
        </div>*/}
      </React.Fragment>
    );
  };
  _renderImageDetails = () => {
    return (
      <React.Fragment>
        <div className="row sub-title required">Images</div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="menulink" className="floatLeft">
              Store Logo
            </label>
            <SimpleImageInput
              placeholder="Store Logo.."
              setImage={value => {
                this.setState({ storeLogo: value });
              }}
              value={this.state.storeLogo}
            ></SimpleImageInput>
            <div className="invalid-feedback">{this.state.storeLogoError}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="menulink" className="floatLeft">
              Store Background
            </label>
            <SimpleImageInput
              placeholder="Store Background.."
              setImage={value => {
                this.setState({ storeBg: value });
              }}
              value={this.state.storeBg}
            ></SimpleImageInput>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="menulink" className="floatLeft">
              Store Map
            </label>
            <SimpleImageInput
              placeholder="Store Map.."
              setImage={value => {
                this.setState({ storeMap: value });
              }}
              value={this.state.storeMap}
            ></SimpleImageInput>
          </div>
        </div>
      </React.Fragment>
    );
  };
  _renderStatusDetails = () => {
    const { languageConfig } = this.props.queryData;
    return (
      <React.Fragment>
        <div className="row sub-title">Actions</div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft">
              Language
            </label>
            <SimpleSelect
              objArray={this.state.langlist}
              callbackChangeFilter={value => {
                this.setState({ language: value });
              }}
              selectedOption={this.state.language}
              id="language"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="status" className="floatLeft">
              Status
            </label>
            <StatusComp
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft">
              Sort Order
            </label>
            <input
              type="text"
              className="form-control"
              id="sortorder"
              value={this.state.sortorder}
              onChange={event =>
                this.setState({ sortorder: event.target.value })
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  
  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className="row rowBottom">
          <div className="col-md-8 col-sm-12 noPadding noMargin">
            <form className="splitFrmDiv ">
              {this._renderMainFormDetails()}
            </form>
            <form
              className="splitFrmDiv text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderContactInformation()}
            </form>
            <form
              className="splitFrmDiv text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderImageDetails()}
            </form>
          </div>
          <div className="col-md-3 col-sm-12 doubleCol">
            <form className="splitFrmDivSec text-center"
            >
              {this._renderStatusDetails()}
            </form>
            <form
              className="splitFrmDivSec text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderGeneralInformation()}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  };
  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };

  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode()
                  ? "Edit Store"
                  : "Store Information"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              {this._renderErrorMessage()}
              {this._renderFormArea()}
            </div>
            <div className="row btn-container form-button">
              <div className="col-sm-12 col-lg-12">
                {this._renderMainButton()}
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/store-information"
            children={
              <div style={{ padding: "2em", color: "green" }}>
                {this._isEditMode()
                  ? "Store Information updated successfully"
                  : "Store Information created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddStoreInformation);

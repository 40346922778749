import React from 'react';
import { connect } from 'react-redux';
import { produce } from 'immer';
import moment from 'moment';
import {
    crudReset,
    updateItem,
    searchGeneral,
    storeProductSearchByFields,
    queryCategoryTree,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import AddOn from './AddOn';
import StatusComp from '../../../components/StatusComp';
import { renderTaxValues, formatDate } from '../../../helpers/Util';
import Filter from '../../../components/Filter';
import DynamicDatePicker from '../../../components/DynamicDatePicker';
import * as CONSTANTS from '../../../constants/constants';

class StoreProductMappingUpdate extends React.PureComponent {
    constructor(props) {
        super(props);

        this.specialdiscounts = {
            SC: 'Senior Citizen',
            PWD: 'PWD',
            SP: 'SP',
            NAAC: 'NAAC',
        };

        this.specialdiscounttypes = Object.keys(this.specialdiscounts);

        this.state = {
            id: '',
            formError: false,
            formErrorMsg: '',
            //isEdit: false,
            productcode: '',
            barcode: '',
            categorycodes: '',
            title: '',
            qrcode: '',
            baseprice: 0,
            taxcategory: '',
            promoinfo: '',
            remark: '',
            status: 'New',
            storeid: '',
            producttag: '',
            recommendedpcodes: '',
            searchkeys: '',
            similarpcodes: '',
            sortorder: '',
            membersegment: '',
            showAddonPage: false,
            addonitems: 0,
            qtylimit: 0,
            storeproductid: '',
            title: '',
            tiers: '',
            sku: '',
            sortordererror: '',
            tiererror: '',
            storeproductpriceerror: '',

            storeproductprices: [
                {
                    id: 0,
                    validfrom: '',
                    validtill: '',
                    segments: '',
                    baseprice: '',
                    idx: -1,
                    rec: {},
                },
            ],
            deletedStoreProductPrices: [],

            tablecols: [
                { dataFieldId: 'segments', label: 'Segment', addRow: true },
                { dataFieldId: 'baseprice', label: 'Price', addRow: true },
                { dataFieldId: 'validfrom', label: 'Valid From', addRow: true },
                { dataFieldId: 'validtill', label: 'Valid Till', addRow: true },
                { dataFieldId: 'AD', label: '' },
            ],

            specialDiscounts: {},
            specialDiscount: {},
            specialdiscounts: [],
        };
    }

    toDate = (dateStr) => {
        let totalStr = dateStr.split(' ');
        if (totalStr.length > 1) {
            var st = totalStr[0];
            var parts = st.split('-');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        } else {
            var parts = dateStr.split('-');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
    };

    setTier = (value) => {
        this.setState({
            tiers: value,
        });
    };

    setUpdateFields = () => {
        const rec = this.props.data;
        const { storeid } = rec;
        const {
            id,
            productcode,
            barcode,
            categorycodes,
            qrcode,
            baseprice,
            promoinfo,
            remark,
            status,
            producttag,
            recommendedpcodes,
            searchkeys,
            similarpcodes,
            sortorder,
            storeproductaddonids,
            additionalfields,
            addonitems,
            taxcategory,
            segments,
            qtylimit,
            sku,
            specialdiscounts,
        } = rec;

        this.setState(
            produce((draft) => {
                draft.id = id ? id : '';
                draft.productcode = productcode ? productcode : '';
                draft.barcode = barcode ? barcode : '';
                draft.categorycodes = categorycodes ? categorycodes : [];
                draft.title = additionalfields ? additionalfields.title : '';
                draft.tiers = segments ? segments.split(',') : '';
                draft.qrcode = qrcode ? qrcode : '';
                draft.baseprice = baseprice ? baseprice : '';
                draft.sku = sku ? sku : '';
                draft.promoinfo = promoinfo ? promoinfo : '';
                draft.remark = remark ? remark : '';
                draft.status = status ? status : 'New';
                draft.storeid = storeid ? storeid : '';
                draft.producttag = producttag ? producttag : '';
                draft.recommendedpcodes = recommendedpcodes
                    ? recommendedpcodes
                    : '';
                draft.searchkeys = searchkeys ? searchkeys : '';
                draft.similarpcodes = similarpcodes ? similarpcodes : '';
                draft.sortorder = sortorder ? sortorder : 0;
                draft.qtylimit = qtylimit ? qtylimit : 0;
                draft.storeproductid = id ? id : '';
                draft.taxcategory = taxcategory ? taxcategory : 'TX0';
                draft.storeproductaddonids = storeproductaddonids
                    ? storeproductaddonids
                    : 0;
                draft.title =
                    (additionalfields &&
                        additionalfields.title &&
                        additionalfields.title) ||
                    '';
                draft.addonitems = addonitems || 0;
                if (window.config.SPECIAL_DISCOUNTS_ENABLED) {
                    let _specialdiscounts = specialdiscounts.trim();
                    draft.specialdiscounts =
                        _specialdiscounts !== undefined &&
                        _specialdiscounts !== ''
                            ? _specialdiscounts.split(',')
                            : [];
                }
            })
        );
    };

    

    componentWillMount = () => {
        this.props.dispatch(
            searchGeneral(
                '/sales/v1/category/search',
                {
                    body: {
                        search_field: 'catgroup',
                        search_condi: 'eq',
                        search_value: 'TAX',
                    },
                },
                'TAX_VALUE_SEARCH_SUCCESS',
                'TAX_VALUE_SEARCH_FAILURE'
            )
        );
        this.setUpdateFields();
        if (this.props.data) {
            const query = `storeid=${this.props.data.storeid}&status=Active&productcode=${this.props.data.productcode}`;
            this.props.dispatch(storeProductSearchByFields(query));
        }

        if (window.config.SPECIAL_DISCOUNTS_ENABLED) {
            this.props.dispatch(
                queryCategoryTree(
                    '',
                    'SPECIALDISCOUNT',
                    '',
                    CONSTANTS.SPECIAL_DISCOUNT_TREE_SEARCH,
                    CONSTANTS.SPECIAL_DISCOUNT_SEARCH_SUCCESS,
                    CONSTANTS.SPECIAL_DISCOUNT_SEARCH_FAILURE
                )
            );
        }
    };

    componentDidUpdate = (prevProps) => {
        if (
            prevProps.queryData.storeproducts !==
            this.props.queryData.storeproducts
        ) {
            const { storeproducts } = this.props.queryData;

            let storeproductprices = [];
            storeproducts.forEach((e, i) => {
                let obj = {
                    id: /*e.id*/ i,
                    baseprice: e.baseprice,
                    validfrom: this.toDate(e.validfrom),
                    validtill: this.toDate(e.validtill),
                    segments: e.segments,
                    idx: e.idx,
                    rec: e,
                };
                storeproductprices.push(obj);
            });
            this.setState({ storeproductprices });
        }

        const { specialDiscounts } = this.props.queryData;
        const { specialDiscounts: prevspecialDiscounts } = prevProps.queryData;

        if (specialDiscounts !== prevspecialDiscounts) {
            let _specialDiscounts = {};
            let _specialDiscount = {};
            if (
                specialDiscounts === undefined ||
                specialDiscounts['subcategories'] === undefined ||
                specialDiscounts['subcategories'].length <= 0
            ) {
            } else {
                specialDiscounts.subcategories
                    .filter(
                        (e) =>
                            e.catgroup !== undefined &&
                            this.specialdiscounttypes.includes(e.catgroup)
                    )
                    .forEach((e) => {
                        let existing = [];
                        if (_specialDiscounts[[e.catgroup]] !== undefined) {
                            existing = _specialDiscounts[[e.catgroup]];
                        }

                        existing.push({
                            value: e.categorycode,
                            label: e.title,
                        });

                        _specialDiscounts[[e.catgroup]] = existing;

                        //update the specialDiscount
                        if (this._isEditMode() || this._isViewMode()) {
                            if (
                                this.state.specialdiscounts.includes(
                                    e.categorycode
                                )
                            ) {
                                _specialDiscount[[e.catgroup]] = e.categorycode;
                            }
                        }
                    });
            }
            this.setState({
                specialDiscounts: _specialDiscounts,
                specialDiscount: _specialDiscount,
            });
        }
    };

    componentWillReceiveProps = () => {
        const { specialDiscounts } = this.props.queryData;

        let _specialDiscounts = {};
        let _specialDiscount = {};
        if (
            specialDiscounts === undefined ||
            specialDiscounts['subcategories'] === undefined ||
            specialDiscounts['subcategories'].length <= 0
        ) {
        } else {
            specialDiscounts.subcategories
                .filter(
                    (e) =>
                        e.catgroup !== undefined &&
                        this.specialdiscounttypes.includes(e.catgroup)
                )
                .forEach((e) => {
                    let existing = [];
                    if (_specialDiscounts[[e.catgroup]] !== undefined) {
                        existing = _specialDiscounts[[e.catgroup]];
                    }

                    existing.push({ value: e.categorycode, label: e.title });

                    _specialDiscounts[[e.catgroup]] = existing;

                    //update the specialDiscount
                    if (this._isEditMode()) {
                        if (
                            this.state.specialdiscounts.includes(e.categorycode)
                        ) {
                            _specialDiscount[[e.catgroup]] = e.categorycode;
                        }
                    }
                });
        }

        this.setState({
            specialDiscounts: _specialDiscounts,
            specialDiscount: _specialDiscount,
        });
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    getCreatePayload = () => {
        if (this.state.storeproductprices.length > 0) {
            let rec = this.state.storeproductprices.map((e) => {
                const { title } = this.state;
                const { idx, segments, baseprice, validfrom, validtill, rec } =
                    e;

                const {
                    id,
                    productcode,
                    barcode,
                    categorycodes,
                    //status,
                    qrcode,
                    promoinfo,
                    remark,
                    storeid,
                    producttag,
                    recommendedpcodes,
                    searchkeys,
                    similarpcodes,
                    qtylimit,
                    sku,
                } = rec;

                const { sortorder, specialDiscount, taxcategory, status } = this.state;

                let body = {
                    // id,
                    productcode,
                    storeid,
                    barcode,
                    qrcode,
                    taxcategory,
                    categorycodes,
                    baseprice,
                    promoinfo,
                    producttag,
                    searchkeys,
                    similarpcodes,
                    sortorder,
                    recommendedpcodes,
                    title,
                    remark,
                    status,
                    qtylimit,
                    sku,
                    validfrom: formatDate(validfrom) + ' ' + '00:00',
                    validtill: formatDate(validtill) + ' ' + '00:00',
                    segments /*: tiers.toString()*/,
                    //  addonitems,
                    additionalfields: {
                        title,
                    },
                    specialdiscounts: window.config.SPECIAL_DISCOUNTS_ENABLED
                        ? Object.values(specialDiscount).length > 0
                            ? Object.values(specialDiscount).join(',')
                            : ' '
                        : null,
                };

                if (idx !== -1) {
                    body['id'] = id;
                    body['idx'] = idx;
                }

                return body;
            });

            if (this.state.deletedStoreProductPrices.length > 0) {
                //check the difference
                const { storeproducts } = this.props.queryData;
                this.state.deletedStoreProductPrices.forEach((deletedR) => {
                    let deletedRec = storeproducts.filter(
                        (e) => e.id === deletedR
                    )[0];
                    deletedRec['status'] = 'Deleted';
                    rec.push(deletedRec);
                });
            }
            const payload = {
                body: rec,
            };
            return payload;
        } else {
            const {
                id,
                productcode,
                barcode,
                categorycodes,
                title,
                status,
                qrcode,
                baseprice,
                promoinfo,
                remark,
                storeid,
                producttag,
                recommendedpcodes,
                searchkeys,
                similarpcodes,
                sortorder,
                qtylimit,
                // addonitems,
                taxcategory,
                tiers,
                sku,
                specialDiscount,
            } = this.state;

            let body = {
                id,
                productcode,
                storeid,
                barcode,
                qrcode,
                taxcategory,
                categorycodes,
                baseprice,
                promoinfo,
                producttag,
                searchkeys,
                similarpcodes,
                sortorder,
                recommendedpcodes,
                title,
                remark,
                status,
                qtylimit,
                sku,
                segments: tiers.toString(),
                //  addonitems,
                additionalfields: {
                    title,
                },
                specialdiscounts: window.config.SPECIAL_DISCOUNTS_ENABLED
                    ? Object.values(specialDiscount).length > 0
                        ? Object.values(specialDiscount).join(',')
                        : ' '
                    : null,
            };

            const payload = {
                body: [body],
            };
            return payload;
        }
    };

    _updateProduct = () => {
        if (this.validateForm()) {
          this.props.dispatch(
              updateItem(
                  'sales/v1/store/product/update/batch',
                  this.getCreatePayload(),
                  -1,
                  'POST'
              )
          );
      }
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            sortordererror: '',
            tiererror: '',
            storeproductpriceerror: '',
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const { sortorder, tiers } = this.state;
        let valid = true;
        if (sortorder === '') {
            this.setState({ sortordererror: 'sortorder is required.' });
            valid = false;
        }
        if (tiers && tiers.length === 0) {
            this.setState({ tiererror: 'Select a tier' });
            valid = false;
        }

        this.state.storeproductprices.every((e) => {
            if (e.baseprice === '') {
                this.setState({
                    storeproductpriceerror: 'base price is not set',
                });
                valid = false;
                return valid;
            }
            if (e.validfrom === '') {
                this.setState({
                    storeproductpriceerror: 'validfrom is not set',
                });
                valid = false;
                return valid;
            }
            if (e.validtill === '') {
                this.setState({
                    storeproductpriceerror: 'validtill is not set',
                });
                valid = false;
                return valid;
            }
            if (e.segments === '') {
                this.setState({
                    storeproductpriceerror: 'segments is not set',
                });
                valid = false;
                return valid;
            }
            return true;
        });

        return valid;
    };

    _isEditMode = () => {
        return this.props.isEdit;
    };

    _isViewMode = () => {
        return this.props.isView;
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <React.Fragment>
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                        onClick={() => {
                            this.props.updateForm(false);
                        }}
                    >
                        Close
                    </button>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={() => this._updateProduct()}
                >
                    Save
                </button>

                <button
                    type="submit"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    onClick={() => {
                        this.props.updateForm(false);
                    }}
                >
                    Discard
                </button>
            </React.Fragment>
        );
    };

    handleDeleteRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.storeproductprices.length;
        if (rowLength !== 1) {
            const updateValues = this.state.storeproductprices.filter(
                (value) => rowId !== value.id
            );
            this.setState({ storeproductprices: updateValues });
            if (row.idx !== -1) {
                this.setState({
                    deletedStoreProductPrices: [
                        ...this.state.deletedStoreProductPrices,
                        row.rec.id,
                    ],
                });
            }
        } else {
            this.setState({
                storeproductprices: [
                    {
                        validfrom: '',
                        validtill: '',
                        segments: '',
                        baseprice: '',
                        id: 0,
                        idx: -1,
                        rec: {},
                    },
                ],
            });
        }
    };

    handleAddRow = (e) => {
        if (e) e.preventDefault();
        const rowLength = this.state.storeproductprices.length;
        let lastele = this.state.storeproductprices[rowLength - 1];
        const item = {
            validfrom: '',
            validtill: '',
            baseprice: '',
            segments: '',
            id: lastele.id + 1,
            idx: -1,
            rec: lastele.rec,
        };
        this.setState(
            {
                storeproductprices: [...this.state.storeproductprices, item],
            },
            () => {
                console.log('Rows After Add', this.state.storeproductprices);
            }
        );
    };

    handleInputChange = (dataField, value, rowId) => {
        let obj = {
            id: rowId,
            [dataField]: dataField === 'segments' ? value.toString() : value,
        };

        this.setState(
            produce((draft) => {
                if (draft.storeproductprices.length === 0) {
                    draft.storeproductprices.push(obj);
                } else {
                    let found = false;
                    draft.storeproductprices.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.storeproductprices.push(obj);
                    }
                }
            }),
            () => {}
        );
    };

    handleDatePicker = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]:
                dataField === 'validfrom' || dataField === 'validtill'
                    ? moment(event, 'DD-MM-YYYY', true).toDate()
                    : moment(event, 'HH:mm'),
        };
        this.setState(
            produce((draft) => {
                if (draft.storeproductprices.length === 0) {
                    draft.storeproductprices.push(obj);
                } else {
                    let found = false;
                    draft.storeproductprices.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.storeproductprices.push(obj);
                    }
                }
            }),
            () => {}
        );
    };

    _renderProdPriceDetails = () => {
        return (
            <>
                <div className="row sub-title">Price Details</div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <DynamicDatePicker
                            columns={this.state.tablecols}
                            rows={this.state.storeproductprices}
                            deleteRow={this.handleDeleteRow}
                            addRow={this.handleAddRow}
                            handleDatePicker={this.handleDatePicker}
                            handleInputChange={this.handleInputChange}
                        />
                    </div>
                    <div className="invalid-feedback">
                        {this.state.storeproductpriceerror}
                    </div>
                </div>
            </>
        );
    };

    handleSpecialDiscountsSelection = (e, group) => {
        if (e.target.value === 'none') {
            let specialDiscount = { ...this.state.specialDiscount };
            delete specialDiscount[[group]];
            this.setState({ specialDiscount });
            return;
        }

        let specialDiscount = { ...this.state.specialDiscount };
        specialDiscount[[group]] = e.target.value;
        this.setState({ specialDiscount });
    };

    _renderSpecialDiscDetails = () => {
        if (!window.config.SPECIAL_DISCOUNTS_ENABLED) {
            return <></>;
        }
        return (
            <React.Fragment>
                <div className="row sub-title">Special Discount Details</div>

                {Object.keys(this.state.specialDiscounts).map((e, i) => (
                    <div key={i}>
                        <div className="row">
                            <div className="form-group col-lg-12 col-sm-12">
                                <label htmlFor={e} className="floatLeft">
                                    {this.specialdiscounts[[e]]}
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-2 col-sm-12">
                                <label htmlFor="none" className="floatLeft">
                                    <input
                                        type="radio"
                                        value="none"
                                        onChange={(event) =>
                                            this.handleSpecialDiscountsSelection(
                                                event,
                                                e
                                            )
                                        }
                                        checked={
                                            this.state.specialDiscount[[e]] ===
                                            undefined
                                        }
                                        disabled={this._isViewMode()}
                                    />
                                    None
                                </label>
                            </div>
                            {this.state.specialDiscounts[[e]].length > 0 &&
                                this.state.specialDiscounts[[e]].map(
                                    (disc, i) => (
                                        <div
                                            className="form-group col-lg-2 col-sm-12"
                                            key={i}
                                        >
                                            <label
                                                htmlFor={disc.value}
                                                className="floatLeft"
                                            >
                                                <input
                                                    type="radio"
                                                    value={disc.value}
                                                    onChange={(event) =>
                                                        this.handleSpecialDiscountsSelection(
                                                            event,
                                                            e
                                                        )
                                                    }
                                                    checked={
                                                        this.state
                                                            .specialDiscount[
                                                            [e]
                                                        ] === disc.value
                                                    }
                                                    disabled={this._isViewMode()}
                                                />
                                                {disc.label}
                                            </label>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>
                ))}
            </React.Fragment>
        );
    };

    _renderProdGeneralDetails = () => {
        const { taxValue } = this.props.queryData;
        let taxvalues = [];
        taxvalues = renderTaxValues(taxValue);
        return (
            <React.Fragment>
                <div className="row sub-title">Basic Details</div>
                <div className="row basic">
                    <div className="form-group col-lg-4 col-sm-12 noPadding">
                        <label htmlFor="title" className="floatLeft">
                            <b> Product Code</b>
                        </label>
                        <div style={{ padding: '2px', fontSize: '16px' }}>
                            <b>{this.state.productcode}</b>
                        </div>
                    </div>
                    <div className="form-group col-lg-4 col-sm-12 noPadding">
                        <label htmlFor="title" className="floatLeft">
                            <b> Product Title</b>
                        </label>
                        <div style={{ padding: '2px', fontSize: '16px' }}>
                            <b>{this.state.title}</b>
                        </div>
                    </div>

                    <div className="form-group col-lg-4 col-sm-12 noPadding">
                        <label htmlFor="categorycodes" className="floatLeft">
                            <b>Category Code</b>
                        </label>
                        <div style={{ padding: '2px', fontSize: '16px' }}>
                            <b>{this.state.categorycodes}</b>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>

                        <StatusComp
                            selectedOption={this.state.status}
                            callbackChangeFilter={(v) => {
                                this.setState({ status: v });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="qrcode" className="floatLeft">
                            QRcode
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="qrcode"
                            value={this.state.qrcode}
                            onChange={(event) => {
                                this.setState({ qrcode: event.target.value });
                            }}
                        />
                    </div>

                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="sku" className="floatLeft">
                            SKU
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="sku"
                            value={this.state.sku}
                            onChange={(event) => {
                                this.setState({ sku: event.target.value });
                            }}
                        />
                    </div>

                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="barcode" className="floatLeft">
                            Barcode
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="barcode"
                            value={this.state.barcode}
                            onChange={(event) => {
                                this.setState({ barcode: event.target.value });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="producttag" className="floatLeft">
                            Product Tag
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="producttag"
                            value={this.state.producttag}
                            onChange={(event) => {
                                this.setState({
                                    producttag: event.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="taxcategory" className="floatLeft">
                            Tax Value
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={taxvalues}
                            selectedOption={this.state.taxcategory}
                            callbackChangeFilter={(event) => {
                                this.setState({
                                    taxcategory: event.target.value,
                                });
                            }}
                            id="taxcategory"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label
                            htmlFor="recommendedpcodes"
                            className="floatLeft"
                        >
                            Recommended Products
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="recommendedpcodes"
                            value={this.state.recommendedpcodes}
                            onChange={(event) => {
                                this.setState({
                                    recommendedpcodes: event.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="searchkeys" className="floatLeft">
                            Search Keys
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="searchkeys"
                            value={this.state.searchkeys}
                            onChange={(event) => {
                                this.setState({
                                    searchkeys: event.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="similarpcodes" className="floatLeft">
                            Similar Product Codes
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="similarpcodes"
                            value={this.state.similarpcodes}
                            onChange={(event) => {
                                this.setState({
                                    similarpcodes: event.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="qtylimit" className="floatLeft">
                            Quantity Limit By
                        </label>
                        <input
                            className="form-control"
                            id="qtylimit"
                            type="number"
                            value={this.state.qtylimit}
                            onChange={(event) => {
                                this.setState({ qtylimit: event.target.value });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label
                            htmlFor="sortorder"
                            className="floatLeft required"
                        >
                            Sortorder
                        </label>
                        <input
                            className="form-control"
                            id="sortorder"
                            type="number"
                            min="0"
                            value={this.state.sortorder}
                            onChange={(event) => {
                                this.setState({
                                    sortorder: event.target.value,
                                });
                            }}
                        />
                        <div className="invalid-feedback">
                            {this.state.sortordererror}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderAddOn = () => {
        const { addonitems } = this.state;
        return (
            <React.Fragment>
                <div className="row sub-title">Add On</div>
                <div className="row" style={{ border: '0.5px solid #f6f6f6' }}>
                    <div className="form-control addondivstyle">
                        <p
                            className="addonbtn"
                            onClick={this._showAddOn}
                        >{`${addonitems} items added`}</p>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.updateForm(false);
    };
    _showAddOn = () => {
        this.setState({ showAddonPage: true });
    };
    _hideAddON = () => {
        this.setState({ showAddonPage: false });
    };
    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv text-center ">
                            {this._renderProdGeneralDetails()}
                            {this._renderProdPriceDetails()}
                            {this._renderSpecialDiscDetails()}
                        </form>
                    </div>
                    <div className="col-md-3 col-sm-12 doubleCol">
                        <form className="splitFrmDivSec text-center">
                            {this._renderAddOn()}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    updateAddOnCount = () => {
        //this.setState({ addonitems: count });
    };

    render() {
        const { isSuccess } = this.props.crudData;
        const { storeid, productcode, storeproductid, addonitems } = this.state;
        if (this.state.showAddonPage === true) {
            return (
                <AddOn
                    updateAddon={this._hideAddON}
                    updateaddoncount={this.updateAddOnCount}
                    storeid={storeid}
                    productcode={productcode}
                    storeproductid={storeproductid}
                    addonitems={addonitems}
                    isView={this.props.isView}
                ></AddOn>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="form_height">
                        <div className="row">
                            <div className="col-md-9">
                                <h2 className="page-header">
                                    {this.state.title}
                                </h2>
                            </div>
                        </div>
                        <div className="row form-container">
                            <div className="col">
                                {this._renderErrorMessage()}

                                {this._renderFormArea()}

                                <div className="row btn-container form-button">
                                    <div className="col-12">
                                        {this._renderMainButton()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ConfirmAlert
                            show={isSuccess}
                            handleClose={this.hideModal}
                            to="/ui/map-store-product"
                            children={
                                <div className="alert alert-success">
                                    Mapping updated successfully
                                </div>
                            }
                        />
                    </div>
                </React.Fragment>
            );
        }
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(StoreProductMappingUpdate);

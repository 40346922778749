import produce from 'immer';
import React from 'react'
import DatePicker from 'react-date-picker';
import { connect } from 'react-redux';
import { crudReset, exportReport, searchGeneral, storeTerminalsSearchByFields } from '../../actions/actions';
import ConfirmAlert from '../../components/ConfirmAlert'
import SimpleTable from '../../components/SimpleTable';
import { filterFormatter, formatDate, permissions } from '../../helpers/Util';
import * as CONSTANTS from '../../constants/constants';
import StoreSearchSingle from '../system/users/StoreSearchSingle';
import SimpleSelect from '../../components/SimpleSelect';

class BIRTermSalesSummary extends React.PureComponent {

  constructor(props) {
    super(props);

    this.companyId = localStorage.getItem("company_id")
    this.module = "REPORTS"
    this.group = "DE_REPORTS_BIR_TERM_SALES_SUMMARY"
    this.allPermissions = []

    this.requestBody = {} //current one
    this.currPage = 1
    this.pageSize = 10

    this.state = {
      currPage: this.currPage,
      pageSize: this.pageSize,
      
      
      language: "en", //default
      langlist: {},
      terminals: [{name: "Select", id: ''}],

      storeid: '',
      terminalid: '',
      trxdate: '',
      
      message: "",
      action: '',
      date_from: new Date(),
      date_till: new Date(),
      tableCols: [
        // {
        //   dataField: "tasktype",
        //   text: "Task Type",
        //   sort: true
        // },
        {
          dataField: "title",
          text: "Path",
          sort: true
        },
        // {
        //   dataField: "id",
        //   text: "Id",
        //   sort: true
        // },
        {
          dataField: "status",
          text: "Status",
          sort: true
        },
        {
          dataField: "additionalfields",
          text: "Filters",
          sort: false,
          formatter: this._filterFormatter
        },
        // {
        //   dataField: "memberid",
        //   text: "Member Id",
        //   sort: true
        // },
        // {
        //   dataField: "storeid",
        //   text: "Store Id",
        //   sort: true
        // },
        {
          dataField: "createdon",
          text: "Exported On",
          sort: true
        },
        {
          dataField: "lastupdatedby",
          text: "Generated by",
          sort: true
        },
        {
          dataField: "",
          text: "Actions",
          formatter: this.cellFormatter,
          formatExtraData: {
            action: "ED"
          },
          classes: () => {
            return "lastTd";
          }
        }
      ],

      
      
      pagesize: this.pageSize,

      storeiderror: '',
      terminaliderror: '',
      datefromerror: '',
      datetillerror: '',
      languageerror: ''
    };
  }

  _filterFormatter = (cell, row, rowIndex, formatExtraData) => {
    return filterFormatter(cell, row, rowIndex, formatExtraData, ['pagesize', 'pageno', 'filepath', 'report'])
  }

  cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.status !== 'exported' ) { return }
    if (row.additionalfields["filepath"] === undefined) { return }
    return (
      <span>
          <img
            title="Download"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/list_download.svg`}
            onClick={() => this.download(row)}
          />
      </span>
    );
  };

  download = (row) => {
    var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          //var url =  localStorage.getItem('imagepath') + "/media" + row.title;
          var url =  localStorage.getItem('imagepath') + row.additionalfields["filepath"];
          console.log('url::', url)
          link.setAttribute("href", url);

          var exportedFilenmae = row.tasktype + '.csv' || 'export.csv';
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
  }

  // API Calls
  createFetchUrl = fetchType => {
    const {
      pageSize,
      currPage
    } = this.state;

    if (fetchType === 'ONLOAD') {
      const requestBody = {
        body: {
          pagesize: pageSize,
          pageno: currPage,
          tasktype: this.getTaskType()
        }
      };
      console.log("...requestBody for ONLOAD::", requestBody)
      return requestBody;
    }

    console.log('tasktype:::', this.requestBody['tasktype'])
    if (this.requestBody['tasktype'] === undefined) {
      this.requestBody['tasktype'] = this.getTaskType()
    }

    const requestBody = {
      body: { ...this.requestBody, pagesize: pageSize, pageno: currPage }
    };
    
    console.log("...requestBody::", requestBody)
    return requestBody;
  };

  callFetch = fetchType => {
    this.props.dispatch(
      searchGeneral(
        "/system/v1/jobtask/search/fields",
        this.createFetchUrl(fetchType),
        CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS,
        CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE,
      )
    );
  };

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    // var newState;
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetch("ONLOAD");
        break;
      case "PAGECLICK":
        this.callFetch()
        break;
    }
  };

  componentWillMount() {

    this.setState({
      storeid: localStorage.getItem("storeid"),
      s_storelabel: localStorage.getItem("storelabel")
    }, () => {
      this.fetchStoreTerminals()
    });

    this.callQueryApiForFetch("ONLOAD")
    this.allPermissions = permissions(this.module, this.group)
  }

  componentDidUpdate = (prevProps) => {
    
    const {  storeTerminals } = this.props.queryData;
    const {  storeTerminals: prevstoreTerminals } = prevProps.queryData;

    if (storeTerminals !== prevstoreTerminals) {
      if (storeTerminals && storeTerminals.length > 0) {
        var terminals = [
          {name: "Select", id: ''}
        ]
        var returnedTerminals = storeTerminals.map(e => 
          ({name : e.terminalid, id : e.terminalid})
        )
        this.setState( { terminals: terminals.concat(returnedTerminals) })
      } else {
        this.setState( { terminals: [{name: "Select", id: ''}], })
      }
    }

  }


  componentWillReceiveProps = () => {

    const { languageConfig, storeTerminals } = this.props.queryData;

    if (languageConfig.length > 0) {
      const list = languageConfig[0].additionalfields
      this.setState({
        langlist: list
      })
    }

    if (storeTerminals && storeTerminals.length > 0) {
      var terminals = [
        {name: "Select", id: ''}
      ]
      var returnedTerminals = storeTerminals.map(e => 
        ({name : e.terminalid, id : e.terminalid})
      )
      this.setState( { terminals: terminals.concat(returnedTerminals) })
    }

  }


  hideAlertModal = e => {
    this.setState({ message: "", action: '' });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.queryData.reportexport = {}
    this.callQueryApiForFetch("ONLOAD");
  };
  
  fetchStoreTerminals = () => {
    const {
      storeid
    } = this.state

    this.props.queryData.storeTerminals = []
    if (storeid && storeid !== '') {
      const queryParams = []
      queryParams.push(`storeid=${storeid}`)
      queryParams.push(`devicegroup=terminal`)
      this.props.dispatch(
        storeTerminalsSearchByFields(queryParams.join('&'),
        CONSTANTS.SEARCH_STORE_TERMINALS_SUCCESS_SILENT,
        CONSTANTS.SEARCH_STORE_TERMINALS_FAILURE_SILENT
        ))
    }
  }

  exportReport = (e) => {
    if (e) e.preventDefault();

    const {
      storeid,
      terminalid,
      date_from,
      date_till,
      language
    } = this.state

    // this.requestBody = {}
    const queryParams = []

    // console.log('storeid::', storeid)
    if (storeid === '' || storeid === null) {
      this.setState({storeiderror: 'Please select the store'})
      return
    } else {
      this.setState({storeiderror: ''})
      // this.requestBody['storeid'] = storeid
      queryParams.push(`storeid=${storeid}`)
    }

    if (terminalid === '') {
      this.setState({terminaliderror: 'Please select the terminal'})
      return
    } else {
      this.setState({ terminaliderror: '' })
      if (terminalid !== '') {
        // this.requestBody['terminalid'] = terminalid
        queryParams.push(`terminalid=${terminalid}`)
      }
    }

    if (language === '') {
      this.setState({languageerror: 'Please select the language'})
      return
    } else {
      this.setState({languageerror: ''})
      // this.requestBody['language'] = language
      queryParams.push(`language=${language}`)
    }

    if (date_from === '') {
      this.setState({datefromerror: 'Please select the date from'})
      return
    } else {
      this.setState({datefromerror: ''})
      queryParams.push(`date_from=${formatDate(date_from)}`)
    }
    
    
    if (date_till === '') {
      this.setState({datefromerror: 'Please select the date till'})
      return
    } else {
      this.setState({datefromerror: ''})
      queryParams.push(`date_till=${formatDate(date_till)}`)
    }
 
    //query
    this.setState({message: 'Request submitted.'})
    this.props.dispatch(exportReport(this.exportReportUrl(), queryParams.join('&')))
  }

  reportTitle = () => {
      return 'Terminal Sales Summary Report'
  }

  exportReportUrl = () => {
    return '/eip/v1/dx/report/bir/terminal/salessummary';
  }

  getTaskType = () => {
      return 'bir-terminal-sales-summary-report'
  }

  _renderSearchCiteria = () => {
    console.log('_renderSearchCriteria.....')

    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-2 col-sm-12 ">
            <label htmlFor="storeid" className="floatLeft required">
              Select Store
            </label>
            <StoreSearchSingle value={this.state.s_storelabel} onSelectStore={this._onSelectStore}/>
            <div className="invalid-feedback">{this.state.storeiderror}</div>
          </div>

          <div className="form-group col-lg-2 col-sm-12">
                  <label htmlFor="fromDate" className="floatLeft required">
                    Date From
                  </label>
                  <DatePicker
                    onChange={(value)=>this.setState({date_from: value}, () => {
                      // if (this.state.date_from > this.state.date_till) {
                        //  this.setState({date_till: value})
                      //}
                    })
                    }
                    value={this.state.date_from}
                    maxDate={new Date()}
                    format="d/M/yy"
                  />
                  <div className="invalid-feedback">{this.state.datefromerror}</div>
                </div>
                <div className="form-group col-lg-2 col-sm-12">
                  <label htmlFor="toDate" className="floatLeft required">
                    Date To
                  </label>
                  <DatePicker
                    minDate={this.state.date_from}
                    onChange={(value)=>this.setState({date_till: value})}
                    value={this.state.date_till}
                    // maxDate={this.state.date_from}
                    // disabled={true}
                    format="d/M/yy"
                  />
                  <div className="invalid-feedback">{this.state.datetillerror}</div>
                </div>

          <div className="form-group col-lg-2 col-sm-12">
            <label htmlFor="terminalid" className="floatLeft required">
              Terminal Id
            </label>
            <div className="row">
              <select
                name="terminalid"
                className="simpleSearchSelect2"
                value={this.state.terminalid}
                onChange={event =>
                  this.setState({ terminalid: event.target.value })
                }
              >
                {this.state.terminals.length !== 0 &&
                  this.state.terminals.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </div>
              <div className="invalid-feedback">{this.state.terminaliderror}</div>
          </div>

          <div className="form-group col-lg-2 col-sm-12">
            <label htmlFor="language" className="floatLeft required">
              Language
            </label>
            <SimpleSelect
              objArray={this.state.langlist}
              callbackChangeFilter={value => {
                this.setState({ language: value });
              }}
              selectedOption={this.state.language}
              id="language"
            />
            <div className="invalid-feedback">{this.state.languageerror}</div>
          </div>  
          
        </div>
         
          <div className="row">

            <div className="col-lg-3 col-sm-12 btn-container1" style={{ marginTop: "1em" }}>
              <button
                type="submit"
                onClick={(e) => this.exportReport(e)}
                className="btn btn-themes btn-rounded"
              >
                Export
              </button>
           
            </div>
            </div>

            <div className="form-group col-lg-7 col-sm-3 noPadding" />
        
      </React.Fragment>
    );
  }

  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, s_storelabel: p2, search_value: p1 },
      ()=>{
      localStorage.setItem("storeid", p1);
      localStorage.setItem("storelabel", p2);

      this.fetchStoreTerminals()
    });
    this.setState({storeiderror: ''})
  };


  _onSelectStore = (selectedStores) => {
    console.log('selected stores from storesearch:;', selectedStores, this.state.storeid)
    this._setTenant(selectedStores.value, selectedStores.label)
  }

  render() {
    const {
      errormsg: queryError,
      servererror: serverError,
      jobtasklogs,
      reportexport
    } = this.props.queryData;
   
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h2 className="page-header">{this.reportTitle()}
              <img
                  title="Refresh"
                  onClick={() => {
                      this.callQueryApiForFetch("ONLOAD");
                  }}
                  className="refreshtree"
                  src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                  />
              </h2>
            </div>
          </div>

          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
              <div className="row">
                  {this._renderSearchCiteria()}
              </div>
          
                <div className="title_devide" />
                <SimpleTable
                  columns={this.state.tableCols}
                  rows={jobtasklogs}
                  pageChangeCallback={(currPage) => {
                    this.setState({
                      currPage: currPage
                    }, () => {
                      this.callQueryApiForFetch("PAGECLICK");
                    })
                  }}
                  callback={(pageSize) => {
                    this.setState(produce(this.state, draft => {
                      if (draft.pageSize < pageSize) {
                        draft.currPage = 1
                      }
                      draft.pageSize = pageSize
                    }), () => {
                      this.callQueryApiForFetch("PAGECLICK")
                    })
                  }}
                  
                  withPageSize={this.state.pageSize}
                  withCurrPage={this.state.currPage}
                  withPageFeedback={true}
                />
                
              </form>
            </div>
          </div>
          <ConfirmAlert
            show={(reportexport && reportexport.status === 'exporting') ? true : false}
            handleClose={this.hideAlertModal}
            children={
              <div
                style={
                  (reportexport && reportexport.status === 'exporting')
                    ? { padding: "2em", color: "green" }
                    : { padding: "2em", color: "red" }
                }
              >
                {(reportexport && reportexport.status === 'exporting') ? this.state.message : ""}
              </div>
            }
          />

         
        </div>
       
      </React.Fragment>
    );

};

  _searchJobTaskLogs = (e) => {
    if (e) { e.preventDefault() }
    console.log('_search Logs')
    
    console.log('state::', this.state)

    this.requestBody = {}
    const queryParams = []

    queryParams.push(`tasktype=${this.getTaskType()}`)
    this.requestBody["tasktype"] = this.getTaskType()

    queryParams.push(`pagesize=${this.pageSize}`)
    queryParams.push(`pageno=${this.currPage}`)

    console.log('requstBody::', this.requestBody)
    //query
    // this.props.dispatch(exportedCouponTransactions(queryParams.join('&')))
  }

  _clearFiltersAndResults = (e) => {
    if (e) { e.preventDefault() }
    //clear all the filters
    this.setState({
      
      id: '',
      memberid: "",
      storeid: "",
      status: '',
      title: "",
      couponcode: '',

      storeiderror: '',
      couponcodeerror: ''
    })
    //clear the search results
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(BIRTermSalesSummary);